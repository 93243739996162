import React, { useContext } from "react";
import "./styles/SectionOne.css";
import { context } from "./Root";

export default function SectionOne() {
  const langContext = useContext(context);
  return (
    <>
      <section className="section-one-bg">
        <div className="text-container">
          <h2
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }
            }
          >
            {langContext.lang === "EN"
              ? "BEST OFFERS"
              : "საუკეთესო შეთავაზებები"}
          </h2>
          <h2
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }
            }
          >
            {langContext.lang === "EN"
              ? "WITH WINE PASS"
              : "ღვინის პასპორტთან ერთად"}
          </h2>
        </div>
      </section>
      <section className="section-one-bg-mob">
        <div className="text-container">
          <h2
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }
            }
          >
            {langContext.lang === "EN"
              ? "BEST OFFERS"
              : "საუკეთესო შეთავაზებები"}
          </h2>
          <h2
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }
            }
          >
            {langContext.lang === "EN"
              ? "WITH WINE PASS"
              : "ღვინის პასპორტთან ერთად"}
          </h2>
        </div>
      </section>
    </>
  );
}
