import React, { useContext } from "react";
import "../components/styles/AboutUs.css";
import { context } from "./Root";

export default function AboutBg({ title }) {
  const langContext = useContext(context);
  return (
    <>
      <div className="aboutUs-bg">
        <div className="text-container">
          <h2
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }
            }
          >
            {title}
          </h2>
        </div>
      </div>
      <section className="section-one-bg-mob-about-us">
        <div className="text-container">
          <h2
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }
            }
            className="text-container-mob"
          >
            {title}
          </h2>
        </div>
      </section>
    </>
  );
}
