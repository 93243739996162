import React, { useContext } from "react";
import "./styles/WInePassBenefit.css";
import { context } from "./Root";

export default function Benefit({ image, info, title }) {
  const langContext = useContext(context);
  return (
    <div className="benefit-wrapper">
      <div className="image-radius">
        <img src={image} alt={image} />
      </div>
      <h4
        className="benefit-title"
        style={
          langContext.lang !== "EN"
            ? { fontFamily: "FiraGo" }
            : { fontFamily: "Poppins" }
        }
      >
        {title}
      </h4>
      <p
        className="benefit-info"
        style={
          langContext.lang !== "EN"
            ? { fontFamily: "FiraGo" }
            : { fontFamily: "Open Sans" }
        }
      >
        {info}
      </p>
    </div>
  );
}
