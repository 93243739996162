import React, { useContext } from "react";
import "./styles/AboutPassport.css";
// import PriorityWinePass from "./PriorityWinePass";
import { motion } from "framer-motion/dist/framer-motion";
import { useMediaQuery } from "@mui/material";
import winePassImg from "../images/winepassport/winepassImg.png";
import { Link } from "react-router-dom";
import { context } from "./Root";

export default function AboutWinePassport() {
  const langContext = useContext(context);

  const isDesktop = useMediaQuery("(min-width: 768px)");
  const variants = isDesktop
    ? {
        initial: { opacity: 0 },
        whileInView: { opacity: 1 },
      }
    : {
        initial: { opacity: 0 },
        whileInView: { opacity: 1 },
      };

  return (
    <>
      <motion.section
        className="passport-container"
        variants={variants}
        initial={variants.initial}
        whileInView={variants.whileInView}
        transition={{ duration: 1.5, ease: "easeOut", delay: 0.5 }}
      >
        <div className="passport-wrapper">
          <motion.div
            className="passport-left-block"
            variants={variants}
            initial={variants.initial}
            whileInView={variants.whileInView}
            transition={{ duration: 2, delay: 1 }}
          >
            <h5
              className="passport-left-block-title"
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "BPG Nino Mtavruli" }
                  : { fontFamily: "Poppins" }
              }
            >
              {langContext.lang === "EN"
                ? "Exclusive offers"
                : "ექსკლუზიური შეთავაზებები"}
            </h5>
            <h4
              className="passport-left-block-subtitle"
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "BPG Nino Mtavruli" }
                  : { fontFamily: "Montserrat" }
              }
            >
              {langContext.lang === "EN"
                ? "WINE TASTING DONE SMARTER"
                : "დააგემოვნე ღვინო გონივრულად"}
            </h4>
            <p
              className="passport-left-block-paragraph"
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Poppins" }
              }
            >
              {langContext.lang === "EN"
                ? " A Georgian Wine Passport is a program that provides you with exclusive access to wineries and tasting rooms. It's a ticket to explore a variety of wines, enjoy special discounts, and document your visits to different wine venues. Wine Passport comes in physical or digital formats and is your guide to a world of wine experiences. It's a way to immerse yourself in the charm of Georgian winemaking and collect cherished memories along the way.Subscribe today to start your journey through the captivating world of Georgian wines. Cheers to new discoveries!"
                : "ქართული ღვინის პასპორტი არის პროგრამა, რომელიც გაძლევთ ექსკლუზიურ წვდომას ღვინის ქარხნებში და სადეგუსტაციო ოთახებში. ეს არის ბილეთი, რათა შეისწავლოთ სხვადასხვა ღვინოები, ისარგებლოთ სპეციალური ფასდაკლებით და დააფიქსიროთ თქვენი ვიზიტები სხვადასხვა ღვინის ობიექტებში. ღვინის პასპორტი მოდის ფიზიკურ ან ციფრულ ფორმატში და არის თქვენი მეგზური ღვინის გამოცდილების სამყაროში. ეს არის გზა ქართული მეღვინეობის ხიბლში ჩაძირვისა და გზად საყვარელი მოგონებების დასაგროვებლად. გამოიწერეთ დღესვე, რათა დაიწყოთ თქვენი მოგზაურობა ქართული ღვინის მომხიბვლელ სამყაროში. გილოცავთ ახალ აღმოჩენებს!"}
            </p>
            <Link
              to={"/faq"}
              className="passport-left-block-span"
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Lato" }
              }
            >
              {langContext.lang === "EN"
                ? "Frequently Asked Questions"
                : "ხშირად დასმული კითხვები"}
            </Link>
          </motion.div>
          <motion.div
            // className="passport-right-block"
            variants={variants}
            initial={variants.initial}
            whileInView={variants.whileInView}
            transition={{ duration: 2, delay: 1 }}
          >
            <img
              className="winepass-image"
              src={winePassImg}
              alt="wine-passport"
            />
          </motion.div>
        </div>
      </motion.section>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button className="buy-now-btn-mob">BUY NOW</button>
      </div>
    </>
  );
}
