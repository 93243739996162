import React, { useEffect } from "react";
import "./styles/Sort.css";

export default function Sort({
  title,
  arrowDownImage,
  arrowUp,
  color,
  setFilter,
  number,
  filter,
  setFilterRegion,
  filterRegion,
}) {
  useEffect(() => {
    const handleWindowClick = () => {
      setFilter(null);
    };

    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);
  const handleClick = (e) => {
    e.stopPropagation();
    setFilter(number);
    if (filter === number) {
      setFilter(null);
      setFilterRegion(filterRegion || "");
    }
  };
  return (
    <div
      onClick={handleClick}
      className="sort-tab-container"
      style={filter === number ? { borderBottom: "2px solid #000000" } : null}
    >
      <span style={{ color: color }} className="tab-span">
        {title}
      </span>
      {filter === number ? (
        <img src={arrowUp} alt={title} />
      ) : (
        <img src={arrowDownImage} alt={title} />
      )}
    </div>
  );
}
