import React, { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";
import "./styles/MapSlider.css";
import LocationGoogleMaps from "./LocationGoogleMaps";
// import { mapSlider } from "./Data";
import georgia from "../images/maploc/georgia.svg";
import abkhazia from "../images/maploc/abkhazia.svg";
import adjara from "../images/maploc/Adjara.svg";
import guria from "../images/maploc/guria.svg";
import imereti from "../images/maploc/imereti.svg";
import racha from "../images/maploc/racha.svg";
import kakheti from "../images/maploc/kakheti.svg";
import kartli from "../images/maploc/kartli.svg";
import mtskheta from "../images/maploc/mtskheta-mtianeti.svg";
import samegrelo from "../images/maploc/samegrelo.svg";
import { context } from "./Root";

export default function MapSlider({
  click,
  setClick,
  selected,
  setSelected,
  filteredData,
  georgiaWineries,
  dataForRegions,
}) {
  const langContext = useContext(context);
  // const [click, setClick] = useState(0);
  // const [selected, setSelected] = useState(null);
  const mapSlider = [
    {
      id: 1,
      mapImg: georgia,
      region: langContext.lang === "EN" ? "Georgia" : "საქართველო",
    },
    {
      id: 2,
      mapImg: abkhazia,
      region: langContext.lang === "EN" ? "Abkhazia" : "აფხაზეთი",
    },
    {
      id: 3,
      mapImg: adjara,
      region: langContext.lang === "EN" ? "Adjara" : "აჭარა",
    },
    {
      id: 4,
      mapImg: guria,
      region: langContext.lang === "EN" ? "Guria" : "გურია",
    },
    {
      id: 5,
      mapImg: imereti,
      region: langContext.lang === "EN" ? "Imereti" : "იმერეთი",
    },
    {
      id: 6,
      mapImg: racha,
      region: langContext.lang === "EN" ? "Racha-lechkhumi" : "რაჭა-ლეჩხუმი",
    },
    {
      id: 7,
      mapImg: kakheti,
      region: langContext.lang === "EN" ? "Kakheti" : "კახეთი",
    },

    {
      id: 9,
      mapImg: kartli,
      region: langContext.lang === "EN" ? "Kartli" : "ქართლი",
    },
    {
      id: 9,
      mapImg: mtskheta,
      region:
        langContext.lang === "EN" ? "Mtskheta-mtianeti" : "მცხეთა-მთიანეთი",
    },
    {
      id: 9,
      mapImg: samegrelo,
      region: langContext.lang === "EN" ? "Samegrelo" : "სამეგრელო",
    },
  ];

  return (
    <div className="mapSliderContainer">
      <LocationGoogleMaps
        locRegion={click}
        selected={selected}
        setSelected={setSelected}
        filteredData={filteredData}
        georgiaWineries={georgiaWineries}
        dataForRegions={dataForRegions}
      />

      <Swiper
        spaceBetween={10}
        slidesPerView={7}
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 4,
          },
          1080: {
            slidesPerView: 7,
          },
          1600: {
            slidesPerView: 9,
          },
        }}
        scrollbar={{ draggable: true }}
        modules={[Scrollbar]}
        className="location-slider-thumbs"
      >
        {mapSlider.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div
                onClick={() => {
                  setClick(index);
                  setSelected(null);
                }}
                className="loc"
                style={
                  click === index
                    ? { backgroundColor: "rgba(62, 62, 62, 0.59)" }
                    : { backgroundColor: "rgba(193, 193, 193, 0.5)" }
                }
              >
                <img
                  className="region-small-img"
                  src={item.mapImg}
                  alt="regions"
                  style={
                    click === index
                      ? {
                          filter: `brightness(0) saturate(100%) invert(98%) sepia(98%) saturate(8%) hue-rotate(113deg) brightness(102%) contrast(102%)`,
                        }
                      : {
                          filter: `brightness(0) saturate(100%) invert(15%) sepia(8%) saturate(831%)
                        hue-rotate(182deg) brightness(93%) contrast(98%)`,
                        }
                  }
                />
                <span
                  style={
                    click === index
                      ? {
                          color: "white",
                          fontSize: "14px",
                          fontFamily:
                            langContext.lang !== "EN" ? "FiraGo" : "Open Sans",
                        }
                      : {
                          color: "black",
                          fontSize: "10px",
                          fontFamily:
                            langContext.lang !== "EN" ? "FiraGo" : "Open Sans",
                        }
                  }
                  className="loc-span regionActive"
                >
                  {item.region}
                </span>
              </div>
            </SwiperSlide>
          );
        })}
        <br />
      </Swiper>
    </div>
  );
}
