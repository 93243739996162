import React from "react";
import { Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// import CartFavorites from "./CartFavorites";
import "./styles/NewCards.css";
import { Link } from "react-router-dom";

export default function CardsMap({
  newItemData,
  // favorite,
  // setFavorite,
  // cart,
  // setCart,
  slides,
}) {
  // const webSite = process.env.REACT_APP_WEBSITE;

  return (
    <Swiper
      modules={[Scrollbar, A11y]}
      spaceBetween={0}
      // slidesPerView={slides}
      scrollbar={{ draggable: true }}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 2,
        },
        1080: {
          slidesPerView: slides,
        },
      }}
    >
      {newItemData &&
        newItemData.map((item) => {
          return (
            <SwiperSlide key={item.id} className="our-wine-cards">
              <div className="our-wine-card">
                <div className="product-left-block">
                  <img
                    className="product-item-img"
                    // src={wine1}
                    src={item.img_path ? item.img_path : item.img_link}
                    alt="wine"
                  />
                </div>
                {/* <div className="overlay">
                  <CartFavorites
                    items={item}
                    favorite={favorite}
                    setFavorite={setFavorite}
                    cart={cart}
                    setCart={setCart}
                  />
                </div> */}
                <div className="left-block">
                  {/* <p className={item.sale ? "sale" : "notsale"}>
                  {item.sale ? item.sale : null}
                </p> */}
                  <Link to={`/wine-detail/${item.id}`} className="item-title">
                    {item.name}
                  </Link>
                  <h5 className="wine-color" style={{ whiteSpace: "nowrap" }}>
                    {item.wine_type_one && item.wine_type_one.name
                      ? item.wine_type_one.name
                      : item.wine_type_one}{" "}
                    {item.wine_type_two && item.wine_type_two.name
                      ? item.wine_type_two.name
                      : item.wine_type_two}
                  </h5>

                  <Link
                    to={`/wineries/detail/${item.company && item.company.id}`}
                    className="item-grape"
                  >
                    {/* {item.item.company && "Grape:"} */}
                    {item.company && item.company.name}
                  </Link>
                  <p className="card-wine-type">{item.year}</p>
                  {/* <p className="item-final-price">{`$${item.finalPrice}`}</p> */}
                  {/* <div className="price-cont">
                <p className="item-final-price">20$</p>
                <span className="item-first-price">40$</span>
              </div> */}
                  {/* <span className="item-first-price">
                  {item.firstPrice ? `$${item.firstPrice}` : null}
                </span> */}
                  {/* <div className="mobile-cart-fav">
                <CartFavorites
                  items={item}
                  favorite={favorite}
                  setFavorite={setFavorite}
                  cart={cart}
                  setCart={setCart}
                />
              </div> */}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
}
