import React, { useEffect } from "react";
import "../components/styles/MobileApp.css";
import logo from "../images/mobApp/findwine_logo-09 1.png";
import playStore from "../images/mobApp/google.png";
import appleStore from "../images/mobApp/apple.png";
import { useNavigate } from "react-router-dom";

export default function MobileApp() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);

  return (
    <main className="mobileApp-container">
      <img className="mob-app-logo" src={logo} alt="logo" />
      <h1>Findwines.ge</h1>
      <p>Will be available at:</p>
      <img style={{ marginBottom: "20px" }} src={playStore} alt="playStore" />
      <img src={appleStore} alt="appleStore" />
    </main>
  );
}
