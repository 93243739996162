import React, { useContext, useEffect, useState } from "react";
import bell from "../images/Icon awesome-bell.png";
import "./styles/EventDown.css";
import api from "../Api/getData";
import { Link } from "react-router-dom";
import { context } from "./Root";

export default function EventDown({ navBar }) {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(true);

  const langContext = useContext(context);

  const sortEvents = (a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  };

  const currentDate = new Date();
  const currentDateString = currentDate.toISOString().split("T")[0];
  const upcomingEvents = events.filter((event) => {
    return event.date >= currentDateString;
  });

  const upComingEvent = upcomingEvents.slice().sort(sortEvents)[0];

  useEffect(() => {
    let loaderTimeout;
    const fetchEventsData = async () => {
      try {
        const res = await api.get("events");
        loaderTimeout = setTimeout(() => {
          setLoader(false);
        }, 1500);
        setEvents(res.data);
      } catch (error) {
        loaderTimeout = setTimeout(() => {
          setLoader(false);
        }, 1500);
        setError(error.message);
      }
    };

    fetchEventsData();
    return () => {
      clearTimeout(loaderTimeout);
    };
  }, []);

  return (
    <>
      <div className="event-container">
        <div className={navBar ? "triagle triagleActive" : "triagle"}></div>
        <div className={navBar ? "event-down active" : "event-down"}>
          <div className={navBar ? "future-event active" : "future-event"}>
            <img src={bell} alt="bell" />
            <span>
              {langContext.lang === "EN"
                ? "Upcoming event"
                : "მომავალი ღონისძიება"}
            </span>
          </div>
          <div className="event-down-line"></div>
          <Link
            to={
              upComingEvent === 0
                ? "event"
                : `/events/detail/${upComingEvent && upComingEvent.id}`
            }
            className={navBar ? "event-info active" : "event-info"}
          >
            {loader ? (
              <div className="event-loader">
                <div
                  style={
                    navBar
                      ? { backgroundColor: "#3a3d43" }
                      : { backgroundColor: "white" }
                  }
                  className="circle"
                ></div>
                <div
                  style={
                    navBar
                      ? { backgroundColor: "#3a3d43" }
                      : { backgroundColor: "white" }
                  }
                  className="circle"
                ></div>
                <div
                  style={
                    navBar
                      ? { backgroundColor: "#3a3d43" }
                      : { backgroundColor: "white" }
                  }
                  className="circle"
                ></div>
                <div
                  style={
                    navBar
                      ? { backgroundColor: "#3a3d43" }
                      : { backgroundColor: "white" }
                  }
                  className="circle"
                ></div>
              </div>
            ) : (
              <>
                {error === "" ? (
                  <>
                    {upComingEvent ? (
                      <>
                        <span>
                          {upComingEvent &&
                            `${upComingEvent.event_name.slice(0.2)}...`}
                        </span>
                        <span>
                          {upComingEvent &&
                            upComingEvent.date.replace(/-/g, "/")}
                        </span>
                      </>
                    ) : (
                      <p
                        style={navBar ? { color: "black" } : { color: "white" }}
                        className="no-expected-event"
                      >
                        {langContext.lang === "EN"
                          ? "There is no expected event"
                          : "უახლოესი ივენთი არ მოიძებნა"}
                      </p>
                    )}
                  </>
                ) : (
                  <span>{error}</span>
                )}
              </>
            )}

            {/* {error === "" ? (
              <>
                <span>{upComingEvent && upComingEvent.company}</span>
                <span>
                  {upComingEvent && upComingEvent.date.replace(/-/g, "/")}
                </span>
              </>
            ) : (
              <span>{error}</span>
            )} */}
          </Link>
        </div>
      </div>
    </>
  );
}
