import React from "react";
import { transformText } from "../data/georgianToEnglish";

export default function WineriesLabel({
  inputTitle,
  filterHandler,
  setPageNumber,
  filterRegion,
}) {
  return (
    <div className="label-wrapper">
      <input
        onChange={() => {
          filterHandler(transformText(inputTitle));
          setPageNumber(0);
        }}
        checked={filterRegion.includes(transformText(inputTitle))}
        type="checkbox"
        value={inputTitle}
      />
      <label>{inputTitle}</label>
    </div>
  );
}
