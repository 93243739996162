import React from "react";
import { useParams } from "react-router-dom";
import { changeTitle } from "../data/GeneralFunction";

export default function WineNews() {
  const { filterArticle } = useParams();

  changeTitle(`Findwine | ${filterArticle}`);

  return <div>{filterArticle}</div>;
}
