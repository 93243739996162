import React, { createContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Cookies from "js-cookie";

export const context = createContext({});

export default function Root() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  const [userLogin, setUserLogin] = useState(false);

  // Effect to run on component mount
  useEffect(() => {
    // Retrieve the session cookie
    const storedUserLogin = Cookies.get("userLogin");

    // Check if the user was previously logged in
    if (storedUserLogin === "loggedIn") {
      setUserLogin(true);
    }
  }, []);

  // Function to handle login
  const handleLogin = () => {
    // Set a session cookie
    Cookies.set("userLogin", "loggedIn");
    // Update local state
    setUserLogin(true);
  };

  // Function to handle logout
  const handleLogout = () => {
    // Remove the session cookie (log out)
    Cookies.remove("userLogin");
    // Update local state
    setUserLogin(false);
  };
  const [showPopUp, setShowPopUp] = useState(false);
  const [view, setView] = useState("login");
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("lang") || "EN");

  // Update localStorage when lang state changes
  useEffect(() => {
    localStorage.setItem("lang", lang);
  }, [lang]);

  useEffect(() => {
    document.body.style.overflow = showPopUp ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showPopUp]);
  const isMobileAppPage = pathname.startsWith("/mobileapp");

  return (
    <context.Provider
      value={{
        userLogin,
        setUserLogin,
        setShowPopUp,
        showPopUp,
        view,
        setView,
        setShowReviewForm,
        showReviewForm,
        setLang,
        lang,
        handleLogin,
        handleLogout,
      }}
    >
      <>
        {!isMobileAppPage && <Navbar />}
        <Outlet />
        {!isMobileAppPage && <Footer />}
      </>
    </context.Provider>
  );
}
