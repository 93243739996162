import React, { useContext } from "react";
import "./styles/WInePassBenefit.css";
import LineMaxWidth from "./LineMaxWidth";
import Benefit from "./Benefit";
import glass from "../images/winepassport/glass-full.svg";
import map from "../images/winepassport/mapsvg.svg";
import trophy from "../images/winepassport/trophy.svg";
import brand from "../images/winepassport/brand-pocket.svg";
import man from "../images/winepassport/Rectangle 577.png";
import manMob from "../images/winepassport/man-with-wine-mob.png";
import { motion } from "framer-motion/dist/framer-motion";
import { useMediaQuery } from "@mui/material";
import { context } from "./Root";

export default function WinePassBenefit() {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const langContext = useContext(context);

  const variants = isDesktop
    ? {
        initial: { opacity: 0, x: -500 },
        whileInView: { opacity: 1, x: 0 },
        transition: { duration: 0.5 },
        initialForBene: { opacity: 0, x: 500 },
      }
    : {
        initial: { opacity: 0 },
        whileInView: { opacity: 1 },
        transition: { duration: 0.5 },
      };
  return (
    <section className="benefit-container">
      <motion.img
        variants={variants}
        initial={variants.initial}
        whileInView={variants.whileInView}
        transition={{ duration: 0.5, ease: "easeOut", delay: 0.5 }}
        className="wine-image-bg"
        src={man}
        alt="man-with-wine"
      />
      <img className="wine-image-bg-mob" src={manMob} alt="man-with-wine" />
      {/* <div className="wine-image-bg"></div> */}
      <motion.div
        className="benefit-wrapper"
        variants={variants}
        initial={variants.initialForBene}
        whileInView={variants.whileInView}
        transition={{ duration: 0.5, ease: "easeOut", delay: 0.5 }}
      >
        <div className="benefit-list1">
          <Benefit
            image={glass}
            title={langContext.lang === "EN" ? "300+ wineries" : "300+ მარანი"}
            info={
              langContext.lang === "EN"
                ? "You can visit more than 300 wineries at your fingertips"
                : "შეგიძლიათ ეწვიოთ 300-ზე მეტ ღვინის მარანს"
            }
          />
          <Benefit
            image={map}
            title={
              langContext.lang === "EN"
                ? "All in one app"
                : "ყველაფერი ერთ აპლიკაციაში"
            }
            info={
              langContext.lang === "EN"
                ? "Georgia’s finest wineries and family cellars all in one app"
                : "საქართველოს საუკეთესო მეღვინეობები და საოჯახო მარნები ერთ აპლიკაციაში"
            }
          />
        </div>
        <LineMaxWidth />
        <div className="benefit-list2">
          <Benefit
            image={trophy}
            title={
              langContext.lang === "EN"
                ? "Best deals"
                : "საუკეთესო შეთავაზებები"
            }
            info={
              langContext.lang === "EN"
                ? "Sip and Save: Exclusive offers at Georgia’s top wineries"
                : "მიირთვით და დაზოგეთ: ექსკლუზიური შეთავაზებები საქართველოს საუკეთესო ღვინის მარნებზე"
            }
          />
          <Benefit
            image={brand}
            title={
              langContext.lang === "EN"
                ? "Memorable Moments"
                : "დასამახსოვრებელი მომენტები"
            }
            info={
              langContext.lang === "EN"
                ? "Your Wine Passport helps you create a diary of your wine-tasting adventures, preserving cherished memories and experiences to look back on."
                : "თქვენი ღვინის პასპორტი გეხმარებათ შექმნათ დღიური თქვენი ღვინის დეგუსტაციის თავგადასავლების შესახებ, შეინარჩუნოთ სანუკვარი მოგონებები და გამოცდილებები, რომლებსაც უნდა გადახედოთ."
            }
          />
        </div>
      </motion.div>
    </section>
  );
}
