import React, { useState, useEffect, useContext } from "react";
import "./styles/AboutWinery.css";
import LineMaxWidth from "./LineMaxWidth";
import WineInfo from "./WineInfo";
// import Done from "../images/Icon material-done.png";
import OurWines from "./OurWines";
import Reviews from "./Reviews";
import Location from "./Location";
import StarRating from "./StarRating";
// import Advantage from "./Advantage";
import Gallery from "./Gallery";
import { Link } from "react-router-dom";
import api from "../Api/getData";
import { context } from "./Root";

export default function AboutWinery({
  index,
  setIndex,
  wineryDetailInfo,
  price,
  guests,
  cart,
  setCart,
  favorite,
  setFavorite,
  NewItemData,
  setShowReviewForm,
  showReviewForm,
  lang,
}) {
  const [userInfo, setUserInfo] = useState({});
  const [purchasedPass, setPurchasedPass] = useState([]);

  const userLogin = useContext(context);

  const authToken = localStorage.getItem("authToken");

  const fetchProfileInfo = async () => {
    try {
      const response = await api.get(`user/profile`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setUserInfo(response.data);
      setPurchasedPass(response.data.passport);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchProfileInfo();
  }, [authToken]);

  const isCompanyInWinePass = purchasedPass.some(
    (arr) =>
      arr.passport &&
      arr.passport.companies &&
      arr.passport.companies.some(
        (comp) => comp.company_id === wineryDetailInfo.id
      )
  );

  return (
    <>
      <div
        className="tab-lists"
        style={showReviewForm ? { filter: "brightness(0.5)" } : null}
      >
        <div
          onClick={() => {
            setIndex(0);
          }}
          className={`tab-Head ${index === 0 ? "active-tab" : null}`}
          style={
            lang === "EN" ? { fontFamily: "Poppins" } : { fontFamily: "FiraGo" }
          }
        >
          {lang === "EN" ? " About the Winery" : "მარნის შესახებ"}
        </div>
        <div
          onClick={() => {
            setIndex(1);
          }}
          className={`tab-Head ${index === 1 ? "active-tab" : null}`}
          style={
            lang === "EN" ? { fontFamily: "Poppins" } : { fontFamily: "FiraGo" }
          }
        >
          {lang === "EN" ? "Location" : "ლოკაცია"}
        </div>
        {/* <div
          onClick={() => {
            setIndex(2);
          }}
          className={`tab-Head ${index === 2 ? "active-tab" : null}`}
        >
          Awards
        </div> */}
        <div
          onClick={() => {
            setIndex(3);
          }}
          className={`tab-Head ${index === 3 ? "active-tab" : null}`}
          style={
            lang === "EN" ? { fontFamily: "Poppins" } : { fontFamily: "FiraGo" }
          }
        >
          {lang === "EN" ? "Reviews" : "შეფასებები"}
        </div>
        {/* <div
          onClick={() => {
            setIndex(4);
          }}
          className={`tab-Head ${index === 4 ? "active-tab" : null}`}
        >
          Faq
        </div> */}
        {wineryDetailInfo.gallery.length >= 1 ? (
          <div
            onClick={() => {
              setIndex(4);
            }}
            className={`tab-Head ${index === 4 ? "active-tab" : null}`}
            style={
              lang === "EN"
                ? { fontFamily: "Poppins" }
                : { fontFamily: "FiraGo" }
            }
          >
            {lang === "EN" ? "Gallery" : "გალერეა"}
          </div>
        ) : null}
      </div>

      <div className="tabContant" hidden={index !== 4}>
        <Gallery lang={lang} galleyData={wineryDetailInfo.gallery} />
      </div>
      <div className="tabContant" hidden={index !== 3}>
        <Reviews reviews={wineryDetailInfo && wineryDetailInfo.review} />
      </div>

      <section className="winery-section">
        <div className="winery-left-block">
          <div className="tabs">
            <div className="tabContant" hidden={index !== 0}>
              <div>
                <h2
                  className="wine-title"
                  style={{
                    fontFamily: lang !== "EN" ? "BPG Nino Mtavruli" : "Poppins",
                  }}
                >
                  {wineryDetailInfo.company}
                </h2>
                {userLogin.userLogin && isCompanyInWinePass && (
                  <StarRating
                    setShowReviewForm={setShowReviewForm}
                    showReviewForm={showReviewForm}
                    user={userInfo.user && userInfo.user.full_name}
                    userLogin={userLogin.userLogin}
                    id={wineryDetailInfo.id}
                  />
                )}
              </div>
              <h5
                className="wineyh5"
                style={{
                  fontFamily: lang !== "EN" ? "BPG Nino Mtavruli" : "Poppins",
                }}
              >
                {lang === "EN" ? "An introduction" : "შესავალი"}
              </h5>
              <p
                className="left-block-aboutwine"
                style={{
                  fontFamily: lang !== "EN" ? "FiraGo" : "Poppins",
                  ...(showReviewForm ? { filter: "brightness(0.5)" } : {}),
                }}
              >
                {wineryDetailInfo.description}
              </p>
              <LineMaxWidth />
              {/* <h2 className="awards">Awards won</h2>
              {wineryDetailInfo.awards !== undefined &&
                wineryDetailInfo.awards.map((item, index) => {
                  return (
                    <p key={index} className="awards-list">
                      {item.award}
                    </p>
                  );
                })} */}
              {/* <p className="awards-list">{award2}</p>
              <p className="awards-list">{award3} </p> */}
              <LineMaxWidth />
              {wineryDetailInfo.products.length >= 1 ? (
                <OurWines
                  favorite={favorite}
                  setFavorite={setFavorite}
                  cart={cart}
                  setCart={setCart}
                  NewItemData={NewItemData}
                  wineryDetailInfo={wineryDetailInfo}
                  lang={lang}
                />
              ) : null}

              <Location lang={lang} wineryDetailInfo={wineryDetailInfo} />
            </div>
            <div className="tabContant" hidden={index !== 1}>
              <Location lang={lang} wineryDetailInfo={wineryDetailInfo} />
            </div>

            {/* <div className="tabContant" hidden={index !== 2}> */}
            {/* <h2 className="awards">Awards won</h2>
              {wineryDetailInfo.awards !== undefined &&
                wineryDetailInfo.awards.map((item, index) => {
                  return (
                    <p key={index} className="awards-list">
                      {item.award}
                    </p>
                  );
                })} */}
            {/* <LineMaxWidth /> */}
            {/* <OurWines
                favorite={favorite}
                setFavorite={setFavorite}
                cart={cart}
                setCart={setCart}
                NewItemData={NewItemData}
              /> */}
            {/* </div> */}

            {/* <div className="tabContant" hidden={index !== 3}>
              <Reviews />
              <OurWines />
            </div> */}
          </div>
        </div>

        <div className="winery-right-block" hidden={index === 3 || index === 4}>
          <>
            <h2
              className="right-block-title"
              style={{
                fontFamily: lang !== "EN" ? "BPG Nino Mtavruli" : "Poppins",
              }}
            >
              {lang === "EN"
                ? "Winery experiences:"
                : "მეღვინეობის გამოცდილება:"}
            </h2>
            <div className="border-for-winery">
              {/* <img
              className="right-block-img"
              src={WineryExperienceImage}
              alt="winery"
            /> */}
              <div className="winery-experience">
                <p
                  className="about-wine"
                  style={{
                    fontFamily: lang !== "EN" ? "FiraGo" : "Poppins",
                  }}
                >
                  {lang === "EN"
                    ? `Wine tasting and tour at ${wineryDetailInfo.company}.`
                    : `ღვინის დეგუსტაცია და ტური ${wineryDetailInfo.company}`}
                </p>
                {wineryDetailInfo &&
                  wineryDetailInfo.tours &&
                  wineryDetailInfo.tours.duration && (
                    <WineInfo
                      className="duration"
                      keyCname="keyCname"
                      valueclassName="valueclassName"
                      title={lang === "EN" ? "Duration" : "ხანგრძლივობა"}
                      value={
                        wineryDetailInfo &&
                        wineryDetailInfo.tours &&
                        wineryDetailInfo.tours.duration
                      }
                    />
                  )}
                <div className="duration">
                  <p
                    className="keyCname"
                    style={{
                      fontFamily: lang !== "EN" ? "FiraGo" : "Poppins",
                    }}
                  >
                    {lang === "EN" ? "Wine types" : "ღვინის ტიპები"}
                  </p>
                  <div>
                    {wineryDetailInfo.products
                      .slice(0, 6)
                      .map((wineType, id) => {
                        return (
                          <p key={id} className="valueclassName">
                            {wineType.wine_type}
                          </p>
                        );
                      })}
                  </div>
                </div>
                <WineInfo
                  className="duration"
                  keyCname="keyCname"
                  valueclassName="valueclassName"
                  title={lang === "EN" ? "Visiting hours" : "ვიზიტის საათები"}
                  value={`${
                    lang === "EN" ? "Mon:" : "ორშ"
                  } ${wineryDetailInfo &&
                    wineryDetailInfo.tours &&
                    wineryDetailInfo.tours.monday}`}
                  value2={`${
                    lang === "EN" ? "Tue:" : "სამ"
                  } ${wineryDetailInfo &&
                    wineryDetailInfo.tours &&
                    wineryDetailInfo.tours.tuesday}`}
                  value3={`${
                    lang === "EN" ? "Wed:" : "ოთხ"
                  } ${wineryDetailInfo &&
                    wineryDetailInfo.tours &&
                    wineryDetailInfo.tours.wednesday}`}
                  value4={`${
                    lang === "EN" ? "Thu:" : "ხუთ"
                  } ${wineryDetailInfo &&
                    wineryDetailInfo.tours &&
                    wineryDetailInfo.tours.thursday}`}
                  value5={`${
                    lang === "EN" ? "Fri:" : "პარ"
                  } ${wineryDetailInfo &&
                    wineryDetailInfo.tours &&
                    wineryDetailInfo.tours.friday}`}
                  value6={`${lang === "EN" ? "Sat:" : "შაბ"} ${
                    wineryDetailInfo &&
                    wineryDetailInfo.tours &&
                    wineryDetailInfo.tours.saturday === null
                      ? "closed"
                      : wineryDetailInfo &&
                        wineryDetailInfo.tours &&
                        wineryDetailInfo.tours.saturday
                  }`}
                  value7={`${lang === "EN" ? "Sun:" : "კვი"} ${
                    wineryDetailInfo &&
                    wineryDetailInfo.tours &&
                    wineryDetailInfo.tours.sunday === null
                      ? "closed"
                      : wineryDetailInfo &&
                        wineryDetailInfo.tours &&
                        wineryDetailInfo.tours.sunday
                  }`}
                />
                <WineInfo
                  className="duration"
                  keyCname="keyCname"
                  valueclassName="valueclassName"
                  title={lang === "EN" ? "Guests" : "სტუმრები"}
                  value={`${guests} ${lang === "EN" ? "persons" : "პერსონა"}`}
                />
                {/* <WineInfo
                className="duration"
                keyCname="keyCname"
                valueclassName="valueclassName"
                title="Duration"
                value="White Dry"
              /> */}
                <p className="from">{lang === "EN" ? "FROM" : "დან"}</p>
                <div className="spans">
                  <span
                    className="price"
                    style={{
                      fontFamily: lang !== "EN" ? "FiraGo" : "Poppins",
                    }}
                  >
                    {price} {lang === "EN" ? "GEL" : "ლარი"}
                  </span>
                  <span
                    className="person"
                    style={{
                      fontFamily: lang !== "EN" ? "FiraGo" : "Poppins",
                    }}
                  >
                    {lang === "EN" ? "per person" : "თითო პერსონა"}
                  </span>
                </div>
                {/* <LineMaxWidth /> */}
                {/* <Advantage image={Done} info="Free WiFi" />
              <Advantage image={Done} info="Free private parking" />
              <Advantage image={Done} info="Possibility for 1-night stay" /> */}
                {/* <button className="view-detail">View Details</button> */}
                <Link to={"/wine-passport"}>
                  <button
                    className="reverve-btn"
                    style={{
                      fontFamily: lang !== "EN" ? "FiraGo" : "Open Sans",
                    }}
                  >
                    {lang === "EN" ? "BUY NOW" : "ყიდვა"}
                  </button>
                </Link>
              </div>
            </div>
          </>

          <h2
            className="message-title"
            style={{
              fontFamily: lang !== "EN" ? "BPG Nino Mtavruli" : "Poppins",
            }}
          >
            {lang === "EN" ? "Send a message" : "შეტყობინების გაგზავნა"}
          </h2>
          <div className="message-inputs">
            <input
              className="text-input"
              type="text"
              style={{
                fontFamily: lang !== "EN" ? "FiraGo" : "Poppins",
              }}
              placeholder={lang === "EN" ? "Name" : "სახელი"}
            />
            <input
              className="text-input"
              type="text"
              style={{
                fontFamily: lang !== "EN" ? "FiraGo" : "Poppins",
              }}
              placeholder={lang === "EN" ? "Email" : "ელფოსტა"}
            />
            <input
              className="text-input"
              type="text"
              style={{
                fontFamily: lang !== "EN" ? "FiraGo" : "Poppins",
              }}
              placeholder={lang === "EN" ? "Phone" : "ნომერი"}
            />
            <textarea
              className="message-textarea"
              style={{
                fontFamily: lang !== "EN" ? "FiraGo" : "Poppins",
              }}
              placeholder={
                lang === "EN" ? "Leave your message" : "დატოვეთ შეტყობინება"
              }
            ></textarea>
            <input
              className="input-submit"
              style={{
                fontFamily: lang !== "EN" ? "FiraGo" : "Poppins",
              }}
              type="submit"
              value={lang === "EN" ? "Send" : "გაგზავნა"}
            />
          </div>
        </div>
      </section>
    </>
  );
}
