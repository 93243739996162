import React, { useContext } from "react";
import "../components/styles/SignIn.css";
import Registration from "./Registration";
import PassReset from "./PassReset";
import SignIn from "./SignIn";
import { MdClear } from "react-icons/md";
import { context } from "./Root";
import { motion } from "framer-motion/dist/framer-motion";

export default function popUp() {
  const popUpContext = useContext(context);
  const signInFormContext = useContext(context);
  const variants = {
    Initial: { opacity: 0, scale: 0.5 },
    animate: { opacity: 1, scale: 1 },
  };

  return (
    <motion.div
      variants={variants}
      initial={variants.Initial}
      animate={variants.animate}
      transition={{
        ease: "easeInOut",
        duration: 1,
        delay: 0.3,
      }}
      className="form-overlay"
    >
      <div className="registration-form">
        <MdClear
          onClick={() => popUpContext.setShowPopUp(false)}
          className="close-btn"
        />

        {signInFormContext.view === "registration" && <Registration />}
        {signInFormContext.view === "login" && <SignIn />}
        {signInFormContext.view === "passReset" && <PassReset />}
      </div>
    </motion.div>
  );
}
