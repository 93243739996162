import React from "react";
import WineriesLabel from "./WineriesLabel";
import LineMaxWidth from "./LineMaxWidth";

export default function WineriesLabelOption({
  labelClassname,
  filterHandler,
  filterData,
  setPageNumber,
  filterRegion,
  handleFilterClick,
}) {
  return (
    <div
      style={{ maxHeight: "380px", overflowY: "auto" }}
      className={labelClassname}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="label-options">
        <div className="label-left-block">
          {filterData
            .slice(0, Math.ceil(filterData.length / 2))
            .map((item, index) => {
              return (
                <WineriesLabel
                  key={index}
                  filterHandler={filterHandler}
                  setPageNumber={setPageNumber}
                  inputTitle={item.name}
                  filterRegion={filterRegion}
                />
              );
            })}
        </div>
        <div className="label-right-block">
          {filterData
            .slice(Math.ceil(filterData.length / 2))
            .map((item, index) => {
              return (
                <WineriesLabel
                  key={index}
                  filterHandler={filterHandler}
                  setPageNumber={setPageNumber}
                  inputTitle={item.name}
                  filterRegion={filterRegion}
                />
              );
            })}
        </div>
      </div>
      <LineMaxWidth />
      {/* <div className="buttons-wrapper">
        <button className="clear-all-btn">Clear all</button>
        <button onSubmit={() => handleFilterClick} className="btn-submit">
          APPLY
        </button>
      </div> */}
    </div>
  );
}
