const georgianToEnglish = {
  ა: "a",
  ბ: "b",
  გ: "g",
  დ: "d",
  ე: "e",
  ვ: "v",
  ზ: "z",
  თ: "t",
  ი: "i",
  კ: "k",
  ლ: "l",
  მ: "m",
  ნ: "n",
  ო: "o",
  პ: "p",
  ჟ: "zh",
  რ: "r",
  ს: "s",
  ტ: "t",
  უ: "u",
  ფ: "f",
  ქ: "q",
  ღ: "gh",
  ყ: "y",
  შ: "sh",
  ჩ: "ch",
  ც: "ts",
  ძ: "dz",
  წ: "w",
  ჭ: "wch",
  ხ: "kh",
  ჯ: "j",
  ჰ: "h",
};

const englishToGeorgian = {
  a: "ა",
  b: "ბ",
  g: "გ",
  d: "დ",
  e: "ე",
  v: "ვ",
  z: "ზ",
  t: "თ",
  i: "ი",
  k: "კ",
  l: "ლ",
  m: "მ",
  n: "ნ",
  o: "ო",
  p: "პ",
  zh: "ჟ",
  r: "რ",
  s: "ს",
  u: "უ",
  f: "ფ",
  gh: "ღ",
  q: "ქ",
  sh: "შ",
  ch: "ჩ",
  ts: "ც",
  dz: "ძ",
  w: "წ",
  y: "ყ",
  wch: "ჭ",
  x: "ხ",
  j: "ჯ",
  h: "ჰ",
};

export const transformText = (georgianText) => {
  return georgianText
    .split("")
    .map((char) => georgianToEnglish[char] || char)
    .join("");
};

export const transformTextEngToGeo = (englishText) => {
  return englishText
    .split("")
    .map((char) => englishToGeorgian[char] || char)
    .join("");
};
