import React, { useEffect } from "react";
import "../components/styles/Payment.css";
import checked from "../images/paymentPages/CheckCircle.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import api from "../Api/getData";

export default function PaymentSuccessful() {
  const location = useLocation();
  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();

  console.log(authToken);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const paymentTransactionId = queryParams.get("payment_transaction_id");
        const response = await api.post(
          `user/check-transaction`,
          {
            transaction_id: paymentTransactionId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.data.checked === 1) {
          navigate("/");
        }
        console.log(paymentTransactionId);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [location.search, authToken]);

  return (
    <main className="successPay-main-container">
      <div className="successPay-container">
        <img src={checked} alt="checked" />
        <h2>Payment Successful!</h2>
        <p>The bank approved your payment. Thanks for the purchase! </p>
        <Link to={"/wine-passport"}>
          <button>BACK TO WINE PASSPORT PAGE</button>
        </Link>
      </div>
    </main>
  );
}
