import React, { useContext, useState, useEffect } from "react";
import ArticlePageBg from "../components/ArticlePageBg";
import { context } from "../components/Root";
import TitleLine from "../components/TitleLine";
// import { articleList } from "../components/Data";
import Article from "../components/Article";
import ReactPaginate from "react-paginate";
import { useMediaQuery, Alert } from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import api from "../Api/getData";
import { changeTitle } from "../data/GeneralFunction";

export default function ArticlePage() {
  const getPageNumberFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const page = parseInt(urlParams.get("page"));
    return isNaN(page) ? 0 : page - 1;
  };
  const initialPageNumber = getPageNumberFromURL();
  const langContext = useContext(context);

  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const popUpContext = useContext(context);
  const [loader, setLoader] = useState(true);
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState("");
  const [year, setYear] = useState("");
  const [monthName, setMonthName] = useState("");
  const [day, setDay] = useState("");

  changeTitle(`Findwine | Articles`);

  useEffect(() => {
    if (articles && articles.length > 0) {
      const dateParts = articles[0].date.split("-");
      const year = dateParts[0];
      const numericMonth = dateParts[1];
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const parsedNumericMonth = parseInt(numericMonth, 10);

      const monthName = monthNames[parsedNumericMonth - 1];

      let day = dateParts[2];
      if (day.startsWith("0")) {
        day = day.substring(1);
      }

      setYear(year);
      setMonthName(monthName);
      setDay(day);
    }
  }, [articles]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const res = await api.get("articles");
        setArticles(res.data);
        setLoader(false);
      } catch (error) {
        setError(error.message);
        setLoader(false);
      }
    };

    fetchArticles();
  }, []);

  const isDesktop = useMediaQuery("(min-width: 768px)");
  // const webSite = process.env.REACT_APP_WEBSITE;

  const articlesPerPage = 9;
  const pagesVisited = pageNumber * articlesPerPage;

  const pageCount = Math.ceil(articles.slice(1).length / articlesPerPage);
  const updateURL = (pageNumber) => {
    window.history.pushState(null, "", `?page=${pageNumber + 1}`);
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    isDesktop
      ? window.scrollTo({ top: 0, behavior: "smooth" })
      : window.scrollTo({ top: 300, behavior: "smooth" });
    updateURL(selected);
  };

  const sortedData = articles.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);

    return dateB - dateA;
  });

  const isDeskOrMob = isDesktop
    ? sortedData.slice(1, sortedData.length)
    : sortedData;
  const displayArticles = isDeskOrMob
    .slice(pagesVisited, pagesVisited + articlesPerPage)
    .map((art) => {
      return (
        <Article
          key={art.id}
          image={art.img_path}
          title={art.title}
          description={art.description}
          authorImg={art && art.user && art.user.img_path}
          author={art && art.user && art.user.name}
          position={art.position}
          id={art.id}
          date={art.created_at}
          category={art.catetory_name && art.catetory_name.name}
        />
      );
    });

  const variants = isDesktop
    ? {
        initial: { opacity: 0, x: -500 },
        animate: { opacity: 1, x: 0 },
        initialForRigthblock: { opacity: 0, x: 500 },
      }
    : {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
      };

  const variantsError = isDesktop
    ? {
        initial: { opacity: 0, x: -500 },
        whileInView: { opacity: 1, x: 0 },
      }
    : {
        initial: { opacity: 0 },
        whileInView: { opacity: 1 },
      };
  return (
    <section
      className="article-section-forOverflow"
      style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}
    >
      <ArticlePageBg
        title={langContext.lang === "EN" ? "ARTICLES" : "სტატიები"}
      />
      <h1
        className="articlePage-title"
        style={
          langContext.lang !== "EN"
            ? { fontFamily: "BPG Nino Mtavruli" }
            : { fontFamily: "Montserrat" }
        }
      >
        {langContext.lang === "EN"
          ? "Explore articles About Georgian wines and wineries"
          : "ნახეთ სტატიები ქართული მეღვინეობის შესახებ"}
      </h1>
      <TitleLine />
      {loader ? (
        <div className="loarder-cont">
          <div className="loader">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      ) : (
        <div className="article-list-container">
          <div className="articlePage-width">
            {isDesktop && articles[0] && (
              <Link
                to={`/articles/detail/${articles[0].id}`}
                className="main-article-wrapper"
              >
                <motion.img
                  variants={variants}
                  initial={variants.initial}
                  animate={variants.animate}
                  transition={{
                    ease: "easeOut",
                    duration: 0,
                    delay: 0.5,
                  }}
                  style={{
                    width: "48%",
                    height: "389px",
                    objectFit: "cover",
                    userSelect: "none",
                  }}
                  src={articles[0].img_path}
                  alt="article"
                />
                <motion.div
                  variants={variants}
                  initial={variants.initialForRigthblock}
                  animate={variants.animate}
                  transition={{
                    ease: "easeOut",
                    duration: 0,
                    delay: 0.5,
                  }}
                  style={{ width: "48%" }}
                >
                  <h4 className="main-article-date">
                    {monthName} {year}, {day} |{" "}
                    {articles[0].category === "1" ? "News" : "Information"}
                  </h4>
                  <h4 className="main-article-title">{articles[0].title}</h4>
                  <p className="main-article-desc">
                    {`${articles[0].description
                      .split(" ")
                      .slice(0, 40)
                      .join(" ")} ...`}
                  </p>
                  <div className="author-container">
                    <img
                      style={{ borderRadius: "50%" }}
                      src={articles[0].user.img_path}
                      alt="author"
                    />
                    <div className="author-desc-container">
                      <span className="author-name">
                        {articles[0].user.name}
                      </span>
                      <span className="author-position">
                        {articles[0].position}
                      </span>
                    </div>
                  </div>
                </motion.div>
              </Link>
            )}

            <div className="article-list">{displayArticles}</div>
            <motion.div
              variants={variantsError}
              initial={variantsError.initial}
              whileInView={variantsError.whileInView}
              transition={{
                ease: "easeOut",
                duration: 1,
                delay: 0.5,
                type: "spring",
              }}
              className="error-handler-contianer"
            >
              {error ? (
                <Alert style={{ textAlign: "center" }} severity="error">
                  {error}
                </Alert>
              ) : null}
            </motion.div>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName="pagination-container"
              previousLinkClassName="pagination-prev"
              nextLinkClassName="pagination-next"
              disabledLinkClassName="pagination-disable"
              activeClassName="pagination-active"
              initialPage={pageNumber}
            />
          </div>
        </div>
      )}
    </section>
  );
}
