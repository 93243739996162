import React, { useContext, useEffect, useRef, useState } from "react";
import "./styles/EventPage.css";
import WinerySelect from "./WinerySelect";
import EventCard from "./EventCard";
import ReactPaginate from "react-paginate";
import api from "../Api/getData";
import { Alert } from "@mui/material";
import { transformText } from "../data/georgianToEnglish";
import { useMediaQuery } from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";
import { context } from "./Root";

export default function EventFirstSection({ lang }) {
  const [pageNumber, setPageNumber] = useState(0);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState("");
  const [events, setEvents] = useState([]);
  const [monthChange, setMonthChange] = useState("Upcoming");
  const [uniqueCategoryNames, setUniqueCategoryNames] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");

  const dateArray = ["Upcoming", "Past"];

  useEffect(() => {
    // Extract and filter unique category names
    const uniqueNamesSet = new Set(
      events
        .map((item) => item.catetory_name && item.catetory_name.name)
        .filter((name) => name !== null && name !== undefined)
    );
    const uniqueNamesArray = Array.from(uniqueNamesSet);

    // Update state with unique category names
    setUniqueCategoryNames(uniqueNamesArray);
  }, [events]);

  const langContext = useContext(context);

  // Function to update URL with the selected month and category filter

  const updateURL = (selectedMonth, selectedCategory) => {
    const queryParams = new URLSearchParams(window.location.search);

    // Always clear existing filters before updating
    queryParams.delete("date-filter");
    queryParams.delete("category-filter");

    if (selectedMonth === "Upcoming") {
      queryParams.set("date-filter", "Upcoming");
    } else {
      queryParams.set("date-filter", transformText(selectedMonth));
    }

    if (selectedCategory && selectedCategory !== "All") {
      queryParams.set("category-filter", selectedCategory);
    }

    const newURL = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState(null, "", newURL);
  };

  // Extract the query parameter and set the initial state based on it

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const dateFilter = queryParams.get("date-filter");
    const categoryFilter = queryParams.get("category-filter");
    const pageNumberParam = queryParams.get("page");

    if (pageNumberParam) {
      setPageNumber(parseInt(pageNumberParam - 1));
    }

    if (dateFilter) {
      setMonthChange(dateFilter);
    }

    if (categoryFilter && categoryFilter !== "undefined") {
      setSelectedCategory(categoryFilter);
    } else {
      setSelectedCategory("All");
    }
  }, []);
  const titleRef = useRef(null);
  useEffect(() => {
    const titleElement = titleRef.current;

    if (titleElement) {
      const { top } = titleElement.getBoundingClientRect();
      window.scrollTo({ top: top, behavior: "smooth" });
    }
  }, [selectedCategory, monthChange]);

  // useEffect(() => {
  //   let loaderTimeout;
  //   loaderTimeout = setTimeout(() => {
  //     setLoader(false);
  //   }, 2000);
  //   return () => {
  //     clearTimeout(loaderTimeout);
  //   };
  // }, []);

  useEffect(() => {
    let loaderTimeout;

    const fetchEventsData = async () => {
      try {
        const response = await api.get("/events");
        setEvents(response.data);

        loaderTimeout = setTimeout(() => {
          setLoader(false);
        }, 2000);
      } catch (error) {
        loaderTimeout = setTimeout(() => {
          setLoader(false);
        }, 2000);
        setError(error.message);
      }
    };
    fetchEventsData();
    return () => {
      clearTimeout(loaderTimeout);
    };
  }, []);

  // useEffect(() => {
  //   const fetchEventsData = async () => {
  //     try {
  //       const res = await api.get("events");
  //       setEvents(res.data);
  //     } catch (error) {
  //       setError(error.message);
  //     }
  //   };

  //   fetchEventsData();
  // }, []);

  const eventPerPage = 5;
  const pagesVisited = pageNumber * eventPerPage;

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    window.scrollTo({ top: 500, behavior: "smooth" });
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("page", (selected + 1).toString());
    window.history.pushState({}, "", newUrl.toString());
  };

  const currentDate = new Date();

  const filteredEvents = events.filter((event) => {
    const eventStartDate = new Date(event.date);

    // Check both date and category filters
    switch (monthChange) {
      case "Upcoming":
        return (
          eventStartDate.getTime() >= currentDate.getTime() &&
          (selectedCategory === "All" ||
            (event.catetory_name &&
              event.catetory_name.name === selectedCategory))
        );
      case "Past":
        return (
          eventStartDate.getTime() < currentDate.getTime() &&
          (selectedCategory === "All" ||
            (event.catetory_name &&
              event.catetory_name.name === selectedCategory))
        );
      default:
        return true;
    }
  });

  const pageCount = Math.ceil(filteredEvents.length / eventPerPage);

  const sortEvents = (a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  };

  const displayEvents = filteredEvents
    .slice(pagesVisited, pagesVisited + eventPerPage)
    .sort(sortEvents)
    .map((item, index) => {
      return (
        <EventCard
          key={item.id}
          date={item.date}
          title={item.event_name}
          category={item.catetory_name && item.catetory_name.name}
          description={item.description}
          eventStart={item.event_start}
          eventEnd={item.event_end}
          region={item.city}
          img={item.img_path}
          index={index}
          id={item.id}
        />
      );
    });

  const isDesktop = useMediaQuery("(min-width: 768px)");

  const variants = isDesktop
    ? {
        initial: { opacity: 0, x: -500 },
        animate: { opacity: 1, x: 0 },
      }
    : {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
      };

  const handleSelectChange = (e) => {
    const selectedMonth = e.target.value;
    setMonthChange(selectedMonth);
    updateURL(selectedMonth);
    setPageNumber(0);
  };

  return (
    <section>
      <div ref={titleRef} className="event-section-container">
        <div className="event-sort-container">
          <WinerySelect
            labelTiTle={langContext.lang === "EN" ? "Category:" : "კატეგორია:"}
            values={["All", ...uniqueCategoryNames]} // Include "All" option along with unique category names
            selectClassName="winerySelect"
            handleSelectChange={(e) => {
              setSelectedCategory(e.target.value);
              updateURL(monthChange, e.target.value); // Update URL with selected month and category
              setPageNumber(0);
            }}
            selectedValue={selectedCategory}
            width="204px"
            mobWidth="290px"
          />

          <WinerySelect
            labelTiTle={lang === "EN" ? "Date:" : "თარიღი:"}
            // value1="Upcoming"
            // value2="Past"
            values={dateArray}
            selectClassName="winerySelect"
            handleSelectChange={handleSelectChange}
            selectedValue={monthChange}
            width="204px"
            mobWidth="290px"
          />
        </div>
      </div>
      {loader ? (
        <div className="loarder-cont">
          <div className="loader">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      ) : (
        <>
          {error === "" ? (
            filteredEvents.length > 0 ? (
              <>
                <div className="event-cards-container">{displayEvents}</div>
                <ReactPaginate
                  key={pageNumber}
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName="pagination-container"
                  previousLinkClassName="pagination-prev"
                  nextLinkClassName="pagination-next"
                  disabledLinkClassName="pagination-disable"
                  activeClassName="pagination-active"
                  initialPage={pageNumber}
                />
              </>
            ) : (
              <motion.p
                variants={variants}
                initial={variants.initial}
                animate={variants.animate}
                transition={{
                  ease: "easeOut",
                  duration: 1,
                  delay: 0.8,
                }}
                className="no-more-event-p"
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "FiraGo" }
                    : { fontFamily: "Montserrat" }
                }
              >
                {langContext.lang === "EN"
                  ? "No more events were found"
                  : "მეტი მოვლენა არ მოიძებნა"}
              </motion.p>
            )
          ) : (
            <Alert style={{ textAlign: "center" }} severity="error">
              {error}
            </Alert>
          )}
        </>
      )}
    </section>
  );
}
