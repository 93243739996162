import React, { useContext } from "react";
import "../components/styles/ArticlePage.css";
import { context } from "./Root";

export default function ArticlePageBg({ title }) {
  const langContext = useContext(context);
  return (
    <>
      <div className="article-bg">
        <div className="text-container">
          <h2
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }
            }
          >
            {title &&
              title
                .split(" ")
                .slice(0, 5)
                .join(" ")}
          </h2>
        </div>
      </div>
      <div className="bg-mob-article">
        <div className="text-container">
          <h2
            className="text-container-mob"
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }
            }
          >
            {title &&
              title
                .split(" ")
                .slice(0, 5)
                .join(" ")}
          </h2>
        </div>
      </div>
    </>
  );
}
