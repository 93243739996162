import React, { useEffect } from "react";
import failed from "../images/paymentPages/reject.svg";
import { Link, useLocation } from "react-router-dom";
import api from "../Api/getData";

export default function PaymentFailed() {
  const location = useLocation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const paymentTransactionId = queryParams.get("payment_transaction_id");
        await api.post(`check-transaction`, {
          transaction_id: paymentTransactionId,
        });
        console.log(paymentTransactionId);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [location.search]);
  return (
    <main className="failedPay-main-container">
      <div className="failedPay-container">
        <img src={failed} alt="failed" />
        <h2>Payment Failed!</h2>
        <p className="failed-p">
          The bank declined your payment. Please contact your bank for details.
        </p>
        <p className="failed-sec-p">
          RESULT: FAILED 3DSECURE: REJECTED 3DSECURE_REASON: 12 CARD_NUMBER:
          431571******8022
        </p>
        <Link to={"/wine-passport"}>
          <button>TRY AGAIN</button>
        </Link>
      </div>
    </main>
  );
}
