import React, { useContext, useState } from "react";
import "./styles/WineryListCard.css";
import star from "../images/wineries/star.png";
import { Link } from "react-router-dom";
import locIcon from "../images/loc-icon.svg";
import api from "../Api/getData";
import { context } from "./Root";

export default function WineryListCard({
  title,
  subTitle,
  description,
  location,
  rating,
  id,
  displayIndex,
  image,
  region,
}) {
  const [error, setError] = useState("");
  const langContext = useContext(context);
  // const webSite = process.env.REACT_APP_WEBSITE;
  const fetchWineryLocation = async (wineryId) => {
    try {
      const res = await api.get(`wineries/${wineryId}`);
      const data = res.data.location;
      const { lat, lng } = data;
      window.open(`https://www.google.com/maps?q=${lat},${lng}`);
    } catch (error) {
      setError(error.message);
    }
  };

  return error === "" ? (
    <>
      <Link
        to={`detail/${id.toString()}`}
        className="winery-list-container-mob-tab"
      >
        <img
          style={{
            objectFit: "cover",
            width: "100%",
            borderRadius: "4px 4px 0 0",
            userSelect: "none",
          }}
          src={image}
          alt="images"
        />
        <div className="winery-info-container">
          <h4>{title}</h4>
          <h4>{subTitle}</h4>
          <p>
            {`${description
              .split(" ")
              .slice(0, 10)
              .join(" ")} ...`}
          </p>
          <div className="winery-region-and-rating-container">
            <div
              onClick={(e) => {
                e.preventDefault();
                fetchWineryLocation(id);
              }}
              className="winery-wrapper"
            >
              <img
                style={{
                  width: "24px",
                  color: "#727477",
                  height: "24px",
                  marginRight: "12px",
                }}
                src={locIcon}
                alt="loc-icon"
              />
              <span>{`${location},${region}`}</span>
            </div>
            <div className="winery-rating-wrapper">
              <span style={{ color: "#3A3D43" }}>{`${rating}/5`}</span>
              <img style={{ userSelect: "none" }} src={star} alt="star" />
            </div>
          </div>
          <button className="learn-more-btn">
            {langContext.lang === "EN" ? "Learn more" : "გაიგე მეტი"}
          </button>
        </div>
      </Link>
      <Link
        to={`detail/${id.toString()}`}
        className="winery-list-container"
        // style={
        //   displayIndex === 2
        //     ? { width: "300px" }
        //     : displayIndex === 1
        //     ? { width: "600px" }
        //     : { width: "400px" }
        // } // initial={{ opacity: 0 }}
        // whileInView={{ opacity: 1 }}
        // transition={{ duration: 1 }}
      >
        <div className="winery-img-zoom-cont">
          <img className="wineryList-img" src={image} alt="images" />
        </div>
        <div className="winery-info-container">
          <h4>{title}</h4>
          <h4>{subTitle}</h4>
          <p>
            {`${description
              .split(" ")
              .slice(0, 10)
              .join(" ")} ...`}
          </p>
          <div className="winery-region-and-rating-container">
            <div
              className="winery-wrapper"
              onClick={(e) => {
                e.preventDefault();
                fetchWineryLocation(id);
              }}
            >
              <img
                style={{
                  width: "24px",
                  color: "#727477",
                  height: "24px",
                  marginRight: "12px",
                }}
                src={locIcon}
                alt="loc-icon"
              />
              <span>{`${location},${region}`}</span>
            </div>
            <div className="winery-rating-wrapper">
              <span style={{ color: "#3A3D43" }}>{`${rating}/5`}</span>
              <img style={{ userSelect: "none" }} src={star} alt="star" />
            </div>
          </div>
          <button className="learn-more-btn">
            {langContext.lang === "EN" ? "Learn more" : "გაიგე მეტი"}
          </button>{" "}
        </div>
      </Link>
    </>
  ) : (
    error
  );
}
