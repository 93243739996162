import wine1 from "../images/item/ველები-ქვევრის-მწვანე-113x300.png";
import wine2 from "../images/item/ამბერ-ქისი-113x300.png";
import wine3 from "../images/item/NAN_ALAZANI-VALLEY_W-113x300.png";
import wine4 from "../images/item/tsinandali-113x300.png";
import wine5 from "../images/item/მწვანე-1-113x300.png";
import similarwine1 from "../images/similarwine/chelsea-pridham-MMOXoxE_Gj0-unsplash.png";
import similarwine2 from "../images/similarwine/kelsey-chance-BB4GbHiU1KY-unsplash.png";
import similarwine3 from "../images/similarwine/marcel-gross-marcelgross-ch-sHTwDYKti6Q-unsplash.png";
import similarwine4 from "../images/similarwine/no-revisions-Q5OpcL166e8-unsplash.png";
import wine from "../images/similarwine/chelsea-pridham-MMOXoxE_Gj0-unsplash.png";
import georgia from "../images/maploc/georgia.svg";
import abkhazia from "../images/maploc/abkhazia.svg";
import adjara from "../images/maploc/Adjara.svg";
import guria from "../images/maploc/guria.svg";
import imereti from "../images/maploc/imereti.svg";
import racha from "../images/maploc/racha.svg";
import kakheti from "../images/maploc/kakheti.svg";
import kartli from "../images/maploc/kartli.svg";
import mtskheta from "../images/maploc/mtskheta-mtianeti.svg";
import samegrelo from "../images/maploc/samegrelo.svg";
import wineListImage1 from "../images/wineries/first.png";
import wineListImage2 from "../images/wineries/Rectangle 583.png";
import wineListImage3 from "../images/wineries/3.png";
import wineListImage4 from "../images/wineries/4.png";
import wineListImage5 from "../images/wineries/5.png";
import wineListImage6 from "../images/wineries/6.png";
import wineListImage7 from "../images/wineries/7.png";
import wineListImage8 from "../images/wineries/8.png";
import wineListImage9 from "../images/wineries/9.png";
import wineListImage10 from "../images/wineries/10.png";
import wineListImage11 from "../images/wineries/11.png";
import wineListImage12 from "../images/wineries/12.png";
import article1Img from "../images/articlePage/thumbnail-2.png";
import article2Img from "../images/articlePage/thumbnail-3.png";
import article3Img from "../images/articlePage/thumbnail-4.png";
import article5Img from "../images/articlePage/thumbnail-6.png";
import article4Img from "../images/articlePage/thumbnail-5.png";
import article6Img from "../images/articlePage/thumbnail-7.png";
import article7Img from "../images/articlePage/thumbnail-8.png";
import article8Img from "../images/articlePage/thumbnail-9.png";
import article9Img from "../images/articlePage/thumbnail-10.png";
import articleMainImg from "../images/articlePage/mainarticle.png";
import author1 from "../images/articlePage/Ellipse 1.svg";
import author2 from "../images/articlePage/author-2.svg";
// Event List Images
import eventImg1 from "../images/event-page/eventImg1.png";
import eventImg2 from "../images/event-page/eventImg2.png";
import eventImg3 from "../images/event-page/eventImg3.png";
import eventImg4 from "../images/event-page/eventImg4.png";
import eventImg5 from "../images/event-page/EventImg5.png";
import eventImg6 from "../images/event-page/eventImg6.png";
import eventImg7 from "../images/event-page/eventImg7.png";
import qr1 from "../images/profilePage/qr.png";
import qr2 from "../images/profilePage/qr2.png";
import winepass1 from "../images/profilePage/winepass1.png";
import winepass2 from "../images/profilePage/winepass2.png";

import testimageForarticle from "../images/detail-bg-mob.png";

import galleryImg1 from "../images/gallery/1.png";
import galleryImg2 from "../images/gallery/2.png";
import galleryImg3 from "../images/gallery/3.png";

export const galleyData = [
  { id: 1, img: galleryImg1 },
  { id: 2, img: galleryImg2 },
  { id: 3, img: galleryImg3 },
  { id: 4, img: galleryImg1 },
  { id: 5, img: galleryImg2 },
  { id: 6, img: galleryImg3 },
];

export const winePassDetail = [
  {
    id: 1,
    name: "TOGONIDZE’S WINE",
    village: "Shalauri",
    city: "telavi",
    region: "kakheti",
    phone: "(+995) 591 22 95 94",
    email: "giatogonidze4@gmail.com",
    offers: ["5 % discount for everyone, who receives a Wine  Passport."],
    wines: ["RKatsiteli", "Mtsvane", "Saperavi"],
    guests: "1-30",
  },
  {
    id: 2,
    name: "DANO WINERY",
    village: "Khornabuji, ",
    city: "Signagi",
    region: "kakheti",
    phone: "(+995) 555 29 61 00",
    email: "danowinery@gmail.com",
    offers: ["5 % discount and addiotional hike to Khornabuji Castle"],
    wines: ["RKatsiteli", "Kakhuri Mtsvane", "Buera"],
    guests: "2-15",
  },
  {
    id: 3,
    name: "ZURAB KVIRIASHVILI WINEYARDS",
    village: "26 Maisi St",
    city: "telavi",
    region: "kakheti",
    phone: "(+995) 577 77 40 03",
    email: "kvineyards@gmail.com",
    offers: ["12 % discount for everyone, who has a Wine Passport."],
    wines: ["RKatsiteli", "Mtsvane", "Saperavi"],
    guests: "2-20",
  },
  {
    id: 4,
    name: "MARTALI WINE",
    village: "Shushiani",
    city: "gurjaani",
    region: "kakheti",
    phone: "(+995) 577 20 30 07",
    email: "martaliwine@gmail.com",
    offers: ["10 %discount for everyone, who receives a Wine  Passport."],
    wines: ["RKatsiteli", "Saperavi", "Saperavi Semysweet"],
    guests: "6-30",
  },
];

export const winePasses = [
  {
    id: 1,
    owner: "Anna Kvernadze",
    access: ["Wine tasting", "purchase deals"],
    area: "All around Georgian vineyards",
    expires: "01/01/2025",
    qr: qr1,
    winepass: winepass1,
    isActive: true,
  },
  {
    id: 2,
    owner: "Anna Kvernadze",
    access: ["Wine tasting", "purchase deals"],
    area: "All around Georgian vineyards",
    expires: "01/01/2022",
    qr: qr2,
    winepass: winepass2,
    isActive: false,
  },
];

export const eventList = [
  {
    id: 1,
    img: eventImg1,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 2,
    img: eventImg2,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 3,
    img: eventImg3,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 4,
    img: eventImg4,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 5,
    img: eventImg5,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 6,
    img: eventImg6,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 7,
    img: eventImg7,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 8,
    img: eventImg1,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },

  {
    id: 11,
    img: eventImg4,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 9,
    img: eventImg2,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 10,
    img: eventImg3,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 12,
    img: eventImg5,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },

  {
    id: 14,
    img: eventImg7,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 13,
    img: eventImg6,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 15,
    img: eventImg1,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 16,
    img: eventImg2,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 17,
    img: eventImg3,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 18,
    img: eventImg4,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 19,
    img: eventImg5,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 20,
    img: eventImg6,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
  {
    id: 21,
    img: eventImg7,
    month: "May",
    day: 12,
    year: 2023,
    eventName: "New Wine Festival",
    eventCompany: "Georgian Wine",
    eventDescription:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
    eventStart: "11 am",
    eventEnd: "6 pm",
    city: "Tbilisi",
  },
];

export const articleList = [
  {
    id: 1,
    img: articleMainImg,
    title: "Georgia Wine Country - The Cradle of Wine",
    company: "Family Cellars",
    date: "July 2, 2021",
    description:
      "The country's extensive wine history, culture, and gastronomy make it extremely attractive for international visitors. This hidden gem of the wine world is now accessible for travellers and wine lovers to discover ancient traditions of winemaking through Georgian wine regions.",
    authorImg: author1,
    author: "Malkhaz Kharbedia",
    position: "Wine writer",
  },
  {
    id: 2,
    img: article1Img,
    title: "How to design a ",
    company: "Family Cellars",
    date: "July 2, 2021",
    description: "Auctor Porta. dolor.",
    authorImg: author2,
    author: "Jenny Wilson",
    position: "Product Designer",
  },
  {
    id: 3,
    img: winepass1,
    title: "How to design a product that can grow itself",
    company: "Family Cellars",
    date: "July 2, 2021",
    description:
      "Auctor Porta. Augue vitae diam mauris faucibus blandit elit per, feugiat leo dui orci. Etiam vestibulum. Nostra netus per conubia dolor.",
    authorImg: author2,
    author: "Jenny Wilson",
    position: "Product Designer",
  },
  {
    id: 4,
    img: testimageForarticle,
    title: "How to design a product that can grow itself",
    company: "Family Cellars",
    date: "July 2, 2021",
    description:
      "Auctor Porta. Augue vitae diam mauris faucibus blandit elit per, feugiat leo dui orci. Etiam vestibulum. Nostra netus per conubia dolor.",
    authorImg: author2,
    author: "Jenny Wilson",
    position: "Product Designer",
  },
  {
    id: 5,
    img: article4Img,
    title: "How to design a product that can grow itself",
    company: "Family Cellars",
    date: "July 2, 2021",
    description:
      "Auctor Porta. Augue vitae diam mauris faucibus blandit elit per, feugiat leo dui orci. Etiam vestibulum. Nostra netus per conubia dolor.",
    authorImg: author2,
    author: "Jenny Wilson",
    position: "Product Designer",
  },
  {
    id: 6,
    img: article5Img,
    title: "How to design a product that can grow itself",
    company: "Family Cellars",
    date: "July 2, 2021",
    description:
      "Auctor Porta. Augue vitae diam mauris faucibus blandit elit per, feugiat leo dui orci. Etiam vestibulum. Nostra netus per conubia dolor.",
    authorImg: author2,
    author: "Jenny Wilson",
    position: "Product Designer",
  },
  {
    id: 7,
    img: article6Img,
    title: "How to design a product that can grow itself",
    company: "Family Cellars",
    date: "July 2, 2021",
    description:
      "Auctor Porta. Augue vitae diam mauris faucibus blandit elit per, feugiat leo dui orci. Etiam vestibulum. Nostra netus per conubia dolor.",
    authorImg: author2,
    author: "Jenny Wilson",
    position: "Product Designer",
  },
  {
    id: 8,
    img: article7Img,
    title: "How to design a product that can grow itself",
    company: "Family Cellars",
    date: "July 2, 2021",
    description:
      "Auctor Porta. Augue vitae diam mauris faucibus blandit elit per, feugiat leo dui orci. Etiam vestibulum. Nostra netus per conubia dolor.",
    authorImg: author2,
    author: "Jenny Wilson",
    position: "Product Designer",
  },
  {
    id: 9,
    img: article8Img,
    title: "How to design a product that can grow itself",
    company: "Family Cellars",
    date: "July 2, 2021",
    description:
      "Auctor Porta. Augue vitae diam mauris faucibus blandit elit per, feugiat leo dui orci. Etiam vestibulum. Nostra netus per conubia dolor.",
    authorImg: author2,
    author: "Jenny Wilson",
    position: "Product Designer",
  },
  {
    id: 10,
    img: article9Img,
    title: "How to design a product that can grow itself",
    company: "Family Cellars",
    date: "July 2, 2021",
    description:
      "Auctor Porta. Augue vitae diam mauris faucibus blandit elit per, feugiat leo dui orci. Etiam vestibulum. Nostra netus per conubia dolor.",
    authorImg: author2,
    author: "Jenny Wilson",
    position: "Product Designer",
  },
  {
    id: 11,
    img: article1Img,
    title: "How to design a product that can grow itself",
    company: "Family Cellars",
    date: "July 2, 2021",
    description:
      "Auctor Porta. Augue vitae diam mauris faucibus blandit elit per, feugiat leo dui orci. Etiam vestibulum. Nostra netus per conubia dolor.",
    authorImg: author2,
    author: "Jenny Wilson",
    position: "Product Designer",
  },
  {
    id: 12,
    img: article3Img,
    title: "How to design a product that can grow itself",
    company: "Family Cellars",
    date: "July 2, 2021",
    description:
      "Auctor Porta. Augue vitae diam mauris faucibus blandit elit per, feugiat leo dui orci. Etiam vestibulum. Nostra netus per conubia dolor.",
    authorImg: author2,
    author: "Jenny Wilson",
    position: "Product Designer",
  },
  {
    id: 13,
    img: article5Img,
    title: "How to design a product that can grow itself",
    company: "Family Cellars",
    date: "July 2, 2021",
    description:
      "Auctor Porta. Augue vitae diam mauris faucibus blandit elit per, feugiat leo dui orci. Etiam vestibulum. Nostra netus per conubia dolor.",
    authorImg: author2,
    author: "Jenny Wilson",
    position: "Product Designer",
  },
  {
    id: 14,
    img: article3Img,
    title: "How to design a product that can grow itself 2 pages",
    company: "Family Cellars",
    date: "July 2, 2021",
    description:
      "Auctor Porta. Augue vitae diam mauris faucibus blandit elit per, feugiat leo dui orci. Etiam vestibulum. Nostra netus per conubia dolor.",
    authorImg: author2,
    author: "Jenny Wilson",
    position: "Product Designer",
  },
  {
    id: 15,
    img: article2Img,
    title: "How to design a product that can grow itself",
    company: "Family Cellars",
    date: "July 2, 2021",
    description:
      "Auctor Porta. Augue vitae diam mauris faucibus blandit elit per, feugiat leo dui orci. Etiam vestibulum. Nostra netus per conubia dolor.",
    authorImg: author2,
    author: "Jenny Wilson",
    position: "Product Designer",
  },
  {
    id: 16,
    img: article8Img,
    title: "How to design a product that can grow itself",
    company: "Family Cellars",
    date: "July 2, 2021",
    description:
      "Auctor Porta. Augue vitae diam mauris faucibus blandit elit per, feugiat leo dui orci. Etiam vestibulum. Nostra netus per conubia dolor.",
    authorImg: author2,
    author: "Jenny Wilson",
    position: "Product Designer",
  },
];

export const markerPositions = {
  georgia: [
    {
      center: {
        lat: 42.3154,
        lng: 43.3569,
      },
    },
    {
      id: 1,
      wineryName: "AKAKISEULI FAMILY CELLAR",
      location: {
        lat: 41.6434,
        lng: 42.9934,
      },
      img: wine,
      region: "Akhaltsikhe",
      village: "Village Tsnisi",
      wineTypes: [
        "Grape",
        "Grape1",
        "Grape2",
        "Grape3",
        "Grape4",
        "Grape5",
        "Grape6",
        "Grape7",
      ],
      territory: "Akhaltsikhe",
    },
    {
      id: 2,
      wineryName: "AKAKISEULI FAMILY CELLAR2",
      location: {
        lat: 41.678246,
        lng: 42.076126,
      },
      img: wine,
      region: "Adjara",
      village: "batumi",
      wineTypes: ["Grape", "Grape1", "Grape2", "Grape3", "Grape4"],
      territory: "Batumi",
    },
    {
      id: 3,
      wineryName: "AKAKISEULI FAMILY CELLAR3",
      location: {
        lat: 41.816,
        lng: 41.7838,
      },
      img: wine,
      region: "Akhaltsikhe",
      village: "Village Tsnisi",
      wineTypes: ["Grape3", "Grape4", "Grape5", "Grape6", "Grape7"],
      territory: "Akhaltsikhe",
    },
    {
      id: 4,
      wineryName: "AKAKISEULI FAMILY CELLAR4",
      location: {
        lat: 41.698756,
        lng: 41.863262,
      },
      img: wine,
      region: "Akhaltsikhe",
      village: "Village Tsnisi",
      wineTypes: ["Grape3", "Grape4", "Grape5", "Grape6", "Grape7"],
      territory: "Akhaltsikhe",
    },
    {
      id: 5,
      wineryName: "Abkhazia FAMILY CELLAR5",
      location: {
        lat: 43.163119,
        lng: 40.326807,
      },
      img: wine,
      region: "Abkhazia",
      village: "Village Tsnisi",
      wineTypes: ["Grape3", "Grape5", "Grape6", "Grape7"],
      territory: "Sokhumi",
    },
    {
      id: 6,
      wineryName: "guria FAMILY CELLAR",
      location: {
        lat: 42.011142,
        lng: 42.235896,
      },
      img: wine,
      region: "Guria",
      village: "Village Tsnisi",
      wineTypes: ["Grape", "Grape1", "Grape2", "Grape6", "Grape7"],
      territory: "Lanchkhuti",
    },
    {
      id: 7,
      wineryName: "imereti FAMILY CELLAR",
      location: {
        lat: 42.178671,
        lng: 42.977009,
      },
      img: wine,
      region: "Imereti",
      village: "Village Tsnisi",
      wineTypes: ["Grape3", "Grape4", "Grape5", "Grape6", "Grape7"],
      territory: "Samtredia",
    },
    {
      id: 8,
      wineryName: "racha FAMILY CELLAR",
      location: {
        lat: 42.525962,
        lng: 43.143593,
      },
      img: wine,
      region: "Racha-lechkhumi",
      village: "Village Tsnisi",
      wineTypes: ["Grape5", "Grape6", "Grape7"],
      territory: "Oni",
    },
    {
      id: 9,
      wineryName: "kakheti FAMILY CELLAR",
      location: {
        lat: 41.633715,
        lng: 45.960916,
      },
      img: wine,
      region: "Kakheti",
      village: "Village Tsnisi",
      wineTypes: ["Grape", "Grape1", "Grape2", "Grape3"],
      territory: "Telavi",
    },
  ],

  kakheti: [
    {
      center: {
        lat: 41.6482,
        lng: 45.6906,
      },
    },
    {
      id: 10,
      wineryName: "kakheti FAMILY CELLAR",
      location: {
        lat: 41.633715,
        lng: 45.960916,
      },
      img: wine,
      region: "Kakheti",
      village: "Village Tsnisi",
      wineTypes: ["Grape", "Grape1", "Grape2", "Grape3"],
      territory: "Telavi",
    },
  ],
  adjara: [
    {
      center: {
        lat: 41.6006,
        lng: 42.0688,
      },
    },
    {
      id: 11,
      wineryName: "batumi FAMILY CELLAR",
      location: {
        lat: 41.678246,
        lng: 42.076126,
      },

      img: wine,
      region: "Adjara",
      village: "batumi",
      wineTypes: ["Grape", "Grape1", "Grape2", "Grape3", "Grape4"],
      territory: "Batumi",
    },
    {
      id: 12,
      wineryName: "AKAKISEULI FAMILY CELLAR",
      location: {
        lat: 41.816,
        lng: 41.7838,
      },
      img: wine,
      region: "Akhaltsikhe",
      village: "Village Tsnisi",
    },
    {
      id: 13,
      wineryName: "AKAKISEULI FAMILY CELLAR",
      location: {
        lat: 41.698756,
        lng: 41.863262,
      },
      img: wine,
      region: "Akhaltsikhe",
      village: "Village Tsnisi",
    },
  ],

  abkhazia: [
    {
      center: {
        lat: 43.0015,
        lng: 41.0234,
      },
    },
    {
      id: 14,
      wineryName: "Abkhazia FAMILY CELLAR",
      location: {
        lat: 43.163119,
        lng: 40.326807,
      },

      img: wine,
      region: "Abkhazia",
      village: "Village Tsnisi",
      wineTypes: ["Grape3", "Grape5", "Grape6", "Grape7"],
      territory: "Sokhumi",
    },
  ],
  guria: [
    {
      center: {
        lat: 41.9443,
        lng: 42.0458,
      },
    },
    {
      id: 15,
      wineryName: "guria FAMILY CELLAR",
      location: {
        lat: 42.011142,
        lng: 42.235896,
      },

      img: wine,
      region: "guria",
      village: "Village Tsnisi",
      wineTypes: ["Grape", "Grape1", "Grape2", "Grape6", "Grape7"],
      territory: "Lanchkhuti",
    },
  ],
  imereti: [
    {
      center: {
        lat: 42.2301,
        lng: 42.9009,
      },
    },
    {
      id: 16,
      wineryName: "imereti FAMILY CELLAR",
      location: {
        lat: 42.178671,
        lng: 42.977009,
      },

      img: wine,
      region: "imereti",
      village: "Village Tsnisi",
      wineTypes: ["Grape3", "Grape4", "Grape5", "Grape6", "Grape7"],
      territory: "Samtredia",
    },
  ],
  racha_lechkhumi: [
    {
      center: {
        lat: 42.6719,
        lng: 43.0563,
      },
    },
    {
      id: 17,
      wineryName: "racha FAMILY CELLAR",
      location: {
        lat: 42.525962,
        lng: 43.143593,
      },
      img: wine,
      region: "racha",
      village: "Village Tsnisi",
      wineTypes: ["Grape5", "Grape6", "Grape7"],
      territory: "Oni",
    },
  ],
};
export const teest = [
  {
    id: 1,
    center: {
      lat: 43.0015,
      lng: 41.0234,
    },
    region_name: "აფხაზეთი",
    city: "სოხუმი",
    winners: [
      {
        id: 1,
        name: "სოხუმის მარანი",
        address: "ჩარდახი",
        location: {
          lat: 43.163119,
          lng: 40.326807,
        },
        description:
          "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
        rating: 5,
        region: "აფხაზეთი",
        city: "სოხუმი",
        wineType: ["ღვინის ტიპი 1", "ღვინის ტიპი 2", "ღვინის ტიპი 3"],
      },
    ],
  },
  {
    id: 2,
    center: {
      lat: 41.6461,
      lng: 41.6405,
    },
    region_name: "აჭარა",
    city: "ბათუმი",
    winners: [
      {
        id: 2,
        name: "ბათუმის მარანი ",
        address: "ჩარდახი",
        location: {
          lat: 41.678246,
          lng: 42.076126,
        },
        description:
          "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
        rating: 5,
        region: "აჭარა",
        city: "ბათუმი",
        wineType: ["ღვინის ტიპი 1"],
      },
    ],
  },
  {
    id: 3,
    center: {
      lat: 42.011142,
      lng: 42.235896,
    },
    region_name: "გურია",
    city: "ლანჩხუთი",
    winners: [
      {
        id: 3,
        name: "გურული მარანი",
        address: "ჩარდახი",
        location: {
          lat: 42.011142,
          lng: 42.235896,
        },
        description:
          "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
        rating: 5,
        region: "გურია",
        city: "ლანჩხუთი",
        wineType: ["ღვინის ტიპი 2", "ღვინის ტიპი 3"],
      },
    ],
  },
  {
    id: 4,
    center: {
      lat: 42.2718,
      lng: 42.706,
    },
    region_name: "იმერეთი",
    city: "ქუთაისი",
    winners: [
      {
        id: 4,
        name: "ქუთაისის მარანი",
        address: "ჩარდახი",
        location: {
          lat: 42.2718,
          lng: 42.706,
        },
        description:
          "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
        rating: 5,
        region: "იმერეთი",
        city: "ქუთაისი",
        wineType: ["ღვინის ტიპი 1", "ღვინის ტიპი 2", "ღვინის ტიპი 3"],
      },
    ],
  },
  {
    id: 5,
    center: {
      lat: 42.5779,
      lng: 43.4351,
    },
    region_name: "იმერეთი",
    city: "ქუთაისი",
    winners: [
      {
        id: 5,
        name: "რაჭის მარანი",
        address: "ჩარდახი",
        location: {
          lat: 42.525962,
          lng: 43.143593,
        },
        description:
          "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
        rating: 5,
        region: "რაჭა-ლეჩხუმი",
        city: "ონი",
        wineType: ["ღვინის ტიპი 1", "ღვინის ტიპი 2", "ღვინის ტიპი 3"],
      },
    ],
  },
  {
    id: 6,
    center: {
      lat: 41.7439,
      lng: 45.7894,
    },
    region_name: "კახეთი",
    city: "თელავი",
    winners: [
      {
        id: 6,
        name: "აკოს ღვინის მარანი",
        address: "ჭავჭავაძის 17ბ",
        location: {
          lat: 41.633715,
          lng: 45.960916,
        },
        description:
          "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
        rating: 5,
        region: "კახეთი",
        city: "თელავი",
        wineType: [
          "ღვინის ტიპი 1",
          "ღვინის ტიპი 2",
          "ღვინის ტიპი 3",
          "ღვინის ტიპი 5",
        ],
      },
      {
        id: 7,
        name: "სატესტო ღვინის მარანი 1",
        address: "სატესტო მისამართი 1",
        location: {
          lat: 41.6111,
          lng: 45.9271,
        },
        description:
          "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
        rating: 5,
        region: "კახეთი",
        city: "სიღნაღი",
        wineType: ["ღვინის ტიპი 1", "ღვინის ტიპი 2", "ღვინის ტიპი 3"],
      },
      {
        id: 8,
        name: "კალამი",
        address: "ჩარდახი",
        location: {
          lat: 41.7439,
          lng: 45.7894,
        },
        description:
          "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy",
        rating: 5,
        region: "კახეთი",
        city: "სიღნაღი",
        wineType: ["ღვინის ტიპი 1", "ღვინის ტიპი 2", "ღვინის ტიპი 3"],
      },
    ],
  },
  {
    id: 7,
    center: {
      lat: 41.7215,
      lng: 44.7743,
    },
  },
  {
    id: 8,
    center: {
      lat: 41.8412,
      lng: 44.7074,
    },
  },
  {
    id: 9,
    center: {
      lat: 42.5091,
      lng: 41.867,
    },
  },
];

export const mapSlider = [
  {
    id: 1,
    mapImg: georgia,
    region: "Georgia",
  },
  {
    id: 2,
    mapImg: abkhazia,
    region: "Abkhazia",
  },
  {
    id: 3,
    mapImg: adjara,
    region: "Adjara",
  },
  {
    id: 4,
    mapImg: guria,
    region: "Guria",
  },
  {
    id: 5,
    mapImg: imereti,
    region: "Imereti",
  },
  {
    id: 6,
    mapImg: racha,
    region: "Racha-Lechkhumi",
  },
  {
    id: 7,
    mapImg: kakheti,
    region: "Kakheti",
  },

  {
    id: 9,
    mapImg: kartli,
    region: "Kartli",
  },
  {
    id: 9,
    mapImg: mtskheta,
    region: "Mtskheta-Mtianeti",
  },
  {
    id: 9,
    mapImg: samegrelo,
    region: "Samegrelo",
  },
];

// export const sliderImages = [
//   location1,
//   location2,
//   location3,
//   location4,
//   location5,
//   location6,
//   location7,
//   location2,
//   location3,
// ];

export const regionForFilter = [
  { id: 1, name: "ქართლი" },
  { id: 2, name: "კახეთი" },
];

export const region = [
  "All Region",
  "Abkhazia",
  "Guria",
  "Racha-lechkhumi",
  "Imereti",
  "Kakheti",
];

export const wineTypeForFilter = [
  { id: 1, name: "ქართლი" },
  { id: 2, name: "კახეთი" },
];
export const wineFromTeritories = [
  { id: 1, name: "ქართლი" },
  { id: 2, name: "კახეთი" },
];
export const wineType = [
  { id: 1, name: "ქართლი" },
  { id: 2, name: "კახეთი" },
];

export const territories = [
  "All territories",
  "Tbilisi",
  "Telavi",
  "Akhaltsikhe",
  "Oni",
  "Batumi",
  "Lanchkhuti",
  "Sokhumi",
];
export const wineTypeForMap = [
  "All Type",
  "Grape",
  "Grape1",
  "Grape2",
  "Grape3",
  "Grape4",
  "Grape5",
  "Grape6",
  "Grape7",
];

export const markerPositions2 = [
  {
    georgia: [
      {
        center: {
          lat: 42.3154,
          lng: 43.3569,
        },
      },
      {
        id: 1,
        wineryName: "AKAKISEULI FAMILY CELLAR",
        location: {
          lat: 41.6434,
          lng: 42.9934,
        },
        img: wine,
        region: "Akhaltsikhe",
        village: "Village Tsnisi",
      },
      {
        id: 2,
        wineryName: "AKAKISEULI FAMILY CELLAR",
        location: {
          lat: 41.678246,
          lng: 42.076126,
        },
        img: wine,
        region: "Adjara",
        village: "batmi",
      },
      {
        id: 3,
        wineryName: "AKAKISEULI FAMILY CELLAR",
        location: {
          lat: 41.816,
          lng: 41.7838,
        },
        img: wine,
        region: "Akhaltsikhe",
        village: "Village Tsnisi",
      },
      {
        id: 4,
        wineryName: "AKAKISEULI FAMILY CELLAR",
        location: {
          lat: 41.698756,
          lng: 41.863262,
        },
        img: wine,
        region: "Akhaltsikhe",
        village: "Village Tsnisi",
      },
      {
        id: 5,
        wineryName: "Abkhazia FAMILY CELLAR",
        location: {
          lat: 43.163119,
          lng: 40.326807,
        },
        img: wine,
        region: "Abkhazia",
        village: "Village Tsnisi",
      },
      {
        id: 6,
        wineryName: "guria FAMILY CELLAR",
        location: {
          lat: 42.011142,
          lng: 42.235896,
        },
        img: wine,
        region: "guria",
        village: "Village Tsnisi",
      },
      {
        id: 7,
        wineryName: "imereti FAMILY CELLAR",
        location: {
          lat: 42.178671,
          lng: 42.977009,
        },
        img: wine,
        region: "imereti",
        village: "Village Tsnisi",
      },
      {
        id: 8,
        wineryName: "racha FAMILY CELLAR",
        location: {
          lat: 42.525962,
          lng: 43.143593,
        },
        img: wine,
        region: "racha",
        village: "Village Tsnisi",
      },
      {
        id: 9,
        wineryName: "kakheti FAMILY CELLAR",
        location: {
          lat: 41.633715,
          lng: 45.960916,
        },
        img: wine,
        region: "kakheti",
        village: "Village Tsnisi",
      },
    ],
  },
  {
    kakheti: [
      {
        center: {
          lat: 41.6482,
          lng: 45.6906,
        },
      },
      {
        id: 10,
        wineryName: "kakheti FAMILY CELLAR",
        location: {
          lat: 41.633715,
          lng: 45.960916,
        },
        img: wine,
        region: "kakheti",
        village: "Village Tsnisi",
      },
    ],
  },

  {
    adjara: [
      {
        center: {
          lat: 41.6006,
          lng: 42.0688,
        },
      },
      {
        id: 11,
        wineryName: "batumi FAMILY CELLAR",
        location: {
          lat: 41.678246,
          lng: 42.076126,
        },

        img: wine,
        region: "Adjara",
        village: "batmi",
      },
      {
        id: 12,
        wineryName: "AKAKISEULI FAMILY CELLAR",
        location: {
          lat: 41.816,
          lng: 41.7838,
        },
        img: wine,
        region: "Akhaltsikhe",
        village: "Village Tsnisi",
      },
      {
        id: 13,
        wineryName: "AKAKISEULI FAMILY CELLAR",
        location: {
          lat: 41.698756,
          lng: 41.863262,
        },
        img: wine,
        region: "Akhaltsikhe",
        village: "Village Tsnisi",
      },
    ],
  },
  {
    abkhazia: [
      {
        center: {
          lat: 43.0015,
          lng: 41.0234,
        },
      },
      {
        id: 14,
        wineryName: "Abkhazia FAMILY CELLAR",
        location: {
          lat: 43.163119,
          lng: 40.326807,
        },

        img: wine,
        region: "abkhazia",
        village: "Village Tsnisi",
      },
    ],
  },
  {
    guria: [
      {
        center: {
          lat: 41.9443,
          lng: 42.0458,
        },
      },
      {
        id: 15,
        wineryName: "guria FAMILY CELLAR",
        location: {
          lat: 42.011142,
          lng: 42.235896,
        },

        img: wine,
        region: "guria",
        village: "Village Tsnisi",
      },
    ],
  },
  {
    imereti: [
      {
        center: {
          lat: 42.2301,
          lng: 42.9009,
        },
      },
      {
        id: 16,
        wineryName: "imereti FAMILY CELLAR",
        location: {
          lat: 42.178671,
          lng: 42.977009,
        },

        img: wine,
        region: "imereti",
        village: "Village Tsnisi",
      },
    ],
  },
  {
    racha_lechkhumi: [
      {
        center: {
          lat: 42.6719,
          lng: 43.0563,
        },
      },
      {
        id: 17,
        wineryName: "racha FAMILY CELLAR",
        location: {
          lat: 42.525962,
          lng: 43.143593,
        },
        img: wine,
        region: "racha",
        village: "Village Tsnisi",
      },
    ],
  },
];

export const wineriesList = {
  georgia: [
    {
      center: {
        lat: 42.3154,
        lng: 43.3569,
      },
      wineriesLists: [
        {
          id: 1,
          image: wineListImage1,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe",
          rating: "5/5",
          regionFilter: "Kakheti",
          wineTypes: [
            "Grape",
            "Grape1",
            "Grape2",
            "Grape3",
            "Grape4",
            "Grape5",
            "Grape6",
            "Grape7",
          ],
          territory: "Tbilisi",
          detail: {
            name: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: [
              "Best organic wine producer , 2020- GOLDEN AWARD",
              "Best organic wine producer , 2020- GOLDEN AWARD",
              "Best organic wine producer , 2020- GOLDEN AWARD",
            ],
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
            location: {
              lat: 43.163119,
              lng: 40.326807,
            },
          },
        },
        {
          id: 2,
          image: wineListImage2,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Kakheti",
          wineTypes: [
            "Grape",
            "Grape1",
            "Grape2",
            "Grape3",
            "Grape4",
            "Grape5",
            "Grape6",
            "Grape7",
          ],

          territory: "Borjomi",
          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 3,
          image: wineListImage3,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Samegrelo",
          wineTypes: [
            "Grape",
            "Grape1",
            "Grape2",
            "Grape3",
            "Grape4",
            "Grape5",
          ],

          territory: "Batumi",
          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 4,
          image: wineListImage4,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Guria",
          wineTypes: [
            "Grape",
            "Grape9",
            "Grape2",
            "Grape3",
            "Grape4",
            "Grape5",
            "Grape6",
          ],

          territory: "Telavi",
          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 5,
          image: wineListImage5,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Kartli",
          wineTypes: [
            "Grape",
            "Grape9",
            "Grape8",
            "Grape3",
            "Grape4",
            "Grape5",
            "Grape6",
          ],

          territory: "Telavi",
          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 6,
          image: wineListImage6,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Kartli",
          wineTypes: [
            "Grape",
            "Grape9",
            "Grape8",
            "Grape3",
            "Grape4",
            "Grape5",
            "Grape6",
          ],

          territory: "Batumi",
          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 7,
          image: wineListImage7,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Imereti",
          wineTypes: ["Grape", "Grape9"],

          territory: "Batumi",
          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 8,
          image: wineListImage8,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Kakheti",
          wineTypes: [
            "Grape",
            "Grape9",
            "Grape8",
            "Grape3",
            "Grape5",
            "Grape6",
          ],

          territory: "Batumi",

          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 9,
          image: wineListImage9,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Kakheti",
          wineTypes: [
            "Grape",
            "Grape9",
            "Grape8",
            "Grape4",
            "Grape5",
            "Grape6",
          ],

          territory: "Batumi",
          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 10,
          image: wineListImage10,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Racha-lechkhumi",
          wineTypes: [
            "Grape",
            "Grape9",
            "Grape8",
            "Grape3",
            "Grape4",
            "Grape5",
            "Grape6",
          ],

          territory: "Telavi",
          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 11,
          image: wineListImage11,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Racha-lechkhumi",
          wineTypes: ["Grape", "Grape4", "Grape5", "Grape6"],

          territory: "Telavi",

          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 12,
          image: wineListImage12,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Samegrelo",
          wineTypes: ["Grape", "Grape9", "Grape8", "Grape5", "Grape6"],

          territory: "Telavi",
          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 13,
          image: wineListImage12,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Samegrelo",
          wineTypes: ["Grape", "Grape3", "Grape4", "Grape5", "Grape6"],

          territory: "Tbilisi",
          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 14,
          image: wineListImage1,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Adjara",
          wineTypes: [
            "Grape",
            "Grape9",
            "Grape8",
            "Grape3",
            "Grape4",
            "Grape5",
            "Grape6",
          ],

          territory: "Tbilisi",
          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 15,
          image: wineListImage2,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Adjara",
          wineTypes: [
            "Grape",
            "Grape9",
            "Grape8",
            "Grape3",
            "Grape4",
            "Grape5",
            "Grape6",
          ],

          territory: "Tbilisi",
          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 16,
          image: wineListImage3,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Imereti",
          wineTypes: ["Grape5", "Grape6"],

          territory: "Tbilisi",

          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 17,
          image: wineListImage4,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Kakheti",
          wineTypes: ["Grape", "Grape9", "Grape5", "Grape6"],

          territory: "Tbilisi",

          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 18,
          image: wineListImage5,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Samegrelo",
          wineTypes: [
            "Grape",
            "Grape9",
            "Grape3",
            "Grape4",
            "Grape5",
            "Grape6",
          ],

          territory: "Tbilisi",

          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 19,
          image: wineListImage6,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Kartli",
          wineTypes: [
            "Grape",
            "Grape9",
            "Grape8",
            "Grape4",
            "Grape5",
            "Grape6",
          ],

          territory: "Tbilisi",

          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 20,
          image: wineListImage7,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Samegrelo",

          wineTypes: ["Grape", "Grape9", "Grape8", "Grape3", "Grape4"],

          territory: "Tbilisi",
          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
        {
          id: 21,
          image: wineListImage8,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Kakheti",
          wineTypes: ["Grape", "Grape9", "Grape8", "Grape3", "Grape6"],

          territory: "Tbilisi",
          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
      ],
    },
  ],
  abkhazia: [
    {
      center: {
        lat: 43.0015,
        lng: 41.0234,
      },
      wineriesLists: [
        {
          id: 1,
          image: wineListImage1,
          title: "Ktw Wine House",
          titleRegion: "Akhaltsikhe",
          markerPositions: {
            id: 14,
            wineryName: "Abkhazia FAMILY CELLAR",
            location: {
              lat: 43.163119,
              lng: 40.326807,
            },

            img: wine,
            region: "abkhazia",
            village: "Village Tsnisi",
          },
          description:
            "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
          region: "Akhaltsikhe, Georgia",
          rating: "5/5",
          regionFilter: "Kakheti",
          wineTypes: [
            "Grape",
            "Grape1",
            "Grape2",
            "Grape3",
            "Grape4",
            "Grape5",
            "Grape6",
            "Grape7",
          ],
          territory: "Tbilisi",
          detail: {
            title: "Akakiseuli Family cellar",
            about:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            award: {
              award1: "Best organic wine producer , 2020- GOLDEN AWARD",
              award2: "Best organic wine producer , 2020- GOLDEN AWARD",
              award3: "Best organic wine producer , 2020- GOLDEN AWARD",
            },
            wines: [
              {
                wineId: 1,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg:
                  "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 2,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 3,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 4,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 5,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 6,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 7,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                firstPrice: "$23.99",
                sale: "SALE!",
                region: "Region: Kakheti",
              },
              {
                wineId: 8,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 9,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/tsinandali-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
              {
                wineId: 10,
                title: "Qvevris Mtsvane",
                wineColor: "WhiteDry",
                linkImg: "../src/images/item/მწვანე-1-113x300.png",
                grape: "Grape Mtsvane",
                characteristic: "High acid, sticky tannins",
                finalPrice: "$19.99",
                region: "Region: Kakheti",
              },
            ],
          },
        },
      ],
    },
  ],
};

export const wineriesList2 = [
  {
    id: 1,
    image: wineListImage1,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe",
    rating: "5/5",
    regionFilter: "Kakheti",
    wineTypes: [
      "Grape",
      "Grape1",
      "Grape2",
      "Grape3",
      "Grape4",
      "Grape5",
      "Grape6",
      "Grape7",
    ],
    territory: "Tbilisi",
    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 2,
    image: wineListImage2,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Kakheti",
    wineTypes: [
      "Grape",
      "Grape1",
      "Grape2",
      "Grape3",
      "Grape4",
      "Grape5",
      "Grape6",
      "Grape7",
    ],

    territory: "Borjomi",
    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 3,
    image: wineListImage3,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Samegrelo",
    wineTypes: ["Grape", "Grape1", "Grape2", "Grape3", "Grape4", "Grape5"],

    territory: "Batumi",
    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 4,
    image: wineListImage4,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Guria",
    wineTypes: [
      "Grape",
      "Grape9",
      "Grape2",
      "Grape3",
      "Grape4",
      "Grape5",
      "Grape6",
    ],

    territory: "Telavi",
    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 5,
    image: wineListImage5,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Kartli",
    wineTypes: [
      "Grape",
      "Grape9",
      "Grape8",
      "Grape3",
      "Grape4",
      "Grape5",
      "Grape6",
    ],

    territory: "Telavi",
    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 6,
    image: wineListImage6,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Kartli",
    wineTypes: [
      "Grape",
      "Grape9",
      "Grape8",
      "Grape3",
      "Grape4",
      "Grape5",
      "Grape6",
    ],

    territory: "Batumi",
    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 7,
    image: wineListImage7,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Imereti",
    wineTypes: ["Grape", "Grape9"],

    territory: "Batumi",
    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 8,
    image: wineListImage8,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Kakheti",
    wineTypes: ["Grape", "Grape9", "Grape8", "Grape3", "Grape5", "Grape6"],

    territory: "Batumi",

    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 9,
    image: wineListImage9,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Kakheti",
    wineTypes: ["Grape", "Grape9", "Grape8", "Grape4", "Grape5", "Grape6"],

    territory: "Batumi",
    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 10,
    image: wineListImage10,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Racha-lechkhumi",
    wineTypes: [
      "Grape",
      "Grape9",
      "Grape8",
      "Grape3",
      "Grape4",
      "Grape5",
      "Grape6",
    ],

    territory: "Telavi",
    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 11,
    image: wineListImage11,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Racha-lechkhumi",
    wineTypes: ["Grape", "Grape4", "Grape5", "Grape6"],

    territory: "Telavi",

    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 12,
    image: wineListImage12,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Samegrelo",
    wineTypes: ["Grape", "Grape9", "Grape8", "Grape5", "Grape6"],

    territory: "Telavi",
    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 13,
    image: wineListImage12,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Samegrelo",
    wineTypes: ["Grape", "Grape3", "Grape4", "Grape5", "Grape6"],

    territory: "Tbilisi",
    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 14,
    image: wineListImage1,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Adjara",
    wineTypes: [
      "Grape",
      "Grape9",
      "Grape8",
      "Grape3",
      "Grape4",
      "Grape5",
      "Grape6",
    ],

    territory: "Tbilisi",
    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 15,
    image: wineListImage2,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Adjara",
    wineTypes: [
      "Grape",
      "Grape9",
      "Grape8",
      "Grape3",
      "Grape4",
      "Grape5",
      "Grape6",
    ],

    territory: "Tbilisi",
    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 16,
    image: wineListImage3,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Imereti",
    wineTypes: ["Grape5", "Grape6"],

    territory: "Tbilisi",

    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 17,
    image: wineListImage4,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Kakheti",
    wineTypes: ["Grape", "Grape9", "Grape5", "Grape6"],

    territory: "Tbilisi",

    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 18,
    image: wineListImage5,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Samegrelo",
    wineTypes: ["Grape", "Grape9", "Grape3", "Grape4", "Grape5", "Grape6"],

    territory: "Tbilisi",

    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 19,
    image: wineListImage6,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Kartli",
    wineTypes: ["Grape", "Grape9", "Grape8", "Grape4", "Grape5", "Grape6"],

    territory: "Tbilisi",

    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 20,
    image: wineListImage7,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Samegrelo",

    wineTypes: ["Grape", "Grape9", "Grape8", "Grape3", "Grape4"],

    territory: "Tbilisi",
    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
  {
    id: 21,
    image: wineListImage8,
    title: "Ktw Wine House",
    titleRegion: "Akhaltsikhe",
    description:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy ",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
    regionFilter: "Kakheti",
    wineTypes: ["Grape", "Grape9", "Grape8", "Grape3", "Grape6"],

    territory: "Tbilisi",
    detail: {
      title: "Akakiseuli Family cellar",
      about:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
      award: {
        award1: "Best organic wine producer , 2020- GOLDEN AWARD",
        award2: "Best organic wine producer , 2020- GOLDEN AWARD",
        award3: "Best organic wine producer , 2020- GOLDEN AWARD",
      },
      wines: [
        {
          wineId: 1,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/chelsea-pridham-MMOXoxE_Gj0-unsplash.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 2,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 3,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 4,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 5,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ველები-ქვევრის-მწვანე-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 6,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/ამბერ-ქისი-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 7,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/NAN_ALAZANI-VALLEY_W-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          firstPrice: "$23.99",
          sale: "SALE!",
          region: "Region: Kakheti",
        },
        {
          wineId: 8,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 9,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/tsinandali-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
        {
          wineId: 10,
          title: "Qvevris Mtsvane",
          wineColor: "WhiteDry",
          linkImg: "../src/images/item/მწვანე-1-113x300.png",
          grape: "Grape Mtsvane",
          characteristic: "High acid, sticky tannins",
          finalPrice: "$19.99",
          region: "Region: Kakheti",
        },
      ],
    },
  },
];

export const NewItemData = [
  {
    id: 1,
    title: "Qvevris Mtsvane",
    wineColor: "WhiteDry",
    linkImg: wine1,
    grape: "Grape Mtsvane",
    characteristic: "High acid, sticky tannins",
    finalPrice: 19.99,
    firstPrice: 23.99,
    sale: "SALE!",
    region: "Region: Kakheti",
    quantity: 1,
  },
  {
    id: 2,
    title: "Qvevris Mtsvane",
    wineColor: "WhiteDry",
    linkImg: wine2,
    grape: "Grape Mtsvane",
    characteristic: "High acid, sticky tannins",
    finalPrice: 19.99,
    firstPrice: 23.99,
    sale: "SALE!",
    region: "Region: Kakheti",
    quantity: 1,
  },
  {
    id: 3,
    title: "Qvevris Mtsvane",
    wineColor: "WhiteDry",
    linkImg: wine3,
    grape: "Grape Mtsvane",
    characteristic: "High acid, sticky tannins",
    finalPrice: 19.99,
    region: "Region: Kakheti",
    quantity: 1,
  },
  {
    id: 4,
    title: "Qvevris Mtsvane",
    wineColor: "WhiteDry",
    linkImg: wine4,
    grape: "Grape Mtsvane",
    characteristic: "High acid, sticky tannins",
    finalPrice: 19.99,
    region: "Region: Kakheti",
    quantity: 1,
  },
  {
    id: 5,
    title: "Qvevris Mtsvane",
    wineColor: "WhiteDry",
    linkImg: wine5,
    grape: "Grape Mtsvane",
    characteristic: "High acid, sticky tannins",
    finalPrice: 19.99,
    region: "Region: Kakheti",
    quantity: 1,
  },

  {
    id: 6,
    title: "Qvevris Mtsvane",
    wineColor: "WhiteDry",
    linkImg: wine1,
    grape: "Grape Mtsvane",
    characteristic: "High acid, sticky tannins",
    finalPrice: 19.99,
    firstPrice: 23.99,
    sale: "SALE!",
    region: "Region: Kakheti",
    quantity: 1,
  },
  {
    id: 7,
    title: "Qvevris Mtsvane",
    wineColor: "WhiteDry",
    linkImg: wine2,
    grape: "Grape Mtsvane",
    characteristic: "High acid, sticky tannins",
    finalPrice: 19.99,
    firstPrice: 23.99,
    sale: "SALE!",
    region: "Region: Kakheti",
    quantity: 1,
  },
  {
    id: 8,
    title: "Qvevris Mtsvane",
    wineColor: "WhiteDry",
    linkImg: wine3,
    grape: "Grape Mtsvane",
    characteristic: "High acid, sticky tannins",
    finalPrice: 19.99,
    region: "Region: Kakheti",
    quantity: 1,
  },
  {
    id: 9,
    title: "Qvevris Mtsvane",
    wineColor: "WhiteDry",
    linkImg: wine4,
    grape: "Grape Mtsvane",
    characteristic: "High acid, sticky tannins",
    finalPrice: 19.99,
    region: "Region: Kakheti",
    quantity: 1,
  },
  {
    id: 10,
    title: "Qvevris Mtsvane",
    wineColor: "WhiteDry",
    linkImg: wine5,
    grape: "Grape Mtsvane",
    characteristic: "High acid, sticky tannins",
    finalPrice: 19.99,
    region: "Region: Kakheti",
    quantity: 1,
  },
];

export const FaqData = [
  {
    question: "Can I use my Wine Passport at any winery or tasting room?",
    answer:
      "Wine Passport programs typically have a list of participating wineries. It's essential to check the list to see where your Passport can be used.",
  },
  {
    question: "Can I get discounts or special offers with my Wine Passport?",
    answer:
      "Yes, many wineries offer exclusive discounts or promotions to Wine Passport holders.",
  },
  {
    question:
      "Do I need a physical booklet or can I use a digital version of the Wine Passport?",
    answer:
      "Wine Passports can come in both physical and digital formats. Some programs even offer a combination of both for added convenience.",
  },
  {
    question:
      "Is the Wine Passport only for wine connoisseurs, or can anyone use it?",
    answer:
      "Wine Passports are designed for wine enthusiasts of all levels, from beginners to connoisseurs, and anyone curious about wine.",
  },
  {
    question:
      "What are the benefits of being a registered Georgian Wine Passport holder?",
    answer:
      "Registered passport holders may receive exclusive offers, event invitations, and updates on new wine releases.",
  },

  {
    question: "Can I purchase a Wine Passport as a gift for someone else?",
    answer:
      "Yes, our Wine Passport program offers the option to purchase a Passport as a gift. You can usually specify the recipient's name during the purchase process.",
  },
  {
    question: "Do Wine Passports have an expiration date?",
    answer:
      "It's essential to check the terms and conditions of the passport. Some are valid for 1 year, while others have no expiration date.",
  },
  {
    question: "Can I share my Wine Passport with a friend or family member?",
    answer:
      "Typically, Wine Passports are intended for individual use, and each person needs their own Passport. Sharing may not be allowed under the program's terms.",
  },
  {
    question:
      "What information is included in a Wine Passport for each winery?",
    answer:
      "A Wine Passport typically provides details about the winery's location, history, available wines, and any special offers or discounts.",
  },
  {
    question: "Are there any age restrictions for Wine Passport holders?",
    answer:
      "The legal drinking age for wine varies by location. Georgian Wine Passport holders should comply with the legal drinking age in Georgia.",
  },

  {
    question:
      "Can I use my Wine Passport at wineries that are not on the list of participating locations?",
    answer:
      "Wine Passports are typically only valid at participating wineries listed in the program. It's essential to check the list before planning your visits.    ",
  },
];

export const SimilarRegionWine = [
  {
    id: 1,
    similarWineImg: similarwine1,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 2,
    similarWineImg: similarwine2,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 3,
    similarWineImg: similarwine3,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 4,
    similarWineImg: similarwine4,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 5,
    similarWineImg: similarwine1,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 6,
    similarWineImg: similarwine2,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 7,
    similarWineImg: similarwine1,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 8,
    similarWineImg: similarwine2,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 9,
    similarWineImg: similarwine3,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 10,
    similarWineImg: similarwine4,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 11,
    similarWineImg: similarwine1,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 12,
    similarWineImg: similarwine2,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 13,
    similarWineImg: similarwine1,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 14,
    similarWineImg: similarwine2,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 15,
    similarWineImg: similarwine3,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 16,
    similarWineImg: similarwine4,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
  {
    id: 17,
    similarWineImg: similarwine1,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },

  {
    id: 18,
    similarWineImg: similarwine2,
    similarWineTitle: "KTW Wine House Akhaltsikhe",
    similarWineParagraph:
      "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd",
    region: "Akhaltsikhe, Georgia",
    rating: "5/5",
  },
];
