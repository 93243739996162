import React, { useContext } from "react";
import "../components/styles/EventDetailPage.css";
import { context } from "./Root";

export default function EventDetailBg({ title, category }) {
  const langContext = useContext(context);
  return (
    <>
      <div className="eventDetail-bg">
        <div className="text-container">
          <h2
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }
            }
          >
            {title}{" "}
          </h2>
          <h4
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }
            }
            className="event-subTitle"
          >
            {category}
          </h4>
        </div>
      </div>
      <div className="section-one-bg-mob-events">
        <div className="text-container">
          <h2
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }
            }
            className="text-container-mob"
          >
            {title}
          </h2>
        </div>
      </div>
    </>
  );
}
