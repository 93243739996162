import React, { useContext } from "react";
import TitleLine from "../components/TitleLine";
import { useMediaQuery } from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";
import { context } from "./Root";

export default function AboutUsContent({
  title,
  image,
  aboutSecondP,
  aboutFirstP,
  order1,
  order2,
}) {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const langContext = useContext(context);

  const variants = isDesktop
    ? {
        leftInitial: { opacity: 0, x: -500 },
        rigthInitial: { opacity: 0, x: 500 },
        animate: { opacity: 1, x: 0 },
      }
    : {
        leftInitial: { opacity: 0 },
        rigthInitial: { opacity: 0 },
        animate: { opacity: 1 },
      };
  return (
    <>
      <h1
        className="event-main-title"
        style={
          langContext.lang !== "EN"
            ? { fontFamily: "BPG Nino Mtavruli" }
            : { fontFamily: "Montserrat" }
        }
      >
        {title}
      </h1>
      <TitleLine />
      <div className="about-us-container">
        <motion.img
          variants={variants}
          initial={order1 === 1 ? variants.leftInitial : variants.rigthInitial}
          whileInView={variants.animate}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 0.8,
          }}
          style={{ order: order1 }}
          src={image}
          alt={title}
        />
        <motion.div
          variants={variants}
          initial={order1 === 1 ? variants.rigthInitial : variants.leftInitial}
          whileInView={variants.animate}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 0.8,
          }}
          style={{ order: order2 }}
        >
          <p
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "FiraGo" }
                : { fontFamily: "Lato" }
            }
          >
            {aboutFirstP}
          </p>
          <p
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "FiraGo" }
                : { fontFamily: "Lato" }
            }
          >
            {aboutSecondP}
          </p>
        </motion.div>
      </div>
    </>
  );
}
