import React, { useContext, useState } from "react";
import "./styles/EventPage.css";
import locIcon from "../images/event-page/locIconLigth.svg";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import api from "../Api/getData";
import { context } from "./Root";

export default function EventCard({
  title,
  description,
  eventStart,
  eventEnd,
  region,
  date,
  img,
  index,
  id,
  category,
}) {
  const [error, setError] = useState("");
  const langContext = useContext(context);

  const isMobile = useMediaQuery("(max-width: 1020px)");
  // const webSite = process.env.REACT_APP_WEBSITE;

  const fetchLocation = async (eventId) => {
    try {
      const res = await api.get(`events/${eventId}`);
      const data = res.data.location;
      const { lat, lng } = data;
      window.open(`https://www.google.com/maps?q=${lat},${lng}`);
    } catch (error) {
      setError(error.message);
    }
  };

  const startHour = Number(eventStart.split(":")[0]);
  const endHour = Number(eventEnd.split(":")[0]);
  const startPeriod = startHour >= 0 && startHour < 12 ? "am" : "pm";
  const endPeriod = endHour >= 0 && endHour < 12 ? "am" : "pm";

  const dateParts = date.split("-");
  const year = dateParts[0];
  const numericMonth = dateParts[1];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[parseInt(numericMonth, 10) - 1];

  let day = dateParts[2];
  if (day.startsWith("0")) {
    day = day.substring(1);
  }

  const isDesktop = useMediaQuery("(min-width: 768px)");

  const variants = isDesktop
    ? {
        leftInitial: { opacity: 0, x: -500 },
        rigthInitial: { opacity: 0, x: 500 },
        animate: { opacity: 1, x: 0 },
      }
    : {
        leftInitial: { opacity: 0 },
        rigthInitial: { opacity: 0 },
        animate: { opacity: 1 },
      };

  return error === "" ? (
    <motion.div
      variants={variants}
      initial={index % 2 === 1 ? variants.rigthInitial : variants.leftInitial}
      whileInView={index % 2 === 1 ? variants.animate : variants.animate}
      viewport={{ once: true }}
      transition={{
        ease: "easeOut",
        duration: 1,
        delay: 0.8,
      }}
      className="event-card-container"
    >
      <div className="img-and-date-wrapper">
        <img className="eventcard-img" src={img} alt="event-img" />
        {isMobile ? (
          <div className="date-wrapper-mob">
            <span className="event-month-mob">{monthName.slice(0, 3)}</span>
            <span className="event-day-mob">{day}</span>
            <span className="event-year-mob">{year}</span>
          </div>
        ) : (
          <div className="date-wrapper">
            <span className="event-month">{monthName.slice(0, 3)}</span>
            <span className="event-day">{day}</span>
            <span className="event-year">{year}</span>
          </div>
        )}
      </div>
      <div className="event-info-container">
        <h4 className="event-title">{title}</h4>
        <h5 className="event-company">{category}</h5>
        <p className="event-desc">
          {`${description
            .split(" ")
            .slice(0, 5)
            .join(" ")} ...`}
        </p>
        <span className="event-duration">
          {eventStart}
          {startPeriod} - {eventEnd}
          {endPeriod}
        </span>
        <div className="loc-btn-wrapper">
          <div
            className="event-location-wrapper"
            onClick={() => {
              fetchLocation(id);
            }}
          >
            <img src={locIcon} alt="location-icon" />
            <span>{region}</span>
          </div>
          <Link to={`/events/detail/${id}`}>
            <button>
              {langContext.lang === "EN" ? "Details" : "დეტალურად"}
            </button>
          </Link>
        </div>
      </div>
    </motion.div>
  ) : (
    error
  );
}
