import React, { useContext, useState } from "react";
import PopUpInput from "./PopUpInput";
import { Link } from "react-router-dom";
import { Form, Formik, Field } from "formik";
import { SignInScema } from "../schemas";
import { context } from "./Root";
import { Alert } from "@mui/material";
import api from "../Api/getData";

export default function SignIn() {
  // const [checked, setChecked] = useState(false);
  const signInFormContext = useContext(context);
  const langContext = useContext(context);
  // const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (values, actions) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));

    try {
      const response = await api.post(`auth`, values);
      const token = response.data.token;

      // Store the token in local storage
      localStorage.setItem("authToken", token);

      // Reset the form after successful submission
      actions.resetForm();

      // Use setTimeout directly without assigning it to a variable
      setTimeout(() => {
        signInFormContext.setShowPopUp(false);
        signInFormContext.handleLogin();
      }, 500);
    } catch (error) {
      // Handle error if the request fails
      setError(error.message);
      console.error("Error:", error.message);
    }
  };

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={SignInScema}
      onSubmit={onSubmit}
      //     validateOnChange={false}
      // validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <>
          <h2>{langContext.lang === "EN" ? "SIGN IN" : "ავტორიაზაცია"}</h2>

          <Form className="login-form">
            <PopUpInput
              name="email"
              type="text"
              placeholder={langContext.lang === "EN" ? "Email" : "ელფოსტა"}
            />
            <PopUpInput
              name="password"
              type="password"
              placeholder={langContext.lang === "EN" ? "Password" : "პაროლი"}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <label className="container">
                  <Field type="checkbox" name="rememberMe" />
                  <div className="checkmark"></div>
                </label>
                <label className="checkbox-label">
                  {langContext.lang === "EN" ? "Remember Me" : "დაამახსორე"}
                </label>
              </div>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  signInFormContext.setView("passReset");
                }}
                className="pass-recover"
              >
                {langContext.lang === "EN"
                  ? "Lost your password?"
                  : "დაგავიწყდა პაროლი?"}
              </Link>
            </div>
            {error === "" ? null : (
              <Alert
                style={{ textAlign: "center", marginTop: "10px" }}
                severity="error"
              >
                The email address or password is incorrect.
              </Alert>
            )}
            <div className="btn-cont">
              <button
                type="submit"
                disabled={isSubmitting}
                className="submit-button"
              >
                {langContext.lang === "EN" ? "SIGN IN" : "შესვლა"}
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  signInFormContext.setView("registration");
                }}
                className="reg-btn"
              >
                {langContext.lang === "EN" ? "Register Now" : "დარეგისტრირდი"}
              </button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
