import React, { useState, useEffect, useContext } from "react";
import "./styles/GoogleMapsCarousel.css";
import Select from "./Select";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import MapSlider from "./MapSlider";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import api from "../Api/getData";
import { useMediaQuery } from "@mui/material";
import filterIcon from "../images/articlePage/filterbtnformap.svg";
import closeBtn from "../images/articlePage/closebtn.svg";
import { motion } from "framer-motion/dist/framer-motion";
import { context } from "./Root";

export default function GoogleMapsCarousel({
  hidden,
  setHidden,
  click,
  setClick,
  selected,
  setSelected,
}) {
  // const [click, setClick] = useState(0);
  // const [selected, setSelected] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [regionSelect, setRegionSelect] = useState("All Region");
  const [wineTypeSelect, setWineTypeSelect] = useState("All Type");
  const [territoriesSelect, setTerritoriesSelect] = useState("All Origins");
  const [filteredData, setFilteredData] = useState([]);
  const [regionInputs, setRegionInputs] = useState([]);
  const [wineTypesInput, setWineTypesInput] = useState([]);
  const [territoriesInput, setTerritoriesInput] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [georgiaWineries, setGeorgiaWineries] = useState([]);

  const langContext = useContext(context);

  const isMobile = useMediaQuery("(max-width: 640px)");

  // fetch all wineries by region
  useEffect(() => {
    const fetchRegionData = async () => {
      const res = await api.get("region-winers");
      setRegionData(res.data);
    };
    fetchRegionData();
  }, []);

  //fetch cities, territories and regions.

  useEffect(() => {
    const fetchCities = async () => {
      const response = await api.get("cities");
      setTerritoriesInput(response.data);
    };
    const fetchRegion = async () => {
      const response = await api.get("regions");
      setRegionInputs(response.data);
    };
    const fetchWineTypes = async () => {
      const response = await api.get("wine-types");
      setWineTypesInput(response.data);
    };
    fetchRegion();
    fetchWineTypes();
    fetchCities();
  }, []);

  // push all wineries in georgia
  const pushWinnersToGeorgia = () => {
    if (regionData.length > 0) {
      const winners = regionData.reduce((acc, curr) => {
        const winnersWithRegion = curr.winners.map((winner) => ({
          ...winner,
          region: curr.region_name,
        }));
        return [...acc, ...winnersWithRegion];
      }, []);
      setGeorgiaWineries(winners);
    }
  };

  useEffect(() => {
    pushWinnersToGeorgia();
  }, [regionData]);

  // handle all selects
  const handleRegionChange = (event) => {
    setRegionSelect(event.target.value);
  };

  const handleWineTypeChange = (event) => {
    setWineTypeSelect(event.target.value);
  };
  const handleTerritoriesChange = (event) => {
    setTerritoriesSelect(event.target.value);
  };

  // Submited function in map
  const handleFormSubmit = (event) => {
    event.preventDefault();

    const filteredData = georgiaWineries.filter((item) => {
      // console.log("item.wine_types", item.wine_types);
      // console.log("wineTypeSelect", wineTypeSelect);
      const nameMatch = item.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const wineTypeSelectMatch =
        wineTypeSelect === "All Type" ||
        // (item.wine_types &&
        //   item.wine_types.some((wine) => wineTypeSelect.includes(wine)));
        (item.wine_type &&
          item.wine_type.length > 0 &&
          item.wine_type.includes(wineTypeSelect));

      const regionMatch =
        regionSelect === "All Region" || item.region === regionSelect;
      const territoriesMatch =
        territoriesSelect === "All Origins" || item.city === territoriesSelect;

      return (
        nameMatch && regionMatch && territoriesMatch && wineTypeSelectMatch
      );
    });

    setFilteredData(filteredData);
    setClick(10);
    setHidden(!hidden);
    setSelected(null);
    setSearchTerm("");
  };

  const isDesktop = useMediaQuery("(min-width: 768px)");
  const variants = isDesktop
    ? {
        initial: { opacity: 0, x: -200 },
        whileInView: { opacity: 1, x: 0 },
      }
    : {
        initial: { opacity: 0 },
        whileInView: { opacity: 1 },
      };

  if (!hidden) {
    return (
      <div className="carousel-map-container">
        {isMobile ? (
          <img
            onClick={() => setHidden((hidden) => !hidden)}
            className="search-toggle-false"
            src={filterIcon}
            alt="filterIcon"
          />
        ) : (
          <button
            onClick={() => setHidden((hidden) => !hidden)}
            className="search-toggle-false"
          >
            <BiChevronRight className="arrow" />
          </button>
        )}

        <MapSlider
          filteredData={filteredData}
          click={click}
          setClick={setClick}
          selected={selected}
          setSelected={setSelected}
          georgiaWineries={georgiaWineries}
          dataForRegions={regionData}
        />
      </div>
    );
  }
  return (
    <div className="carousel-map-container">
      <motion.div
        variants={variants}
        initial={variants.initial}
        whileInView={variants.whileInView}
        transition={{ duration: 0.8, delay: 0.5 }}
        viewport={{ once: true }}
        className="google-map-search-container "
      >
        <form className="google-map-search " onSubmit={handleFormSubmit}>
          <h4
            className="search-title"
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "FiraGo" }
                : { fontFamily: "Poppins" }
            }
          >
            {langContext.lang === "EN" ? "Find on map" : "იპოვეთ რუკაზე"}
          </h4>
          <Autocomplete
            popupIcon={"▼"}
            options={georgiaWineries}
            getOptionLabel={(option) => option.name}
            ListboxProps={{ style: { maxHeight: "200px" } }}
            onChange={(event, value) => {
              setSearchTerm(value ? value.name : "");
              // console.log(value);
            }}
            sx={{ fontSize: 4 }}
            renderInput={(params) => (
              <TextField
                {...params}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="input-wrapper"
                size="small"
                InputLabelProps={{
                  sx: {
                    color: "white",
                    "&.Mui-focused": { color: "white" },
                    top: "11.5%",
                  },
                }}
                // InputLabelProps={{ className: "TextField__label" }}
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "FiraGo" }
                    : { fontFamily: "Poppins" }
                }
                label={langContext.lang === "EN" ? "Search" : "ძებნა"}
                placeholder={langContext.lang === "EN" ? "Search" : "ძებნა"}
              />
            )}
          />

          <div className="checkbox-wrapper">
            <input type="checkbox" />
            <label
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Poppins" }
              }
            >
              {langContext.lang === "EN"
                ? "Organic producer"
                : "ორგანული მწარმოებელი"}
            </label>
          </div>
          <div className="select-cont">
            <label
              className="select-label"
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Poppins" }
              }
            >
              {langContext.lang === "EN" ? "Region" : "რეგიონი"}
            </label>
            <div className="select-wrapper">
              <select
                className="select-box"
                value={regionSelect}
                onChange={handleRegionChange}
              >
                <option
                  style={
                    langContext.lang !== "EN"
                      ? { fontFamily: "FiraGo" }
                      : { fontFamily: "Poppins" }
                  }
                  className="option"
                  value="All Region"
                >
                  {langContext.lang === "EN" ? "All Region" : "ყველა რეგიონი"}
                </option>
                {regionInputs.map((item, index) => {
                  return <Select item={item.name} key={index} />;
                })}
              </select>
            </div>
          </div>
          <div className="select-cont">
            <label
              className="select-label"
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Poppins" }
              }
            >
              {langContext.lang === "EN" ? "Wine type" : "ღვინის ტიპი"}
            </label>
            <div className="select-wrapper">
              <select
                className="select-box"
                value={wineTypeSelect}
                onChange={handleWineTypeChange}
              >
                <option
                  style={
                    langContext.lang !== "EN"
                      ? { fontFamily: "FiraGo" }
                      : { fontFamily: "Poppins" }
                  }
                  className="option"
                  value="All Type"
                >
                  {langContext.lang === "EN" ? "All Type" : "ყველა ტიპი"}
                </option>
                {wineTypesInput.map((item, index) => {
                  return <Select item={item.name} key={index} />;
                })}
              </select>
            </div>
          </div>
          <div className="select-cont">
            <label
              className="select-label"
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Poppins" }
              }
            >
              {langContext.lang === "EN" ? "Origins" : "წარმოშობა"}
            </label>
            <div className="select-wrapper">
              <select
                className="select-box"
                value={territoriesSelect}
                onChange={handleTerritoriesChange}
              >
                <option
                  style={
                    langContext.lang !== "EN"
                      ? { fontFamily: "FiraGo" }
                      : { fontFamily: "Poppins" }
                  }
                  className="option"
                  value="All territories"
                >
                  {langContext.lang === "EN"
                    ? "All Origins"
                    : "ყველა წარმოშობა"}
                </option>
                {territoriesInput.map((item, index) => {
                  return <Select item={item.name} key={index} />;
                })}
              </select>
            </div>
          </div>
          <button className="search-btn" type="submit">
            {langContext.lang === "EN" ? "Search" : "ძებნა"}
          </button>
        </form>
        {isMobile ? (
          <img
            onClick={() => setHidden((hidden) => !hidden)}
            className="search-toggle"
            src={closeBtn}
            alt="closebtn"
          />
        ) : (
          <button
            onClick={() => setHidden((hidden) => !hidden)}
            className="search-toggle"
          >
            <BiChevronLeft className="arrow" />
          </button>
        )}
      </motion.div>
      <div style={{ position: "absolute", width: "100%" }}>
        <MapSlider
          click={click}
          setClick={setClick}
          selected={selected}
          setSelected={setSelected}
          filteredData={filteredData}
          georgiaWineries={georgiaWineries}
          dataForRegions={regionData}
        />
      </div>
    </div>
  );
}
