import React, { useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import mapIcon from "../images/mapIcon.svg";
import wine from "../images/similarwine/chelsea-pridham-MMOXoxE_Gj0-unsplash.png";
import "./styles/GoogleMaps.css";

const libraries = ["places"];

export default function GoogleMaps({ eventDetail }) {
  const mapContainerStyle = {
    width: "100%",
    height: "80vh",
  };

  let center = { lat: 0, lng: 0 };

  if (eventDetail && eventDetail.location) {
    const { lat, lng } = eventDetail.location;
    if (!isNaN(lat) && !isNaN(lng)) {
      center = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };
    }
  }
  // const center = {
  //   lat: 41.6434,
  //   lng: 42.9934,
  // };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAP7_Dlc7qRZaIGbqEupCR3Kl8TLaNptOo",
    libraries,
  });

  const [selected, setSelected] = useState(null);

  if (loadError) return "Error loading maps";
  if (!isLoaded)
    return (
      <div className="loarder-cont">
        <div className="loader">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      </div>
    );

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={15}
        center={center}
        // options={options}
        onClick={() => setSelected(null)}
      >
        <div className="google-maps-key">
          <Marker
            position={center}
            icon={{
              url: mapIcon,
              scaledSize: new window.google.maps.Size(30, 30),
            }}
            onClick={() => {
              setSelected(eventDetail);
            }}
          ></Marker>
        </div>

        {selected && (
          <InfoWindow
            position={center}
            options={{
              pixelOffset: new window.google.maps.Size(0, -40),
            }}
            onCloseClick={() => setSelected(null)}
          >
            <div>
              <div className="infoWindow-container">
                <img className="infoWindow-img" src={wine} alt="wine" />
                <div className="infoWindow-content">
                  <h4 className="infoWindow-title">{selected.company}</h4>
                  <h5 className="infoWindow-region">
                    Event:{selected.event_name}
                  </h5>
                  <p className="infoWindow-street">Location:{selected.city}</p>
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
}
