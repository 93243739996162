import React, { useContext } from "react";
import "../components/styles/Contact.css";
import { context } from "./Root";

export default function ContactBg({ title }) {
  const langContext = useContext(context);
  return (
    <>
      <div className="contact-bg">
        <div className="text-container">
          <h2
            style={{
              fontFamily:
                langContext.lang !== "EN" ? "BPG Nino Mtavruli" : "Montserrat",
            }}
          >
            {title}
          </h2>
        </div>
      </div>
      <div className="section-one-bg-mob-contact">
        <div className="text-container">
          <h2
            className="text-container-mob"
            style={{
              fontFamily:
                langContext.lang !== "EN" ? "BPG Nino Mtavruli" : "Montserrat",
            }}
          >
            {title}
          </h2>
        </div>
      </div>
    </>
  );
}
