import React, { useContext, useState } from "react";
import ContactBg from "../components/ContactBg";
// import Test from "./components/Test";
import "../components/styles/Contact.css";
import location from "../images/contactPage/location.svg";
import phone from "../images/contactPage/phone.svg";
import email from "../images/contactPage/email.svg";
import line from "../images/contactPage/Line.svg";
import { context } from "../components/Root";
import TitleLine from "../components/TitleLine";
import { motion } from "framer-motion/dist/framer-motion";
import { useMediaQuery } from "@mui/material";
import { changeTitle } from "../data/GeneralFunction";
import mapIcon from "../images/mapIcon.svg";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import wineClubLogo from "../images/wineClubLogo.png";
import ContactForm from "../components/ContactForm";
import { Link } from "react-router-dom";
import directionVector from "../images/direction-vector.svg";

export default function Contact() {
  const popUpContext = useContext(context);
  const langContext = useContext(context);

  const [selected, setSelected] = useState(null);

  const mapContainerStyle = {
    width: "100%",
    height: "60vh",
  };
  changeTitle("Findwine | Contact");

  const isDesktop = useMediaQuery("(min-width: 768px)");

  const variantsFirstSec = isDesktop
    ? {
        leftInitial: { opacity: 0, x: -500 },
        rigthInitial: { opacity: 0, x: 500 },
        animate: { opacity: 1, x: 0 },
      }
    : {
        leftInitial: { opacity: 0 },
        rigthInitial: { opacity: 0 },
        animate: { opacity: 1 },
      };

  return (
    <main
      className="contact-main"
      style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}
    >
      <ContactBg
        title={langContext.lang === "EN" ? "CONTACT US" : "კონტაქტი"}
      />
      <h1
        className="contact-main-title"
        style={{
          fontFamily:
            langContext.lang !== "EN" ? "BPG Nino Mtavruli" : "Montserrat",
        }}
      >
        {langContext.lang === "EN" ? "Contact" : "კონტაქტი"}
      </h1>
      <TitleLine />
      <section className="contact-section1">
        <motion.div
          variants={variantsFirstSec}
          initial={variantsFirstSec.leftInitial}
          whileInView={variantsFirstSec.animate}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 0.8,
          }}
          className="contact-left-block"
        >
          <p
            className="contact-desc"
            style={{
              fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Lato",
            }}
          >
            <>
              Welcome to
              <Link style={{ color: "#8e8e93" }} to={"/about-us"}>
                Wineshop LLC,
              </Link>
              your destination for exquisite wines and exceptional service. We
              value your inquiries, feedback, and are here to assist you. Please
              feel free to reach out to us using the contact information below.
            </>
          </p>
          <div className="contact-contact-detail">
            <img src={location} alt="location" />
            <img src={line} alt="line" />
            <div className="detail-column">
              <span
                className="adress-title"
                style={{
                  fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Lato",
                }}
              >
                {langContext.lang === "EN"
                  ? "Office address"
                  : "ოფისის მისამართი"}
              </span>
              <span
                className="contact-detail-info"
                style={{
                  fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Lato",
                }}
              >
                {langContext.lang === "EN"
                  ? "45 Vazha Pshavela Ave, Tbilisi 0177"
                  : "ვაჟა-ფშაველას გამზირი 45"}
              </span>
            </div>
          </div>
          <div className="contact-contact-detail">
            <img src={phone} alt="phone" />
            <img src={line} alt="line" />
            <div className="detail-column">
              <span
                className="adress-title"
                style={{
                  fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Lato",
                }}
              >
                {langContext.lang === "EN"
                  ? "Request a call back"
                  : "დაგვიკავშირდით "}
              </span>
              <span
                className="contact-detail-info"
                style={{
                  fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Lato",
                }}
              >
                +995-577-24-20-25
              </span>
            </div>
          </div>
          <div className="contact-contact-detail">
            <img src={email} alt="email" />
            <img src={line} alt="line" />
            <div className="detail-column">
              <span
                className="adress-title"
                style={{
                  fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Lato",
                }}
              >
                {langContext.lang === "EN" ? "Email us" : "ელფოსტა"}
              </span>
              <span
                className="contact-detail-info"
                style={{
                  fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Lato",
                }}
              >
                contact@findwines.ge
              </span>
              <span
                className="contact-detail-info"
                style={{
                  fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Lato",
                }}
              >
                Info@wineshop.ge
              </span>
            </div>
          </div>
        </motion.div>
        <motion.div
          variants={variantsFirstSec}
          initial={variantsFirstSec.rigthInitial}
          whileInView={variantsFirstSec.animate}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 0.8,
          }}
          viewport={{ once: true }}
          className="rigth-bg-contact"
        >
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={15}
            center={{ lat: 41.7257487, lng: 44.7436216 }}
            // options={options}
            onClick={() => setSelected(null)}
          >
            <div className="google-maps-key">
              <Marker
                position={{ lat: 41.7257487, lng: 44.7436216 }}
                icon={{
                  url: mapIcon,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                onClick={() => {
                  setSelected({
                    company: "Wine Club",
                  });
                }}
              ></Marker>
            </div>

            {selected && (
              <InfoWindow
                position={{ lat: 41.7257487, lng: 44.7436216 }}
                options={{
                  pixelOffset: new window.google.maps.Size(0, -40),
                }}
                onCloseClick={() => setSelected(null)}
              >
                <div>
                  <div className="infoWindow-container">
                    <img
                      className="infoWindow-img"
                      src={wineClubLogo}
                      alt="wine"
                    />
                    <div className="infoWindow-content">
                      <h4 className="infoWindow-title">{selected.company}</h4>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (selected) {
                            const apiKey =
                              "AIzaSyAP7_Dlc7qRZaIGbqEupCR3Kl8TLaNptOo";
                            window.open(
                              `https://www.google.com/maps/dir/?api=1&destination=41.7257487,44.7436216&key=${apiKey}`
                            );
                          } else {
                            console.error(
                              "Selected location does not have valid latitude and longitude."
                            );
                          }
                        }}
                        className="exact-Address"
                      >
                        <img src={directionVector} alt="direction-vector" />
                        Directions
                      </button>
                    </div>
                  </div>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </motion.div>
      </section>

      {/* <motion.form
          variants={variantsSecondSec}
          initial={variantsSecondSec.Initial}
          whileInView={variantsSecondSec.animate}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 0.2,
          }}
          viewport={{ once: true }}
          onSubmit={formSubmit}
          className="contact-form-container"
        >
          <div className="contact-inputs-container">
            <div className="contact-label-input">
              <label>Your name</label>
              <input
                value={nameInput}
                onChange={(e) => {
                  setNameInput(e.target.value);
                  setIsInvalid(false);
                  setAllRigth(false);
                }}
                type="text"
                placeholder="Your name"
              />
            </div>
            <div className="contact-label-input">
              <label>Email</label>
              <input
                value={emailInput}
                onChange={(e) => {
                  setEmailInput(e.target.value);
                  setIsInvalid(false);
                  setInvalidEmail(false);
                  setAllRigth(false);
                }}
                type="text"
                placeholder="Your email"
              />
            </div>
          </div>
          {isInvalid && (
            <Alert severity="error">Name, Email and message are required</Alert>
          )}
          {nameLength && (
            <Alert severity="error">Name must be min 5 letters</Alert>
          )}
          {invalidEmail && (
            <Alert severity="error">
              Invalid email format. Please enter a valid email address.
            </Alert>
          )}

          <div className="contact-texarea">
            <label>Message</label>
            <textarea
              value={texarea}
              onChange={(e) => {
                setTexarea(e.target.value);
                setIsInvalidTexarea(false);
                setAllRigth(false);
              }}
              placeholder="Your message"
            />
          </div>
          {isInvalidTexarea && (
            <Alert style={{ marginBottom: "10px" }} severity="error">
              Message is required and min 10 letters
            </Alert>
          )}
          <ReCAPTCHA
            sitekey="6LcyJ4AnAAAAAFyYY0DSbnSfVJYlFxDVtrzB6yHk"
            onChange={onChange}
          />
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="message-btn"
          >
            <img src={airLine} alt="messageIcon" />
            <img src={btnLine} alt="line" />
            Send request
          </motion.button>
          {allRigth && <Alert severity="success">Message sent</Alert>}
        </motion.form> */}
      <ContactForm />
    </main>
  );
}
