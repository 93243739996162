import React, { useState, useEffect, useContext } from "react";
import NewCards from "../components/NewCards";
// import RegistrationSection from "../components/RegistrationSection";
import WineriesMap from "../components/WineriesMap";
import FirstSection from "../components/FirstSection";
import api from "../Api/getData";
import { context } from "../components/Root";
import Article from "../components/Article";
import TitleLine from "../components/TitleLine";
import "../components/styles/Articles.css";
import { Alert, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";
import { changeTitle } from "../data/GeneralFunction";

export default function Home({ cart, setCart, favorite, setFavorite }) {
  const [hidden, setHidden] = useState(true);
  const [mobHidden, setMobHidden] = useState(false);
  const [products, setProducts] = useState([]);
  const [articlesError, setArticlesError] = useState("");
  const [articles, setArticles] = useState([]);
  const [prodError, setProdError] = useState("");

  const popUpContext = useContext(context);
  const langContext = useContext(context);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const res = await api.get("articles");
        setArticles(res.data);
      } catch (error) {
        setArticlesError(error.message);
      }
    };

    fetchArticles();
  }, []);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const res = await api.get("products");
        setProducts(res.data);
      } catch (error) {
        setProdError(error.message);
      }
    };
    // const fetchProductData = async () => {
    //   const res = await api.get("products");
    //   setProducts(res.data);
    // };
    fetchProductData();
  }, []);

  changeTitle("Findwine | Home");

  const isDesktop = useMediaQuery("(min-width: 768px)");
  const sortedData = articles.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);

    return dateB - dateA;
  });

  const variants = isDesktop
    ? {
        initial: { opacity: 0, x: -500 },
        whileInView: { opacity: 1, x: 0 },
      }
    : {
        initial: { opacity: 0 },
        whileInView: { opacity: 1 },
      };

  return (
    <main
      className="home-main"
      style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}
    >
      <FirstSection />
      <div className="winery-map-desktop">
        <WineriesMap
          title={
            langContext.lang === "EN" ? "GEORGIAN WINERIES" : "ქართული მარნები"
          }
          hidden={hidden}
          setHidden={setHidden}
        />
      </div>
      <div className="winery-map-mob-tab">
        <WineriesMap
          title={
            langContext.lang === "EN" ? "GEORGIAN WINERIES" : "ქართული მარნები"
          }
          hidden={mobHidden}
          setHidden={setMobHidden}
        />
      </div>
      <NewCards
        title={langContext.lang === "EN" ? "NEW ITEMS" : "ახალი პროდუქტები"}
        favorite={favorite}
        setFavorite={setFavorite}
        cart={cart}
        setCart={setCart}
        NewItemData={[...products].sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )}
        showViewAll={true}
        error={prodError}
      />
      {/* <NewCards
        title="Most Popular"
        favorite={favorite}
        setFavorite={setFavorite}
        cart={cart}
        setCart={setCart}
        NewItemData={products}
        showViewAll={true}
        error={prodError}
      /> */}
      <h3
        className="article-main-title"
        style={
          langContext.lang !== "EN"
            ? { fontFamily: "BPG Nino Mtavruli" }
            : { fontFamily: "Montserrat" }
        }
      >
        {langContext.lang === "EN" ? "LATEST ARTICLES" : "ახალი სტატიები"}
      </h3>
      <TitleLine />
      {/* <Articles title="Popular articles" /> */}
      {articlesError === "" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "189px",
          }}
        >
          <div className="article-home-page-width">
            <div className="home-article-list" style={{ width: "100%" }}>
              {sortedData.slice(0, 3).map((item) => {
                return (
                  <Article
                    key={item.id}
                    image={item.img_path}
                    title={item.title}
                    description={item.description}
                    authorImg={item && item.user && item.user.img_path}
                    author={item && item.user && item.user.name}
                    position={item.position}
                    id={item.id}
                    date={item.created_at}
                    category={item.catetory_name && item.catetory_name.name}
                  />
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <motion.div
          variants={variants}
          initial={variants.initial}
          whileInView={variants.whileInView}
          transition={{
            ease: "easeOut",
            duration: 1,
            delay: 0.5,
            type: "spring",
          }}
          className="error-handler-contianer"
        >
          <Alert
            style={{ textAlign: "center", marginBottom: "189px" }}
            severity="error"
          >
            {articlesError}
          </Alert>
        </motion.div>
      )}

      {/* <RegistrationSection /> */}
    </main>
  );
}
