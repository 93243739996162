import React from "react";
// import { galleyData } from "./Data";
import TitleLine from "./TitleLine";
import "../components/styles/Gallery.css";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

export default function MyGallery({ index, galleyData, lang }) {
  // const webSite = process.env.REACT_APP_WEBSITE;

  return (
    <section hidden={index === 4}>
      <h2
        className="gallery-title"
        style={{
          fontFamily: lang !== "EN" ? "BPG Nino Mtavruli" : "Montserrat",
        }}
      >
        {lang === "EN" ? "Gallery" : "გალერეა"}
      </h2>
      <TitleLine />
      <div className="gallery-grid-container">
        <Gallery>
          {galleyData &&
            galleyData.map((galleryImg) => {
              return (
                <Item
                  key={galleryImg.id}
                  original={galleryImg.image}
                  thumbnail={galleryImg.image}
                  width="1000"
                  height="550"
                  Transition="none"
                >
                  {({ ref, open }) => (
                    <img
                      ref={ref}
                      onClick={open}
                      src={galleryImg.image}
                      alt="galleryImage"
                      className="gellery-item"
                    />
                  )}
                </Item>
              );
            })}
        </Gallery>
      </div>
    </section>
  );
}
