import React from "react";
import GoogleMaps from "./GoogleMaps";
import "./styles/Location.css";

export default function Location({ wineryDetailInfo, lang }) {
  return (
    <div>
      <h5
        className="location-title"
        style={{
          fontFamily: lang !== "EN" ? "BPG Nino Mtavruli" : "Poppins",
        }}
      >
        {lang === "EN" ? "Location" : "ლოკაცია"}
      </h5>
      <GoogleMaps wineryDetailInfo={wineryDetailInfo} />
    </div>
  );
}
