import "./styles/OurWines.css";
// import { NewItemData } from "./Data";
import React, { useState } from "react";
import ViewAll from "./ViewAll";
import CardsMap from "./CardsMap";
import "./styles/NewCards.css";
import { Link } from "react-router-dom";
// import { BsFillCartCheckFill } from "react-icons/bs";
// import { MdFavorite } from "react-icons/md";
// import FavAndCartIcon from "./FavAndCartIcon";

export default function OurWines({
  cart,
  setCart,
  favorite,
  setFavorite,
  wineryDetailInfo,
  lang,
}) {
  const [hidden, setHidden] = useState(true);
  if (!hidden) {
    return (
      <>
        <div className="our-wines">
          <h2
            className="our-wines-title"
            style={{
              fontFamily: lang !== "EN" ? "BPG Nino Mtavruli" : "Poppins",
            }}
          >
            {lang === "EN" ? "Our wines" : "ჩვენი ღვინოები"}
          </h2>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <FavAndCartIcon
              icon={<BsFillCartCheckFill className="cart-icon" />}
              length={cart.length}
              link="/cart"
            /> */}
            {/* <FavAndCartIcon
              icon={<MdFavorite className="cart-icon" />}
              length={favorite.length}
              link="/favorite"
            /> */}
            <Link to={"/wines"}>
              <button
                onClick={() => setHidden(!hidden)}
                className="view-all-btn"
              >
                {lang === "EN" ? "View all" : "ნახე ყველა"}
              </button>
            </Link>
          </div>
        </div>
        <div className="view-all-our-wines">
          {wineryDetailInfo !== undefined &&
            wineryDetailInfo.products !== undefined && (
              <ViewAll
                favorite={favorite}
                setFavorite={setFavorite}
                cart={cart}
                setCart={setCart}
                NewItemData={wineryDetailInfo.products}
              />
            )}
        </div>
      </>
    );
  }
  return (
    <>
      <div className="our-wines">
        <h2
          className="our-wines-title"
          style={{
            fontFamily: lang !== "EN" ? "BPG Nino Mtavruli" : "Poppins",
          }}
        >
          {lang === "EN" ? "Our wines" : "ჩვენი ღვინოები"}
        </h2>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {/* <FavAndCartIcon
            icon={<BsFillCartCheckFill className="cart-icon" />}
            length={cart.length}
            link="/cart"
          /> */}
          {/* <FavAndCartIcon
            icon={<MdFavorite className="cart-icon" />}
            length={favorite.length}
            link="/favorite"
          /> */}
          <Link to={"/wines"}>
            <button onClick={() => setHidden(!hidden)} className="view-all-btn">
              {lang === "EN" ? "View all" : "ნახე ყველა"}
            </button>
          </Link>
        </div>
      </div>
      <div className="our-wine">
        {wineryDetailInfo !== undefined &&
          wineryDetailInfo.products !== undefined && (
            <CardsMap
              newItemData={wineryDetailInfo.products}
              favorite={favorite}
              setFavorite={setFavorite}
              cart={cart}
              setCart={setCart}
              slides={3}
            />
          )}
      </div>
    </>
  );
}
