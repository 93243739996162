import React, { useState } from "react";
// import { MdFavorite } from "react-icons/md";
// import { IoMdCart } from "react-icons/io";
import cartImg from "../images/heart.svg";
import heart from "../images/cart.svg";

export default function CartFavorites({
  items,
  favorite,
  setFavorite,
  cart,
  setCart,
}) {
  const [isRed, setIsRed] = useState(false);
  const [isCart, setIsCart] = useState(false);

  const toggleFavorite = (item) => {
    setIsRed(!isRed);

    if (isRed) {
      const filteredFavorite = favorite.filter((prod) => {
        return prod.id !== item.id;
      });

      setFavorite(filteredFavorite);
    } else {
      if (!favorite.map((fav) => fav.id).includes(item.id))
        setFavorite((current) => [...current, item]);
    }
  };

  const toggleCart = (item) => {
    setIsCart(!isCart);
    if (isCart) {
      const filteredCart = cart.filter((prod) => {
        return prod.id !== item.id;
      });
      setCart(filteredCart);
    } else {
      if (!cart.map((cartItem) => cartItem.id).includes(item.id)) {
        setCart((current) => [...current, item]);
      }
    }
  };

  return (
    <>
      <img
        className="heart"
        onClick={(e) => {
          e.preventDefault();
          toggleCart(items);
        }}
        style={
          cart.map((item) => item.id).includes(items.id)
            ? {
                filter:
                  "brightness(0) saturate(100%) invert(17%) sepia(90%) saturate(6885%) hue-rotate(7deg) brightness(97%) contrast(124%)",
              }
            : { color: "white", fontSize: "47px" }
        }
        src={heart}
        alt="heart"
      />
      <img
        className="favorite-img"
        onClick={(e) => {
          e.preventDefault();
          toggleFavorite(items);
        }}
        style={
          favorite.map((item) => item.id).includes(items.id)
            ? {
                filter:
                  "brightness(0) saturate(100%) invert(17%) sepia(90%) saturate(6885%) hue-rotate(7deg) brightness(97%) contrast(124%)",
              }
            : { color: "white", fontSize: "47px" }
        }
        src={cartImg}
        alt="cart"
      />
      {/* <IoMdCart
        onClick={() => toggleCart(items)}
        style={
          cart.map((item) => item.id).includes(items.id)
            ? { color: "red", fontSize: "47px" }
            : { color: "white", fontSize: "47px" }
        }
      />
      <MdFavorite
        onClick={() => toggleFavorite(items)}
        style={
          favorite.map((item) => item.id).includes(items.id)
            ? { color: "red", fontSize: "47px" }
            : { color: "white", fontSize: "47px" }
        }
      /> */}
    </>
  );
}
