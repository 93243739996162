import React from "react";
import { Link } from "react-router-dom";

export default function FavAndCartIcon({ icon, length, link }) {
  return (
    <Link to={link} className="cart-icon-cont">
      {icon}
      <span className="cart-number">{length}</span>
    </Link>
  );
}
