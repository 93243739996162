import React, { useContext, useState } from "react";
// import PopUpInput from "./PopUpInput";
import "../components/styles/SignIn.css";
// import { resetPasswordSchema } from "../schemas";
import { Link } from "react-router-dom";
// import { Form, Formik } from "formik";
import { context } from "./Root";
import api from "../Api/getData";
import { Alert } from "@mui/material";

export default function PassReset() {
  const [error, setError] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [emailError, setEmailError] = useState("");
  const [allRigth, setAllRigth] = useState(false);

  // const onSubmit = async (values, actions) => {
  //   await new Promise((resolve) => setTimeout(resolve, 1000));
  //   try {
  //     console.log(values);
  //     actions.resetForm();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // const onSubmit = async (values, actions) => {
  //   await new Promise((resolve) => setTimeout(resolve, 1000));

  //   try {
  //     // Perform your asynchronous action here, e.g., make an API request
  //     // await axios.post("http://188.129.222.217:8000/api/reset-password", values);
  //     await api.post("reset-password", values);

  //     // Log values after the asynchronous action
  //     console.log("Submitted form with values:", values);

  //     // Reset the form after successful submission
  //     actions.resetForm();

  //     // If you want to do something after the form is reset, you can add it here
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmailInput(enteredEmail);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(
      emailPattern.test(enteredEmail) ? "" : "Enter a valid email address"
    );
  };

  const signInFormContext = useContext(context);
  const langContext = useContext(context);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Perform your asynchronous action here, e.g., make an API request
      // await axios.post("http://188.129.222.217:8000/api/reset-password", values);
      await api.post("reset-password", { email: emailInput });

      // Reset the form after successful submission
      setEmailInput("");
      setAllRigth(true);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <h2>
        {langContext.lang === "EN" ? "Reset Password" : "აღადგინე პაროლი"}
      </h2>

      <form onSubmit={handleSubmit} className="login-form">
        {!allRigth && (
          <input
            style={
              emailError !== ""
                ? { border: "1px solid red" }
                : { border: "1px solid #d9d9d9" }
            }
            className="pass-input"
            type="email"
            id="email"
            name="email"
            value={emailInput}
            onChange={handleEmailChange}
            placeholder="Email"
            required
          />
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {allRigth && (
            <Alert severity="success">
              {langContext.lang === "EN"
                ? "Your new password has been sent to your email"
                : "თქვენი ახალი პაროლი გამოგზავნილი ელფოსტაზე"}
            </Alert>
          )}
          {allRigth ? (
            <button
              className="submit-button"
              onClick={() => signInFormContext.setShowPopUp(false)}
            >
              OK
            </button>
          ) : (
            <button className="submit-button" type="submit">
              {langContext.lang === "EN" ? "Reset Password" : "აღადგინე პაროლი"}{" "}
            </button>
          )}

          {error !== "" && <p>{error}</p>}
          <div className="reg-container">
            <span>
              {langContext.lang === "EN"
                ? "Haven't got an account yet?"
                : "არ გაქვს ექაუნთი?"}
            </span>
            <Link
              onClick={() => {
                signInFormContext.setView("registration");
              }}
              className="reg-title"
            >
              {langContext.lang === "EN" ? "Register Here" : "დარეგისტრირდი"}
            </Link>
          </div>
        </div>
      </form>
    </>

    //    <Formik
    //    initialValues={{ email: "" }}
    //    validationSchema={resetPasswordSchema}
    //    onSubmit={onSubmit}
    //  >
    //    {({ isSubmitting }) => (
    //      <>
    //        <h2>
    //          {langContext.lang === "EN" ? "Reset Password" : "აღადგინე პაროლი"}
    //        </h2>

    //        <Form className="login-form">
    //          {/* <PopUpInput
    //          name="fullName"
    //          type="text"
    //          placeholder={langContext.lang === "EN" ? "Full Name" : "სახელი"}
    //        /> */}
    //          <PopUpInput
    //            name="email"
    //            type="email"
    //            placeholder={langContext.lang === "EN" ? "Email" : "ელფოსტა"}
    //          />
    //          <div
    //            style={{
    //              display: "flex",
    //              alignItems: "center",
    //              justifyContent: "center",
    //              flexDirection: "column",
    //            }}
    //          >
    //            <button
    //              disabled={isSubmitting}
    //              className="submit-button"
    //              type="submit"
    //              onClick={() => onSubmit()}
    //            >
    //              {langContext.lang === "EN"
    //                ? "Reset Password"
    //                : "აღადგინე პაროლი"}{" "}
    //            </button>
    //            {error !== "" && <p>{error}</p>}
    //            <div className="reg-container">
    //              <span>
    //                {langContext.lang === "EN"
    //                  ? "Haven't got an account yet?"
    //                  : "არ გაქვს ექაუნთი?"}
    //              </span>
    //              <Link
    //                onClick={() => {
    //                  signInFormContext.setView("registration");
    //                }}
    //                className="reg-title"
    //              >
    //                {langContext.lang === "EN"
    //                  ? "Register Here"
    //                  : "დარეგისტრირდი"}
    //              </Link>
    //            </div>
    //          </div>
    //        </Form>
    //      </>
    //    )}
    //  </Formik>
  );
}
