import * as yup from "yup";

const passwordRules = /^.{8,}$/;

export const SignInScema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Required"),
});

export const registrationtionSchema = yup.object().shape({
  fullName: yup
    .string()
    .required("Required")
    .min(3),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Required"),
  password: yup
    .string()
    .min(8)
    .matches(passwordRules, "Please create a stronger password")
    .required("Required"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

export const resetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Required"),
});
