import React, { useContext } from "react";
import "../components/styles/ProductPage.css";
import { context } from "./Root";

export default function ProductInfo({ info, value }) {
  const langContext = useContext(context);
  return (
    <div className="product-info-container">
      <h5
        style={
          langContext.lang !== "EN"
            ? { fontFamily: "FiraGo" }
            : { fontFamily: "Poppins" }
        }
      >
        {info}
      </h5>
      <span
        style={
          langContext.lang !== "EN"
            ? { fontFamily: "FiraGo" }
            : { fontFamily: "Poppins" }
        }
        className="product-value"
      >
        {value}
      </span>
    </div>
  );
}
