import React, { useContext, useState } from "react";
import ContactForm from "../components/ContactForm";
import "../components/styles/FaqPage.css";
import TitleLine from "../components/TitleLine";
import FaqNew from "../components/FaqNew";
// import { FaqData } from "../components/Data";
import { changeTitle } from "../data/GeneralFunction";
import { context } from "../components/Root";

export default function FaqPage() {
  const [questionClick, setQuestionClick] = useState(false);

  const FaqData = (language) => {
    if (language === "EN") {
      return [
        {
          question: "Can I use my Wine Passport at any winery or tasting room?",
          answer:
            "Wine Passport programs typically have a list of participating wineries. It's essential to check the list to see where your Passport can be used.",
        },
        {
          question:
            "Can I get discounts or special offers with my Wine Passport?",
          answer:
            "Yes, many wineries offer exclusive discounts or promotions to Wine Passport holders.",
        },
        {
          question:
            "Do I need a physical booklet or can I use a digital version of the Wine Passport?",
          answer:
            "Wine Passports can come in both physical and digital formats. Some programs even offer a combination of both for added convenience.",
        },
        {
          question:
            "Is the Wine Passport only for wine connoisseurs, or can anyone use it?",
          answer:
            "Wine Passports are designed for wine enthusiasts of all levels, from beginners to connoisseurs, and anyone curious about wine.",
        },
        {
          question:
            "What are the benefits of being a registered Georgian Wine Passport holder?",
          answer:
            "Registered passport holders may receive exclusive offers, event invitations, and updates on new wine releases.",
        },
        {
          question:
            "Can I purchase a Wine Passport as a gift for someone else?",
          answer:
            "Yes, our Wine Passport program offers the option to purchase a Passport as a gift. You can usually specify the recipient's name during the purchase process.",
        },
        {
          question: "Do Wine Passports have an expiration date?",
          answer:
            "It's essential to check the terms and conditions of the passport. Some are valid for 1 year, while others have no expiration date.",
        },
        {
          question:
            "Can I share my Wine Passport with a friend or family member?",
          answer:
            "Typically, Wine Passports are intended for individual use, and each person needs their own Passport. Sharing may not be allowed under the program's terms.",
        },
        {
          question:
            "What information is included in a Wine Passport for each winery?",
          answer:
            "A Wine Passport typically provides details about the winery's location, history, available wines, and any special offers or discounts.",
        },
        {
          question: "Are there any age restrictions for Wine Passport holders?",
          answer:
            "The legal drinking age for wine varies by location. Georgian Wine Passport holders should comply with the legal drinking age in Georgia.",
        },
        {
          question:
            "Can I use my Wine Passport at wineries that are not on the list of participating locations?",
          answer:
            "Wine Passports are typically only valid at participating wineries listed in the program. It's essential to check the list before planning your visits.",
        },
      ];
    } else {
      // Add Georgian questions and answers here
      return [
        {
          question:
            "შემიძლია თუ არა ჩემი ღვინის პასპორტის გამოყენება ნებისმიერ ღვინის მარანში ან სადეგუსტაციო ოთახში?",
          answer:
            "ღვინის პასპორტის პროგრამებს, როგორც წესი, აქვს მონაწილე მარნების სია. აუცილებელია სიის შემოწმება, თუ სად შეიძლება გამოიყენოთ თქვენი პასპორტი.",
        },
        {
          question:
            "შემიძლია თუ არა ფასდაკლებების ან სპეციალური შეთავაზებების მიღება ჩემი ღვინის პასპორტით?",
          answer:
            "დიახ, ბევრი მარანი გთავაზობთ ექსკლუზიურ ფასდაკლებებს ან აქციებს ღვინის პასპორტის მფლობელებს.",
        },
        {
          question:
            "მჭირდება ფიზიკური ბუკლეტი თუ შემიძლია გამოვიყენო ღვინის პასპორტის ციფრული ვერსია?",
          answer:
            "ღვინის პასპორტები შეიძლება გამოვიდეს როგორც ფიზიკურ, ასევე ციფრულ ფორმატში. ზოგიერთი პროგრამა კი გთავაზობთ ორივეს კომბინაციას დამატებითი მოხერხებულობისთვის.",
        },
        {
          question:
            "ღვინის პასპორტი მხოლოდ ღვინის მცოდნეებისთვისაა თუ ყველას შეუძლია მისი გამოყენება?",
          answer:
            "ღვინის პასპორტები განკუთვნილია ყველა დონის ღვინის მოყვარულთათვის, დამწყებთათვის დაწყებული მცოდნემდე და ვინც დაინტერესებულია ღვინით",
        },
        {
          question:
            "რა სარგებელი აქვს საქართველში ღვინის რეგისტრირებული პასპორტის მფლობელს?",
          answer:
            "რეგისტრირებულ პასპორტის მფლობელებს შეუძლიათ მიიღონ ექსკლუზიური შეთავაზებები, ღონისძიების მოსაწვევები და განახლებები ღვინის ახალი გამოშვების შესახებ.",
        },
        {
          question: "შემიძლია ვიყიდო ღვინის პასპორტი სხვისთვის საჩუქრად?",
          answer:
            "დიახ, ჩვენი ღვინის პასპორტის პროგრამა გთავაზობთ პასპორტის საჩუქრად შეძენის შესაძლებლობას. ჩვეულებრივ, შეგიძლიათ მიუთითოთ მიმღების სახელი შეძენის პროცესში.",
        },
        {
          question: "აქვს თუ არა ღვინის პასპორტს ვარგისიანობის ვადა?",
          answer:
            "აუცილებელია პასპორტის პირობების შემოწმება. ზოგიერთი მოქმედების ვადა 1 წელია, ზოგს კი ვადის გასვლის ვადა არ აქვს.",
        },
        {
          question:
            "შემიძლია თუ არა ჩემი ღვინის პასპორტი გავუზიარო მეგობარს ან ოჯახის წევრს?",
          answer:
            "როგორც წესი, ღვინის პასპორტები განკუთვნილია ინდივიდუალური გამოყენებისთვის და თითოეულ ადამიანს სჭირდება საკუთარი პასპორტი. გაზიარება შეიძლება არ იყოს დაშვებული პროგრამის პირობებით.",
        },
        {
          question:
            "რა ინფორმაცია შედის ღვინის პასპორტში თითოეული ღვინის მარნისთვის?",
          answer:
            "ღვინის პასპორტი, როგორც წესი, შეიცავს დეტალებს ღვინის მარნის ადგილმდებარეობის, ისტორიის, ხელმისაწვდომი ღვინოების და ნებისმიერი სპეციალური შეთავაზებისა თუ ფასდაკლების შესახებ.",
        },
        {
          question:
            "არის თუ არა ასაკობრივი შეზღუდვები ღვინის პასპორტის მფლობელებისთვის?",
          answer:
            "ღვინის ლეგალური სასმელის ასაკი განსხვავდება მდებარეობის მიხედვით. ქართული ღვინის პასპორტის მფლობელებმა უნდა დაიცვან საქართველოში დალევის კანონიერი ასაკი.",
        },
        {
          question:
            "შემიძლია თუ არა ჩემი ღვინის პასპორტის გამოყენება ღვინის მარნებში, რომლებიც არ არიან მონაწილე ადგილების სიაში?",
          answer:
            "ღვინის პასპორტები, როგორც წესი, მოქმედებს მხოლოდ პროგრამაში ჩამოთვლილ მონაწილე ღვინის ქარხნებში. ვიზიტების დაგეგმვამდე აუცილებელია სიის შემოწმება.",
        },
      ];
    }
  };

  const langContext = useContext(context);

  changeTitle(`Findwine | Faq`);

  const toggle = (index) => {
    if (questionClick === index) {
      return setQuestionClick(null);
    }
    setQuestionClick(index);
  };
  return (
    <main>
      <section>
        <h1
          className="faq-main-title"
          style={
            langContext.lang !== "EN"
              ? { fontFamily: "BPG Nino Mtavruli" }
              : { fontFamily: "Montserrat" }
          }
        >
          {langContext.lang === "EN"
            ? "FREQUENTLY ASKED QUESTIONS"
            : "ხშირად დასმული კითხვები"}
        </h1>
        <TitleLine />
        <div className="faqs-container">
          {FaqData(langContext.lang).map((item, index) => {
            return (
              <FaqNew
                key={index}
                question={item.question}
                answer={item.answer}
                index={index}
                toggle={toggle}
                questionClick={questionClick}
              />
            );
          })}
        </div>
      </section>
      <section>
        <ContactForm />
      </section>
    </main>
  );
}
