import React from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import MapStyles from "./MapStyles";
// import mapIcon from "../images/winepassport/Ellipse 3.svg";
import mapIcon from "../images/mapIcon.svg";
import "./styles/GoogleMaps.css";
// import { markerPositions, markerPositions2 } from "./Data";
import { teest } from "./Data";
import { Link } from "react-router-dom";
import wineImg from "../../src/images/marcel-gross-marcelgross-ch-sHTwDYKti6Q-unsplash.png";
import dicertionVector from "../images/direction-vector.svg";

const libraries = ["places"];

const options = {
  styles: MapStyles.styles,
  mapId: "9c5b2a4f53a00c38",
};

export default function LocationGoogleMaps({
  locRegion,
  selected,
  setSelected,
  filteredData,
  dataForRegions,
  georgiaWineries,
}) {
  const mapContainerStyle = {
    width: "100%",
    height: "100vh",
  };

  const regionData = (locRegion) => {
    switch (locRegion) {
      case 0:
        return georgiaWineries;
      case 4:
        const filteredRegion4 = dataForRegions.find(
          (item) =>
            item.region_name.toLocaleLowerCase() ===
            "Imereti".toLocaleLowerCase()
        );
        return filteredRegion4 && filteredRegion4.winners
          ? filteredRegion4.winners
          : [];
      case 6:
        const filteredRegion6 = dataForRegions.find(
          (item) =>
            item.region_name.toLocaleLowerCase() ===
            "Kakheti".toLocaleLowerCase()
        );
        return filteredRegion6 && filteredRegion6.winners
          ? filteredRegion6.winners
          : [];
      case 1:
        const filteredRegion1 = dataForRegions.find(
          (item) =>
            item.region_name.toLocaleLowerCase() ===
            "Abkhazeti".toLocaleLowerCase()
        );
        return filteredRegion1 && filteredRegion1.winners
          ? filteredRegion1.winners
          : [];
      case 2:
        const filteredRegion2 = dataForRegions.find(
          (item) =>
            item.region_name.toLocaleLowerCase() ===
            "Adjara".toLocaleLowerCase()
        );
        return filteredRegion2 && filteredRegion2.winners
          ? filteredRegion2.winners
          : [];
      case 3:
        const filteredRegion3 = dataForRegions.find(
          (item) =>
            item.region_name.toLocaleLowerCase() === "Guria".toLocaleLowerCase()
        );
        return filteredRegion3 && filteredRegion3.winners
          ? filteredRegion3.winners
          : [];
      case 5:
        const filteredRegion5 = dataForRegions.find(
          (item) => item.region_name.toLocaleLowerCase() === "Racha-Lechkhumi"
        );
        return filteredRegion5 && filteredRegion5.winners
          ? filteredRegion5.winners
          : [];
      case 10:
        return filteredData;
      case 7:
        const filteredRegion7 = dataForRegions.find(
          (item) =>
            item.region_name.toLocaleLowerCase() ===
            "Kvemo Kartli".toLocaleLowerCase()
        );
        return filteredRegion7 && filteredRegion7.winners
          ? filteredRegion7.winners
          : [];
      case 8:
        const filteredRegion8 = dataForRegions.find(
          (item) =>
            item.region_name.toLocaleLowerCase() ===
            "Mtskheta-Mtianeti".toLocaleLowerCase()
        );
        return filteredRegion8 && filteredRegion8.winners
          ? filteredRegion8.winners
          : [];
      case 9:
        const filteredRegion9 = dataForRegions.find(
          (item) =>
            item.region_name.toLocaleLowerCase() ===
            "Samegrelo".toLocaleLowerCase()
        );
        return filteredRegion9 && filteredRegion9.winners
          ? filteredRegion9.winners
          : [];
      default:
        return [];
    }
  };

  const regionCenter = (locRegion) => {
    switch (locRegion) {
      case 0:
        return {
          lat: 42.3154,
          lng: 43.3569,
        };

      case 10:
        return {
          lat: 42.3154,
          lng: 43.3569,
        };
      default:
        return teest[locRegion - 1].center;
    }
  };

  const regionZoom = (locRegion) => {
    switch (locRegion) {
      case 0:
        return 9;
      case 1:
        return 14.2;
      case 2:
        return 14.3;
      case 3:
        return 14.4;
      case 4:
        return 14.5;
      case 5:
        return 14.6;
      case 6:
        return 14.7;
      case 7:
        return 14.8;
      case 8:
        return 14.9;
      case 9:
        return 15;
      case 10:
        return 9;
      default:
        return undefined; // You may want to handle other cases as needed.
    }
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAP7_Dlc7qRZaIGbqEupCR3Kl8TLaNptOo",
    libraries,
  });

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={regionZoom(locRegion)}
        center={regionCenter(locRegion)}
        options={options}
        onClick={() => setSelected(null)}
      >
        {dataForRegions.length > 0 &&
          regionData(locRegion).map((markerposition, index) => {
            return (
              <div key={index} className="google-maps-key">
                <Marker
                  position={markerposition.location}
                  icon={{
                    url: mapIcon,
                    scaledSize: new window.google.maps.Size(30, 30),
                  }}
                  onClick={() => {
                    setSelected(markerposition);
                  }}
                ></Marker>
              </div>
            );
          })}

        {selected && (
          <InfoWindow
            position={selected.location}
            options={{
              pixelOffset: new window.google.maps.Size(0, -40),
            }}
            onCloseClick={() => setSelected(null)}
          >
            <div>
              <Link
                to={`wineries/detail/${selected.id}`}
                className="infoWindow-container"
              >
                <img className="infoWindow-img" src={wineImg} alt="wine" />
                <div className="infoWindow-content">
                  <h4 className="infoWindow-title">{selected.name}</h4>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        selected &&
                        selected.location &&
                        selected.location.lat &&
                        selected.location.lng
                      ) {
                        const destination = `${selected.location.lat},${selected.location.lng}`;
                        const apiKey =
                          "AIzaSyAP7_Dlc7qRZaIGbqEupCR3Kl8TLaNptOo";
                        window.open(
                          `https://www.google.com/maps/dir/?api=1&destination=${destination}&key=${apiKey}`
                        );
                      } else {
                        console.error(
                          "Selected location does not have valid latitude and longitude."
                        );
                      }
                    }}
                    className="exact-Address"
                  >
                    <img src={dicertionVector} alt="direction-vector" />
                    Directions
                  </button>

                  {/* <h5 className="infoWindow-region">
                    Region:{selected.region}
                  </h5> */}
                  {/* <p className="infoWindow-street">{selected.village}</p> */}
                </div>
              </Link>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
}
