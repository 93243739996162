import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BsFillCartCheckFill } from "react-icons/bs";
import { MdFavorite } from "react-icons/md";
import FavAndCartIcon from "../components/FavAndCartIcon";
import TitleLine from "../components/TitleLine";
// import LineMaxWidth from "./components/LineMaxWidth";
import "../components/styles/Cart.css";
import { BsArrowUpShort } from "react-icons/bs";
import { BsArrowDownShort } from "react-icons/bs";

export default function Cart({ cart, setCart, clearCart, favorite }) {
  useEffect(() => {
    handlePrice();
  });

  const [price, setPrice] = useState(0);

  const handleDecrement = (itemId) => {
    const updateCart = cart.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          quantity: item.quantity > 1 ? item.quantity - 1 : 1,
        };
      }
      return item;
    });
    handlePrice();

    setCart(updateCart);
  };
  const handleRemove = (itemId) => {
    const updateCart = cart.filter((item) => item.id !== itemId);

    handlePrice();

    setCart(updateCart);
  };

  const handleIncrement = (itemId) => {
    const updateCart = cart.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          quantity: item.quantity + 1,
        };
      }
      return item;
    });
    handlePrice();

    setCart(updateCart);
  };

  const handlePrice = () => {
    let total = 0;
    cart.map((item) => (total += item.quantity * item.finalPrice));
    setPrice(total);
  };

  return (
    <div className="cart-container">
      <h1 className="section-one-title">Cart Items</h1>
      <TitleLine />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <FavAndCartIcon
          icon={<BsFillCartCheckFill className="cart-icon" />}
          length={cart.length}
          link="/cart"
        />
        <FavAndCartIcon
          icon={<MdFavorite className="cart-icon" />}
          length={favorite.length}
          link="/favorite"
        />
      </div>
      {cart.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px 0",
          }}
        >
          <h2>Cart Items Are Empty</h2>
          <Link style={{ textDecoration: "none", marginLeft: "10px" }} to="/">
            <button style={{ marginTop: 0 }} className="clear-Favorite-Btn">
              Home
            </button>
          </Link>
        </div>
      )}
      {cart.length > 0 && (
        <div className="main-container-cart">
          <div className="cart-items-container">
            {cart.map((item, index) => {
              return (
                <div key={index} className="cart-item-wrapper">
                  <img
                    className="cart-item-image"
                    src={item.linkImg}
                    alt="phone"
                  />
                  <div className="cart-item-details">
                    <div className="cart-item-characteristics">
                      <p className="characteristics-name">{item.title}</p>
                      <p className="characteristics-price">{`$${item.finalPrice}`}</p>
                      <button
                        onClick={() => handleRemove(item.id)}
                        className="characteristics-remove-btn"
                      >
                        remove
                      </button>
                    </div>
                    <div className="cart-item-count">
                      <BsArrowUpShort
                        onClick={() => handleIncrement(item.id)}
                        className="cart-item-counter-arrow"
                      />
                      <p style={{ userSelect: "none" }}>{item.quantity}</p>
                      <BsArrowDownShort
                        onClick={() => handleDecrement(item.id)}
                        className="cart-item-counter-arrow"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="bottom-container">
              <div className="line"></div>
              <div className="total-cost">
                <p>Total</p>
                <p>{price.toFixed(2)}</p>
              </div>
              <button onClick={clearCart} className="clear-btn">
                CLEAR CART
              </button>
            </div>
          </div>
        </div>
        // <div>
        //   {cart.map((item) => {
        //     return (
        //       <div className="cart-items-container">
        //         <div className="carts-wrapper">
        //           <div className="cart-item">
        //             <img src={item.linkImg} alt={item.title} />
        //             <div className="cart-item-rigth-block">
        //               <p className="cart-item-rigth-block-title">
        //                 {item.title}
        //               </p>
        //               <span className="cart-item-rigth-block-chara">
        //                 {item.characteristic}
        //               </span>
        //               <p className="cart-item-rigth-block-price">
        //                 Price:{item.finalPrice}
        //               </p>
        //             </div>
        //           </div>
        //           <div className="button-container">
        //             <button onClick={() => handleDecrement(item.id)}>-</button>
        //             <span>{item.quantity}</span>
        //             <button onClick={() => handleIncrement(item.id)}>+</button>
        //           </div>
        //           <div className="price-remove-btn">
        //             <p>{(item.finalPrice * item.quantity).toFixed(2)}</p>
        //             <button
        //               className="remove-btn"
        //               onClick={() => handleRemove(item.id)}
        //             >
        //               Remove
        //             </button>
        //           </div>
        //         </div>
        //         <LineMaxWidth />
        //       </div>
        //     );
        //   })}
        //   <div className="price-button-cont">
        //     <div className="total-price-cont">
        //       <p>Total Price</p>
        //       <p>{price.toFixed(2)}</p>
        //     </div>
        //     <div style={{ display: "flex", justifyContent: "flex-end" }}>
        //       <button className="clear-all-btn" onClick={clearCart}>
        //         Clear Cart
        //       </button>
        //     </div>
        //   </div>
        // </div>
      )}
    </div>
  );
}
