import React, { useContext } from "react";
import "../components/styles/AboutUs.css";
import AboutBg from "../components/AboutBg";
import { context } from "../components/Root";
import AboutUsContent from "../components/AboutUsContent";
import missionImage from "../images/aboutpage/mission.png";
import awarness from "../images/aboutpage/awareness.png";
import { useMediaQuery } from "@mui/material";
import { changeTitle } from "../data/GeneralFunction";
import TitleLine from "../components/TitleLine";

export default function AboutUs() {
  const popUpContext = useContext(context);
  const langContext = useContext(context);

  const isDesktop = useMediaQuery("(min-width: 768px)");
  changeTitle(`Findwine | About us`);

  return (
    <main
      className="about-main"
      style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}
    >
      <AboutBg
        title={langContext.lang === "EN" ? "ABOUT US" : "ჩვენ შესახებ"}
      />
      <h1
        className="section-one-title"
        style={
          langContext.lang !== "EN"
            ? { fontFamily: "BPG Nino Mtavruli" }
            : { fontFamily: "Montserrat" }
        }
      >
        {langContext.lang === "EN" ? "ABOUT US" : "ჩვენ შესახებ"}
      </h1>
      <TitleLine />
      <div className="about-us-new-container">
        <p>
          Welcome to Wineshop LLC, where our passion for wine meets the
          expertise of IT professionals. Founded in 2016 by a group of dedicated
          wine enthusiasts with a wealth of experience in the tech industry, our
          unique blend of skills and love for wine drives our mission to
          revolutionize the way small winemakers connect with the world.
        </p>
        <p>
          At Wineshop LLC, we believe that every great wine has a story to tell,
          and we are here to help winemakers share their stories with the world.
          Our vision goes beyond traditional consulting – we are your partners
          in the digital realm, offering a comprehensive range of services
          tailored to the unique needs of wineries.
        </p>
        <p>
          Our mission is to empower small winemakers by providing expert
          consultations that cover every aspect of their online presence. From
          creating captivating Facebook and Instagram accounts to ensuring their
          business is prominently featured on search engines and maps, our team
          is dedicated to elevating their digital footprint and reaching a
          global audience.
        </p>
        <p>
          In collaboration with Wine Club Georgia, we go the extra mile to
          ensure that wineries have the opportunity to shine on the grand stage.
          Through our close partnership, we facilitate their participation in
          prestigious wine festivals and events, amplifying their reach and
          impact within the industry.
        </p>
        <p>
          Join us on this exciting journey as we raise a glass to the remarkable
          world of wine, technology, and the endless possibilities that unfold
          when passion meets innovation.
        </p>
      </div>
      <AboutUsContent
        title={
          langContext.lang === "EN"
            ? "ABOUT WINE PASSPORT PROJECT"
            : "ღვინის პასპორტის პროექტის შესახებ"
        }
        image={missionImage}
        order1={1}
        order2={2}
        aboutSecondP={
          langContext.lang === "EN"
            ? "At Wine Passport, our mission goes beyond curating exceptional wine-tasting experiences. We're committed to fostering a deeper appreciation for Georgian wine and amplifying its presence on the global stage."
            : "ღვინის პასპორტში ჩვენი მისია სცილდება ღვინის დეგუსტაციის განსაკუთრებული გამოცდილების კურირებას. ჩვენ მზად ვართ გავაძლიეროთ ქართული ღვინის უფრო ღრმა შეფასება და გავაძლიეროთ მისი ყოფნა გლობალურ სცენაზე."
        }
        aboutFirstP={
          langContext.lang === "EN"
            ? 'Welcome to Wine Passport, a proud member of "Wine Club" – a close-knit union of professionals, wine lovers and enthusiasts who share a common passion: the love of wine. Our collective goal is to champion and celebrate the magnificent world of Georgian wine, recognized for its exceptional quality and centuries-old traditions.'
            : 'კეთილი იყოს თქვენი მობრძანება ღვინის პასპორტში, "ღვინის კლუბის" ამაყი წევრი - პროფესიონალთა, ღვინის მოყვარულთა და ენთუზიასტების მჭიდრო კავშირი, რომლებსაც აქვთ საერთო ვნება: ღვინის სიყვარული. ჩვენი კოლექტიური მიზანია გავაძლიეროთ და აღვნიშნოთ ქართული ღვინის ბრწყინვალე სამყარო, რომელიც აღიარებულია განსაკუთრებული ხარისხითა და მრავალსაუკუნოვანი ტრადიციებით.'
        }
      />
      <AboutUsContent
        title={
          langContext.lang === "EN"
            ? "RAISING AWARENESS"
            : "ცნობადობის ამაღლება"
        }
        image={awarness}
        order1={isDesktop ? 2 : 1}
        order2={isDesktop ? 1 : 2}
        aboutSecondP={
          langContext.lang === "EN"
            ? "With our Wine Passport program, we want to be a driving force in making wine tourism in Georgia more popular and well-known. Through our collective efforts, we are dedicated to raising awareness about Georgian wine, its unique characteristics, and the diverse winemaking regions throughout the country."
            : "„ჩვენი ღვინის პასპორტის პროგრამით ჩვენ გვინდა ვიყოთ მამოძრავებელი ძალა ღვინის ტურიზმის გასაუმჯობესებლად საქართველოში. ჩვენი კოლექტიური ძალისხმევით, ჩვენ ერთგული ვართ ქართული ღვინის, მისი უნიკალური მახასიათებლებისა და მეღვინეობის მრავალფეროვნების შესახებ ცნობიერების ამაღლებისკენ. რეგიონებში მთელი ქვეყნის მასშტაბით“."
        }
        aboutFirstP={
          langContext.lang === "EN"
            ? "We believe that wine tourism plays a main role in the growth of Georgia's wine industry. Our Wine Passport program is designed to make wine tourism accessible and enjoyable, contributing to the development of this exciting sector."
            : "ჩვენ გვჯერა, რომ ღვინის ტურიზმი მთავარ როლს თამაშობს საქართველოს ღვინის ინდუსტრიის ზრდაში. ჩვენი Wine Passport პროგრამა შექმნილია იმისათვის, რომ ღვინის ტურიზმი იყოს ხელმისაწვდომი და სასიამოვნო, რაც ხელს უწყობს ამ საინტერესო სექტორის განვითარებას."
        }
      />
    </main>
  );
}
