import React, { useContext } from "react";
import questionIcon from "../images/faqPage/Vector.svg";
import "../components/styles/FaqPage.css";
import arrowUp from "../images/faqPage/arrowUp.svg";
import arrowDown from "../images/faqPage/arrowDown.svg";
import { context } from "./Root";

export default function FaqNew({
  question,
  answer,
  index,
  questionClick,
  toggle,
  key,
}) {
  const langContext = useContext(context);
  return (
    <div
      style={
        questionClick === index
          ? { maxHeight: "500px" }
          : { maxHeight: "100px" }
      }
      key={key}
      className="faq-container"
    >
      <div className="faq-img-question-wrapper" onClick={() => toggle(index)}>
        <div className="faq-img-quest">
          <img src={questionIcon} alt="questionIcon" />
          <p
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "FiraGo" }
                : { fontFamily: "Lato" }
            }
          >
            {question}
          </p>
        </div>
        {questionClick === index ? (
          <img src={arrowUp} alt="arrowUp" />
        ) : (
          <img src={arrowDown} alt="arrowDown" />
        )}
      </div>
      {questionClick === index ? (
        <p
          style={
            langContext.lang !== "EN"
              ? { fontFamily: "FiraGo" }
              : { fontFamily: "Lato" }
          }
          className="faq-answ"
        >
          {answer}
        </p>
      ) : null}
    </div>
  );
}
