import React from "react";
import { Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles/Reviews.css";
import Stars from "./Stars";
import TitleLine from "./TitleLine";
import { motion } from "framer-motion/dist/framer-motion";
import { useMediaQuery } from "@mui/material";

export default function Reviews({ index, lang, reviews }) {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const variants = isDesktop
    ? {
        initial: { opacity: 0, x: -500 },
        whileInView: { opacity: 1, x: 0 },
        transition: { duration: 0.5 },
      }
    : {
        initial: { opacity: 0 },
        whileInView: { opacity: 1 },
        transition: { duration: 10 },
      };
  return (
    <>
      <section hidden={index === 3}>
        <h2 className="review-title">
          {lang === "GE" ? "მომხმარებლების შეფასებები" : "CUSTOMER REVIEWS"}
        </h2>
        <TitleLine />

        <motion.div
          className="navigation"
          variants={variants}
          initial={variants.initial}
          whileInView={variants.whileInView}
          transition={{ duration: 1 }}
        >
          <Swiper
            modules={[Pagination, A11y, Autoplay]}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            spaceBetween={20}
            slidesPerView={3}
            pagination={{ clickable: true }}
            className="swiper-reviews"
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 2,
              },
              1080: {
                slidesPerView: 3,
              },
            }}
          >
            {reviews &&
              reviews.map((review, index) => {
                return (
                  <SwiperSlide key={index} className="review-slider">
                    <Stars star={review.rating} />
                    <p className="review-quote">
                      {review.client_user && review.client_user.full_name}
                    </p>
                    <p className="review-author">{review.message}</p>
                  </SwiperSlide>
                );
              })}
            <br />
            <br />
            <br />
          </Swiper>
        </motion.div>
      </section>
    </>
  );
}
