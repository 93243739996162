import React from "react";
import "./styles/WineriesLabel.css";
import { useMediaQuery } from "@mui/material";

export default function WinerySelect({
  labelTiTle,
  // value1,
  // value2,
  // value3,
  // value4,
  values,
  handleSelectChange,
  selectedValue,
  selectClassName,
  width,
  mobWidth,
}) {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return (
    <div className="select-container">
      <label
        style={{
          color: "#3A3D43",
          fontFamily: "Poppins",
          marginRight: "6px",
          opacity: "0.5",
        }}
      >
        {labelTiTle}
      </label>
      <select
        className={selectClassName}
        onChange={handleSelectChange}
        value={selectedValue}
        style={isDesktop ? { width: width } : { width: mobWidth }}
      >
        {values.map((value, index) => {
          return (
            <option key={index} className="winery-select-option" value={value}>
              {value}
            </option>
          );
        })}
        {/* <option className="winery-select-option" value={value1}>
          {value1}
        </option>
        <option className="" value={value2}>
          {value2}
        </option>
        {value3 && (
          <option className="" value={value3}>
            {value3}
          </option>
        )}
        {value4 && (
          <option className="" value={value4}>
            {value4}
          </option>
        )} */}
      </select>
    </div>
  );
}
