import React, { useContext } from "react";
import EventBg from "../components/EventBg";
import "../components/styles/EventPage.css";
import TitleLine from "../components/TitleLine";
import EventFirstSection from "../components/EventFirstSection";
import { context } from "../components/Root";
import { changeTitle } from "../data/GeneralFunction";

export default function Event() {
  const popUpContext = useContext(context);
  const langContext = useContext(context);

  changeTitle(`Findwine | Events`);

  return (
    <main
      className="event-main"
      style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}
    >
      <EventBg />
      <h1
        className="event-main-title"
        style={
          langContext.lang !== "EN"
            ? { fontFamily: "FiraGo" }
            : { fontFamily: "Montserrat" }
        }
      >
        {langContext.lang === "EN" ? "Events" : "ღონისძიებები"}
      </h1>
      <TitleLine />
      <EventFirstSection lang={langContext.lang} />
    </main>
  );
}
