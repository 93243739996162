import React, { useContext, useEffect, useState } from "react";
import "../components/styles/WinePassDetailPage.css";
import WinePassInfo from "../components/WinePassInfo";
import { context } from "../components/Root";
import { Link, useParams } from "react-router-dom";
import api from "../Api/getData";
import TitleLine from "../components/TitleLine";
import { changeTitle } from "../data/GeneralFunction";
import { transformText } from "../data/georgianToEnglish";
import leftArrow from "../images/winePassDetail/ArrowLeft.svg";
import ModalComp from "../components/ModalComp";

export default function WinePassDetailPage() {
  const popUpContext = useContext(context);
  const [winePassportDetail, setWinePassportDetail] = useState([]);
  const [winePassError, setWinePassError] = useState("");
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(1);
  const handleClose = () => setOpen(false);
  const { id } = useParams();
  const langContext = useContext(context);
  const loginContext = useContext(context);

  console.log(winePassportDetail);

  const handleButtonClick = async () => {
    // Check if the user is logged in

    // If not logged in, open the popup
    if (!loginContext.userLogin) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      loginContext.setShowPopUp(true);
    } else {
      try {
        // Make the API request with the Authorization header
        // await api.post(
        //   "user/purchase-passport",
        //   { passport_id: id },
        //   {
        //     headers: {
        //       Authorization: `Bearer ${authToken}`,
        //     },
        //   }
        // );
        setOpen(true);
        console.log("Purchase successful");
      } catch (error) {
        console.log("Error:", error);
      }
      console.log(id);
    }
  };

  changeTitle(
    `Findwine | ${
      winePassportDetail.name === undefined
        ? ""
        : transformText(
            `Wine passport ${winePassportDetail.name.split(" ").slice(2, 3)}`
          )
    }`
  );

  const fetchWinePassDetail = async () => {
    try {
      const res = await api.get(`wine-passport/${id}`);
      setWinePassportDetail(res.data);
    } catch (error) {
      setWinePassError(error.message);
    }
  };

  useEffect(() => {
    fetchWinePassDetail();
  }, [id]);

  return (
    <>
      <main
        style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}
        className="wine-pass-detail-main"
      >
        {winePassError === "" ? (
          <h1 className="wine-pass-detail-main-title">
            {winePassportDetail && winePassportDetail.name}
          </h1>
        ) : (
          winePassError
        )}

        <TitleLine />
        <Link to={"/wine-passport"}>
          <img
            style={{ marginBottom: "29px", marginTop: "-50px" }}
            src={leftArrow}
            alt="arrowLeft"
          />
        </Link>
        <div className="winePassDetail-container">
          {winePassportDetail &&
            winePassportDetail.companies &&
            winePassportDetail.companies.map((passDetail) => {
              return (
                <WinePassInfo
                  id={passDetail.companies.id}
                  key={passDetail.companies.name}
                  name={passDetail.companies.name}
                  village={passDetail.companies.village}
                  city={passDetail.companies.city.name}
                  region={passDetail.companies.region.name}
                  phone={passDetail.companies.phone}
                  email={passDetail.companies.email}
                  offers={
                    passDetail.companies.tours &&
                    passDetail.companies.tours.offers
                  }
                  wines={passDetail.companies.wine_grapes}
                  guests={
                    passDetail.companies.tours &&
                    passDetail.companies.tours.guests
                  }
                />
              );
            })}
          <button
            onClick={() => handleButtonClick()}
            className="winePassDetail-btn"
          >
            {langContext.lang === "EN" ? "BUY NOW" : "ყიდვა"}
          </button>
        </div>
      </main>
      <ModalComp
        open={open}
        handleClose={handleClose}
        count={count}
        setCount={setCount}
        price={winePassportDetail.price}
        title={winePassportDetail.name}
        id={winePassportDetail.id}
      />
    </>
  );
}
