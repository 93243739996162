import React, { useContext, useState } from "react";
import "./styles/Package.css";
import PackageBenefit from "./PackageBenefit";
import { Link } from "react-router-dom";
import { context } from "./Root";
// import api from "../Api/getData";

import ModalComp from "./ModalComp";

export default function Package({
  title,
  info,
  // info2,
  // info3,
  // info4,
  price,
  mark,
  id,
}) {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(1);
  const handleClose = () => setOpen(false);
  const langContext = useContext(context);
  const loginContext = useContext(context);
  // const authToken = localStorage.getItem("authToken");
  // console.log(authToken);
  // console.log(price);
  // console.log(info);
  // console.log(title);

  const handleButtonClick = async () => {
    // Check if the user is logged in

    // If not logged in, open the popup
    if (!loginContext.userLogin) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      loginContext.setShowPopUp(true);
    } else {
      try {
        setOpen(true);
        // Make the API request with the Authorization header
        // await api.post(
        //   "user/purchase-passport",
        //   { passport_id: id },
        //   {
        //     headers: {
        //       Authorization: `Bearer ${authToken}`,
        //     },
        //   }
        // );

        console.log("Purchase successful");
      } catch (error) {
        console.log("Error:", error);
      }
      console.log(id);
    }
  };

  return (
    <>
      <div className="package-cont">
        <div className="package-wrapper">
          <h4
            className="package-wrapper-title"
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "FiraGo" }
                : { fontFamily: "Open Sans" }
            }
          >
            {title}
          </h4>
          {price === 100 ? (
            <>
              <div className="mark">{mark}</div>
              <div className="triangle-topright"></div>
            </>
          ) : null}

          <PackageBenefit info={info} />
        </div>
        <div>
          <div className="price-wrapper">
            <p
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Open Sans" }
              }
            >
              {langContext.lang === "EN" ? "Price" : "ფასი"}
            </p>
            <span>{price}</span>
          </div>
          <Link
            to={`/wine-passport/detail/${id}`}
            className="wine-pass-see-details"
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "FiraGo" }
                : { fontFamily: "Poppins" }
            }
          >
            {langContext.lang === "EN" ? " See details" : "ნახე დეტალურად"}
          </Link>
          <button onClick={() => handleButtonClick()} className="package-btn">
            <p
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Open Sans" }
              }
            >
              {langContext.lang === "EN" ? "BUY NOW" : "ყიდვა"}
            </p>
            {/* <p
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Open Sans" }
              }
              className="wine-pass-coming"
            >
              {langContext.lang === "EN" ? "COMING SOON" : "მალე"}
            </p> */}
          </button>
        </div>
      </div>
      <ModalComp
        open={open}
        handleClose={handleClose}
        count={count}
        setCount={setCount}
        price={price}
        title={title}
        id={id}
      />
      {/* <Modal open={open} onClose={handleClose}>
        <div className="modal-container">
          <div className="wine-pass-popup-left-container">
            <div className="wine-left-popup-head-container">
              <img src={winePass} alt="wine-pass" />
              <div>
                <h2>PRIORITY WINE PASSPORT</h2>
                <h4>01/01/2024 - 01/01/2025</h4>
                <span>More Details</span>
              </div>
            </div>
            <div className="quantities-container">
              <span className="quantity-title">Quantity</span>
              <div className="count-btns-container">
                <button
                  disabled={count === 1}
                  onClick={() => setCount((prev) => prev - 1)}
                  className="decr-btn"
                >
                  -
                </button>
                <span className="quantity">{count}</span>
                <button
                  onClick={() => setCount((prev) => prev + 1)}
                  className="incr-btn"
                >
                  +
                </button>
              </div>
            </div>
            <div className="wine-pass-left-line"></div>
            <p className="wine-pass-left-price">Price</p>
            <div className="wine-pass-name-and-price">
              <p className="priority-wine-passport">
                Priority wine passport <span className="x-quantity">x </span>
                <span className="span-count">{count}</span>
              </p>
              <span className="each-wine-pass-price">59 GEL</span>
            </div>
            <div className="wine-pass-left-line"></div>
            <div className="total-wine-pass-price-cont">
              <span className="total-price-span">Total price</span>
              <span className="total-price-sec-span">{count * 59} GEL</span>
            </div>
          </div>
          <div className="wine-pass-popup-right-container">
            <img src={closeBtn} alt="close-btn" />
          </div>
        </div>
      </Modal> */}
    </>
  );
}
