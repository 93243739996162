import React from "react";
import "./styles/TitleLine.css";

export default function TitleLine() {
  return (
    <div className="line-center">
      <div className="title-line">
        <div className="title-line2"></div>
      </div>
    </div>
  );
}
