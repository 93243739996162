import React, { useState } from "react";
import "./styles/SimilarWine.css";
import { Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import StarRatingImage from "../images/similarwine/Icon awesome-star.png";
import { Link } from "react-router-dom";
import { Alert, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";
import locIcon from "../images/loc-icon.svg";
import api from "../Api/getData";

export default function SimilarWine({
  wineries,
  error,
  wineryDetailInfo,
  id,
  lang,
}) {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [locationError, setLocationError] = useState("");

  // const webSite = process.env.REACT_APP_WEBSITE;

  console.log(wineries);

  const fetchSimilarWineryLocation = async (wineryId) => {
    try {
      const res = await api.get(`wineries/${wineryId}`);
      const data = res.data.location;
      const { lat, lng } = data;
      window.open(`https://www.google.com/maps?q=${lat},${lng}`);
    } catch (error) {
      setLocationError(error.message);
    }
  };

  const variants = isDesktop
    ? {
        initial: { opacity: 0, x: -500 },
        whileInView: { opacity: 1, x: 0 },
      }
    : {
        initial: { opacity: 0 },
        whileInView: { opacity: 1 },
      };

  return (
    <section className="similar-wine-section">
      <h2 className="similar-wine-main-title">
        {`${lang === "EN" ? "Other wineries in" : "სხვა მარნები"} ${
          wineryDetailInfo.region
        }${lang === "EN" ? "" : "ს რეგიონში"}`}
      </h2>
      {error && (
        <motion.div
          variants={variants}
          initial={variants.initial}
          whileInView={variants.whileInView}
          transition={{
            ease: "easeOut",
            duration: 1,
            delay: 0.5,
            type: "spring",
          }}
          className="error-handler-contianer"
        >
          <Alert style={{ textAlign: "center" }} severity="error">
            {error}
          </Alert>
        </motion.div>
      )}

      <Swiper
        modules={[Scrollbar, A11y]}
        spaceBetween={30}
        slidesPerView={3.5}
        scrollbar={{ draggable: true }}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 2,
          },
          1080: {
            slidesPerView: 3.5,
          },
        }}
      >
        {wineries !== undefined &&
          wineries.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                <Link to={`/wineries/detail/${item.id}`}>
                  <div className="similar-wine-content">
                    <img
                      className="similar-wine-img"
                      src={item.img_path}
                      alt="wine"
                    />
                    <div className="similar-wine-text-content">
                      <h1 className="similar-wine-title">
                        {item.name.length > 20
                          ? `${item.name.slice(0, 15)}...`
                          : item.name}
                      </h1>
                      <p className="similar-wine-p">{item.description}</p>
                      <div className="similar-wine-location-rating">
                        {locationError === "" ? (
                          <div
                            className="similar-wine-location"
                            onClick={(e) => {
                              e.preventDefault();
                              fetchSimilarWineryLocation(item.id);
                            }}
                          >
                            <img
                              style={{ fontSize: "24px", color: "#727477" }}
                              src={locIcon}
                              alt="location-icon"
                            />
                            <p className="similar-wine-location-p">{`${item.city.name}, ${wineryDetailInfo.region}`}</p>
                          </div>
                        ) : (
                          locationError
                        )}

                        <div className="similar-wine-rating">
                          <p className="similar-wine-rating-p">{`${5}/5`}</p>
                          <img src={StarRatingImage} alt="star" />
                        </div>
                      </div>
                      <div className="wine-btn-flex">
                        <button
                          className="similar-wine-btn"
                          style={{
                            fontFamily: lang !== "EN" ? "FiraGo" : "Poppins",
                          }}
                        >
                          Learn more
                        </button>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
        {wineries !== undefined && wineries.length === 0 && error === "" && (
          <h5
            className="onlyOne-wineryRegion"
            style={{
              fontFamily: lang !== "EN" ? "FiraGo" : "Poppins",
            }}
          >
            {`${
              lang === "EN"
                ? "There are no more wineries in"
                : "არცერთი სხვა მარანი არ არის"
            }  ${wineryDetailInfo.region}${lang === "EN" ? "" : "ს რეგიონში"}`}
          </h5>
        )}
        <br />
      </Swiper>
    </section>
  );
}
