import React, { useContext, useState, useEffect } from "react";
import ArticlePageBg from "../components/ArticlePageBg";
import { context } from "../components/Root";
import "../components/styles/ArticleDetail.css";
import TitleLine from "../components/TitleLine";
import Article from "../components/Article";
import { motion } from "framer-motion/dist/framer-motion";
import { Alert, useMediaQuery } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import api from "../Api/getData";
import { transformText } from "../data/georgianToEnglish";
import { changeTitle } from "../data/GeneralFunction";

export default function ArticleDetailPage() {
  const popUpContext = useContext(context);
  // const [readMore, setReadMore] = useState(false);
  const [articleDetail, setArticleDetail] = useState({});
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(true);
  const [articles, setArticles] = useState([]);
  const [year, setYear] = useState("");
  const [monthName, setMonthName] = useState("");
  const [day, setDay] = useState("");
  const initialSliceLength = 300;
  const [isFullTextVisible, setIsFullTextVisible] = useState(false);

  const langContext = useContext(context);

  const { id } = useParams();
  changeTitle(
    `Findwine | ${articleDetail.title === undefined ? "" : articleDetail.title}`
  );

  const similarArticles = articles.filter(
    (article) => article.id.toString() !== id.toString()
  );

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const res = await api.get("articles");
        setArticles(res.data);
        setLoader(false);
      } catch (error) {
        setError(error.message);
        setLoader(false);
      }
    };

    fetchArticles();
  }, []);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const res = await api.get(`articles/${id}`);
        setArticleDetail(res.data);
        setLoader(false);
      } catch (error) {
        setError(error.message);
        setLoader(false);
      }
    };
    fetchArticles();
  }, [id]);

  useEffect(() => {
    window.history.pushState(
      null,
      "",
      `${
        articleDetail.title !== undefined
          ? `?article=${transformText(articleDetail.title)}`
          : ""
      }`
    );
  }, [articleDetail.title]);

  useEffect(() => {
    if (articleDetail && articleDetail.date) {
      const dateParts = articleDetail.date.split("-");
      const year = dateParts[0];
      const numericMonth = dateParts[1];
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const parsedNumericMonth = parseInt(numericMonth, 10);

      const monthName = monthNames[parsedNumericMonth - 1];

      let day = dateParts[2];
      if (day.startsWith("0")) {
        day = day.substring(1);
      }

      setYear(year);
      setMonthName(monthName);
      setDay(day);
    }
  }, [articleDetail]);

  const isDesktop = useMediaQuery("(min-width: 768px)");
  // const webSite = process.env.REACT_APP_WEBSITE;

  const toggleTextVisibility = () => {
    setIsFullTextVisible(!isFullTextVisible);
    isDesktop && window.scrollTo({ top: 1000, behavior: "smooth" });
  };

  const fullTextVisibilityStyle = isDesktop
    ? { columnCount: isFullTextVisible ? "2" : "1" }
    : { columnCount: "1" };

  const variants = isDesktop
    ? {
        initial: { opacity: 0, x: -500 },
        initialForAuthor: { opacity: 0, x: -500 },
        animateForImg: { opacity: 1 },
        initailForH2: { opacity: 0 },
        initialForImg: { opacity: 0 },
        animate: { opacity: 1, x: 0 },
        initialForSimilar: { opacity: 0, x: -200 },
        whileInViewSimilar: { opacity: 1, x: 0 },
        animateForh2: { opacity: 1 },
        whileInView: { opacity: 1 },
        initialForRigthblock: { opacity: 0, x: 500 },
        whileInViewAuthor: { opacity: 1, x: 0 },
      }
    : {
        initial: { opacity: 0, x: -500 },
        initialForAuthor: { opacity: 0 },
        initailForH2: { opacity: 0 },
        animateForh2: { opacity: 1 },
        animate: { opacity: 1, x: 0 },
        animateForImg: { opacity: 1 },
        whileInView: { opacity: 1 },
        initialForImg: { opacity: 0 },
        whileInViewAuthor: { opacity: 1 },
        initialForSimilar: { opacity: 0 },
        whileInViewSimilar: { opacity: 1 },
      };

  const variantsError = isDesktop
    ? {
        initial: { opacity: 0, x: -500 },
        whileInView: { opacity: 1, x: 0 },
      }
    : {
        initial: { opacity: 0 },
        whileInView: { opacity: 1 },
      };
  return (
    <section
      className="article-section-forOverflow"
      style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}
    >
      <ArticlePageBg title={articleDetail.title} />
      {loader ? (
        <div className="loarder-cont">
          <div className="loader">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      ) : error ? (
        <motion.div
          variants={variantsError}
          initial={variantsError.initial}
          whileInView={variantsError.whileInView}
          transition={{
            ease: "easeOut",
            duration: 1,
            delay: 0.5,
            type: "spring",
          }}
          className="error-handler-contianer"
        >
          <Alert style={{ textAlign: "center" }} severity="error">
            {error}
          </Alert>
          <Link to="/">Go Home</Link>
        </motion.div>
      ) : (
        <>
          <motion.h4
            variants={variants}
            initial={variants.initial}
            animate={variants.animate}
            viewport={{ once: true }}
            transition={{ duration: 1.5, ease: "easeOut", delay: 0.5 }}
            className="main-article-date"
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            {monthName} {day} {year} |{" "}
            {articleDetail.catetory_name && articleDetail.catetory_name.name}
          </motion.h4>
          <motion.h2
            variants={variants}
            initial={variants.initailForH2}
            animate={variants.animateForh2}
            viewport={{ once: true }}
            transition={{ duration: 1.5, ease: "easeOut", delay: 0.5 }}
            className="article-detail-title"
          >
            {articleDetail.title}
          </motion.h2>
          <TitleLine />
          <div className="article-detail-container">
            <div className="articles-detail-width">
              <div style={{ width: "100%" }}>
                <motion.img
                  variants={variants}
                  initial={variants.initialForImg}
                  animate={variants.animateForImg}
                  viewport={{ once: true }}
                  transition={{ duration: 2, ease: "easeOut", delay: 1.5 }}
                  style={{
                    borderRadius: "4px",
                    width: "100%",
                    marginBottom: "56px",
                    maxHeight: "80vh",
                    objectFit: "cover",
                  }}
                  src={articleDetail.img_path}
                  alt="article"
                />
                <motion.p
                  variants={variants}
                  initial={variants.initailForH2}
                  whileInView={variants.whileInView}
                  viewport={{ once: true }}
                  transition={{ duration: 1.5, ease: "easeOut", delay: 0.5 }}
                  style={fullTextVisibilityStyle}
                  className="article-detail-container-p"
                >
                  {isFullTextVisible ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: articleDetail.description,
                      }}
                    ></div>
                  ) : (
                    <>
                      {articleDetail && articleDetail.description && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: articleDetail.description.slice(
                              0,
                              initialSliceLength
                            ),
                          }}
                        ></div>
                      )}
                    </>
                  )}
                </motion.p>
                {articleDetail &&
                articleDetail.description &&
                articleDetail.description.length > initialSliceLength ? (
                  <button
                    onClick={toggleTextVisibility}
                    className="article-detail-btn"
                  >
                    {isFullTextVisible ? "Read less..." : "Read more..."}
                  </button>
                ) : null}

                <motion.div
                  variants={variants}
                  initial={variants.initialForAuthor}
                  whileInView={variants.whileInViewAuthor}
                  viewport={{ once: true }}
                  transition={{
                    ease: "easeOut",
                    duration: 1,
                    delay: 1,
                  }}
                  className="detail-author-cont"
                >
                  <img
                    src={articleDetail.user && articleDetail.user.img_path}
                    alt="author"
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span className="detail-author-name">
                      {articleDetail.user && articleDetail.user.name}
                    </span>
                    <span className="detail-author-position">
                      {articleDetail.position}
                    </span>
                  </div>
                </motion.div>
              </div>
              <div>
                <h2
                  className="article-detail-similar-article-title"
                  style={{
                    fontFamily:
                      langContext.lang !== "EN"
                        ? "BPG Nino Mtavruli"
                        : "Montserrat",
                  }}
                >
                  {langContext.lang === "EN"
                    ? "SIMILAR ARTICLES"
                    : "მსგავსი სტატიები"}
                </h2>
                <TitleLine />
                <motion.div
                  variants={variants}
                  initial={variants.initialForSimilar}
                  whileInView={variants.whileInViewSimilar}
                  viewport={{ once: true }}
                  transition={{
                    ease: "easeOut",
                    duration: 1,
                    delay: 0.5,
                    type: "spring",
                  }}
                  className="article-list"
                  style={{ marginBottom: "200px" }}
                >
                  {articleDetail &&
                    articleDetail.catetory_name &&
                    (() => {
                      const filteredArticles = similarArticles.filter(
                        (item) =>
                          item.catetory_name.name ===
                          articleDetail.catetory_name.name
                      );

                      return filteredArticles.length === 0
                        ? similarArticles
                            .slice(0, 3)
                            .map((item) => (
                              <Article
                                key={item.id}
                                image={item.img_path}
                                title={item.title}
                                description={item.description}
                                authorImg={
                                  item && item.user && item.user.img_path
                                }
                                author={item && item.user && item.user.name}
                                position={item.position}
                                id={item.id}
                                date={item.created_at}
                                category={
                                  item.catetory_name && item.catetory_name.name
                                }
                              />
                            ))
                        : filteredArticles
                            .slice(0, 3)
                            .map((item) => (
                              <Article
                                key={item.id}
                                image={item.img_path}
                                title={item.title}
                                description={item.description}
                                authorImg={
                                  item && item.user && item.user.img_path
                                }
                                author={item && item.user && item.user.name}
                                position={item.position}
                                id={item.id}
                                date={item.created_at}
                                category={
                                  item.catetory_name && item.catetory_name.name
                                }
                              />
                            ));
                    })()}
                </motion.div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
