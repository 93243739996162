import React, { useEffect, useState, useContext } from "react";
import SectionOne from "../components/SectionOne";
import TitleLine from "../components/TitleLine";
import "../components/styles/SectionOne.css";
import AboutWinePassport from "../components/AboutWinePassport";
import WinePassBenefit from "../components/WinePassBenefit";
import PackageSection from "../components/PackageSection";
import api from "../Api/getData";
import { context } from "../components/Root";
import { changeTitle } from "../data/GeneralFunction";
// import useCustomHooksGetData from "../hooks/useCustomHookGetData";

export default function WinePassport() {
  const [winePass, setWinePass] = useState([]);
  const [error, setError] = useState("");

  const popUpContext = useContext(context);
  const langContext = useContext(context);

  // const testPass = useCustomHooksGetData("wine-passport");

  changeTitle("Findwine | Wine-passport");

  useEffect(() => {
    const fetchWinePass = async () => {
      try {
        const response = await api.get("/wine-passport");
        setWinePass(response.data);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchWinePass();
  }, []);

  return (
    <div
      className="winePass-cont-forOverflow"
      style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}
    >
      <SectionOne />
      <h1
        className="section-one-title"
        style={
          langContext.lang !== "EN"
            ? { fontFamily: "BPG Nino Mtavruli" }
            : { fontFamily: "Montserrat" }
        }
      >
        {langContext.lang === "EN"
          ? "What is wine passport?"
          : "რა არის ღვინის პასპორტი"}
      </h1>
      <TitleLine />
      <AboutWinePassport />
      <h2
        className="section-one-title"
        style={
          langContext.lang !== "EN"
            ? { fontFamily: "BPG Nino Mtavruli" }
            : { fontFamily: "Montserrat" }
        }
      >
        {langContext.lang === "EN"
          ? "WINE PASS BENEFITS"
          : "ღვინის პასპორტის ბენეფიტები"}
      </h2>
      <TitleLine />
      <WinePassBenefit />
      <h2
        className="section-one-title"
        style={
          langContext.lang !== "EN"
            ? { fontFamily: "BPG Nino Mtavruli" }
            : { fontFamily: "Montserrat" }
        }
      >
        {langContext.lang === "EN" ? "Select package" : "აირჩიე პაკეტი"}
      </h2>
      <TitleLine />
      <PackageSection error={error} winePass={winePass} />
    </div>
  );
}
