import React from "react";
import "../components/styles/Terms.css";
import { changeTitle } from "../data/GeneralFunction";

export default function Terms() {
  changeTitle("Findwine | Terms");

  return (
    <>
      <div className="title-cont">
        <h1 className="term-title">Terms of use</h1>
      </div>
      <main className="terms-main-container">
        <p>
          <h4>1. General Conditions</h4>
          These terms and conditions (the “Terms and Conditions”) constitute a
          binding agreement between you and Wineshop LLC (the “Company”). The
          Terms and Conditions govern any use of the Company’s website -
          findwines.ge (the “Website”) and the receipt of the Company’s
          services. Please read the Terms and Conditions carefully. The use of
          any services of the Website indicates your unconditional acceptance of
          the Terms and Conditions. Note that the Website is owned by the
          Company, a limited liability company organized and existing under the
          laws of Georgia, with its legal address at Guramishvili Ave. N34, Flat
          3a, Tbilisi, Georgia and identification number 400170239.
        </p>
        <p>
          <h4>2. About the Wine Passport</h4>A Georgian Wine Passport (“Wine
          Passport”) is a program that provides you with exclusive access to
          wineries and tasting rooms. Wine passport gives you the possibility to
          explore a variety of wines, enjoy special discounts, obtain event
          invitations, receive exclusive offers and updates on new wine
          releases, and document your visits to different wine venues in
          Georgia. Wine Passport comes in physical or digital formats and is a
          guide to a world of Georgian wine experiences. Wine Passport programs
          provide a list of participating wineries in Georgia, and are
          exclusively applicable to the mentioned designated wineries. It is
          important to note that the list of wineries showcased on the Website
          may undergo changes without any prior notice.
        </p>
        <p>
          <h4>3. Statements and Warranties</h4>
        </p>
        <p>
          By consenting to the Terms and Conditions, you acknowledge and agree
          to the following:
        </p>
        <p>
          3.1. You are a fully capable physical person, at least 18 years of
          age.
        </p>
        <p>
          3.2. You possess full authority to electronically sign (indicated by
          checking a box) and fulfill the obligations outlined in the Terms and
          Conditions.
        </p>
        <p>
          3.3. You are not currently under the influence of narcotic, alcoholic,
          psychotropic or toxic substances. Additionally, you are not the
          subject to error, fraud, pressure, threats or any other illegal
          influence. Furthermore, you have not been subjected to violence,
          threats, deception, misleading information, or any other prohibited
          actions by the Company or any third party. You are fully cognizant of
          the content of your will, the essence of the Terms and Conditions, and
          the legal consequences that may arise from it.
        </p>
        <p>
          3.4. You will conscientiously, fully and thoroughly fulfill your
          obligations under the Terms and Conditions.
        </p>
        <p>
          3.5. You have thoroughly reviewed the Terms and Conditions and
          unconditionally accept them.
        </p>
        <p>
          3.6. You have provided a true, accurate, current and complete
          information about yourself.
        </p>
        <p>
          3.7. You are not involved in or participating in any illegal
          activities, including but not limited to money laundering, arms
          trafficking, terrorism, or any other unlawful activities, as defined
          by the legislation of Georgia and the country of your citizenship.
        </p>
        <p>
          3.8. Your actions are not and will not be directed towards deceiving
          the Company and/or any other third party. In adherence to this
          principle, the documents (if applicable) and/or any other information
          provided by you to the Company for the acquisition and/or utilization
          of the Wine Passport are or will be true, accurate, current and
          complete at the time of submission.
        </p>
        <p>
          3.9. Your actions are and will be in compliance with the local and/or
          international legislation both during the acquisition of the Wine
          Passport and throughout the entire duration of the validity of the
          Terms and Conditions.
        </p>
        <p>
          3.10. You consent to the Company searching, verifying, and processing
          any information specified by you and/or related to it, within the
          scope of your consent, including personal information, following the
          submission of an application for obtaining the Wine Passport and
          throughout the duration of its usage.
        </p>
        <p>
          3.11. You consent to receiving promotional and/or communication emails
          from the Website.
        </p>
        <p>
          3.12. The Company reserves the right to revoke the Wine Passport with
          prior notice if, upon discovery, it is determined that the information
          provided by you during the Wine Passport purchase is untrue,
          inaccurate, or incomplete.
        </p>
        <p>
          3.13. You confirm and agree that you will not undertake any action
          that:
        </p>
        <div style={{ paddingLeft: "50px" }}>
          <p>3.13.1. Is illegal, discriminatory or fraudulent;</p>
          <p>
            3.13.2. Violates the Company’s privacy, as well as Terms and
            Conditions;
          </p>
          <p>
            3.13.3. Allows unauthorized access or collection of information
            using automated means from the Website (without prior consent from
            the Company) or accessing information for which you have not
            obtained permission from the Company;
          </p>
          <p>
            3.13.4. Involves the collection or harvesting of information
            regarding other users of the Website for any purpose, including, but
            not limited to, sending unsolicited commercial emails to such users;
          </p>
          <p>
            3.13.5. Contains viruses and/or malwares or any other information
            that hinders, complicates or damages the proper operation or
            functionality of the Website, or its content and services;
          </p>
          <p>
            3.13.6. Infringes upon or abuses the rights of others, including but
            not limited to, impersonating any other person, such as a registered
            user of the Website or an employee of the Company.
          </p>
        </div>
        <p>
          3.14. The Company reserves the right to make additions, deletions, or
          modifications to its services without prior notice.
        </p>
        <p>
          3.15. The Company does not guarantee that the Website is free of
          viruses or other harmful components.
        </p>
        <p>
          3.16. The design of the Website, software and any other materials
          presented on the Website are subject to intellectual property rights,
          hence are protected accordingly.
        </p>
        <p>
          3.17. The information presented on the Website is intended for general
          information and educational purposes only. Under no circumstances
          shall the Company be held liable for any special, direct, indirect,
          consequential, or incidental damages or any damages whatsoever,
          whether in an action of contract, negligence, or any other nature,
          arising out of or in connection with the content or services provided
          by the Company, including the purchase of the Wine Passport.
        </p>
        <p>
          3.18. The information presented on the Website may contain
          discrepancies. The Company shall not be held liable for any such
          discrepancies.
        </p>
        <p>
          3.19. Under no circumstances shall the Company be held liable for
          changes in the conditions and services offered by any of the wineries
          listed in the Wine Passport. It is strongly advised to verify the
          information directly with the representatives of the listed wineries.
        </p>
        <p>
          3.20. The Company provides access to information on third-party
          products and services or links to websites solely for the purpose of
          offering general and educational information. The Company does not
          guarantee the accuracy, relevance, timeliness, or completeness of any
          information found on the provided external links. Your use of
          third-party information and/or external links is at your own risk, and
          the Company disclaims any responsibility or liability for the content,
          use, or availability of such third-party information and/or websites.
          The Company does not verify the content and/or accuracy of such
          third-party information or data, nor does it warrant that such content
          is free of viruses or other harmful materials. Therefore, the Company
          makes no warranties or representations and assumes no responsibility
          for any electronic information and its content provided by any third
          party, including, without limitation, the accuracy, subject, quality,
          or timeliness of any electronic content.
        </p>
        <p>
          3.21. The Company disclaims any and all liability arising from your
          actions, breach of any agreement, including the Terms and Conditions,
          non-compliance with applicable laws and regulations, etc.
        </p>
        <p>3.22. The Company is not liable for, including:</p>
        <div style={{ paddingLeft: "50px" }}>
          <p>3.22.1. The damage caused by you to any third party;</p>
          <p>
            3.22.2. The results of a technical problem and/or interruption of
            the Wine Passport;
          </p>
          <p>
            3.22.3. Any damage resulting from the non-fulfillment or improper
            fulfillment of obligations on your part;
          </p>
          <p>
            3.22.4. The accuracy of any information provided by you to the
            Company and any damages caused by such inaccuracy.
          </p>
        </div>
        <p>
          3.23. The Company enters into this agreement based on the statements,
          guarantees, and obligations outlined in the Terms and Conditions.
          Consequently, following the conclusion of the agreement between you
          and the Company, any breach of the statements, guarantees, and
          obligations stipulated in the Terms and Conditions provides sufficient
          grounds for the Company to unilaterally refuse to provide any and all
          services, including, but not limited to, revoking the Wine Passport.
        </p>
        <p>
          <h4>4. Limitation of Liability</h4>
        </p>
        <p>
          4.1. The Company shall not be held liable for (1) the actions of third
          parties, including, but not limited to, wineries; (2) the information
          presented on the Website; (3) the safety, including, but not limited
          to, the safety from viruses and malwares, of the Website and the
          external links provided on the Website.
        </p>
        <p>
          4.2. Under no circumstances shall the Company and/or its respective
          officers, directors, employees, agents, successors, subsidiaries,
          divisions, or any third party providing information on the Website be
          liable for any special, direct, indirect, consequential, or incidental
          damages (including, but not limited to damages for loss of profit,
          loss of data, or loss of use) arising out of the use or inability to
          use the Website.
        </p>
        <p>
          4.3. Under no circumstances shall the Company or its respective
          officers, directors, employees, agents, successors, subsidiaries,
          divisions, or any third party providing information on the Website be
          liable for any damages and losses resulting from your use of the
          Website, whether in contract, tort, or otherwise, exceeding the amount
          you paid to the Company in connection with the purchase of the Wine
          Passport.
        </p>
        <p>
          4.4. The Company does not guarantee the uninterrupted operation of the
          Website. The Company shall not be held liable for the unavailability
          of services during the period when a technical error is detected. The
          Company is not responsible for technical malfunctions of networks,
          systems, servers, providers, services, etc.; nor for the interruption
          or delay in the data processing process, destruction, theft,
          unauthorized access to the material provided by you.
        </p>
        <p>
          4.5. The Company reserves the right to reject and/or cancel
          applications and/or transactions, as well as revoke issued Wine
          Passports, with individuals who are involved in a legal dispute with
          the Company; who have previously violated the Company’s Terms and
          Conditions; who are suspected of any kind of fraud; or who provide
          untrue, incomplete, and/or inaccurate information, including but not
          limited to, their identification data.
        </p>
        <p>
          4.6. The Company is not responsible for cancellations, postponements,
          or changes in the events and/or special offers provided by the listed
          wineries.{" "}
        </p>
        <p>
          <h4>5. Eligibility and Conditions for Obtaining the Wine Passport</h4>
        </p>
        <p>
          5.1. The Website and its services are intended for individuals who are
          18 years and older, and who have not been suspended or removed by the
          Company for any reason. By accepting the Terms and Conditions, you
          agree to provide true, accurate, and complete information about
          yourself, including, but not limited to, about your age.
        </p>
        <p>
          5.2. The Wine Passport is designed for the exclusive use of each
          individual customer. You are not allowed to sell, lend, and/or
          otherwise transfer the Wine Passport to any other third party.
        </p>
        <p>
          5.3. The Company does not offer refunds on any issued Wine Passports.{" "}
        </p>
        <p>
          5.4. An option is available to purchase the Wine Passport as a gift.
          You must specify the recipient’s name and provide all required
          information during the purchase process. The Wine Passport will be
          issued for the specified person and will not be subject to selling,
          lending, or otherwise transferring to any other party.
        </p>
        <p>
          5.5. Depending on the package, Wine Passports may be issued for
          varying periods of time. Some may be issued for a duration of one
          year, while others may be issued without an expiration date.
        </p>
        <p>
          <h4>6. Intellectual Property</h4>
        </p>
        <p>
          6.1. All content within the Website is protected by law, and the
          rights to it belong to the Company and may only be used with the prior
          written consent of the Company.
        </p>
        <p>
          6.2. Any information, text and/or image presented on the Website is
          the property of the Company, and its full or partial use, duplication,
          and/or reproduction without the prior written consent from the Company
          is prohibited.{" "}
        </p>
        <p>
          6.3. The violation of these rights will result in consequences as
          provided for by the legislation of Georgia.
        </p>
        <p>
          <h4>7. Governing Law and Dispute Settlement</h4>
        </p>
        <p>
          7.1. All relationships between you and the Company, regarding the
          services provided on the Website, are governed by the legislation of
          Georgia.
        </p>
        <p>
          7.2. Any disputes and/or differences arising out of or in connection
          with the services provided on the Website shall be settled by the
          Tbilisi City Court, in accordance with the legislation of Georgia.
        </p>
        <p>
          <h4>8. Privacy Policy</h4>
        </p>
        <p>
          8.1. By accessing and using the Website, you confirm that you have
          read and agree to the Company’s privacy policy and Terms and
          Conditions.
        </p>
        <p>
          8.2. The Privacy Policy sets out the terms and conditions under which
          the Company will collect and process your personal data for the
          purpose of providing services.{" "}
        </p>
        <p>
          8.3. The Company collects and processes your personal data solely for
          the purpose of providing services, monitoring the provided services
          and improving the quality of service, as well as for direct marketing
          purposes.
        </p>
        <p>
          8.4. The Company commits to providing you with complete and
          comprehensive information regarding the processing of your personal
          data upon your request, but no later than within 10 days of your
          request. You have the right to request and receive the following
          information:
        </p>
        <div style={{ paddingLeft: "50px" }}>
          <p>8.4.1. which personal data are being processed;</p>
          <p>8.4.2. the purpose of data processing;</p>
          <p>8.4.3. the legal grounds for data processing;</p>
          <p>8.4.4. the ways in which the data were collected;</p>
          <p>
            8.4.5. to whom your personal data were disclosed, and the grounds
            and purpose of the disclosure.
          </p>
        </div>
        <p>
          8.5. You have the right to request the correction, update, addition,
          blocking, deletion, and/or destruction of your personal data if it is
          incomplete, inaccurate, not current, and/or if it was collected and
          processed against the requirements of the legislation of Georgia. You
          can also request the termination of the processing of your data for
          direct marketing purposes.
        </p>
        <p>
          8.6. The Company has a lawful basis for processing your information.
        </p>
        <p>
          8.7. When you engage in any activity through the Website, including
          but not limited to purchasing the Wine Passport, the Company may ask
          you to provide certain personal information, such as:
        </p>
        <div style={{ paddingLeft: "50px" }}>
          <p>8.7.1. Your name and surname;</p>
          <p>8.7.2. Date of birth;</p>
          <p>8.7.3. Personal number;</p>
          <p>8.7.4. Phone number;</p>
          <p>8.7.5. Email address;</p>
          <p>8.7.6. Address;</p>
          <p>
            8.7.7. Additional details about you that you may voluntarily
            provide.
          </p>
        </div>
        <p>
          8.8. When utilizing the services on the Website, including but not
          limited to purchasing the Wine Passport, you will be required to
          provide your credit card number, expiration date, and authentication
          code, and/or other relevant details. However, it is important to note
          that this information is not stored on the Website. Consequently, the
          Company does not retain your complete credit card information,
          preserving only the first six and last four digits essential for
          identifying the card type and its validity.
        </p>
        <p>
          8.9. Information about you is acquired through your voluntary consent
          when you provide details on the Website.
        </p>
        <p>
          8.10. The Company retains your personal information for the entire
          duration of the service, and additionally, for a period of 10 years
          following the conclusion of the service. This extended period is
          maintained to address any inquiries and/or complaints, fulfill
          accounting requirements in accordance with relevant regulations, and
          meet other obligations stipulated by the legislation of Georgia.
        </p>
        <p>
          8.11. The Company employs all reasonable measures to safeguard your
          personal data. However, the Company cannot be held liable in the event
          that third parties illegally access and/or use your data.
        </p>
        <p>
          <h4>9. Communication</h4>
        </p>
        <p>
          9.1. You can contact the Company regarding any information or
          dissatisfaction through email at info@winshop.ge.{" "}
        </p>
        <p>
          <h4>10. Miscellaneous </h4>
        </p>
        <p>
          10.1. The Company reserves the right to update and/or modify the Terms
          and Conditions at its own discretion at any time without the
          obligation of prior notice. Your continued use of the Website and its
          services constitutes the acceptance of the aforementioned updates
          and/or modifications.
        </p>
        <p>
          10.2. The Company, upon the existence of a legitimate purpose, is
          entitled to request additional information from you, such as
          additional documentation and/or any information in accordance with the
          legislation of Georgia.
        </p>
        <p>
          10.3. If any clause or part of the Terms and Conditions is revoked,
          invalidated, or terminated, it will not affect the validity of the
          remaining clauses of the Terms and Conditions.
        </p>
        <span style={{ marginTop: "200px" }}>Last Update: January 2024.</span>
      </main>
    </>
  );
}
