import React, { useEffect, useState, useContext } from "react";
// import { useLoaderData } from "react-router-dom";
import AboutWinery from "../components/AboutWinery";
import SimilarWine from "../components/SimilarWine";
import WineriesDetailBg from "../components/WineriesDetailBg";
import Reviews from "../components/Reviews";
import api from "../Api/getData";
import { useParams, Link } from "react-router-dom";
import { context } from "../components/Root";
import { Alert, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";
import Gallery from "../components/Gallery";
import { transformText } from "../data/georgianToEnglish";
import { changeTitle } from "../data/GeneralFunction";

export default function WineryDetail({
  cart,
  setCart,
  favorite,
  setFavorite,
  NewItemData,
}) {
  const [index, setIndex] = useState(0);
  const [wineries, setWineries] = useState([]);
  const [error, setError] = useState("");
  const [wineryDetailInfo, setWineryDetailInfo] = useState([]);
  const [regionWineries, setRegionWineries] = useState([]);
  const [loader, setLoader] = useState(true);

  const langContext = useContext(context);

  changeTitle(
    `Findwine | ${
      wineryDetailInfo.company === undefined
        ? ""
        : transformText(wineryDetailInfo.company)
    }`
  );

  const isDesktop = useMediaQuery("(min-width: 768px)");
  const variantsError = isDesktop
    ? {
        initial: { opacity: 0, x: -500 },
        whileInView: { opacity: 1, x: 0 },
      }
    : {
        initial: { opacity: 0 },
        whileInView: { opacity: 1 },
      };

  const popUpContext = useContext(context);

  const { id } = useParams();
  const wineryId = parseInt(id, 10);

  const filteresimilarWineries = regionWineries.filter(
    (item) => item.id !== wineryId
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const regionId = () => {
        //   console.log(wineryDetailInfo);
        //   if (wineryDetailInfo.region === "კახეთი") {
        //     let regionItem = allRegionsData.find(
        //       (item) =>
        //         item.region_name.toLowerCase() === "კახეთი".toLowerCase()
        //     );
        //     if (regionItem) {
        //       return regionItem.id;
        //     }
        //   } else if (wineryDetailInfo.region === "Kvemo Kartli") {
        //     let regionItem = allRegionsData.find(
        //       (item) => item.region_name === "Kvemo Kartli"
        //     );
        //     if (regionItem) {
        //       return regionItem.id;
        //     }
        //   } else if (wineryDetailInfo.region === "Abkhazeti") {
        //     let regionItem = allRegionsData.find(
        //       (item) => item.region_name === "Abkhazeti"
        //     );
        //     if (regionItem) {
        //       return regionItem.id;
        //     }
        //   } else if (wineryDetailInfo.region === "Adjara") {
        //     let regionItem = allRegionsData.find(
        //       (item) => item.region_name === "Adjara"
        //     );
        //     if (regionItem) {
        //       return regionItem.id;
        //     }
        //   } else if (wineryDetailInfo.region === "Guria") {
        //     let regionItem = allRegionsData.find(
        //       (item) => item.region_name === "Guria"
        //     );
        //     if (regionItem) {
        //       return regionItem.id;
        //     }
        //   } else if (wineryDetailInfo.region === "Imereti") {
        //     let regionItem = allRegionsData.find(
        //       (item) => item.region_name === "Imereti"
        //     );
        //     if (regionItem) {
        //       return regionItem.id;
        //     }
        //   } else if (wineryDetailInfo.region === "Racha-Lechkhumi") {
        //     let regionItem = allRegionsData.find(
        //       (item) => item.region_name === "Racha-Lechkhumi"
        //     );
        //     if (regionItem) {
        //       return regionItem.id;
        //     }
        //   } else if (wineryDetailInfo.region === "Mtskheta-Mtianeti") {
        //     let regionItem = allRegionsData.find(
        //       (item) => item.region_name === "Mtskheta-Mtianeti"
        //     );
        //     if (regionItem) {
        //       return regionItem.id;
        //     }
        //   } else if (wineryDetailInfo.region === "Samegrelo") {
        //     let regionItem = allRegionsData.find(
        //       (item) => item.region_name === "Samegrelo"
        //     );
        //     if (regionItem) {
        //       return regionItem.id;
        //     }
        //   }
        // };
        // const idRegion = regionId();
        if (wineryDetailInfo.region_id !== undefined) {
          const response = await api.get(
            `region-winers/${wineryDetailInfo.region_id}`
          );
          setWineries(response.data);
        }
      } catch (error) {
        setError(error.message);
      }
    };
    fetchData();
  }, [wineryDetailInfo]);

  const pushWinnersToRegion = () => {
    const winners = wineries.reduce(
      (acc, curr) => [...acc, ...curr.winners],
      []
    );
    setRegionWineries(winners);
  };

  useEffect(() => {
    pushWinnersToRegion();
  }, [wineries]);
  useEffect(() => {
    window.history.pushState(
      null,
      "",
      `${
        wineryDetailInfo.company !== undefined
          ? `?winery=${transformText(wineryDetailInfo.company)}`
          : ""
      }`
    );
  }, [wineryDetailInfo.company]);

  useEffect(() => {
    const fetchDetailInfo = async () => {
      try {
        const response = await api.get(`/wineries/${id}`);
        setLoader(false);
        setWineryDetailInfo(response.data);
      } catch (error) {
        setLoader(false);
        setError(error.message);
        // if (error.response) {
        //   setNotFound(true);
        // }
      }
    };
    fetchDetailInfo();
  }, [id]);

  // const wineydetail = useLoaderData();
  return (
    <div style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}>
      <WineriesDetailBg
        showReviewForm={popUpContext.showReviewForm}
        wineryDetailInfo={wineryDetailInfo.company}
      />
      {loader ? (
        <div className="loarder-cont">
          <div className="loader">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      ) : error ? (
        <motion.div
          variants={variantsError}
          initial={variantsError.initial}
          whileInView={variantsError.whileInView}
          transition={{
            ease: "easeOut",
            duration: 1,
            delay: 0.5,
            type: "spring",
          }}
          className="error-handler-contianer"
        >
          <Alert style={{ textAlign: "center" }} severity="error">
            {error}
          </Alert>
          <Link to="/">Go Home</Link>
        </motion.div>
      ) : (
        <>
          <AboutWinery
            lang={langContext.lang}
            wineryDetailInfo={wineryDetailInfo}
            price={
              wineryDetailInfo &&
              wineryDetailInfo.tours &&
              wineryDetailInfo.tours.price
            }
            guests={
              wineryDetailInfo &&
              wineryDetailInfo.tours &&
              wineryDetailInfo.tours.guests
            }
            setIndex={setIndex}
            index={index}
            favorite={favorite}
            setFavorite={setFavorite}
            cart={cart}
            setCart={setCart}
            NewItemData={NewItemData}
            setShowReviewForm={popUpContext.setShowReviewForm}
            showReviewForm={popUpContext.showReviewForm}
          />
          {wineryDetailInfo.gallery.length >= 1 ? (
            <Gallery
              lang={langContext.lang}
              galleyData={wineryDetailInfo.gallery}
              index={index}
            />
          ) : null}
          {wineryDetailInfo.review.length >= 1 ? (
            <Reviews
              lang={langContext.lang}
              index={index}
              reviews={wineryDetailInfo.review}
            />
          ) : null}

          <SimilarWine
            id={id}
            wineryDetailInfo={wineryDetailInfo}
            error={error}
            wineries={filteresimilarWineries}
            setError={setError}
            lang={langContext.lang}
          />
        </>
      )}
    </div>
  );
}
