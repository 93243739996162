import React, { useState, useEffect, useContext } from "react";
import "./styles/Navbar.css";
import { Link, NavLink } from "react-router-dom";
import logo from "../images/findwine_logo-03.png";
import logo2 from "../images/findwine_logo-09.png";
// import * as Icons from "react-icons/rx";
// import LangButton from "./LangButton";
import EventDown from "./EventDown";
import { MdOutlineClear } from "react-icons/md";
import "./styles/Button.css";
import PopUp from "./PopUp";
import burger from "../../src/images/Hamburger_LG.svg";
import { context } from "./Root";
// import { RxTriangleUp } from "react-icons/rx";
// import { RxTriangleRight } from "react-icons/rx";
import profileIcon from "../images/profileIcon.svg";
import profileSmallIcon from "../images/profileSmallIcon.svg";
import blackProfileIcon from "../images/blackprofileicon.svg";
import signOut from "../images/SignOut.svg";

export default function Navbar() {
  const [eventDown, setEventDown] = useState(false);
  // const [showWineries, setShowWineries] = useState(false);
  const [showCatalog, setShowCatalog] = useState(false);
  const [showArticle, setShowArticle] = useState(false);
  const [showBugerMenu, setShowBurgerMenu] = useState(false);
  const [navBar, setNavBar] = useState(false);
  // const [showWineCatalog, setShowWineCatalog] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const popUpContext = useContext(context);
  const signInFormContext = useContext(context);
  const langContext = useContext(context);

  useEffect(() => {
    if (popUpContext.showPopUp) {
      disableBackgroundElements();
    } else {
      enableBackgroundElements();
    }
  }, [popUpContext.showPopUp]);

  useEffect(() => {
    const handleWindowClick = () => {
      setShowCatalog(false);
      // setShowWineCatalog(false);
      setShowArticle(false);
      setShowProfile(false);
    };

    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  const disableBackgroundElements = () => {
    const focusableElements = document.querySelectorAll(
      'a, button, input,div, textarea, select, [tabindex]:not([tabindex="-1"])'
    );

    focusableElements.forEach((element) => {
      if (
        element.className === "pass-input" ||
        element.className === "input-error" ||
        element.className === "submit-button" ||
        element.className === "pass-recover" ||
        element.className === "checkmark" ||
        element.className === "container"
      ) {
        return; // Skip the specific div element
      }

      element.setAttribute("tabindex", "-1");
      element.dataset.tabIndexBackup = element.tabIndex;
    });

    document.body.classList.add("no-scroll");
  };

  const enableBackgroundElements = () => {
    const focusableElements = document.querySelectorAll(
      "[data-tab-index-backup]"
    );
    focusableElements.forEach((element) => {
      element.setAttribute("tabindex", element.dataset.tabIndexBackup);
      element.removeAttribute("data-tab-index-backup");
    });
    document.body.classList.remove("no-scroll");
  };

  const changeNavBarBackground = () => {
    if (window.scrollY >= 100) {
      setNavBar(true);
    } else {
      setNavBar(false);
    }
  };

  window.addEventListener("scroll", changeNavBarBackground);

  return (
    <>
      <header
        style={
          popUpContext.showPopUp || popUpContext.showReviewForm
            ? { filter: "brightness(0.5)", pointerEvents: "none" }
            : null
        }
        className={navBar ? "header active" : "header"}
      >
        <nav>
          <Link
            to="/"
            className={navBar ? "navbar-logo active" : "navbar-logo"}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              setShowCatalog(false);
              setShowArticle(false);
              setShowProfile(false);
            }}
          >
            <img
              onClick={() => {
                setShowBurgerMenu(false);
              }}
              className="logo"
              src={navBar ? logo2 : logo}
              alt="logo"
            />
          </Link>
          <Link
            to="/"
            className="navbar-logo-mob"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              setShowCatalog(false);
              setShowArticle(false);
              setShowProfile(false);
            }}
          >
            <img
              onClick={() => {
                setShowBurgerMenu(false);
              }}
              className="logo"
              src={logo}
              alt="logo"
            />
          </Link>
          <ul className={navBar ? "navbar-ul active" : "navbar-ul"}>
            <li className="nav-item">
              <NavLink
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "FiraGo" }
                    : { fontFamily: "Montserrat" }
                }
                className={navBar ? "navlink navactive" : "navlink"}
                to="/wine-passport"
                onClick={() => {
                  setShowCatalog(false);
                  setShowProfile(false);
                  setShowArticle(false);
                }}
              >
                {langContext.lang === "EN"
                  ? "WINE PASSPORT"
                  : "ღვინის პასპორტი"}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={"/wines"}
                // style={showCatalog ? { color: "#e79a74" } : null}
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "FiraGo" }
                    : { fontFamily: "Montserrat" }
                }
                onClick={(e) => {
                  setShowCatalog(!showCatalog);
                  setShowArticle(false);
                  // e.stopPropagation();
                  setShowProfile(false);
                }}
                className={navBar ? "navlink navactive" : "navlink"}
              >
                {langContext.lang === "EN" ? "WINES" : "ღვინოები"}
                {/* კატალოგი ამოვიღეთ სამომავლოდ თუ დამჭირდა დესკტოპ {showCatalog ? (
                  <RxTriangleUp className="icon" />
                ) : (
                  <Icons.RxTriangleDown className="icon" />
                )} */}
              </NavLink>
              {/* {showCatalog && (
                <>
                  <div className="catalog-Dropdown">
                    <div className="wine-catalog-menu">
                      <div className="catalog-sub-menu">
                        <div
                          style={showWineCatalog ? { color: "white" } : null}
                          onMouseEnter={() => {
                            setEventDown(false);
                            setShowWineCatalog(true);
                            setShowArticle(false);
                          }}
                          onMouseLeave={() => setShowWineCatalog(false)}
                          // onClick={(e) => {
                          //   setShowWineCatalog(!showWineCatalog);
                          //   e.stopPropagation();
                          // }}
                        >
                          <Link className="wines-link" to={"wines"}>
                            WINES <RxTriangleRight />
                          </Link>
                          {showWineCatalog && (
                            <div className="wine-catalog-lists">
                              <Link
                                className="wine-catalog-link"
                                onClick={() => {
                                  setShowCatalog(false);
                                  setShowProfile(false);
                                }}
                                to={"/wines/family-wine-cellars"}
                              >
                                FAMILY WINE CELLARS
                              </Link>
                              <Link
                                className="wine-catalog-link"
                                onClick={() => {
                                  setShowCatalog(false);
                                  setShowProfile(false);
                                }}
                                to={"/wines/qvevry"}
                              >
                                QVEVRY
                              </Link>
                              <Link
                                className="wine-catalog-link"
                                onClick={() => {
                                  setShowCatalog(false);
                                  setShowProfile(false);
                                }}
                                to={"/wines/red"}
                              >
                                RED
                              </Link>
                              <Link
                                className="wine-catalog-link"
                                onClick={() => {
                                  setShowCatalog(false);
                                  setShowProfile(false);
                                }}
                                to={"/wines/white"}
                              >
                                WHITE
                              </Link>
                              <Link
                                className="wine-catalog-link"
                                onClick={() => {
                                  setShowCatalog(false);
                                  setShowProfile(false);
                                }}
                                to={"/wines/rose"}
                              >
                                ROSE
                              </Link>
                            </div>
                          )}
                        </div>
                        <Link
                          className={"wineries-sub-link"}
                          style={{ color: "white" }}
                          // style={showWineries ? { border: "none" } : { border: "none" }}
                          to="/wineries"
                          onClick={() => {
                            setShowCatalog(false);
                            setShowProfile(false);
                            setShowArticle(false);
                          }}
                        >
                          WINERIES
                        </Link>
                        <Link
                          className={"wineries-sub-link"}
                          style={{ color: "white" }}
                          // style={showWineries ? { border: "none" } : { border: "none" }}
                          to="/wine-bars"
                          onClick={() => {
                            setShowCatalog(false);
                            setShowProfile(false);
                            setShowArticle(false);
                          }}
                        >
                          WINE BARS
                        </Link>
                        <Link
                          className={"wineries-sub-link"}
                          style={{ color: "white" }}
                          // style={showWineries ? { border: "none" } : { border: "none" }}
                          to="/shop"
                          onClick={() => {
                            setShowCatalog(false);
                            setShowProfile(false);
                            setShowArticle(false);
                          }}
                        >
                          SHOP
                        </Link>
                        <Link
                          className={"wineries-sub-link"}
                          style={{ color: "white" }}
                          // style={showWineries ? { border: "none" } : { border: "none" }}
                          to="/courses"
                          onClick={() => {
                            setShowCatalog(false);
                            setShowProfile(false);
                            setShowArticle(false);
                          }}
                        >
                          COURSES
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              )} */}
            </li>
            <li className="nav-item">
              <NavLink
                to={"/wineries"}
                // style={showCatalog ? { color: "#e79a74" } : null}
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "FiraGo" }
                    : { fontFamily: "Montserrat" }
                }
                onClick={(e) => {
                  setShowCatalog(!showCatalog);
                  setShowArticle(false);
                  // e.stopPropagation();
                  setShowProfile(false);
                }}
                className={navBar ? "navlink navactive" : "navlink"}
              >
                {langContext.lang === "EN" ? "WINERIES" : "მარნები"}
              </NavLink>
            </li>

            <li
              onMouseEnter={() => {
                setEventDown(true);
                setShowArticle(false);
                setShowCatalog(false);
                // setShowWineCatalog(false);
              }}
              onMouseLeave={() => setEventDown(false)}
              className="nav-item-event"
            >
              <NavLink
                className={navBar ? "navlink navactive" : "navlink"}
                to="/events"
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "FiraGo" }
                    : { fontFamily: "Montserrat" }
                }
                onClick={() => {
                  setShowCatalog(false);
                  setShowArticle(false);
                  setShowProfile(false);
                }}
              >
                {langContext.lang === "EN" ? "EVENTS" : "ღონისძიებები"}
              </NavLink>
              {eventDown && <EventDown navBar={navBar} />}
            </li>
            <li
              // onMouseEnter={() => {
              //   setEventDown(false);
              //   setShowArticle(true);
              //   setShowCatalog(false);
              //   setShowWineCatalog(false);
              // }}
              // onMouseLeave={() => setShowArticle(false)}
              className="nav-item"
            >
              <NavLink
                to="/articles"
                // style={showArticle ? { color: "#e79a74" } : null}
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "FiraGo" }
                    : { fontFamily: "Montserrat" }
                }
                onClick={() => {
                  // setShowArticle(!showArticle);
                  setShowCatalog(false);
                  setShowProfile(false);
                }}
                className={navBar ? "navlink navactive" : "navlink"}
              >
                {langContext.lang === "EN" ? "ARTICLES" : "სტატიები"}
                {/* {showArticle ? (
                  <RxTriangleUp className="icon" />
                ) : (
                  <Icons.RxTriangleDown className="icon" />
                )} */}
              </NavLink>
              {showArticle && (
                <>
                  <div
                    onMouseEnter={() => {
                      setEventDown(false);
                      setShowArticle(true);
                      setShowCatalog(false);
                      // setShowWineCatalog(false);
                    }}
                    onMouseLeave={() => setShowArticle(false)}
                    onClick={() => {
                      setShowCatalog(false);
                      setShowProfile(false);
                    }}
                    className="article-Dropdown"
                  >
                    <div className="wine-catalog-menu">
                      <div className="catalog-sub-menu">
                        <Link
                          className={"wineries-sub-link"}
                          style={{ color: "white" }}
                          // style={showWineries ? { border: "none" } : { border: "none" }}
                          to="/articles/wine-news"
                          onClick={() => {
                            setShowCatalog(false);
                            setShowArticle(false);
                            setShowProfile(false);
                          }}
                        >
                          {langContext.lang === "EN"
                            ? "WINE NEWS"
                            : "ღვინის ამბები"}
                        </Link>
                        <Link
                          className={"wineries-sub-link"}
                          style={{ color: "white" }}
                          // style={showWineries ? { border: "none" } : { border: "none" }}
                          to="/articles/wine-testing"
                          onClick={() => {
                            setShowCatalog(false);
                            setShowArticle(false);
                            setShowProfile(false);
                          }}
                        >
                          WINE TESTING
                        </Link>
                        <Link
                          className={"wineries-sub-link"}
                          style={{ color: "white" }}
                          // style={showWineries ? { border: "none" } : { border: "none" }}
                          to="/articles/infographics"
                          onClick={() => {
                            setShowCatalog(false);
                            setShowArticle(false);
                            setShowProfile(false);
                          }}
                        >
                          INFOGRAPHICS
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </li>

            <li className="nav-item">
              <NavLink
                className={navBar ? "navlink navactive" : "navlink"}
                to="/contact"
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "FiraGo" }
                    : { fontFamily: "Montserrat" }
                }
                onClick={() => {
                  setShowCatalog(false);
                  setShowProfile(false);
                  setShowArticle(false);
                  // setShowWineCatalog(false);
                }}
              >
                {langContext.lang === "EN" ? "CONTACT" : "კონტაქტი"}
              </NavLink>
            </li>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              {/* <LangButton /> */}
              {/* <NavLink to="sign-in"> */}
              {popUpContext.userLogin ? (
                // <CgProfile
                //   style={navBar ? { color: "white" } : { color: "#262a2e" }}
                //   fontSize={35}
                //   cursor="pointer"
                //   onClick={(e) => {
                //     setShowProfile(!showProfile);
                //     setShowArticle(false);
                //     setShowCatalog(false);
                //     setShowWineCatalog(false);
                //     e.stopPropagation();
                //   }}
                // />

                <img
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    setShowProfile(!showProfile);
                    setShowArticle(false);
                    setShowCatalog(false);
                    // setShowWineCatalog(false);
                    e.stopPropagation();
                  }}
                  src={navBar ? profileIcon : blackProfileIcon}
                  alt="profile-pic"
                />
              ) : (
                <>
                  <img
                    style={{ marginRight: "19px", cursor: "pointer" }}
                    onClick={() => {
                      popUpContext.setShowPopUp(true);
                      signInFormContext.setView("login");
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      setShowArticle(false);
                      setShowCatalog(false);
                    }}
                    src={navBar ? profileIcon : blackProfileIcon}
                    alt="profile-icon"
                  />
                  {/* <button
                    onClick={() => {
                      popUpContext.setShowPopUp(true);
                      signInFormContext.setView("login");
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      setShowArticle(false);
                      setShowCatalog(false);
                    }}
                    className="btn"
                    style={
                      langContext.lang !== "EN"
                        ? { fontFamily: "FiraGo" }
                        : { fontFamily: "Montserrat" }
                    }
                  >
                    {langContext.lang === "EN" ? "Sign in" : "შესვლა"}
                  </button> */}
                </>
              )}

              {showProfile && (
                <div
                  onClick={() => {
                    setShowCatalog(false);
                    setShowArticle(false);
                  }}
                  className="profile-Dropdown"
                >
                  <div className="profile-menu">
                    <div className="profile-sub-menu">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "14px",
                        }}
                      >
                        <img src={profileSmallIcon} alt="profileIcon" />

                        <Link
                          className="log-out-btn-navbar"
                          style={{ color: "white" }}
                          // style={showWineries ? { border: "none" } : { border: "none" }}
                          to="/profile"
                          onClick={() => {
                            setShowCatalog(false);
                            setShowArticle(false);
                            setShowProfile(false);
                          }}
                        >
                          {langContext.lang === "EN" ? "Profile" : "პროფილი"}
                        </Link>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={signOut} alt="signOut" />
                        <Link
                          className="log-out-btn-navbar"
                          onClick={() => {
                            popUpContext.handleLogout();
                            setShowProfile(false);
                          }}
                        >
                          {langContext.lang === "EN" ? "Log out" : "გამოსვლა"}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* </NavLink> */}
            </div>
          </ul>

          <div
            className={
              navBar ? "burger-menu-tab-mob active" : "burger-menu-tab-mob"
            }
          >
            {popUpContext.userLogin ? (
              <img
                style={{ marginRight: "19px" }}
                onClick={(e) => {
                  setShowProfile(!showProfile);
                  setShowArticle(false);
                  setShowCatalog(false);
                  // setShowWineCatalog(false);
                  setShowBurgerMenu(false);
                  e.stopPropagation();
                }}
                src={profileIcon}
                alt="profile-icon"
              />
            ) : (
              // <CgProfile
              //   color="white"
              //   fontSize={35}
              //   cursor="pointer"
              //   style={{ marginRight: "19px" }}
              //   onClick={(e) => {
              //     setShowProfile(!showProfile);
              //     setShowArticle(false);
              //     setShowCatalog(false);
              //     setShowWineCatalog(false);
              //     setShowBurgerMenu(false);
              //     e.stopPropagation();
              //   }}
              // />
              <img
                style={{ marginRight: "19px" }}
                src={profileIcon}
                alt="profileIcon"
                onClick={() => {
                  setShowBurgerMenu(false);
                  popUpContext.setShowPopUp(true);
                  signInFormContext.setView("login");
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setShowArticle(false);
                  setShowCatalog(false);
                }}
              />
            )}

            {showBugerMenu ? (
              <MdOutlineClear
                className={navBar ? "burger-menu active" : "burger-menu"}
                onClick={() => {
                  setShowBurgerMenu(!showBugerMenu);
                }}
              />
            ) : (
              <img
                className={navBar ? "burger-menu active" : "burger-menu"}
                onClick={() => {
                  setShowBurgerMenu(!showBugerMenu);
                  popUpContext.setShowPopUp(false);
                  setShowProfile(false);
                }}
                src={burger}
                alt="burger"
              />
            )}
            {/* <div className="lang-reg">
              <LangButton />
              <Button />
            </div> */}
          </div>
        </nav>
        {showProfile && (
          <ul className="profile-ul-tab">
            <li className="nav-item">
              <NavLink
                className={navBar ? "navlink navactive" : "navlink"}
                onClick={() => {
                  setShowBurgerMenu(false);
                  setShowProfile(false);
                }}
                to="/profile"
              >
                {langContext.lang === "EN" ? "Profile" : "პროფილი"}
              </NavLink>
            </li>
            <li className="nav-item-btn">
              <button
                onClick={() => {
                  popUpContext.setUserLogin(false);
                  setShowProfile(false);
                }}
                className="mob-log-out-btn"
              >
                {langContext.lang === "EN" ? "Log out" : "გამოსვლა"}
              </button>
            </li>
          </ul>
        )}
        {showBugerMenu && (
          <ul className="navbar-ul-tab">
            <li className="nav-item">
              <NavLink
                className={navBar ? "navlink navactive" : "navlink"}
                onClick={() => {
                  setShowBurgerMenu(false);
                }}
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "FiraGo" }
                    : { fontFamily: "Montserrat" }
                }
                to="/wine-passport"
              >
                {langContext.lang === "EN"
                  ? "WINE PASSPORT"
                  : "ღვინის პასპორტი"}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "FiraGo" }
                    : { fontFamily: "Montserrat" }
                }
                className={navBar ? "navlink navactive" : "navlink"}
                onClick={() => {
                  setShowBurgerMenu(false);
                }}
                to="/wines"
              >
                {langContext.lang === "EN" ? "WINES" : "ღვინოები"}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "FiraGo" }
                    : { fontFamily: "Montserrat" }
                }
                className={navBar ? "navlink navactive" : "navlink"}
                onClick={() => {
                  setShowBurgerMenu(false);
                }}
                to="/wineries"
              >
                {langContext.lang === "EN" ? "WINERIES" : "მარნები"}
              </NavLink>
            </li>
            {/*  კატალოგი ამოვიღეთ სამომავლოდ თუ დამჭირდა დესკტოპ <li className="nav-item nav-item-event">
              <Link
                to={"#"}
                style={showCatalog ? { color: "#e79a74" } : null}
                onClick={(e) => {
                  setShowCatalog(!showCatalog);
                  setShowArticle(false);
                  e.stopPropagation();
                }}
                className={navBar ? "navlink navactive" : "navlink"}
              >
                CATALOG
                {showCatalog ? (
                  <RxTriangleUp className="icon" />
                ) : (
                  <Icons.RxTriangleDown className="icon" />
                )}
              </Link>
            </li>
            {showCatalog && (
              <>
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="catalog-Dropdown-mob"
                >
                  {showCatalog && (
                    <div className="wine-catalog-lists-mob">
                      <Link
                        to={"#"}
                        style={showWineCatalog ? { color: "#e79a74" } : null}
                        className="wine-catalog-link-mob"
                        onClick={() => {
                          setShowWineCatalog(!showWineCatalog);
                        }}
                      >
                        WINES
                        {showWineCatalog ? (
                          <RxTriangleUp className="icon" />
                        ) : (
                          <Icons.RxTriangleDown className="icon" />
                        )}
                      </Link>

                      {showWineCatalog && (
                        <>
                          <Link
                            className="wine-catalog-link-mob"
                            onClick={() => {
                              setShowCatalog(false);
                              setShowBurgerMenu(false);
                            }}
                            to={"/wines"}
                          >
                            WINES
                          </Link>
                          <Link
                            className="wine-catalog-link-mob"
                            onClick={() => {
                              setShowCatalog(false);
                              setShowBurgerMenu(false);
                            }}
                            to={"/wines/family-wine-cellars"}
                          >
                            FAMILY WINE CELLARS
                          </Link>
                          <Link
                            className="wine-catalog-link-mob"
                            onClick={() => {
                              setShowCatalog(false);
                              setShowBurgerMenu(false);
                            }}
                            to={"/wines/qvevry"}
                          >
                            QVEVRY
                          </Link>
                          <Link
                            className="wine-catalog-link-mob"
                            onClick={() => {
                              setShowCatalog(false);
                              setShowBurgerMenu(false);
                            }}
                            to={"/wines/red"}
                          >
                            RED
                          </Link>
                          <Link
                            className="wine-catalog-link-mob"
                            onClick={() => {
                              setShowCatalog(false);
                              setShowBurgerMenu(false);
                            }}
                            to={"/wines/white"}
                          >
                            WHITE
                          </Link>
                          <Link
                            className="wine-catalog-link-mob"
                            onClick={() => {
                              setShowCatalog(false);
                              setShowBurgerMenu(false);
                            }}
                            to={"/wines/rose"}
                          >
                            ROSE
                          </Link>
                        </>
                      )}
                      <Link
                        to={"/wineries"}
                        className="wine-catalog-link-mob"
                        onClick={() => {
                          setShowCatalog(false);
                          setShowBurgerMenu(false);
                        }}
                      >
                        WINERIES
                      </Link>
                      <Link
                        to={"/wine-bars"}
                        className="wine-catalog-link-mob"
                        onClick={() => {
                          setShowCatalog(false);
                          setShowBurgerMenu(false);
                        }}
                      >
                        WINE BARS
                      </Link>
                      <Link
                        to={"/shop"}
                        className="wine-catalog-link-mob"
                        onClick={() => {
                          setShowCatalog(false);
                          setShowBurgerMenu(false);
                        }}
                      >
                        SHOP
                      </Link>
                      <Link
                        to={"/courses"}
                        className="wine-catalog-link-mob"
                        onClick={() => {
                          setShowCatalog(false);
                          setShowBurgerMenu(false);
                        }}
                      >
                        COURSES
                      </Link>
                    </div>
                  )}
                </div>
              </>
            )} */}
            <li className="nav-item-event">
              <Link
                className={navBar ? "navlink navactive" : "navlink"}
                to="/events"
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "FiraGo" }
                    : { fontFamily: "Montserrat" }
                }
                onClick={() => {
                  setShowBurgerMenu(false);
                }}
              >
                {langContext.lang === "EN" ? "EVENTS" : "ღონისძიებები"}
              </Link>
              {/* {eventDown && (
                <EventDown
                  navBar={navBar}
                  title="Wine festival"
                  date="12/05/2023"
                />
              )} */}
            </li>
            <li className="nav-item">
              <NavLink
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "FiraGo" }
                    : { fontFamily: "Montserrat" }
                }
                className={navBar ? "navlink navactive" : "navlink"}
                to="/contact"
                onClick={() => {
                  setShowBurgerMenu(false);
                }}
              >
                {langContext.lang === "EN" ? "CONTACT" : "კონტაქტი"}
              </NavLink>
            </li>
            <li className="nav-item">
              <Link
                to={"/articles"}
                // style={showArticle ? { color: "#e79a74" } : null}
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "FiraGo" }
                    : { fontFamily: "Montserrat" }
                }
                onClick={(e) => {
                  // setShowArticle(!showArticle);
                  // setShowCatalog(false);
                  // e.stopPropagation();
                  setShowBurgerMenu(false);
                }}
                className={navBar ? "navlink navactive" : "navlink"}
              >
                {langContext.lang === "EN" ? "ARTICLES" : "სტატიები"}

                {/* {showArticle ? (
                  <RxTriangleUp className="icon" />
                ) : (
                  <Icons.RxTriangleDown className="icon" />
                )} */}
              </Link>
            </li>
            {/* {showArticle && (
              <>
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="catalog-Dropdown-mob"
                >
                  {showArticle && (
                    <div className="wine-catalog-lists-mob">
                      <Link
                        style={showWineCatalog ? { color: "#e79a74" } : null}
                        className="wine-catalog-link-mob"
                        onClick={() => {
                          setShowCatalog(false);
                          setShowBurgerMenu(false);
                        }}
                        to={"/Articles"}
                      >
                        Articles
                      </Link>
                      <Link
                        style={showWineCatalog ? { color: "#e79a74" } : null}
                        className="wine-catalog-link-mob"
                        onClick={() => {
                          setShowCatalog(false);
                          setShowBurgerMenu(false);
                        }}
                        to={"/articles/wine-news"}
                      >
                        WINE NEWS
                      </Link>
                      <Link
                        to={"/articles/wine-testing"}
                        className="wine-catalog-link-mob"
                        onClick={() => {
                          setShowCatalog(false);
                          setShowBurgerMenu(false);
                        }}
                      >
                        WINE TESTING
                      </Link>
                      <Link
                        to={"/articles/wine-Infographics"}
                        className="wine-catalog-link-mob"
                        onClick={() => {
                          setShowCatalog(false);
                          setShowBurgerMenu(false);
                        }}
                      >
                        INFOGRAPHICS
                      </Link>
                    </div>
                  )}
                </div>
              </>
            )} */}
          </ul>
        )}
      </header>
      {popUpContext.showPopUp && <PopUp />}
    </>
  );
}
