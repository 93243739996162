import React, { useEffect, useState } from "react";
import "../components/styles/ProfilePage.css";
import api from "../Api/getData";
import WineryProfilePage from "../components/WineryProfilePage";
import ClientProfilePage from "../components/ClientProfilePage";

export default function ProfilePage() {
  const [userInfo, setUserInfo] = useState({});

  console.log(userInfo);

  const authToken = localStorage.getItem("authToken");
  const fetchProfileInfo = async () => {
    try {
      const response = await api.get(`user/profile`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setUserInfo(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchProfileInfo();
  }, [authToken]);

  return userInfo.user && userInfo.user.role === "client" ? (
    <ClientProfilePage />
  ) : userInfo.user && userInfo.user.role === "Winemaker" ? (
    <WineryProfilePage />
  ) : null;
}
