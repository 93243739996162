import React, { useContext, useState } from "react";
import Alert from "@mui/material/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import btnLine from "../images/contactPage/Linebtn.svg";
import airLine from "../images/contactPage/messagebtn.svg";
import { motion } from "framer-motion/dist/framer-motion";
import TitleLine from "./TitleLine";
import { context } from "./Root";
import api from "../Api/getData";

export default function ContactForm() {
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [texarea, setTexarea] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const [nameLength, setNameLength] = useState(false);
  const [isInvalidTexarea, setIsInvalidTexarea] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [allRigth, setAllRigth] = useState(false);
  const [recaptchaChecked, setRecaptchaChecked] = useState(false);

  const langContext = useContext(context);

  const variantsSecondSec = {
    Initial: { opacity: 0, scale: 0.5 },
    animate: { opacity: 1, scale: 1 },
  };
  const onChange = () => {
    setRecaptchaChecked(true);
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    if (allRigth) {
      setAllRigth(false);
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (
      nameInput.trim() === "" ||
      emailInput.trim() === "" ||
      texarea.trim() === ""
    ) {
      setIsInvalid(true);
      setNameLength(false);
      setIsInvalidTexarea(false);
    } else if (nameInput.length <= 5) {
      setIsInvalid(false);
      setNameLength(true);
      setIsInvalidTexarea(false);
    } else if (!emailRegex.test(emailInput)) {
      setIsInvalid(false);
      setNameLength(false);
      setIsInvalidTexarea(false);
      setInvalidEmail(true);
    } else if (texarea.length <= 10) {
      setIsInvalid(false);
      setNameLength(false);
      setIsInvalidTexarea(true);
    } else if (recaptchaChecked) {
      setIsInvalid(false);
      setNameLength(false);
      setIsInvalidTexarea(false);
      setInvalidEmail(false);
      setNameInput("");
      setEmailInput("");
      setTexarea("");
      setAllRigth(true);
      try {
        await api.post(`send-contact-email`, {
          name: nameInput,
          email: emailInput,
          message: texarea,
        });

        console.log("Form submitted successfully!");
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <section className="contact-section2">
      <h2
        className="contact-title"
        style={{
          fontFamily:
            langContext.lang !== "EN" ? "BPG Nino Mtavruli" : "Montserrat",
        }}
      >
        {langContext.lang === "EN" ? "Contact form" : "მოგვწერეთ"}
      </h2>
      <TitleLine />
      <motion.form
        variants={variantsSecondSec}
        initial={variantsSecondSec.Initial}
        whileInView={variantsSecondSec.animate}
        transition={{
          ease: "easeInOut",
          duration: 1,
          delay: 0.2,
        }}
        viewport={{ once: true }}
        onSubmit={formSubmit}
        className="contact-form-container"
      >
        <div className="contact-inputs-container">
          <div className="contact-label-input">
            <label
              style={{
                fontFamily:
                  langContext.lang !== "EN" ? "BPG Nino Mtavruli" : "Lato",
              }}
            >
              {langContext.lang === "EN" ? "Your name" : "სახელი"}
            </label>
            <input
              value={nameInput}
              onChange={(e) => {
                setNameInput(e.target.value);
                setIsInvalid(false);
                setAllRigth(false);
              }}
              style={{
                fontFamily:
                  langContext.lang !== "EN" ? "BPG Nino Mtavruli" : "Lato",
              }}
              type="text"
              placeholder={langContext.lang === "EN" ? "Your name" : "სახელი"}
            />
          </div>
          <div className="contact-label-input">
            <label
              style={{
                fontFamily:
                  langContext.lang !== "EN" ? "BPG Nino Mtavruli" : "Lato",
              }}
            >
              {langContext.lang === "EN" ? "Email" : "ელფოსტა"}
            </label>
            <input
              value={emailInput}
              onChange={(e) => {
                setEmailInput(e.target.value);
                setIsInvalid(false);
                setInvalidEmail(false);
                setAllRigth(false);
              }}
              type="text"
              style={{
                fontFamily:
                  langContext.lang !== "EN" ? "BPG Nino Mtavruli" : "Lato",
              }}
              placeholder={langContext.lang === "EN" ? "Your email" : "ელფოსტა"}
            />
          </div>
        </div>
        {isInvalid && (
          <Alert severity="error">
            {langContext.lang === "EN"
              ? "Name, Email and message are required"
              : "სახელი,ელფოსტა და შეტყობინების ველები აუცილებელია"}
          </Alert>
        )}
        {nameLength && (
          <Alert severity="error">
            {langContext.lang === "EN"
              ? "Name must be min 5 letters"
              : "სახელი უნდა შეიცავდეს მინიმუმ 5 ასობგერას"}
          </Alert>
        )}
        {invalidEmail && (
          <Alert severity="error">
            {langContext.lang === "EN"
              ? "Invalid email format. Please enter a valid email address."
              : "არასწორია ელფოსტის ფორმატი.გთხოვთ შეიყვანოთ ვალიდური ელფოსტის მისამართი"}
          </Alert>
        )}

        <div className="contact-texarea">
          <label
            style={{
              fontFamily:
                langContext.lang !== "EN" ? "BPG Nino Mtavruli" : "Lato",
            }}
          >
            {langContext.lang === "EN" ? "Message" : "შეტყობინება"}
          </label>
          <textarea
            style={{
              fontFamily:
                langContext.lang !== "EN" ? "BPG Nino Mtavruli" : "Lato",
            }}
            value={texarea}
            onChange={(e) => {
              setTexarea(e.target.value);
              setIsInvalidTexarea(false);
              setAllRigth(false);
            }}
            placeholder={
              langContext.lang === "EN" ? "Your message" : "შეტყობინება"
            }
          />
        </div>
        {isInvalidTexarea && (
          <Alert style={{ marginBottom: "10px" }} severity="error">
            {langContext.lang === "EN"
              ? "Message is required and min 10 letters"
              : "შეტყობინება აუცილებელია და უნდა შედგებოდეს მინიმუმ 10 ასობგერისგან"}
          </Alert>
        )}
        <ReCAPTCHA
          sitekey="6LcyJ4AnAAAAAFyYY0DSbnSfVJYlFxDVtrzB6yHk"
          // sitekey="6LfEAmMpAAAAAGOX3FSPMKiV5xXKh4y6qwUXPEoa" new key
          // sitekey="6LcyJ4AnAAAAAFyYY0DSbnSfVJYlFxDVtrzB6yHk" old key
          onChange={onChange}
        />
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="message-btn"
        >
          <img src={airLine} alt="messageIcon" />
          <img src={btnLine} alt="line" />
          {langContext.lang === "EN" ? "Send request" : "გაგზავნა"}
        </motion.button>
        {allRigth && (
          <Alert severity="success">
            {langContext.lang === "EN"
              ? "Message sent"
              : "შეტყობინება გაგზავნილია"}
          </Alert>
        )}
      </motion.form>
    </section>
  );
}
