import React from "react";
import arrow from "../images/event-page/arrow.svg";
import "../components/styles/EventDetailPage.css";
import { Link } from "react-router-dom";

export default function ParticipantEvent({ celler, id }) {
  return (
    <Link
      to={`/wineries/detail/${id}`}
      className="participant-company-container"
    >
      <img src={arrow} alt="arrow" />
      <span className="participant-company">{celler}</span>
    </Link>
  );
}
