import React, { useContext, useState } from "react";
import "../components/styles/WinePassDetailPage.css";
import wineryLocation from "../images/winePassDetail/location.svg";
import phoneIcon from "../images/winePassDetail/Phone.svg";
import emailIcon from "../images/winePassDetail/email.svg";
import wineIcon from "../images/winePassDetail/wineIcon.svg";
import offerIcon from "../images/winePassDetail/offer.svg";
import eye from "../images/winepassport/eye.svg";
import { Link } from "react-router-dom";
import { context } from "./Root";

export default function WinePassInfo({
  name,
  village,
  city,
  region,
  phone,
  email,
  offers,
  wines,
  guests,
  id,
}) {
  const [showPhone, setShopPhone] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showEmailText, setShowEmailText] = useState(false);
  const [showPhoneText, setShowPhoneText] = useState(false);

  const langContext = useContext(context);

  const formatHiddenPhone = () => {
    if (phone) {
      const countryCode = phone.slice(0, 3);
      const firstPart = phone.slice(3, 5);
      const hiddenPart = "*".repeat(2);
      const hiddenPart2 = "*".repeat(2);

      return `${countryCode} ${firstPart} ${hiddenPart} ${hiddenPart2}`;
    }
  };

  const formatPhone = () => {
    if (phone) {
      const countryCode = phone.slice(0, 3);
      const firstPart = phone.slice(3, 5);
      const secondPart = phone.slice(5, 7);
      const thirdPart = phone.slice(7);

      return `${countryCode} ${firstPart} ${secondPart} ${thirdPart}`;
    }
  };

  const formatHiddenEmail = () => {
    if (email) {
      const beforeAtLenght = email.indexOf("@") + 1;
      // const rombNumber = beforeAtLenght - 6;
      return (
        email.slice(0, beforeAtLenght) +
        "*".repeat(email.length - beforeAtLenght)
      );
    }
  };

  return (
    <div className="winePassInfo-container">
      <Link to={`/wineries/detail/${id}`}>
        <h2>{name}</h2>
      </Link>
      <div className="passInfo-wrapper">
        <div className="pass-info-wrap">
          <h4>{langContext.lang === "EN" ? "CONTACT" : "კონტაქტი"}</h4>
          <div>
            <div className="passInfo-icons-wrap">
              <img src={wineryLocation} alt="winery-location" />
              <p>{`${
                village === null || village === "" ? "" : `Village ${village},`
              }${city} ${region} region`}</p>
            </div>
            <div className="show-passInfo-mob-email">
              <div style={{ marginRight: "10px" }}>
                <div className="passInfo-icons-wrapShowPass">
                  <div className="phoneIcon-Phone">
                    <img src={phoneIcon} alt="phone" />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p>
                        {showPhone
                          ? `${formatPhone()}`
                          : // [...Array(phone && phone.length)].map((i) => (
                            //     <span key={i}>♦</span>
                            //   ))
                            `(+995) ${formatHiddenPhone()}`}
                      </p>
                    </div>
                  </div>
                  {!showPhone ? (
                    <div style={{ position: "relative" }}>
                      <img
                        onMouseEnter={() => setShowPhoneText(true)}
                        onMouseLeave={() => setShowPhoneText(false)}
                        onClick={(e) => {
                          setShopPhone(!showPhone);
                          e.preventDefault();
                        }}
                        src={eye}
                        alt="eye"
                      />
                      {showPhoneText && (
                        <span className="show-text">
                          {langContext.lang === "EN" ? "Show" : "ნახვა"}
                        </span>
                      )}
                    </div>
                  ) : null}
                </div>

                <div className="passInfo-icons-wrapShowPass">
                  <div className="phoneIcon-Phone">
                    <img src={emailIcon} alt="email" />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p>
                        {showEmail
                          ? email
                          : // [...Array(email && email.length)].map((i) => (
                            //     <span key={i}>♦</span>
                            //   ))
                            // `${email && email.slice(0, 6)}${email &&
                            //   "♦".repeat(email.length - 6)}`
                            formatHiddenEmail()}
                      </p>
                    </div>
                  </div>
                  {!showEmail ? (
                    <div style={{ position: "relative" }}>
                      <img
                        onMouseEnter={() => setShowEmailText(true)}
                        onMouseLeave={() => setShowEmailText(false)}
                        onClick={(e) => {
                          setShowEmail(!showEmail);
                          e.preventDefault();
                        }}
                        src={eye}
                        alt="eye"
                      />
                      {showEmailText && (
                        <span className="show-text">
                          {langContext.lang === "EN" ? "Show" : "ნახვა"}
                        </span>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pass-info-wrap">
          <h4>{langContext.lang === "EN" ? "OFFERS" : "შეთავაზებები"}</h4>

          <div className="passInfo-icons-wrap">
            {/* <img src={offerIcon} alt="offers" /> */}
            {/* <p>
              {offers &&
                JSON.parse(offers).map((offer, index, array) => (
                  <>{`${offer}${index === array.length - 1 ? "." : ", "}`}</>
                ))}
            </p> */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              {offers &&
                JSON.parse(offers).map((offer, index) => {
                  return (
                    <div
                      key={index}
                      style={{ display: "flex", alignItems: "flex-start" }}
                    >
                      <img src={offerIcon} alt="offers" />
                      <p>{offer}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="pass-info-wrap">
          <h4>{langContext.lang === "EN" ? "WINES" : "ღვინოები"}</h4>
          {wines &&
            wines.map((wine, index) => {
              return (
                <div key={index} className="passInfo-icons-wrap">
                  <img src={wineIcon} alt="offers" />
                  <p>{wine}</p>
                </div>
              );
            })}
        </div>
        <div className="pass-info-wrap">
          <h4>{langContext.lang === "EN" ? "GUESTS" : "სტუმრები"}</h4>
          <p>{`${guests}`}</p>
        </div>
      </div>
    </div>
  );
}
