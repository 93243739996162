import React, { useState } from "react";
import "./index.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import WineryDetail from "./pages/Winery-detail";
import Root from "./components/Root";
import WinePassport from "./pages/WinePassport";
import NotFound from "./components/NotFound";
import Wineries from "./pages/Wineries";
import Cart from "./pages/Cart";
import Favorite from "./pages/Favorite";
import ArticlePage from "./pages/ArticlePage";
import ArticleDetailPage from "./pages/ArticleDetailPage";
import Event from "./pages/Event";
import EventDetailPage from "./pages/EventDetailPage";
import ProductPage from "./pages/ProductPage";
import WineBars from "./pages/WineBars";
import Shop from "./pages/Shop";
import Courses from "./pages/Courses";
// import WineTesting from "./pages/WineTesting";
// import Infographics from "./pages/Infographics";
import ProfilePage from "./pages/ProfilePage";
import ArticleFilter from "./pages/ArticleFilter";
import WineCatalog from "./pages/WineCatalog";
import WineFilter from "./pages/WineFilter";
import AboutUs from "./pages/AboutUs";
import MobileApp from "./pages/MobileApp";
import WinePassDetailPage from "./pages/WinePassDetailPage";
import FaqPage from "./pages/FaqPage";
import Verification from "./pages/Verification";
import PaymentSuccessful from "./pages/PaymentSuccessful";
import PaymentFailed from "./pages/PaymentFailed";
import Terms from "./pages/Terms";

export default function App() {
  // const storedCart = JSON.parse(localStorage.getItem("cart"));
  // const storedFavorite = JSON.parse(localStorage.getItem("favorite"));

  const [cart, setCart] = useState([]);
  const [favorite, setFavorite] = useState([]);

  const clearFavorite = () => {
    setFavorite([]);
  };

  const clearCart = () => {
    setCart([]);
  };

  // const clearFavorite = () => {
  //   setFavorite([]);
  //   localStorage.removeItem("favorite");
  // };
  // const clearCart = () => {
  //   setCart([]);
  //   localStorage.removeItem("cart");
  // };
  // useEffect(() => {
  //   localStorage.setItem("cart", JSON.stringify(cart));
  //   localStorage.setItem("favorite", JSON.stringify(favorite));
  // }, [cart, favorite]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route
          index={true}
          element={
            <Home
              cart={cart}
              setCart={setCart}
              favorite={favorite}
              setFavorite={setFavorite}
            />
          }
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/wineries">
          <Route index element={<Wineries />} />
          <Route
            path="detail/:id"
            element={
              <WineryDetail
                cart={cart}
                setCart={setCart}
                favorite={favorite}
                setFavorite={setFavorite}
              />
            }
          />
        </Route>
        {/* <Route path="/WINE-PASSPORT" element={<WinePassport />} /> */}
        <Route path="/wine-passport">
          <Route index element={<WinePassport />} />
          <Route path="detail/:id" element={<WinePassDetailPage />} />
        </Route>

        <Route>
          <Route path="/shop" element={<Shop />} />
          <Route path="/courses" element={<Courses />} />
          {/* <Route path="/wine-news" element={<ArticleFilter />} />
          <Route path="/wine-testing" element={<WineTesting />} />
          <Route path="/wine-Infographics" element={<Infographics />} /> */}
          <Route path="/profile" element={<ProfilePage />} />
          <Route
            path="wine-detail/:id"
            element={
              <ProductPage
                cart={cart}
                setCart={setCart}
                favorite={favorite}
                setFavorite={setFavorite}
              />
            }
          />
        </Route>
        <Route path="/wines">
          <Route
            index
            element={
              <WineCatalog
                cart={cart}
                setCart={setCart}
                favorite={favorite}
                setFavorite={setFavorite}
              />
            }
          />
          <Route
            path=":filterWine"
            element={
              <WineFilter
                cart={cart}
                setCart={setCart}
                favorite={favorite}
                setFavorite={setFavorite}
              />
            }
          />
        </Route>
        <Route
          path="/cart"
          element={
            <Cart
              setCart={setCart}
              cart={cart}
              clearCart={clearCart}
              favorite={favorite}
            />
          }
        />
        <Route path="/wine-bars" element={<WineBars />} />
        <Route
          path="/favorite"
          element={
            <Favorite
              cart={cart}
              setCart={setCart}
              favorite={favorite}
              setFavorite={setFavorite}
              clearFavorite={clearFavorite}
            />
          }
        />

        {/* <Route path="/articles">
          <Route index element={<ArticlePage />} />
          <Route path="detail/:id" element={<ArticleDetailPage />} />
        </Route> */}

        <Route path="/articles">
          <Route index element={<ArticlePage />} />
          <Route path=":filterArticle" element={<ArticleFilter />} />
          <Route path="detail/:id" element={<ArticleDetailPage />} />
        </Route>
        <Route path="payment/successful" element={<PaymentSuccessful />} />
        <Route path="payment/failed" element={<PaymentFailed />} />

        <Route path="/events">
          <Route index element={<Event />} />
          <Route path="detail/:id" element={<EventDetailPage />} />
        </Route>
        <Route path="/verification">
          <Route index element={<Verification />} />
          <Route path=":hash" element={<Verification />} />
        </Route>
        <Route path="about-us" element={<AboutUs />} />
        <Route path="faq" element={<FaqPage />} />
        {/* <Route path="verification" element={<Verification />} /> */}
        <Route path="mobileapp" element={<MobileApp />} />
        <Route path="terms-and-conditions" element={<Terms />} />

        <Route path="*" element={<NotFound />} />
      </Route>
    )
  );
  return <RouterProvider cart={cart} setCart={setCart} router={router} />;
}
