import React, { useState, useContext, useEffect } from "react";
import "../components/styles/ProductPage.css";
import ProductInfo from "../components/ProductInfo";
import NewCards from "../components/NewCards";
import { context } from "../components/Root";
import api from "../Api/getData";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { useMediaQuery } from "@mui/material";
import { changeTitle } from "../data/GeneralFunction";
import qvevryIcon from "../images/productPage/qvevry.svg";
import qvevryIconOf from "../images/productPage/qvevyOff.svg";
import filtered from "../images/productPage/filtered.svg";
import unfiltered from "../images/productPage/Filter_Off.svg";
import fl from "../images/productPage/Leaf.svg";
import flof from "../images/productPage/LeafOff.svg";

export default function ProductPage({ cart, setCart, favorite, setFavorite }) {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState("");
  const popUpContext = useContext(context);
  const [productDetail, setProductDetail] = useState({});

  const langContext = useContext(context);
  changeTitle(
    `Findwine | ${productDetail.name === undefined ? "" : productDetail.name}`
  );

  // const webSite = process.env.REACT_APP_WEBSITE;

  const { id } = useParams();

  const isDesktop = useMediaQuery("(min-width: 768px)");

  const variantsLeft = isDesktop
    ? {
        leftInitial: { opacity: 0, x: -500 },
        rigthInitial: { opacity: 0, x: 500 },
        animate: { opacity: 1, x: 0 },
      }
    : {
        leftInitial: { opacity: 0 },
        rigthInitial: { opacity: 0 },
        animate: { opacity: 1 },
      };
  const variantsRigth = {
    Initial: { opacity: 0, scale: 0.5 },
    animate: { opacity: 1, scale: 1 },
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const res = await api.get("products");
        setProducts(res.data);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchProductData();
  }, []);

  console.log(productDetail);

  useEffect(() => {
    const fetchProductDetailData = async () => {
      try {
        const res = await api.get(`products/${id}`);
        setProductDetail(res.data);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchProductDetailData();
  }, [id]);

  var titleElement = document.getElementById("myTitle");

  // Get the width in pixels
  var titleWidth = titleElement && titleElement.offsetWidth;

  useEffect(() => {
    const calculateAndSetWidth = () => {
      const titleElement = document.getElementById("myTitle");
      const lineElement = document.querySelector(".title-product-line2");

      if (titleElement && lineElement) {
        const titleWidth = titleElement.offsetWidth;
        lineElement.style.width = `${titleWidth}px`;
      }
    };

    // Call the function when the component mounts and whenever productDetail.name changes
    calculateAndSetWidth();
  }, [productDetail.name]);

  return (
    <main
      className="product-main-container"
      style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}
    >
      <section className="product-first-section">
        <motion.div
          variants={variantsLeft}
          initial={variantsLeft.leftInitial}
          whileInView={variantsLeft.animate}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 0.8,
          }}
          className="product"
        >
          <img
            className="product-page-product-image"
            src={productDetail.img_path}
            alt="wine"
          />
        </motion.div>
        <motion.div
          variants={variantsRigth}
          initial={variantsRigth.Initial}
          whileInView={variantsRigth.animate}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 0.8,
          }}
          className="product-rigth-block"
        >
          <h1
            className="product-title"
            id="myTitle"
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }
            }
          >
            {productDetail.name}
          </h1>
          <div className="line-product">
            <div className="title-product-line">
              <div
                style={{ width: `${titleWidth}px ` }}
                className="title-product-line2"
              ></div>
            </div>
          </div>
          <div className="prod-desk-icons-container">
            <p
              className="product-desk"
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Poppins" }
              }
            >
              {productDetail.wine_type_one && productDetail.wine_type_one.name}{" "}
              {productDetail.wine_type_two && productDetail.wine_type_two.name}{" "}
              | {productDetail.year}
            </p>
            <div className="wines-icons-wrapper">
              {productDetail.wine_option &&
              productDetail.wine_option.includes("1") ? (
                <img src={unfiltered} alt="icons" />
              ) : (
                <img src={filtered} alt="icons" />
              )}
              {productDetail.wine_option &&
              productDetail.wine_option.includes("2") ? (
                <img src={qvevryIcon} alt="icons" />
              ) : (
                <img src={qvevryIconOf} alt="icons" />
              )}{" "}
              {productDetail.wine_option &&
              productDetail.wine_option.includes("3") ? (
                <img src={fl} alt="icons" />
              ) : (
                <img src={flof} alt="icons" />
              )}
            </div>
          </div>
          <div style={{ marginBottom: "19px" }} className="line-product">
            <div className="title-product-line"></div>
          </div>
          <ProductInfo
            info={langContext.lang === "EN" ? "Producer" : "მწარმოებელი"}
            value={productDetail.company && productDetail.company.name}
          />
          <ProductInfo
            info={langContext.lang === "EN" ? "Volume" : "მოცულობა"}
            value={productDetail.volume}
          />
          <ProductInfo
            info={langContext.lang === "EN" ? "Alcohol" : "ალკოჰოლი"}
            value={productDetail.alcohol ? `${productDetail.alcohol}` : "15%"}
          />
          <ProductInfo
            info={langContext.lang === "EN" ? "Aroma" : "არომატი"}
            value={productDetail.aroma}
          />
          <ProductInfo
            info={langContext.lang === "EN" ? "Taste" : "გემო"}
            value={productDetail.taste}
          />
          <ProductInfo
            info={langContext.lang === "EN" ? "Grape" : "ყურძენი"}
            value={productDetail.wine_grape && productDetail.wine_grape.name}
          />
          <ProductInfo
            info={langContext.lang === "EN" ? "Origin" : "წარმოშობა"}
            value={productDetail.wine_origin && productDetail.wine_origin.name}
          />
          <ProductInfo
            info={langContext.lang === "EN" ? "Region" : "რეგიონი"}
            value={productDetail.region && productDetail.region.name}
          />
        </motion.div>
      </section>
      <section className="product-second-section">
        <NewCards
          title={
            langContext.lang === "EN"
              ? "OTHER WINES FROM THIS WINERY"
              : "იგივე მარნის ღვინოები"
          }
          favorite={favorite}
          setFavorite={setFavorite}
          cart={cart}
          setCart={setCart}
          NewItemData={
            productDetail &&
            productDetail.company &&
            products.filter(
              (wines) =>
                wines.company_id === productDetail.company.id &&
                wines.id !== productDetail.id
            )
          }
          showViewAll={false}
          error={error}
        />
        <NewCards
          title={
            langContext.lang === "EN"
              ? "YOU MAY ALSO LIKE"
              : "შეიძლება ასევე მოგეწონოთ"
          }
          favorite={favorite}
          setFavorite={setFavorite}
          cart={cart}
          setCart={setCart}
          NewItemData={
            productDetail.wine_type_one === null ||
            (undefined && productDetail.wine_type_two === null) ||
            undefined
              ? products
              : productDetail.wine_type_one &&
                productDetail.wine_type_two &&
                products.filter(
                  (item) =>
                    (item.wine_type_one &&
                      item.wine_type_one.name ===
                        productDetail.wine_type_one.name) ||
                    (item.wine_type_two &&
                      item.wine_type_two.name ===
                        productDetail.wine_type_two.name)
                )
          }
          showViewAll={false}
          error={error}
        />
      </section>
    </main>
  );
}
