import React, { useContext, useState, useEffect } from "react";
import "../components/styles/EventDetailPage.css";
import EventDetailBg from "../components/EventDetailBg";
import clock from "../images/event-page/Clock.svg";
import locIcon from "../images/event-page/locEventDetail.svg";
import EventDetailInfo from "../components/EventDetailInfo";
import ParticipantEvent from "../components/ParticipantEvent";
import EventLocationMap from "../components/EventLocationMap";
import { context } from "../components/Root";
import { useParams } from "react-router-dom";
import api from "../Api/getData";
import { Alert, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";
import { transformText } from "../data/georgianToEnglish";
import { changeTitle } from "../data/GeneralFunction";

export default function EventDetailPage() {
  const [seeAll, setSeeAll] = useState(false);
  const popUpContext = useContext(context);
  const [eventDetail, setEventDetail] = useState({});
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(true);
  const [year, setYear] = useState("");
  const [monthName, setMonthName] = useState("");
  const [day, setDay] = useState("");

  const langContext = useContext(context);
  changeTitle(
    `Findwine | ${
      eventDetail.event_name === undefined ? "" : eventDetail.event_name
    }`
  );

  useEffect(() => {
    if (eventDetail && eventDetail.date) {
      const dateParts = eventDetail.date.split("-");
      const year = dateParts[0];
      const numericMonth = dateParts[1];
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const parsedNumericMonth = parseInt(numericMonth, 10);

      const monthName = monthNames[parsedNumericMonth - 1];

      let day = dateParts[2];
      if (day.startsWith("0")) {
        day = day.substring(1);
      }

      setYear(year);
      setMonthName(monthName);
      setDay(day);
    }
  }, [eventDetail]);
  useEffect(() => {
    window.history.pushState(
      null,
      "",
      `${
        eventDetail.event_name !== undefined
          ? `?event=${transformText(eventDetail.event_name)}`
          : ""
      }`
    );
  }, [eventDetail.event_name]);

  const isDesktop = useMediaQuery("(min-width: 768px)");
  const variantsError = isDesktop
    ? {
        initial: { opacity: 0, x: -500 },
        whileInView: { opacity: 1, x: 0 },
      }
    : {
        initial: { opacity: 0 },
        whileInView: { opacity: 1 },
      };

  const { id } = useParams();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const res = await api.get(`events/${id}`);
        setEventDetail(res.data);
        setLoader(false);
      } catch (error) {
        setError(error.message);
        setLoader(false);
      }
    };
    fetchArticles();
  }, [id]);

  const fetchEventLocation = async (eventId) => {
    try {
      const res = await api.get(`events/${eventId}`);
      const data = res.data.location;
      const { lat, lng } = data;
      window.open(`https://www.google.com/maps?q=${lat},${lng}`);
    } catch (error) {
      setError(error.message);
    }
  };

  const startHour = Number(
    eventDetail &&
      eventDetail.event_start &&
      eventDetail.event_start.split(":")[0]
  );
  const endHour = Number(
    eventDetail && eventDetail.event_end && eventDetail.event_end.split(":")[0]
  );
  const startPeriod = startHour >= 0 && startHour < 12 ? "am" : "pm";
  const endPeriod = endHour >= 0 && endHour < 12 ? "am" : "pm";

  return (
    <main style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}>
      <EventDetailBg
        category={eventDetail.catetory_name && eventDetail.catetory_name.name}
        title={eventDetail.event_name}
      />
      {loader ? (
        <div className="loarder-cont">
          <div className="loader">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      ) : error ? (
        <motion.div
          variants={variantsError}
          initial={variantsError.initial}
          whileInView={variantsError.whileInView}
          transition={{
            ease: "easeOut",
            duration: 1,
            delay: 0.5,
            type: "spring",
          }}
          className="error-handler-contianer"
        >
          <Alert style={{ textAlign: "center" }} severity="error">
            {error}
          </Alert>
        </motion.div>
      ) : (
        <section className="eventDetail-section">
          <div className="event-firstsection">
            <div className="date-wrapper-DetailPage">
              <span className="event-month">{monthName.slice(0, 3)}</span>
              <span className="event-day">{day}</span>
              <span className="event-year">{year}</span>
            </div>
            <div className="clock-and-loc-container">
              <div className="clock-wrapper">
                <img className="clockIcon" src={clock} alt="clock" />
                <span className="event-duration">
                  {eventDetail.event_start} {startPeriod} -{" "}
                  {eventDetail.event_end} {endPeriod}
                </span>
              </div>
              <div className="clock-wrapper">
                <img src={locIcon} alt="locIcon" />
                <span
                  onClick={() => fetchEventLocation(id)}
                  className="event-duration"
                >
                  {eventDetail.city}
                </span>
              </div>
            </div>
          </div>
          <EventDetailInfo
            title={
              langContext.lang === "EN"
                ? "About festival"
                : "ფესტივალის შესახებ"
            }
            fntsize="32px"
            detail={eventDetail.description}
          />
          {/* <EventDetailInfo
            title="Festival supporters"
            fntsize="22px"
            detail={
              eventDetail &&
              eventDetail.suport_company &&
              eventDetail.suport_company.company.map((support) => {
                return support.name;
              })
            }
          /> */}
          <div className="detailInfo-wrapper">
            {eventDetail.support_company.length > 0 ? (
              <>
                <h4
                  style={{
                    fontSize: "22px",
                    fontFamily:
                      langContext.lang !== "EN"
                        ? "BPG Nino Mtavruli"
                        : "Montserrat",
                  }}
                >
                  {langContext.lang === "EN"
                    ? "Festival supporters"
                    : "ფესტივალის მხარდამჭერები"}
                </h4>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {eventDetail.support_company.map((supComp, index) => {
                    return (
                      <p>
                        {supComp.name}
                        {index !== eventDetail.support_company.length - 1
                          ? ", "
                          : "."}
                      </p>
                    );
                  })}
                </div>
              </>
            ) : null}
          </div>
          <EventDetailInfo
            title={
              langContext.lang === "EN"
                ? "Festival organizers"
                : "ფესტივალის ორგანიზატორები"
            }
            fntsize="22px"
            detail={eventDetail.company}
          />
          {eventDetail.support_winer.length > 0 ? (
            <div className="participants-wrapper">
              <h4
                className="participantsList"
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "BPG Nino Mtavruli" }
                    : { fontFamily: "Montserrat" }
                }
              >
                {langContext.lang === "EN"
                  ? "Participant wineries and cellars:"
                  : "მონაწილე მარნები:"}
              </h4>
              <div className="participant-container">
                {eventDetail &&
                  eventDetail.support_winer &&
                  eventDetail.support_winer.slice(0, 9).map((supportwinner) => {
                    // Check if supportwinner and company are not null
                    if (supportwinner && supportwinner.company) {
                      return (
                        <ParticipantEvent
                          key={supportwinner.id}
                          celler={supportwinner.company.name}
                          id={supportwinner.company.id}
                        />
                      );
                    }
                    return null; // or handle the case when company is null
                  })}
                {seeAll && (
                  <div>
                    {eventDetail.support_winer.slice(9).map((supComp) => {
                      // Check if supComp and company are not null
                      if (supComp && supComp.company) {
                        return (
                          <ParticipantEvent
                            key={supComp.company.id}
                            id={supComp.company.id}
                            celler={supComp.company.name}
                          />
                        );
                      }
                      return null; // or handle the case when company is null
                    })}
                  </div>
                )}
              </div>

              <button
                onClick={() => setSeeAll(!seeAll)}
                className="participant-see-allBtn"
              >
                {eventDetail.support_company.length > 9
                  ? seeAll
                    ? "SEE LESS"
                    : "SEE All"
                  : null}
              </button>
            </div>
          ) : null}

          <div className="event-location-container">
            <h4
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "BPG Nino Mtavruli" }
                  : { fontFamily: "Poppins" }
              }
            >
              {langContext.lang === "EN" ? "Location on map" : "ლოკაცია მეპზე"}
            </h4>
            <EventLocationMap eventDetail={eventDetail} />
            {/* <img src={eventLocation} alt="event-location" /> */}
          </div>
        </section>
      )}
    </main>
  );
}
