import React, { useContext, useState } from "react";
import vectorIcon from "../images/catalogPage/Vector.svg";
import { IoIosArrowUp } from "react-icons/io";
import { context } from "./Root";

export default function CatalogFilterOption({
  filterTitle,
  values,
  handleInputCheckboxChange,
  filterTechnology,
}) {
  const [showCatalogFilter, setShowCatalogFilter] = useState(false);
  const langContext = useContext(context);
  return (
    <div className="catalog-filter-cont-line">
      <div
        className="title-img-cont"
        onClick={() => setShowCatalogFilter(!showCatalogFilter)}
      >
        <h5
          style={
            langContext.lang !== "EN"
              ? { fontFamily: "FiraGo" }
              : { fontFamily: "Montserrat" }
          }
        >
          {filterTitle}
        </h5>
        {showCatalogFilter ? (
          <IoIosArrowUp />
        ) : (
          <img src={vectorIcon} alt="vector" />
        )}
      </div>
      {showCatalogFilter && (
        <div className="label-column">
          {values &&
            values.map((value) => {
              return (
                <div key={value} className="input-options">
                  <input
                    type="checkbox"
                    value={value}
                    checked={filterTechnology.includes(value)}
                    onChange={() => {
                      handleInputCheckboxChange(value);
                    }}
                  />
                  <label
                    style={
                      langContext.lang !== "EN"
                        ? { fontFamily: "FiraGo" }
                        : { fontFamily: "Montserrat" }
                    }
                  >
                    {value}
                  </label>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
