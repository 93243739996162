import React, { useContext } from "react";
import "./styles/EventBg.css";
import { context } from "./Root";

export default function EventBg() {
  const langContext = useContext(context);
  return (
    <>
      <div className="eventDetail-bg">
        <div className="text-container">
          <h2
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }
            }
          >
            {langContext.lang === "EN" ? "UPCOMING" : "მომავალი"}{" "}
          </h2>
          <h2
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }
            }
          >
            {langContext.lang === "EN" ? "EVENTS" : "ღონისძიებები"}
          </h2>
        </div>
      </div>
      <section className="section-one-bg-mob-events">
        <div className="text-container">
          <h2
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }
            }
            className="text-container-mob"
          >
            {langContext.lang === "EN"
              ? "UPCOMING EVENTS"
              : "მომავალი ღონისძიებები"}
          </h2>
        </div>
      </section>
    </>
  );
}
