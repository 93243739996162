import React from "react";
import Package from "./Package";
import "./styles/Package.css";
import { motion } from "framer-motion/dist/framer-motion";
import { Alert, useMediaQuery } from "@mui/material";

export default function PackageSection({ winePass, error }) {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const variants = isDesktop
    ? {
        initial: { opacity: 0, x: -500 },
        whileInView: { opacity: 1, x: 0 },
      }
    : {
        initial: { opacity: 0 },
        whileInView: { opacity: 1 },
      };

  return (
    <motion.section
      className="package-container"
      variants={variants}
      initial={variants.initial}
      viewport={{ once: true }}
      whileInView={variants.whileInView}
      transition={{ ease: "easeOut", duration: 1, delay: 0.5, type: "spring" }}
    >
      {winePass.map((pass) => {
        return (
          <Package
            key={pass.name}
            title={pass.name}
            info={pass.passport_description}
            price={pass.price}
            // winePass={winePass}
            mark="BEST VALUE"
            id={pass.id}
          />
        );
      })}
      {error !== "" && <Alert severity="error">{error}</Alert>}
    </motion.section>
  );
}
