import React, { useContext } from "react";
import "../components/styles/EventDetailPage.css";
import { context } from "./Root";

export default function EventDetailInfo({ title, detail, fntsize }) {
  const langContext = useContext(context);
  return (
    <div className="detailInfo-wrapper">
      <h4
        style={{
          fontFamily:
            langContext.lang !== "EN" ? "BPG Nino Mtavruli" : "Montserrat",
          fontSize: fntsize,
        }}
      >
        {title}
      </h4>
      <p
        style={
          langContext.lang !== "EN"
            ? { fontFamily: "FiraGo" }
            : { fontFamily: "Montserrat" }
        }
      >
        {detail}
      </p>
    </div>
  );
}
