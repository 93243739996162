import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import "./styles/StarRating.css";
import { MdClear } from "react-icons/md";
import { Alert } from "@mui/material";
import api from "../Api/getData";

export default function StarRating({
  setShowReviewForm,
  showReviewForm,
  user,
  userLogin,
  id,
}) {
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [messageTexarea, setMessageTexarea] = useState("");
  const [messageIsInvalid, setMessageIsInvalid] = useState(false);
  const [messageSend, setMessageSend] = useState(false);

  useEffect(() => {
    document.body.style.overflow = showReviewForm ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showReviewForm]);

  useEffect(() => {
    const handleWindowClick = () => {
      setShowReviewForm(false);
      setRating(null);
      setMessageTexarea("");
    };

    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  const stars = [];

  for (let i = 1; i <= 5; i++) {
    stars.push(
      <FaStar
        key={i}
        className="stars"
        size={20}
        color={i <= rating ? "#F6BE59" : "#ccc"} // Color filled stars yellow, others gray
      />
    );
  }

  const authToken = localStorage.getItem("authToken");

  const formSubmit = async (e) => {
    e.preventDefault();
    const messageRegax = /^.{5,}$/;

    if (!messageRegax.test(messageTexarea)) {
      setMessageIsInvalid(true);
    } else {
      setMessageSend(true);
      try {
        await api.post(
          `user/write-review`,
          {
            company_id: id,
            rating: rating,
            message: messageTexarea,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setRating(null);
        setMessageTexarea("");

        console.log("Form submitted successfully!");
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {
        setShowReviewForm(false);
      }, 1000);
    }
  };

  return (
    <div className="star-container" onClick={(e) => e.stopPropagation()}>
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;

        return (
          <label key={index}>
            <input
              className="star-input"
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => {
                setRating(ratingValue);
                setShowReviewForm(true);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            />
            <FaStar
              className="stars"
              size={20}
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(null)}
              color={ratingValue <= (hover || rating) ? "#F6BE59" : "#e4e5e9"}
            />
          </label>
        );
      })}
      <p>({rating} out 5)</p>
      {showReviewForm && (
        <div className="showreview-container">
          <div className="star-cont">
            <div className="stars-rating-cont">
              <div>{stars}</div>
              <p>({rating} out 5)</p>
            </div>
            <MdClear
              className="close-Btn"
              onClick={() => {
                setShowReviewForm(false);
                setRating(null);
              }}
            />
          </div>
          <form onSubmit={formSubmit}>
            <label className="review-label">
              <input
                type="text"
                value={userLogin ? user : ""}
                readOnly
                disabled
              />
            </label>
            <label className="review-texarea-label">
              <textarea
                onChange={(e) => {
                  setMessageTexarea(e.target.value);
                  setMessageIsInvalid(false);
                }}
                maxLength={255}
                value={messageTexarea}
                placeholder="Your message"
              />
            </label>
            {messageIsInvalid && (
              <Alert
                style={{ marginTop: "10px", width: "100%" }}
                severity="error"
              >
                Invalid message format. Please enter message min 5 characters.
              </Alert>
            )}
            {messageSend && (
              <Alert style={{ margin: "10px 0" }} severity="success">
                Message send successfully
              </Alert>
            )}
            <button onClick={formSubmit}>Send</button>
          </form>
        </div>
      )}
    </div>
  );
}
