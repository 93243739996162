import React, { useContext, useEffect, useState } from "react";
import "../components/styles/CatalogPage.css";
import CatalogBg from "../components/CatalogBg";
import TitleLine from "../components/TitleLine";
import api from "../Api/getData";
import vectorIcon from "../images/catalogPage/Vector.svg";
import CatalogFilterOption from "../components/CatalogFilterOption";
// import CartFavorites from "../components/CartFavorites";
import ReactPaginate from "react-paginate";
import { BiFilter } from "react-icons/bi";
import { context } from "../components/Root";
import { Link, useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { IoIosArrowUp } from "react-icons/io";
import { changeTitle } from "../data/GeneralFunction";

export default function WineFilter({ cart, setCart, favorite, setFavorite }) {
  const getPageNumberFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const page = parseInt(urlParams.get("page"));
    return isNaN(page) ? 0 : page - 1;
  };
  const initialPageNumber = getPageNumberFromURL();
  const [products, setProducts] = useState([]);
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [showCatalogFilter, setShowCatalogFilter] = useState(false);
  const [showMobFilter, setShowMobFilter] = useState(false);
  const popUpContext = useContext(context);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(true);

  const { filterWine } = useParams();

  changeTitle(`Findwine | ${filterWine.toUpperCase()}`);
  // filter values
  const [wineTypes, setWineTypes] = useState([]);
  const [techValues, setTechValues] = useState([]);
  const [volumeValues, setVolumeValues] = useState([]);
  const [aromaValues, setAromaValues] = useState([]);
  const [breedValues, setBreedValues] = useState([]);
  const [yearValues, setYearValues] = useState([]);

  // filter items
  const [selectedWineType, setSelectedWineType] = useState(null);
  const [filterTechnology, setFilterTechnology] = useState([]);
  const [filterVolume, setFilterVolume] = useState([]);
  const [filterAroma, setFilterAroma] = useState([]);
  const [filterBreed, setFilterBreed] = useState([]);
  const [filterYear, setFilterYear] = useState([]);

  // const webSite = process.env.REACT_APP_WEBSITE;

  useEffect(() => {
    let loaderTimeout;

    const fetchProductData = async () => {
      try {
        const response = await api.get("/products");
        setProducts(
          response.data.filter(
            (item) =>
              item.color ===
              (filterWine === "family-wine-cellars" ? "family" : filterWine)
          )
        );
        loaderTimeout = setTimeout(() => {
          setLoader(false);
        }, 2000);
      } catch (error) {
        loaderTimeout = setTimeout(() => {
          setLoader(false);
        }, 2000);
        setError(error.message);
      }
    };
    fetchProductData();
    return () => {
      clearTimeout(loaderTimeout);
    };
  }, [filterWine]);

  useEffect(() => {
    const techValuesSet = new Set(products.map((item) => item.technology));
    const uniqueTechValues = Array.from(techValuesSet);
    setTechValues(uniqueTechValues);

    const volumeValuesSet = new Set(products.map((item) => item.volume));
    const uniqueVolumes = Array.from(volumeValuesSet);
    setVolumeValues(uniqueVolumes);

    const aromaValuesSet = new Set(products.map((item) => item.aroma));
    const uniqueAromas = Array.from(aromaValuesSet);
    setAromaValues(uniqueAromas);

    const breedValuesSet = new Set(products.map((item) => item.breed));
    const uniquebreeds = Array.from(breedValuesSet);
    setBreedValues(uniquebreeds);

    const YearValuesSet = new Set(products.map((item) => item.year));
    const uniqueYears = Array.from(YearValuesSet);
    setYearValues(uniqueYears);
  }, [products]);

  useEffect(() => {
    const fetchWineTypes = async () => {
      try {
        const response = await api.get("wine-types");
        setWineTypes(response.data);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchWineTypes();
  }, []);

  const handleSpanClick = (wineType) => {
    setSelectedWineType(wineType);
  };

  const handleTechnologyCheckboxChange = (techFilter) => {
    if (filterTechnology.includes(techFilter)) {
      setFilterTechnology(
        filterTechnology.filter((prev) => prev !== techFilter)
      );
    } else {
      setFilterTechnology([...filterTechnology, techFilter]);
    }
  };
  const handleVolumeCheckboxChange = (volume) => {
    if (filterVolume.includes(volume)) {
      setFilterVolume(filterVolume.filter((prev) => prev !== volume));
    } else {
      setFilterVolume([...filterVolume, volume]);
    }
  };
  const handleAromaCheckboxChange = (aroma) => {
    if (filterAroma.includes(aroma)) {
      setFilterAroma(filterAroma.filter((prev) => prev !== aroma));
    } else {
      setFilterAroma([...filterAroma, aroma]);
    }
  };
  const handleBreedCheckboxChange = (breed) => {
    if (filterBreed.includes(breed)) {
      setFilterBreed(filterBreed.filter((prev) => prev !== breed));
    } else {
      setFilterBreed([...filterBreed, breed]);
    }
  };
  const handleYearCheckboxChange = (year) => {
    if (filterYear.includes(year)) {
      setFilterYear(filterYear.filter((prev) => prev !== year));
    } else {
      setFilterYear([...filterYear, year]);
    }
  };

  const filteredWines = products.filter((item) => {
    const wineTypeMatch = selectedWineType
      ? item.wine_type === selectedWineType
      : products;
    const technologyMatch =
      filterTechnology.length === 0 ||
      filterTechnology.includes(item.technology);
    const volumeMatch =
      filterVolume.length === 0 || filterVolume.includes(item.volume);
    const aromaMatch =
      filterAroma.length === 0 || filterAroma.includes(item.aroma);
    const breedMatch =
      filterBreed.length === 0 || filterBreed.includes(item.aroma);
    const yearMatch = filterYear.length === 0 || filterYear.includes(item.year);

    return (
      wineTypeMatch &&
      technologyMatch &&
      volumeMatch &&
      aromaMatch &&
      breedMatch &&
      yearMatch
    );
  });

  const winePerPage = 24;
  const pagesVisited = pageNumber * winePerPage;

  const pageCount = Math.ceil(filteredWines.length / winePerPage);

  const updateURL = (pageNumber) => {
    window.history.pushState(null, "", `?page=${pageNumber + 1}`);
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    window.scrollTo({ top: 500, behavior: "smooth" });

    updateURL(selected);
  };

  const displayWines = filteredWines
    .slice(pagesVisited, pagesVisited + winePerPage)
    .map((item) => {
      return (
        <div key={item.id} className="our-wine-cards">
          <Link to={`/wine-detail/${item.id}`} className="our-wine-card">
            <div className="product-left-block">
              <img
                className="product-item-img"
                // src={wine1}
                src={item.img_path}
                alt="wine"
              />
            </div>
            {/* <div className="overlay">
              <CartFavorites
                items={item}
                favorite={favorite}
                setFavorite={setFavorite}
                cart={cart}
                setCart={setCart}
              />
            </div> */}
            <div className="left-block">
              {/* <p className={item.sale ? "sale" : "notsale"}>
                  {item.sale ? item.sale : null}
                </p> */}
              <p className="item-title">{item.name}</p>
              <h5 className="wine-color">{item.color}</h5>
              <h5 className="item-grape">{item.breed}</h5>
              <p className="card-wine-type">ღვინის ტიპი 1</p>
              {/* <p className="item-final-price">{`$${item.finalPrice}`}</p> */}
              {/* <div className="price-cont">
                <p className="item-final-price">20$</p>
                <span className="item-first-price">40$</span>
              </div> */}
              {/* <span className="item-first-price">
                  {item.firstPrice ? `$${item.firstPrice}` : null}
                </span> */}
              {/* <div className="mobile-cart-fav">
                <CartFavorites
                  items={item}
                  favorite={favorite}
                  setFavorite={setFavorite}
                  cart={cart}
                  setCart={setCart}
                />
              </div> */}
            </div>
          </Link>
        </div>
      );
    });

  return (
    <main style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}>
      <CatalogBg
        title={
          filterWine === "family-wine-cellars"
            ? "FAMILY WINE CELLARS"
            : filterWine.toUpperCase()
        }
      />
      <h1 className="catalog-main-title">
        {filterWine === "family-wine-cellars"
          ? "FAMILY WINE CELLARS"
          : filterWine.toUpperCase()}
      </h1>
      <TitleLine />
      <section className="catalog-section">
        <div className="catalog-filter-container">
          <h4 className="filt-title">FILTERS</h4>
          <div className="catalog-filter-cont-line">
            <div
              className="title-img-cont"
              onClick={() => setShowCatalogFilter(!showCatalogFilter)}
            >
              <h5>WINE TYPE</h5>
              {showCatalogFilter ? (
                <IoIosArrowUp />
              ) : (
                <img src={vectorIcon} alt="vector" />
              )}
            </div>
            {showCatalogFilter && (
              <div className="filter-option-cont">
                {wineTypes.map((type) => {
                  const filteredProducts = products.filter(
                    (item) => item.wine_type === type.name
                  );

                  return (
                    <span
                      onClick={() => handleSpanClick(type.name)}
                      key={type.id}
                    >
                      {type.name} ({filteredProducts.length})
                    </span>
                  );
                })}
              </div>
            )}
          </div>
          <CatalogFilterOption
            filterTitle="Technology"
            values={techValues}
            handleInputCheckboxChange={handleTechnologyCheckboxChange}
            filterTechnology={filterTechnology}
          />
          <CatalogFilterOption
            filterTitle="Volume"
            values={volumeValues}
            handleInputCheckboxChange={handleVolumeCheckboxChange}
            filterTechnology={filterVolume}
          />
          <CatalogFilterOption
            filterTitle="Aroma"
            values={aromaValues}
            handleInputCheckboxChange={handleAromaCheckboxChange}
            filterTechnology={filterAroma}
          />
          <CatalogFilterOption
            filterTitle="Breed"
            values={breedValues}
            filterTechnology={filterBreed}
            handleInputCheckboxChange={handleBreedCheckboxChange}
          />
          <CatalogFilterOption
            filterTitle="Year"
            values={yearValues}
            filterTechnology={filterYear}
            handleInputCheckboxChange={handleYearCheckboxChange}
          />
        </div>
        <div className="catalog-filter-mob-tab">
          <button
            onClick={() => setShowMobFilter(!showMobFilter)}
            className="filtermobBtn"
          >
            <BiFilter className="filter-for-mobTab" />
            <span>Filter</span>
          </button>
          {showMobFilter && (
            <div className="catalog-filter-containers">
              <div className="catalog-filter-cont-line">
                <div
                  className="title-img-cont"
                  onClick={() => setShowCatalogFilter(!showCatalogFilter)}
                >
                  <h5>WINE TYPE</h5>
                  <img src={vectorIcon} alt="vector" />
                </div>
                {showCatalogFilter && (
                  <div className="filter-option-cont">
                    {wineTypes.map((type) => {
                      const filteredProducts = products.filter(
                        (item) => item.wine_type === type.name
                      );

                      return (
                        <span
                          onClick={() => handleSpanClick(type.name)}
                          key={type.id}
                        >
                          {type.name} ({filteredProducts.length})
                        </span>
                      );
                    })}
                  </div>
                )}
              </div>
              <CatalogFilterOption
                filterTitle="Technology"
                values={techValues}
                handleInputCheckboxChange={handleTechnologyCheckboxChange}
                filterTechnology={filterTechnology}
              />
              <CatalogFilterOption
                filterTitle="Volume"
                values={volumeValues}
                handleInputCheckboxChange={handleVolumeCheckboxChange}
                filterTechnology={filterVolume}
              />
              <CatalogFilterOption
                filterTitle="Aroma"
                values={aromaValues}
                handleInputCheckboxChange={handleAromaCheckboxChange}
                filterTechnology={filterAroma}
              />
              <CatalogFilterOption
                filterTitle="Breed"
                values={breedValues}
                filterTechnology={filterBreed}
                handleInputCheckboxChange={handleBreedCheckboxChange}
              />
              <CatalogFilterOption
                filterTitle="Year"
                values={yearValues}
                filterTechnology={filterYear}
                handleInputCheckboxChange={handleYearCheckboxChange}
              />
            </div>
          )}
        </div>
        <>
          {error === "" ? (
            <>
              {loader ? (
                <div className="loarder-cont" style={{ width: "100%" }}>
                  <div className="loader">
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </div>
                </div>
              ) : (
                <div className="catalog-wines">{displayWines}</div>
              )}
            </>
          ) : (
            <Alert style={{ backgroundColor: "white" }} severity="error">
              {error}
            </Alert>
          )}
        </>
      </section>
      <ReactPaginate
        key={pageNumber}
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName="pagination-container"
        previousLinkClassName="pagination-prev"
        nextLinkClassName="pagination-next"
        disabledLinkClassName="pagination-disable"
        activeClassName="pagination-active"
        initialPage={pageNumber}
      />
    </main>
  );
}
