import React, { useContext } from "react";
import logo from "../images/findwine_logo-03.png";
import phone from "../images/smartphone.svg";
import "./styles/Footer.css";
import email from "../images/email (1).svg";
import location from "../images/location (1).svg";
import phoneMob from "../images/phoneMob.svg";
import emailMob from "../images/emailMob.svg";
import locationMob from "../images/locationMob.svg";
import { context } from "./Root";
import usAidLogo from "../images/usaid.png";
import fbIcon from "../images/footer-fb-img.svg";
import instaIcon from "../images/footer-insta-img.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  const popUpContext = useContext(context);
  const langContext = useContext(context);

  return (
    <>
      <footer
        className="footer-desk"
        style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}
      >
        <div className="footer-first-section">
          <div className="logo-wrapper">
            <div className="footer-first-row">
              <img className="footer-logo" src={logo} alt="logo" />
              <div>
                <div className="phone">
                  <img src={phone} alt="phone" />
                  <p
                    style={
                      langContext.lang !== "EN"
                        ? { fontFamily: "FiraGo" }
                        : { fontFamily: "Montserrat" }
                    }
                  >
                    +995-577-24-20-25
                  </p>
                </div>
                <div className="phone">
                  <img src={email} alt="email" />
                  <p
                    style={
                      langContext.lang !== "EN"
                        ? { fontFamily: "FiraGo" }
                        : { fontFamily: "Montserrat" }
                    }
                  >
                    contact@findwines.ge
                  </p>
                </div>
                <div className="phone">
                  <img src={location} alt="phone" />
                  <p
                    style={
                      langContext.lang !== "EN"
                        ? { fontFamily: "FiraGo" }
                        : { fontFamily: "Montserrat" }
                    }
                  >
                    {langContext.lang === "EN"
                      ? "45 Vazha Pshavela Ave, Tbilisi 0177"
                      : "ვაჟა-ფშაველას გამზირი 45"}
                  </p>
                </div>
                <div className="footer-socials">
                  <p
                    style={
                      langContext.lang !== "EN"
                        ? { fontFamily: "FiraGo" }
                        : { fontFamily: "Montserrat" }
                    }
                  >
                    {langContext.lang === "EN"
                      ? "Follow us on:"
                      : "გამოგვიწერეთ"}
                  </p>
                  <a
                    href="https://www.facebook.com/findwinesge/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src={fbIcon} alt="fb-icon" />
                  </a>
                  <a href="/" rel="noopener noreferrer" target="_blank">
                    <img src={instaIcon} alt="insta-icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-second-row">
            <span
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Montserrat" }
              }
            >
              {langContext.lang === "EN"
                ? "Useful links"
                : "მნიშვნელოვანი ბმულები"}
            </span>
            <ul className="footer-ul">
              <li>
                <a
                  href="https://8000vintages.ge/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={
                    langContext.lang !== "EN"
                      ? { fontFamily: "FiraGo" }
                      : { fontFamily: "Montserrat" }
                  }
                >
                  8000vintages
                </a>
              </li>
              <li>
                <a
                  href="http://vinoge.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={
                    langContext.lang !== "EN"
                      ? { fontFamily: "FiraGo" }
                      : { fontFamily: "Montserrat" }
                  }
                >
                  vinoge
                </a>
              </li>
              <li>
                <Link
                  to={"/faq"}
                  style={
                    langContext.lang !== "EN"
                      ? { fontFamily: "FiraGo" }
                      : { fontFamily: "Montserrat" }
                  }
                >
                  Faq
                </Link>
              </li>
              <li>
                <Link
                  to={"/about-us"}
                  style={
                    langContext.lang !== "EN"
                      ? { fontFamily: "FiraGo" }
                      : { fontFamily: "Montserrat" }
                  }
                >
                  {langContext.lang === "EN" ? "About us" : "ჩვენ შესახებ"}
                </Link>
              </li>
              <li>
                <Link
                  to={"/terms-and-conditions"}
                  style={
                    langContext.lang !== "EN"
                      ? { fontFamily: "FiraGo" }
                      : { fontFamily: "Montserrat" }
                  }
                >
                  {langContext.lang === "EN"
                    ? "Terms and conditions"
                    : "წესები და პირობები"}
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-second-row">
            <img
              style={{ margin: "-25px 0 0 -25px" }}
              src={usAidLogo}
              alt="usaid-logo"
            />
            <p
              className="about-me-p"
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Montserrat" }
              }
            >
              {langContext.lang === "EN"
                ? "Wine Passport initiative was made possible by the American people through the U.S. Agency for International Development (USAID) Economic Security Program. The opinions expressed herein are those of the author(s) and do not necessarily reflect the views of the U.S. Agency for International Development."
                : "ღვინის პასპორტის ინიციატივა ამერიკელმა ხალხმა აშშ-ის საერთაშორისო განვითარების სააგენტოს (USAID) ეკონომიკური უსაფრთხოების პროგრამის მეშვეობით გახდა შესაძლებელი. აქ გამოთქმული მოსაზრებები ეკუთვნის ავტორ(ებ)ს და არ ასახავს აშშ-ს საერთაშორისო განვითარების სააგენტოს შეხედულებებს."}
            </p>
          </div>
        </div>

        <p
          className="rights-p"
          style={
            langContext.lang !== "EN"
              ? { fontFamily: "FiraGo" }
              : { fontFamily: "Montserrat" }
          }
        >
          {langContext.lang === "EN" ? (
            <>
              © 2023{" "}
              <Link
                style={{ color: "rgba(255, 255, 255, 0.8)" }}
                to={"/about-us"}
              >
                Wineshop LLC.
              </Link>{" "}
              All rights reserved.
            </>
          ) : (
            <>
              © 2023{" "}
              <Link
                style={{ color: "rgba(255, 255, 255, 0.8)" }}
                to={"/about-us"}
              >
                Wineshop LLC.
              </Link>{" "}
              ყველა უფლება დაცულია
            </>
          )}
        </p>
      </footer>
      <footer
        className="footer-mob"
        style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}
      >
        <div className="footer-mob">
          <img className="footer-logo" src={logo} alt="logo" />
          <div className="footer-mob-img-wrapper">
            <img src={phoneMob} alt="phoneMob" />
            <span
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Montserrat" }
              }
            >
              +995-577-24-20-25
            </span>
          </div>
          <div className="footer-mob-img-wrapper">
            <img src={emailMob} alt="phoneMob" />
            <span
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Montserrat" }
              }
            >
              contact@findwines.ge
            </span>
          </div>
          <div className="footer-mob-img-wrapper">
            <img src={locationMob} alt="phoneMob" />
            {langContext.lang === "EN"
              ? "45 Vazha Pshavela Ave, Tbilisi 0177"
              : "ვაჟა-ფშაველას გამზირი 45"}{" "}
          </div>
          <img src={usAidLogo} alt="usaid-logo" />
          <p
            className="about-me-p-mob"
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "FiraGo" }
                : { fontFamily: "Montserrat" }
            }
          >
            {langContext.lang === "EN"
              ? "Wine Passport initiative was made possible by the American people through the U.S. Agency for International Development (USAID) Economic Security Program. The opinions expressed herein are those of the author(s) and do not necessarily reflect the views of the U.S. Agency for International Development."
              : "ღვინის პასპორტის ინიციატივა ამერიკელმა ხალხმა აშშ-ის საერთაშორისო განვითარების სააგენტოს (USAID) ეკონომიკური უსაფრთხოების პროგრამის მეშვეობით გახდა შესაძლებელი. აქ გამოთქმული მოსაზრებები ეკუთვნის ავტორ(ებ)ს და არ ასახავს აშშ-ს საერთაშორისო განვითარების სააგენტოს შეხედულებებს."}
          </p>
          {/* <Link to={"/about-us"}>About us</Link>
          <Link to={"/wines"}>Catalog</Link>
          <Link to={"/contact"}>Contact us</Link>
          <Link to={"/"}>Privacy Policy</Link>
          <Link to={"/"}>Reviews</Link> */}
        </div>
        <p
          className="rights-p-mob"
          style={
            langContext.lang !== "EN"
              ? { fontFamily: "FiraGo" }
              : { fontFamily: "Montserrat" }
          }
        >
          {langContext.lang === "EN" ? (
            <>
              © 2023{" "}
              <Link
                style={{ color: "rgba(255, 255, 255, 0.8)" }}
                to={"/about-us"}
              >
                Wineshop LLC.
              </Link>{" "}
              All rights reserved.
            </>
          ) : (
            <>
              © 2023{" "}
              <Link
                style={{ color: "rgba(255, 255, 255, 0.8)" }}
                to={"/about-us"}
              >
                Wineshop LLC.
              </Link>{" "}
              ყველა უფლება დაცულია
            </>
          )}
        </p>
      </footer>
    </>
  );
}
