import { useField } from "formik";
import React from "react";

export default function PopUpInput({ ...props }) {
  const [field, meta] = useField(props);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {meta.error && <p className="input-error-message">{meta.error}</p>}
      <input
        {...field}
        {...props}
        className={meta.error ? "input-error" : "pass-input"}
      />
    </div>
  );
}
