import { Alert, Modal } from "@mui/material";
import React, { useState } from "react";
import closeBtn from "../images/wine-pass-popup/Close_LG.svg";
import winePass from "../images/wine-pass-popup/winepass.png";
import "./styles/Package.css";
import visaIcon from "../images/wine-pass-popup/visa.svg";
import masterCardIcon from "../images/wine-pass-popup/mastercard-color large.svg";
import amexIcon from "../images/wine-pass-popup/amex.svg";
import paypalIcon from "../images/wine-pass-popup/paypal.svg";
import { Link } from "react-router-dom";
import api from "../Api/getData";

export default function ModalComp({
  open,
  handleClose,
  count,
  setCount,
  price,
  title,
  id,
}) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [notSelectAnyCard, setNotSelectAnyCard] = useState(false);
  const [userNotAcceptTerms, setUserNotAcceptTerms] = useState(false);
  const [loader, setLoader] = useState(false);

  const authToken = localStorage.getItem("authToken");

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);
    if (selectedPaymentMethod === null) {
      setNotSelectAnyCard(false);
    }
  };

  const handleTermnAccept = () => {
    setAcceptTerms(!acceptTerms);
    if (!acceptTerms) {
      setUserNotAcceptTerms(false);
    }
  };

  const paySubmit = async (e) => {
    e.preventDefault();
    if (selectedPaymentMethod === null) {
      setNotSelectAnyCard(true);
    } else if (acceptTerms === false) {
      setUserNotAcceptTerms(true);
    } else {
      try {
        setLoader(true);
        const response = await api.post(
          `user/process-payment`,
          { passport_id: id, qty: count, card: selectedPaymentMethod },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        window.location.href = response.data.paymentUrl;
        console.log(response.data);
      } catch (error) {
        console.log(error.message);
      }
      console.log("form submitted");
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal-container">
        <div className="wine-pass-popup-left-container">
          <div className="wine-left-popup-head-container">
            <img src={winePass} alt="wine-pass" />
            <div>
              <h2>{title}</h2>
              <h4>01/01/2024 - 01/01/2025</h4>
              <Link to={`/wine-passport/detail/${id}`}>
                <span>More Details</span>
              </Link>
            </div>
          </div>
          <div className="quantities-container">
            <span className="quantity-title">Quantity</span>
            <div className="count-btns-container">
              <button
                disabled={count === 1}
                onClick={() => setCount((prev) => prev - 1)}
                className="decr-btn"
              >
                -
              </button>
              <span className="quantity">{count}</span>
              <button
                onClick={() => setCount((prev) => prev + 1)}
                className="incr-btn"
              >
                +
              </button>
            </div>
          </div>
          <div className="wine-pass-left-line"></div>
          <p className="wine-pass-left-price">Price</p>
          <div className="wine-pass-name-and-price">
            <p className="priority-wine-passport">
              Priority wine passport <span className="x-quantity">x </span>
              <span className="span-count">{count}</span>
            </p>
            <span className="each-wine-pass-price">{price} GEL</span>
          </div>
          <div className="wine-pass-left-line"></div>
          <div className="total-wine-pass-price-cont">
            <span className="total-price-span">Total price</span>
            <span className="total-price-sec-span">{count * price} GEL</span>
          </div>
        </div>
        <div className="wine-pass-popup-right-container">
          <img
            onClick={() => handleClose()}
            className="pay-close-btn"
            src={closeBtn}
            alt="close-btn"
          />
          <h2>Payment method</h2>
          <div className="pay-card-container">
            <div className="pay-check-span">
              <input
                checked={selectedPaymentMethod === "visa/mastercard"}
                onChange={() => handlePaymentMethodChange("visa/mastercard")}
                className="pay-popup-checkbox"
                type="checkbox"
              />
              <span>Visa/Mastercard</span>
            </div>
            <div>
              <img
                style={{ marginRight: "8.5px" }}
                src={visaIcon}
                alt="visaIcon"
              />
              <img src={masterCardIcon} alt="masterCard-icon" />
            </div>
          </div>
          <div className="pay-card-container" style={{ marginTop: "15px" }}>
            <div className="pay-check-span">
              <input
                checked={selectedPaymentMethod === "amex"}
                onChange={() => handlePaymentMethodChange("amex")}
                className="pay-popup-checkbox"
                type="checkbox"
              />
              <span>AMEX</span>
            </div>
            <img src={amexIcon} alt="amexIcon" />
          </div>
          <div className="pay-card-container" style={{ marginTop: "15px" }}>
            <div className="pay-check-span">
              <input
                checked={selectedPaymentMethod === "paypal"}
                onChange={() => handlePaymentMethodChange(null)}
                className="pay-popup-checkbox"
                type="checkbox"
              />
              <span>Paypal(Coming soon)</span>
            </div>
            <img src={paypalIcon} alt="paypalIcon" />
          </div>
          {notSelectAnyCard && (
            <Alert
              style={{ textAlign: "center", marginTop: "10px" }}
              severity="error"
            >
              Please select any card
            </Alert>
          )}
          <div className="terms-wrapper">
            <input
              checked={acceptTerms}
              onChange={handleTermnAccept}
              className="pay-popup-checkbox"
              type="checkbox"
            />
            <p>
              I accept{" "}
              <Link style={{ color: "#B44D2D" }} to={"/terms-and-conditions"}>
                Terms and Conditions
              </Link>
            </p>
          </div>
          {userNotAcceptTerms && (
            <Alert
              style={{ textAlign: "center", marginBottom: "10px" }}
              severity="error"
            >
              Please read and accept the terms and conditions
            </Alert>
          )}
          <button
            style={{
              position: "relative",
              opacity: loader ? 0.7 : 1,
            }}
            onClick={paySubmit}
            className="pay-btn"
          >
            PAY{" "}
            {loader && (
              <div className="spinner center">
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
              </div>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}
