import React from "react";
import NotFoundImage from "../images/findwine_logo-02.png";
import "./styles/NotFound.css";
import { Link } from "react-router-dom";
import { changeTitle } from "../data/GeneralFunction";

export default function NotFound() {
  changeTitle(`Findwines | Not found`);

  return (
    <div class="notFound-background-img">
      <div class="notFound-container">
        <img class="logo" src={NotFoundImage} alt="logo" />
        <h1>PAGE NOT FOUND</h1>
        <div className="notFound-wrapper">
          <Link to="/">
            <button>Home</button>{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}
