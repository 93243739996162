import React, { useContext } from "react";
import "../components/styles/CatalogPage.css";
import { context } from "./Root";

export default function CatalogBg({ title }) {
  const langContext = useContext(context);
  return (
    <>
      <div className="catalog-bg">
        <div className="text-container">
          <h2
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "FiraGo" }
                : { fontFamily: "Montserrat" }
            }
          >
            {title}
          </h2>
        </div>
      </div>
      <div className="section-one-bg-mob-catalog">
        <div className="text-container">
          <h2
            className="text-container-mob"
            style={
              langContext.lang !== "EN"
                ? { fontFamily: "FiraGo" }
                : { fontFamily: "Montserrat" }
            }
          >
            {title}
          </h2>
        </div>
      </div>
    </>
  );
}
