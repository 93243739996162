import React, { useContext } from "react";
import "../components/styles/AboutWinery.css";
import LineMaxWidth from "./LineMaxWidth";
import { context } from "./Root";

export default function WineInfo(props) {
  const langContext = useContext(context);
  return (
    <>
      <div className={props.className}>
        <p
          className={props.keyCname}
          style={{
            fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Poppins",
          }}
        >
          {props.title}
        </p>
        <div>
          <p
            className={props.valueclassName}
            style={{
              fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Poppins",
            }}
          >
            {props.value}
          </p>
          <p
            className={props.valueclassName}
            style={{
              fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Poppins",
            }}
          >
            {props.value2}
          </p>
          <p
            className={props.valueclassName}
            style={{
              fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Poppins",
            }}
          >
            {props.value3}
          </p>
          <p
            className={props.valueclassName}
            style={{
              fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Poppins",
            }}
          >
            {props.value4}
          </p>
          <p
            className={props.valueclassName}
            style={{
              fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Poppins",
            }}
          >
            {props.value5}
          </p>
          <p
            className={props.valueclassName}
            style={{
              fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Poppins",
            }}
          >
            {props.value6}
          </p>
          <p
            className={props.valueclassName}
            style={{
              fontFamily: langContext.lang !== "EN" ? "FiraGo" : "Poppins",
            }}
          >
            {props.value7}
          </p>
        </div>
      </div>
      <LineMaxWidth />
    </>
  );
}
