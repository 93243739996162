import React, { useContext } from "react";
import "./styles/FirstSection.css";
import fbIcon from "../images/Icon awesome-facebook-square.png";
import instaIcon from "../images/Icon metro-instagram.png";
import bg from "../images/bg.png";
import { Link } from "react-router-dom";
import { context } from "./Root";
import mobImage from "../images/mob-home-image.png";

export default function FirstSection() {
  const langContext = useContext(context);

  return (
    <>
      <section className="bg-image">
        <div className="header-overlay">
          <div className="text-block">
            <h4
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Montserrat" }
              }
            >
              {langContext.lang === "EN"
                ? "Connecting wine lovers"
                : "აკავშირებს ღვინის მოყვარულებს"}
            </h4>
            <p
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Montserrat" }
              }
            >
              {langContext.lang === "EN" ? "ENJOY GEORGIAN" : "გაერთე ქართული"}
            </p>
            <p
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Montserrat" }
              }
            >
              {langContext.lang === "EN" ? "WINERIES WITH" : "მარნებით"}
            </p>
            <p>
              {langContext.lang === "EN" ? "OUR HIGHWAY" : "ჩვენი გზავმკლევი"}
            </p>
            <span
              style={
                langContext.lang !== "EN"
                  ? { fontFamily: "FiraGo" }
                  : { fontFamily: "Montserrat" }
              }
            >
              {langContext.lang === "EN" ? "WINE PASSPORT" : "ღვინის პასპორტი"}
            </span>
            <button className="buy-now-btn1" style={{ cursor: "pointer" }}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  padding: "40px",
                }}
                to={"/wine-passport"}
              >
                {langContext.lang === "EN" ? "BUY NOW" : "ყიდვა"}
              </Link>
            </button>
            {/* <div className="tab-and-mob-follow-cont">
            <p>{langContext.lang === "EN" ? "FOllOW US" : "გამოგვიწერეთ"}</p>
            <div className="social-wrapper">
              <a
                href="https://www.facebook.com/Findwinesge/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img className="social-icon" src={fbIcon} alt="fbicon" />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="social-icon"
                  src={instaIcon}
                  alt="instagram-icon"
                />
              </a>
            </div>
          </div> */}
          </div>
        </div>
        <div className="image-and-social">
          <img className="main-bg-wine" src={bg} alt="bg" />
          <div className="follow">
            <div className="bg-line"></div>
            <p>{langContext.lang === "EN" ? "FOllOW US" : "გამოგვიწერეთ"}</p>
            <a
              href="https://www.facebook.com/Findwinesge/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img className="social-icon" src={fbIcon} alt="fbicon" />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="social-icon"
                src={instaIcon}
                alt="instagram-icon"
              />
            </a>
          </div>
        </div>
        <div className="mob-home-page-image">
          <img style={{ width: "100%" }} src={mobImage} alt="mob" />
          <div className="follow">
            <div className="bg-line"></div>
            <p>{langContext.lang === "EN" ? "FOllOW US" : "გამოგვიწერეთ"}</p>
            <a
              href="https://www.facebook.com/Findwinesge/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img className="social-icon" src={fbIcon} alt="fbicon" />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="social-icon"
                src={instaIcon}
                alt="instagram-icon"
              />
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
