import React, { useContext, useState } from "react";
import GoogleMapsCarousel from "./GoogleMapsCarousel";
import TitleLine from "./TitleLine";
import "./styles/WineriesMap.css";
import { context } from "./Root";

export default function WineriesMap({ hidden, setHidden, title }) {
  const [click, setClick] = useState(6);
  const [selected, setSelected] = useState(null);
  const langContext = useContext(context);

  return (
    <>
      <section className="google-map-section">
        <h4
          style={
            langContext.lang !== "EN"
              ? { fontFamily: "BPG Nino Mtavruli" }
              : { fontFamily: "Montserrat" }
          }
          className="winery-title"
        >
          {title}
        </h4>
        <TitleLine />
        <GoogleMapsCarousel
          click={click}
          setClick={setClick}
          selected={selected}
          setSelected={setSelected}
          hidden={hidden}
          setHidden={setHidden}
        />
      </section>
    </>
  );
}
