import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../Api/getData";
import { changeTitle } from "../data/GeneralFunction";
import "../components/styles/Verification.css";
import logo from "../images/verification/logo.svg";
import { context } from "../components/Root";

export default function Verification() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const hash = useParams();

  const popUpContext = useContext(context);

  changeTitle("Findwine | Verification");

  useEffect(() => {
    const sendHashToServer = (hash) => {
      // Use Axios to send the hash back to the server
      api
        .post(`activate-user`, hash)
        .then((response) => {
          // Handle the response from the server
          setSuccessMessage(response.data);
        })
        .catch((error) => {
          setErrorMessage(error);
          // console.error("Error:", error);
        });
    };
    sendHashToServer(hash);
  }, [hash.hash]);

  return (
    <div className="verification-container">
      <div class="ver-logo-bg">
        <img src={logo} alt="logo" />
      </div>
      <h5>HELLO</h5>
      {successMessage !== "" && (
        <>
          <p className="verification-message">
            YOUR ACCOUNT HAS BEEN CREATED SUCCESSFULLY
          </p>
          <button
            onClick={() => popUpContext.setShowPopUp(true)}
            className="verification-button"
          >
            SIGN IN
          </button>
        </>
      )}
      {errorMessage !== "" && (
        <>
          <p className="verification-message">
            YOUR ACCOUNT IS ALREADY ACTIVATED
          </p>
          <button
            onClick={() => popUpContext.setShowPopUp(true)}
            className="verification-button"
          >
            SIGN IN
          </button>
        </>
      )}
    </div>
  );
}
