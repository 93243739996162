import React from "react";
import "./styles/GoogleMapsCarousel.css";

export default function Select(props) {
  return (
    <>
      <option className="option" value={props.item}>
        {props.item}
      </option>
    </>
  );
}
