import "./styles/NewCards.css";
import TitleLine from "./TitleLine";
import React, { useContext } from "react";
import CardsMap from "./CardsMap";
// import FavAndCartIcon from "./FavAndCartIcon";
// import { BsFillCartCheckFill } from "react-icons/bs";
// import { MdFavorite } from "react-icons/md";
import { Link } from "react-router-dom";
import { Alert, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";
import { context } from "./Root";

export default function NewCards({
  favorite,
  setFavorite,
  cart,
  setCart,
  NewItemData,
  title,
  showViewAll,
  error,
}) {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const langContext = useContext(context);

  const variants = isDesktop
    ? {
        initial: { opacity: 0, x: -500 },
        whileInView: { opacity: 1, x: 0 },
      }
    : {
        initial: { opacity: 0 },
        whileInView: { opacity: 1 },
      };

  return (
    <>
      <h3
        className="new-items-titles"
        style={
          langContext.lang !== "EN"
            ? { fontFamily: "BPG Nino Mtavruli" }
            : { fontFamily: "Montserrat" }
        }
      >
        {title}
      </h3>
      <TitleLine />
      {NewItemData && NewItemData.length === 0 && (
        <motion.div
          variants={variants}
          initial={variants.initial}
          whileInView={variants.whileInView}
          transition={{
            ease: "easeOut",
            duration: 1,
            delay: 0.5,
            type: "spring",
          }}
          className="error-handler-contianer"
        >
          <Alert style={{ textAlign: "center" }} severity="error">
            There is no more wines from this winery
          </Alert>
        </motion.div>
      )}

      {error === "" ? (
        <>
          <div className="view-all-deskt">
            {showViewAll && (
              <Link to={"/wines"} className="view-all-btn">
                View all
              </Link>
            )}
          </div>
          {/* <TitleLine /> */}
          <div className="view-all" style={{ marginBottom: "15px" }}>
            {/* <FavAndCartIcon
          icon={<BsFillCartCheckFill className="cart-icon" />}
          length={cart.length}
          link="/cart"
        /> */}
            {/* <FavAndCartIcon
          icon={<MdFavorite className="cart-icon" />}
          length={favorite.length}
          link="/favorite"
        /> */}
          </div>
          <div className="padd">
            <section className="slider">
              <CardsMap
                favorite={favorite}
                setFavorite={setFavorite}
                cart={cart}
                setCart={setCart}
                newItemData={NewItemData}
                slides={4}
              />
            </section>
          </div>
        </>
      ) : (
        <motion.div
          variants={variants}
          initial={variants.initial}
          whileInView={variants.whileInView}
          transition={{
            ease: "easeOut",
            duration: 1,
            delay: 0.5,
            type: "spring",
          }}
          className="error-handler-contianer"
        >
          <Alert style={{ textAlign: "center" }} severity="error">
            {error}
          </Alert>
        </motion.div>
      )}

      <div className="view-all-mob">
        <Link to={"/wines"} className="view-all-btn">
          View all
        </Link>
      </div>
    </>
  );
}
