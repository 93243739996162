import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import personalInfoIcon from "../images/profilePage/personalInfoIcon.svg";
import editInfoIcon from "../images/profilePage/PencilSimpleLine.svg";
import bonusImage from "../images/profilePage/Rectangle 39931.png";
import bonusIcon from "../images/profilePage/bonusIcon.svg";
import Alert from "@mui/material/Alert";
import api from "../Api/getData";
import { context } from "../components/Root";
import { changeTitle } from "../data/GeneralFunction";
import QRCode from "react-qr-code";
import passImage from "../images/winepassport/passport.png";
import { Modal } from "@mui/material";
import closeBtn from "../images/profilePage/close-btn.svg";
import Cookies from "js-cookie";

export default function ClientProfilePage() {
  const [showProfileHideWinePass, setShowProfileHideWinePass] = useState(true);
  const [passInput, setPassInput] = useState("");
  const [passRecoveryInput, setPassRecoveryInput] = useState("");
  const [passIsInvalid, setPassIsInvalid] = useState(false);
  const [invalidFullName, setInvalitFullName] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPhoneNumber, setInvalPhoneNumber] = useState(false);
  const [invalidBirthday, setInvalBirthday] = useState(false);
  const [error, setError] = useState("");
  const [userInfo, setUserInfo] = useState({});
  // const [passwordsNotMatches, setPasswordsNotMatches] = useState(false);
  const [allRigth, setAllRigth] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [profileEdit, setProfileEdit] = useState();
  const [loading, setLoading] = useState(true);
  const [countryData, setCountryData] = useState([]);
  const [openDeleteAcc, setOpenDeleteAcc] = useState(false);
  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const popUpContext = useContext(context);
  const langContext = useContext(context);

  const handleClose = () => setOpenDeleteAcc(false);
  const handleDelete = async () => {
    try {
      await api.delete("/user/delete-user", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      Cookies.remove("userLogin");
      popUpContext.setUserLogin(false);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  console.log(error && error);

  useEffect(() => {
    // If the user is not logged in and the loading is completed, navigate to the home page
    if (!popUpContext.userLogin && !loading) {
      navigate("/");
    }
  }, [popUpContext.userLogin, loading]);

  changeTitle(`Findwines | ${userInfo.user && userInfo.user.full_name}`);

  const fetchOptionalCountry = async () => {
    try {
      const res = await api.get(`countries`);
      setCountryData(res.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchOptionalCountry();
  }, []);

  const getCountryNameById = (idString) => {
    const id = parseInt(idString, 10); // Parse the string to an integer with base 10
    const country = countryData.find((country) => country.id === id);
    return country ? country.name : "Unknown Country";
  };

  const countryIdString = userInfo.user && userInfo.user.country;

  const fetchProfileInfo = async () => {
    try {
      const response = await api.get(`user/profile`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setUserInfo(response.data);
      setProfileEdit(response.data.user);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false); // Set loading to false whether the request was successful or not
    }
  };
  useEffect(() => {
    fetchProfileInfo();
  }, [authToken]);

  const handleInputChange = (e) => {
    setInvalitFullName(false);
    setInvalidEmail(false);
    setInvalPhoneNumber(false);
    setInvalBirthday(false);
    const { name, value } = e.target;
    const valueWithoutExtraSpaces = value.replace(/\s+/g, " ");

    setProfileEdit((prevProfile) => ({
      ...prevProfile,
      [name]: valueWithoutExtraSpaces,
    }));
  };

  const handleSave = async () => {
    // API calls
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const phoneRegex = /^\+995\d{9}$/;
    const phoneRegex = /^.{5,}$/;
    const fullnameRegex = /^.{5,}$/;
    const birthdayRegex = /^.{2,}$/;

    if (!fullnameRegex.test(profileEdit.full_name)) {
      setInvalitFullName(true);
    } else if (!emailRegex.test(profileEdit.email)) {
      setInvalidEmail(true);
    } else if (!phoneRegex.test(profileEdit.phone)) {
      setInvalPhoneNumber(true);
    } else if (!birthdayRegex.test(profileEdit.birthday)) {
      setInvalBirthday(true);
    } else {
      try {
        await api.post(
          `user/edit-user`,
          {
            phone: profileEdit.phone,
            country: profileEdit.country,
            full_name: profileEdit.full_name,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
            },
          }
        );
        fetchProfileInfo();
      } catch (error) {
        console.log(error.message);
      }
      setEditInfo(false);
    }
  };

  // const [isloggedIn, setIsLoggedIn] = useState(false);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!isloggedIn) {
  //     navigate("/");
  //   }
  // }, []);
  // const sortedWinePasses = winePasses.slice().sort((a, b) => {
  //   const dateA = new Date(a.expires);
  //   const dateB = new Date(b.expires);
  //   return dateB - dateA;
  // });

  const changePassSubmit = async (e) => {
    e.preventDefault();
    // check this users old password
    const passRegex = /^.{8,}$/;
    if (!passRegex.test(passInput) || !passRegex.test(passRecoveryInput)) {
      setPassIsInvalid(true);
      // } else if (passInput !== passRecoveryInput) {
      //   setPassIsInvalid(false);
      // setPasswordsNotMatches(true);
    } else {
      // send request for change password
      try {
        await api.post(
          `user/set-password`,
          {
            old_password: passInput,
            new_password: passRecoveryInput,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
            },
          }
        );
        setAllRigth(true);
        setPassIsInvalid(false);
        // setPasswordsNotMatches(false);
        setPassInput("");
        setPassRecoveryInput("");
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      <main
        style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}
        className="profile-page"
      >
        <div className="profile-page-main-titles">
          <h1
            onClick={() => setShowProfileHideWinePass(true)}
            className="profile-title"
            style={{
              ...(showProfileHideWinePass
                ? { color: "#3A3D43", borderBottom: "2px solid #B44D2D" }
                : { color: "rgba(58, 61, 67, 0.4)", borderBottom: "none" }),
              ...(langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }),
            }}
          >
            {langContext.lang === "EN" ? "PROFILE" : "პროფილი"}
          </h1>
          <h2
            onClick={() => setShowProfileHideWinePass(false)}
            className="wine-pass-title"
            style={{
              ...(!showProfileHideWinePass
                ? { color: "#3A3D43", borderBottom: "2px solid #B44D2D" }
                : { color: "rgba(58, 61, 67, 0.4)", borderBottom: "none" }),
              ...(langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }),
            }}
          >
            {langContext.lang === "EN" ? "WINE PASSPORT" : "ღვინის პასპორტი"}{" "}
          </h2>
        </div>
        <div style={{ borderBottom: "1px solid #C0C0C0", width: "100%" }}></div>
        {showProfileHideWinePass && (
          <>
            <section className="profile-page-container">
              <div className="personal-info-container">
                <div className="personal-info-head">
                  <div className="pers-info-wrapper">
                    <img
                      style={{ userSelect: "none" }}
                      src={personalInfoIcon}
                      alt="personal-info-icon"
                    />
                    <h5
                      style={
                        langContext.lang !== "EN"
                          ? { fontFamily: "BPG Nino Mtavruli" }
                          : { fontFamily: "Montserrat" }
                      }
                    >
                      {langContext.lang === "EN"
                        ? "PERSONAL INFO"
                        : "პერსონალური ინფორმაცია"}
                    </h5>
                  </div>
                  <img
                    src={editInfoIcon}
                    alt="editInfo"
                    style={{ cursor: "pointer", userSelect: "none" }}
                    onClick={() => setEditInfo(!editInfo)}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "rgba(41, 44, 49, 0.2)",
                    marginBottom: "25px",
                  }}
                ></div>

                {!editInfo && (
                  <>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN"
                          ? "Full name"
                          : "სრული სახელი"}
                      </span>
                      <span
                        className="prof-info-value"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {userInfo.user && userInfo.user.full_name}
                      </span>
                    </div>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN" ? "E-mail" : "ელფოსტა"}
                      </span>
                      <span
                        className="prof-info-value"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {userInfo.user && userInfo.user.email}
                      </span>
                    </div>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN" ? "Phone" : "ტელეფონი"}
                      </span>
                      <span
                        className="prof-info-value"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {userInfo.user && userInfo.user.phone}
                      </span>
                    </div>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN" ? "Country" : "ქვეყანა"}
                      </span>
                      <span
                        className="prof-info-value"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {userInfo.user && getCountryNameById(countryIdString)}
                      </span>
                    </div>
                  </>
                )}
                {editInfo && (
                  <div>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN"
                          ? "Full name"
                          : "სრული სახელი"}
                      </span>
                      <input
                        className="edit-info-data"
                        type="text"
                        name="full_name"
                        value={profileEdit.full_name}
                        onChange={handleInputChange}
                        placeholder="Enter your fullname"
                        style={invalidFullName ? { borderColor: "red" } : null}
                      />
                    </div>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN" ? "E-mail" : "ელფოსტა"}
                      </span>
                      <input
                        readOnly
                        className="edit-info-data"
                        type="text"
                        name="email"
                        value={profileEdit.email}
                        onChange={handleInputChange}
                        style={{ opacity: "0.5" }}
                        placeholder="Enter your e-mail"
                      />
                    </div>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN" ? "Phone" : "ტელეფონი"}
                      </span>
                      <input
                        className="edit-info-data"
                        type="text"
                        name="phone"
                        value={profileEdit.phone}
                        onChange={handleInputChange}
                        style={
                          invalidPhoneNumber ? { borderColor: "red" } : null
                        }
                        placeholder="Enter your phone"
                      />
                    </div>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN" ? "Country" : "ქვეყანა"}
                      </span>
                      <input
                        readOnly
                        className="edit-info-data"
                        name="country"
                        value={
                          userInfo.user && getCountryNameById(countryIdString)
                        }
                        onChange={handleInputChange}
                        // style={invalidBirthday ? { borderColor: "red" } : null}
                        style={{ opacity: "0.5" }}
                        type="text"
                        placeholder="Enter your country"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        className="edit-btn"
                        onClick={handleSave}
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Montserrat" }
                        }
                      >
                        {langContext.lang === "EN" ? "SAVE" : "შენახვა"}
                      </button>
                    </div>
                    {invalidFullName && (
                      <Alert style={{ marginTop: "10px" }} severity="error">
                        {langContext.lang === "EN"
                          ? " Invalid fullname format. Please enter fullname min 5 characters."
                          : "არასწორი სრული სახელის ფორმატია.გთხოვთ შეიყვანოთ სრული სახელი მინიმუმ 5 ასობგერით"}
                      </Alert>
                    )}
                    {invalidEmail && (
                      <Alert style={{ marginTop: "10px" }} severity="error">
                        {langContext.lang === "EN"
                          ? "Invalid email format. Please enter a valid email address."
                          : "არასწორია ელფოსტის ფორმატი.გთხოვთ შეიყვანოთ ვალიდური ელფოსტის მისამართი"}{" "}
                      </Alert>
                    )}
                    {invalidPhoneNumber && (
                      <Alert style={{ marginTop: "10px" }} severity="error">
                        {langContext.lang === "EN"
                          ? " Invalid phone format. Please enter valid phone number (+995 *** ** ** **)"
                          : "არასწორია ნომრის ფორმატი.გთხოვთ შეიყვანოთ ვალიდური ნომერი (+995 *** ** ** **)"}
                      </Alert>
                    )}
                    {invalidBirthday && (
                      <Alert style={{ marginTop: "10px" }} severity="error">
                        {langContext.lang === "EN"
                          ? "Invalid birthday format. Please enter valid birthday (dd/mm/yyyy)"
                          : "არასწორია დაბადების დღის ფორმათი.გთხოვთ შეიყვანოთ ვალიდური დაბადების დღის ფორმატი (dd/mm/yyyy)"}
                      </Alert>
                    )}
                  </div>
                )}
              </div>
              <div className="bonus-point-container">
                <img className="bonus-img" src={bonusImage} alt="wine" />
                <div className="bonus-container">
                  <div className="bonus-wrap">
                    <img
                      style={{ userSelect: "none" }}
                      src={bonusIcon}
                      alt="bonusIcon"
                    />
                    <span
                      style={
                        langContext.lang !== "EN"
                          ? { fontFamily: "FiraGo" }
                          : { fontFamily: "Montserrat" }
                      }
                    >
                      {langContext.lang === "EN"
                        ? "BONUS POINTS"
                        : "ბონუს ქულები"}
                    </span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "rgba(41, 44, 49, 0.2)",
                    }}
                  ></div>
                  <div className="bonus-text">
                    <p
                      style={
                        langContext.lang !== "EN"
                          ? { fontFamily: "FiraGo" }
                          : { fontFamily: "Inter" }
                      }
                    >
                      {langContext.lang === "EN" ? "You have" : "შენ გაქვს"}
                    </p>
                    <span
                      style={
                        langContext.lang !== "EN"
                          ? { fontFamily: "FiraGo" }
                          : { fontFamily: "Poppins" }
                      }
                    >
                      {userInfo.user && userInfo.user.earned_points}
                    </span>
                    <h4
                      style={
                        langContext.lang !== "EN"
                          ? { fontFamily: "FiraGo" }
                          : { fontFamily: "Poppins" }
                      }
                    >
                      {langContext.lang === "EN"
                        ? "bonus points"
                        : "ბონუს ქულები"}{" "}
                    </h4>
                  </div>
                  <p
                    className="useBonusPoints"
                    style={
                      langContext.lang !== "EN"
                        ? { fontFamily: "FiraGo" }
                        : { fontFamily: "Poppins" }
                    }
                  >
                    {langContext.lang === "EN"
                      ? "How to use bonus points?"
                      : "როგორ გამოვიყენოთ ბონუს ქულები?"}
                  </p>
                </div>
              </div>
              <div className="change-pass-container">
                <span
                  style={
                    langContext.lang !== "EN"
                      ? { fontFamily: "FiraGo" }
                      : { fontFamily: "Montserrat" }
                  }
                >
                  {langContext.lang === "EN"
                    ? "CHANGE PASSWORD"
                    : "პაროლის შეცვლა"}
                </span>
                <p
                  style={
                    langContext.lang !== "EN"
                      ? { fontFamily: "FiraGo" }
                      : { fontFamily: "Inter" }
                  }
                >
                  {langContext.lang === "EN"
                    ? "To change your password, please input current and new password."
                    : "პაროლის შესაცვლელად,გთხოვთ შეიყვანოთ მიმდინარე და ახალი პაროლი"}
                </p>
                <form
                  style={{ display: "flex", flexDirection: "column" }}
                  onSubmit={changePassSubmit}
                >
                  <input
                    value={passInput}
                    onChange={(e) => {
                      setPassInput(e.target.value);
                      setPassIsInvalid(false);
                      // setPasswordsNotMatches(false);
                      setAllRigth(false);
                    }}
                    type="password"
                    style={
                      langContext.lang !== "EN"
                        ? { fontFamily: "FiraGo" }
                        : { fontFamily: "Inter" }
                    }
                    placeholder={
                      langContext.lang === "EN"
                        ? "Current password"
                        : "მიმდინარე პაროლი"
                    }
                  />

                  <input
                    value={passRecoveryInput}
                    type="password"
                    style={
                      langContext.lang !== "EN"
                        ? { fontFamily: "FiraGo" }
                        : { fontFamily: "Inter" }
                    }
                    onChange={(e) => {
                      setPassRecoveryInput(e.target.value);
                      setPassIsInvalid(false);
                      // setPasswordsNotMatches(false);
                      setAllRigth(false);
                    }}
                    placeholder={
                      langContext.lang === "EN"
                        ? "New password"
                        : "ახალი პაროლი"
                    }
                  />
                  {passIsInvalid && (
                    <Alert severity="error" style={{ marginBottom: "10px" }}>
                      {langContext.lang === "EN"
                        ? "Password must be at least 8 characters including a number"
                        : "პაროლი უნდა შეიცავდეს მინიმუმ 8 სიმბოლოს ციფრების ჩათვლით"}
                    </Alert>
                  )}
                  {/* {passwordsNotMatches && (
                <Alert severity="error" style={{ marginBottom: "10px" }}>
                  Passwords must match
                </Alert>
              )} */}
                  {allRigth && (
                    <Alert style={{ marginBottom: "10px" }} severity="success">
                      {langContext.lang === "EN"
                        ? "Password change successfully"
                        : "პაროლი შეიცვალა წარმატებით"}
                    </Alert>
                  )}

                  <button
                    type="submit"
                    style={
                      langContext.lang !== "EN"
                        ? { fontFamily: "FiraGo" }
                        : { fontFamily: "Montserrat" }
                    }
                  >
                    {langContext.lang === "EN" ? "SAVE" : "შენახვა"}
                  </button>
                </form>
              </div>
            </section>
            <div className="delete-account-container">
              <h5>Delete Account</h5>
              <p>
                If you no longer wish to use Findwine application, you can
                permanently.
                <span onClick={() => setOpenDeleteAcc(true)}>delete</span> your
                account.
              </p>
            </div>
          </>
        )}
        {!showProfileHideWinePass && (
          <div className="profile-wine-pass-main-container">
            {userInfo &&
              userInfo.passport &&
              userInfo.passport.map((winepass) => {
                return (
                  <div
                    className="profile-wine-pass-container"
                    key={winepass.id}
                  >
                    <div className="profile-wine-pass-left-block">
                      <h4 className="current-wine-pass-title">
                        {/* {winepass.isActive
                        ? langContext.lang === "EN"
                          ? "CURRENT PASSPORT"
                          : "მიმდინარე პასპორტი"
                        : langContext.lang === "EN"
                        ? "PREVIOUS PASSPORT"
                        : "გამოყენებული პასპორტი"} */}
                        CURRENT PASSPORT
                      </h4>
                      <div
                        style={{
                          width: "100%",
                          height: "1px",
                          backgroundColor: "rgba(41, 44, 49, 0.2)",
                        }}
                      ></div>
                      <div>
                        <div className="winepass-profile-info">
                          <img
                            className="winepass-img"
                            src={passImage}
                            alt="winepass-img"
                          />
                          <div className="wine-pass-detail-info">
                            <div className="prof-info-wrapper">
                              <span
                                className="wine-info-props"
                                style={
                                  langContext.lang !== "EN"
                                    ? { fontFamily: "FiraGo" }
                                    : { fontFamily: "Poppins" }
                                }
                              >
                                {langContext.lang === "EN"
                                  ? "Owner"
                                  : "მფლობელი"}
                              </span>
                              <span className="prof-info-value">
                                {userInfo.user && userInfo.user.full_name}
                              </span>
                            </div>
                            <div className="prof-info-wrapper">
                              <span
                                className="wine-info-props"
                                style={
                                  langContext.lang !== "EN"
                                    ? { fontFamily: "FiraGo" }
                                    : { fontFamily: "Poppins" }
                                }
                              >
                                {langContext.lang === "EN"
                                  ? "Passport Type"
                                  : "პასპორტის ტიპი"}
                              </span>
                              <span className="prof-info-value">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <h5
                                    className="wine-pass-access"
                                    style={
                                      langContext.lang !== "EN"
                                        ? { fontFamily: "FiraGo" }
                                        : { fontFamily: "Poppins" }
                                    }
                                  >
                                    {winepass.passport &&
                                      winepass.passport.name}
                                  </h5>
                                </div>
                              </span>
                            </div>
                            <Link
                              to={`/wine-passport/detail/${winepass.passport &&
                                winepass.passport.id}`}
                              className="prof-info-wrapper"
                            >
                              <span
                                className="wine-info-props"
                                style={
                                  langContext.lang !== "EN"
                                    ? { fontFamily: "FiraGo" }
                                    : { fontFamily: "Poppins" }
                                }
                              >
                                {langContext.lang === "EN"
                                  ? "Wineries"
                                  : "მარნები"}
                              </span>
                              <span className="prof-info-value">
                                {winepass.passport &&
                                  winepass.passport.company_count}
                              </span>
                            </Link>
                            <div className="prof-info-wrapper">
                              <span
                                className="wine-info-props"
                                style={
                                  langContext.lang !== "EN"
                                    ? { fontFamily: "FiraGo" }
                                    : { fontFamily: "Poppins" }
                                }
                              >
                                {langContext.lang === "EN"
                                  ? "Expires"
                                  : "იწურება"}
                              </span>
                              <span className="prof-info-value">
                                {
                                  new Date(winepass.expire_date)
                                    .toISOString()
                                    .split("T")[0]
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profile-wine-pass-rigth-block">
                      <h4
                        className="qr-title"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN" ? "QR Code" : "QR კოდი"}
                      </h4>
                      <div className="qr-container">
                        <QRCode
                          style={{ width: "148px", height: "142px" }}
                          value={winepass.hash ? winepass.hash : "1"}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </main>
      <Modal open={openDeleteAcc} onClose={handleClose}>
        <div className="delete-account-modal-container">
          <div className="delete-account-wrapper">
            <img
              className="delete-acc-closeBtn"
              onClick={() => setOpenDeleteAcc(false)}
              src={closeBtn}
              alt="close-btn"
            />
            <h4>ARE YOU SURE?</h4>
            <p>
              By clicking delete all your information will be permanently
              deleted and you will no longer have access to your account.
            </p>
            <div className="delete-account-btns">
              <button
                className="delete-acc-deleteBtn"
                onClick={() => handleDelete()}
              >
                DELETE
              </button>
              <button
                className="delete-acc-cancelBtn"
                onClick={() => setOpenDeleteAcc(false)}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
