import React from "react";
import TitleLine from "../components/TitleLine";
import CartFavorites from "../components/CartFavorites";
import "../components/styles/Favorite.css";
// import { BsFillCartCheckFill } from "react-icons/bs";
import { MdFavorite } from "react-icons/md";
import FavAndCartIcon from "../components/FavAndCartIcon";
import { Link } from "react-router-dom";
import wine1 from "../images/item/ველები-ქვევრის-მწვანე-113x300.png";

export default function Favorite({
  favorite,
  setCart,
  cart,
  setFavorite,
  clearFavorite,
}) {
  //   console.log(favorite);

  return (
    <div>
      <h1 className="section-one-title" style={{ marginTop: "50px" }}>
        Favorite Items
      </h1>
      <TitleLine />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {/* <FavAndCartIcon
          icon={<BsFillCartCheckFill className="cart-icon" />}
          length={cart.length}
          link="/cart"
        /> */}
        <FavAndCartIcon
          icon={<MdFavorite className="cart-icon" />}
          length={favorite.length}
          link="/favorite"
        />
      </div>
      {favorite.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px 0",
          }}
        >
          <h2>Favorite Items Are Empty</h2>
          <Link style={{ textDecoration: "none", marginLeft: "10px" }} to="/">
            <button style={{ marginTop: 0 }} className="clear-Favorite-Btn">
              Home
            </button>
          </Link>
        </div>
      )}
      <div className="favorite-container">
        {favorite.map((item) => {
          return (
            <div key={item.id} className="our-wine-cards favorites">
              <div className="our-wine-card">
                <img src={wine1} alt="wine" />
                <div className="overlay">
                  <CartFavorites
                    items={item}
                    favorite={favorite}
                    setFavorite={setFavorite}
                    cart={cart}
                    setCart={setCart}
                  />
                </div>
                <div className="left-block">
                  {/* <p className={item.sale ? "sale" : "notsale"}>
                  {item.sale ? item.sale : null}
                </p> */}
                  <p className="item-title">{item.name}</p>
                  <h5 className="wine-color">{item.color}</h5>
                  <h5 className="item-grape">{item.breed}</h5>
                  <p className="">ღვინის ტიპი 1</p>
                  {/* <p className="item-final-price">{`$${item.finalPrice}`}</p> */}
                  <div className="price-cont">
                    <p className="item-final-price">20$</p>
                    <span className="item-first-price">40$</span>
                  </div>
                  {/* <span className="item-first-price">
                  {item.firstPrice ? `$${item.firstPrice}` : null}
                </span> */}
                  <div className="mobile-cart-fav">
                    <CartFavorites
                      items={item}
                      favorite={favorite}
                      setFavorite={setFavorite}
                      cart={cart}
                      setCart={setCart}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {favorite.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "100px 0",
          }}
        >
          <button className="clear-Favorite-Btn" onClick={clearFavorite}>
            Clear All
          </button>
        </div>
      )}
    </div>
  );
}
