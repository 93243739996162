import React from "react";
import vector from "../images/winepassport/Vector.svg";
import "./styles/Package.css";

export default function PackageBenefit({ info }) {
  return (
    <>
      {info.map((inf, index) => {
        return (
          <div key={index} className="package-benefit-wrapper">
            <img src={vector} alt="vector" />
            <p>{inf.description}</p>
          </div>
        );
      })}
    </>
  );
}
