import React from "react";
import CartFavorites from "./CartFavorites";
import "./styles/ViewAll.css";
import wine1 from "../images/item/ველები-ქვევრის-მწვანე-113x300.png";

export default function ViewAll({
  favorite,
  setFavorite,
  cart,
  setCart,
  NewItemData,
}) {
  return (
    <>
      {NewItemData.map((item) => {
        return (
          <div key={item.id} className="our-wine-cards">
            <div className="our-wine-card">
              <img src={wine1} alt="wine" />
              <div className="overlay">
                <CartFavorites
                  items={item}
                  favorite={favorite}
                  setFavorite={setFavorite}
                  cart={cart}
                  setCart={setCart}
                />
              </div>
              <div className="left-block">
                <p className={item.sale ? "sale" : "notsale"}>
                  {item.sale ? item.sale : null}
                </p>
                <p className="item-title">{item.name}</p>
                <h5 className="wine-color">{item.color}</h5>
                <h5 className="item-grape">{item.breed}</h5>
                <p className="">ღვინის ტიპი 1</p>
                <div className="price-cont">
                  <p className="item-final-price">20$</p>
                  <span className="item-first-price">40$</span>
                </div>
                {/* <span className="item-first-price">
                  {item.firstPrice ? `$${item.firstPrice}` : null}
                </span> */}
                <div className="mobile-cart-fav">
                  <CartFavorites
                    items={item}
                    favorite={favorite}
                    setFavorite={setFavorite}
                    cart={cart}
                    setCart={setCart}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
