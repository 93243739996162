import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
// import avatarImg from "../images/articlePage/Ellipse 1.svg";
// import avatarImg from "../images/sven-wilhelm-2cRXSWyMHA8-unsplash.png";

export default function Article({
  image,
  title,
  description,
  author,
  position,
  authorImg,
  id,
  date,
  category,
}) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  // const webSite = process.env.REACT_APP_WEBSITE;
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    const inputDate = date;

    const formatDate = (inputDate) => {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(inputDate).toLocaleDateString(undefined, options);
    };

    setFormattedDate(formatDate(inputDate));
  }, []);
  return (
    <Link
      to={`/articles/detail/${id}`}
      className="article-wrapper"
      // style={isMobile ? { border: "1px solid #d6d6d6" } : null}
    >
      <div>
        <div className="article-img-zoom-cont">
          <img className="article-wrapper-img" src={image} alt="articleimage" />
        </div>
        <div className="mob-article-padding">
          <span className="article-card-title">
            {formattedDate} | {category}
          </span>
          <div>
            <h4>
              {title.split(" ").length > 10
                ? `${title
                    .split(" ")
                    .slice(0, 10)
                    .join(" ")} ...`
                : title}
            </h4>
            <p
              dangerouslySetInnerHTML={{
                __html: `${description
                  .split(" ")
                  .slice(0, 10)
                  .join(" ")} ...`,
              }}
            ></p>
          </div>
        </div>
      </div>
      <div style={isMobile ? { padding: "0 10px" } : null}>
        <div className="author-cont">
          <img src={authorImg} alt="authorimg" />
          <div className="author-wrap">
            <span className="article-author">{author}</span>
            <span className="article-position">{position}</span>
          </div>
        </div>
      </div>
    </Link>
  );
}
