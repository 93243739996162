import React, { useContext, useEffect, useState } from "react";
// import WineriesBg from "../components/WineriesBg";
import TitleLine from "../components/TitleLine";
import twoRow from "../images/wineries/Group 34.png";
import threeRow from "../images/wineries/Group 32.png";
import fourRow from "../images/wineries/Group 33.png";
import Sort from "../components/Sort";
import arrowDown from "../images/wineries/arrowDowns.png";
import WineryListCard from "../components/WineryListCard";
import arrowUp from "../images/wineries/arrowtop.png";
import "../components/styles/WineriesLabel.css";
import WinerisLabelOption from "../components/WineriesLabelOption";
import WinerySelect from "../components/WinerySelect";
import ReactPaginate from "react-paginate";
import api from "../Api/getData";
// import search from "../images/search2.svg";
import { context } from "../components/Root";
import { Alert, Autocomplete, TextField, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";
import { transformText } from "../data/georgianToEnglish";
import { changeTitle } from "../data/GeneralFunction";
// import useCustomHooksGetData from "../hooks/useCustomHookGetData";
// import useCustomHooksGetData from "../hooks/useCustomHookGetData";

export default function Wineries() {
  const [displayIndex, setDisplayIndex] = useState(0);
  const [filter, setFilter] = useState(0);
  const [Wineries, setWineries] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [showSort, setShowSort] = useState(false);
  const [filterRegion, setFilterRegion] = useState([]);
  const [filterTerritory, setFilterTerritory] = useState([]);
  const [selectedWineTypes, setSelectedWineTypes] = useState([]);
  const [regionInputs, setRegionInputs] = useState([]);
  const [wineTypesInput, setWineTypesInput] = useState([]);
  const [territoriesInput, setTerritoriesInput] = useState([]);
  const [error, setError] = useState("");
  const [searchWineryInput, setSearchWineryInput] = useState("");
  const [loader, setLoader] = useState(true);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);

  const selectValues = ["Top Rated", "Top Rated", "Top Rated"];

  changeTitle("Findwine | Wineries");

  const popUpContext = useContext(context);
  const langContext = useContext(context);

  const isDesktop = useMediaQuery("(min-width: 768px)");

  const variants = isDesktop
    ? {
        initial: { opacity: 0, x: -500 },
        whileInView: { opacity: 1, x: 0 },
      }
    : {
        initial: { opacity: 0 },
        whileInView: { opacity: 1 },
      };

  useEffect(() => {
    let loaderTimeout;

    const fetchData = async () => {
      try {
        const response = await api.get("/wineries");
        setWineries(response.data);
        setAutocompleteOptions(
          response.data.map((winery) => ({ company: winery.company }))
        );

        loaderTimeout = setTimeout(() => {
          setLoader(false);
        }, 2000);
      } catch (error) {
        loaderTimeout = setTimeout(() => {
          setLoader(false);
        }, 2000);
        setError(error.message);
      }
    };
    fetchData();
    return () => {
      clearTimeout(loaderTimeout);
    };
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await api.get("cities");
        setTerritoriesInput(response.data);
      } catch (error) {
        setError(error.message);
      }
    };
    const fetchRegion = async () => {
      try {
        const response = await api.get("regions");
        setRegionInputs(response.data);
      } catch (error) {
        setError(error.message);
      }
    };
    const fetchWineTypes = async () => {
      try {
        const response = await api.get("wine-types");
        setWineTypesInput(response.data);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchRegion();
    fetchWineTypes();
    fetchCities();
  }, []);

  const filtered = Wineries.filter((item) => {
    console.log(item);

    const nameMatch = item.company
      .toLowerCase()
      .includes(searchWineryInput.toLowerCase());
    const regionMatch =
      filterRegion.length === 0 ||
      filterRegion.includes(transformText(item.region));
    const territoryMatch =
      filterTerritory.length === 0 ||
      filterTerritory.includes(transformText(item.city));
    const wineTypeMatch =
      selectedWineTypes.length === 0 ||
      item.wine_types.some((type) =>
        selectedWineTypes.includes(transformText(type))
      );

    return regionMatch && territoryMatch && nameMatch && wineTypeMatch;
  });

  const wineriesPerPage = 12;
  const pagesVisited = pageNumber * wineriesPerPage;

  const pageCount = Math.ceil(filtered.length / wineriesPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    setFilter(0);
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("page", (selected + 1).toString());
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.history.pushState({}, "", newUrl.toString());
  };
  // useEffect(() => {
  //   const url = new URL(window.location.href);
  //   const pageNumberParam = url.searchParams.get("page");
  //   if (pageNumberParam) {
  //     setPageNumber(parseInt(pageNumberParam - 1));
  //   }
  // }, []);

  const displayWineries = filtered
    .slice(pagesVisited, pagesVisited + wineriesPerPage)
    .map((winery) => {
      return (
        <WineryListCard
          title={winery.company}
          subTitle={winery.titleRegion}
          description={winery.description}
          location={winery.city}
          rating={winery.raiting}
          image={winery.img_path}
          key={winery.id}
          id={winery.id}
          displayIndex={displayIndex}
          region={winery.region}
        />
      );
    });

  const filterHandler = (regionfilter) => {
    // let newFilterRegion;

    if (filterRegion.includes(regionfilter)) {
      setFilterRegion(filterRegion.filter((prev) => prev !== regionfilter));
    } else {
      setFilterRegion([...filterRegion, regionfilter]);
    }

    // setFilterRegion(newFilterRegion);

    // Update the URL with the new filterRegion values
    const newUrl = new URL(window.location.href);

    // Check if the regionfilter is in the URL's search params and toggle it
    const regionParam = newUrl.searchParams.get("region");
    if (regionParam) {
      const regions = regionParam.split(",");
      if (regions.includes(regionfilter)) {
        newUrl.searchParams.set(
          "region",
          regions.filter((prev) => prev !== regionfilter).join(",")
        );
      } else {
        newUrl.searchParams.set("region", `${regionParam},${regionfilter}`);
      }
    } else {
      newUrl.searchParams.set("region", regionfilter);
    }

    // Push the new URL state
    window.history.pushState({}, "", newUrl.toString());
  };

  // filter function for territory
  const filterTerritoryFn = (territory) => {
    let newFilterTerritory;

    if (filterTerritory.includes(territory)) {
      newFilterTerritory = filterTerritory.filter(
        (prevTerritory) => prevTerritory !== territory
      );
    } else {
      newFilterTerritory = [...filterTerritory, territory];
    }

    setFilterTerritory(newFilterTerritory);

    // Update the URL with the new filterTerritory values
    const newUrl = new URL(window.location.href);

    // Check if the territory is in the URL's search params and toggle it
    const territoryParam = newUrl.searchParams.get("territory");
    if (territoryParam) {
      const territories = territoryParam.split(",");
      if (territories.includes(territory)) {
        newUrl.searchParams.set(
          "territory",
          territories.filter((prev) => prev !== territory).join(",")
        );
      } else {
        newUrl.searchParams.set("territory", `${territoryParam},${territory}`);
      }
    } else {
      newUrl.searchParams.set("territory", territory);
    }

    // Push the new URL state
    window.history.pushState({}, "", newUrl.toString());
  };

  // filter function for wine type
  const filterWineType = (wineType) => {
    let newSelectedWineTypes;

    if (selectedWineTypes.includes(wineType)) {
      newSelectedWineTypes = selectedWineTypes.filter(
        (selectedType) => selectedType !== wineType
      );
    } else {
      newSelectedWineTypes = [...selectedWineTypes, wineType];
    }

    setSelectedWineTypes(newSelectedWineTypes);

    // Update the URL with the new selectedWineTypes values
    const newUrl = new URL(window.location.href);

    // Check if the wine types are in the URL's search params and toggle them
    const wineTypeParam = newUrl.searchParams.get("winetype");
    if (wineTypeParam) {
      const wineTypes = wineTypeParam.split(",");
      if (wineTypes.includes(wineType)) {
        newUrl.searchParams.set(
          "winetype",
          wineTypes.filter((prev) => prev !== wineType).join(",")
        );
      } else {
        newUrl.searchParams.set("winetype", `${wineTypeParam},${wineType}`);
      }
    } else {
      newUrl.searchParams.set("winetype", newSelectedWineTypes.join(","));
    }

    // Push the new URL state
    window.history.pushState({}, "", newUrl.toString());
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const regionParam = url.searchParams.get("region");
    const winetypeParam = url.searchParams.get("winetype");
    const territoryParam = url.searchParams.get("territory");
    const pageNumberParam = url.searchParams.get("page");

    if (pageNumberParam) {
      setPageNumber(parseInt(pageNumberParam - 1));
    }

    if (regionParam) {
      const regions = regionParam.split(",");
      setFilterRegion(regions);
    }

    if (winetypeParam) {
      const wineTypes = winetypeParam.split(",");
      setSelectedWineTypes(wineTypes);
    }
    if (territoryParam) {
      const territory = territoryParam.split(",");
      setFilterTerritory(territory);
    }
  }, []);

  return (
    <div style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}>
      {/* <WineriesBg /> */}
      <div className="winery-titles">
        <h1
          className="section-one-title"
          style={
            langContext.lang !== "EN"
              ? { fontFamily: "BPG Nino Mtavruli" }
              : { fontFamily: "Montserrat" }
          }
        >
          {langContext.lang === "EN"
            ? "WINERIES OF GEORGIA"
            : "მარნები საქართველოში"}
        </h1>
        <TitleLine />
      </div>
      {/* <div className="wineries-list-container"> */}
      <div className="winery-cont">
        <div className="input-search-img-mob">
          <Autocomplete
            options={autocompleteOptions}
            getOptionLabel={(option) => option.company}
            ListboxProps={{ style: { maxHeight: "200px" } }}
            onChange={(event, value) => {
              setSearchWineryInput(value ? value.company : "");
            }}
            renderInput={(params) => (
              <TextField
                style={
                  langContext.lang !== "EN"
                    ? { fontFamily: "FiraGo" }
                    : { fontFamily: "Poppins" }
                }
                {...params}
                value={searchWineryInput}
                onChange={(e) => setSearchWineryInput(e.target.value)}
                className="winery-input"
                InputLabelProps={{
                  sx: {
                    color: "#D6D6D6",
                    "&.Mui-focused": { color: "#D6D6D6" },
                  },
                }}
                label={langContext.lang === "EN" ? "Search" : "ძიება"}
                placeholder={langContext.lang === "EN" ? "Search" : "ძიება"}
              />
            )}
          />
          {/* <input
              onChange={(e) => setSearchWineryInput(e.target.value)}
              value={searchWineryInput}
              className="winery-input-mob"
              type="text"
              placeholder="Search"
            />
            <img className="searchs-icon" src={search} alt="search" /> */}
        </div>
        <div className="sorts-cont">
          <div className="sort-container-sort-icon">
            <span
              className="grsort"
              onClick={() => {
                setShowSort(!showSort);
              }}
            >
              Filters
            </span>
            {/* <GrSort
              onClick={() => {
                setShowSort(!showSort);
              }}
              className="grsort"
            /> */}

            {showSort && (
              <>
                <div className="sort-containers">
                  <Sort
                    setFilter={setFilter}
                    number={1}
                    title="Region"
                    arrowDownImage={arrowDown}
                    arrowUp={arrowUp}
                    filter={filter}
                    setFilterRegion={setFilterRegion}
                    filterRegion={filterRegion}
                  />
                  <Sort
                    setFilter={setFilter}
                    number={2}
                    title="Wine types"
                    arrowDownImage={arrowDown}
                    arrowUp={arrowUp}
                    filter={filter}
                    setFilterRegion={setFilterRegion}
                  />
                  <Sort
                    setFilter={setFilter}
                    number={3}
                    title="Terroirs"
                    arrowDownImage={arrowDown}
                    arrowUp={arrowUp}
                    filter={filter}
                    setFilterRegion={setFilterRegion}
                    filterRegion={filterRegion}
                  />
                  {filter === 1 ? (
                    <WinerisLabelOption
                      filterHandler={filterHandler}
                      labelClassname="region-sort-absolute"
                      filterData={regionInputs}
                      setPageNumber={setPageNumber}
                      filterRegion={filterRegion}
                    />
                  ) : filter === 2 ? (
                    <WinerisLabelOption
                      filterHandler={filterWineType}
                      filterRegion={selectedWineTypes}
                      labelClassname="Wine-type-sort-absolute"
                      filterData={wineTypesInput}
                      setPageNumber={setPageNumber}
                    />
                  ) : filter === 3 ? (
                    <WinerisLabelOption
                      filterHandler={filterTerritoryFn}
                      labelClassname="Teritories-sort-absolute"
                      filterData={territoriesInput}
                      setPageNumber={setPageNumber}
                      filterRegion={filterTerritory}
                    />
                  ) : filter === 4 ? (
                    <h4>asdsada</h4>
                  ) : null}
                </div>
              </>
            )}
            <div className="input-sort-container">
              <div className="winery-input-width">
                <Autocomplete
                  options={autocompleteOptions}
                  getOptionLabel={(option) => option.company}
                  ListboxProps={{ style: { maxHeight: "200px" } }}
                  onChange={(event, value) => {
                    // console.log(value);
                    setSearchWineryInput(value ? value.company : "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={searchWineryInput}
                      onChange={(e) => setSearchWineryInput(e.target.value)}
                      className="winery-input"
                      style={
                        langContext.lang !== "EN"
                          ? { fontFamily: "FiraGo" }
                          : { fontFamily: "Poppins" }
                      }
                      InputLabelProps={{
                        sx: {
                          color: "black",
                          "&.Mui-focused": { color: "black" },
                        },
                      }}
                      label={langContext.lang === "EN" ? "Search" : "ძიება"}
                      placeholder={
                        langContext.lang === "EN" ? "Search" : "ძიება"
                      }
                    />
                  )}
                />
                {/* <input
                    onChange={(e) => setSearchWineryInput(e.target.value)}
                    value={searchWineryInput}
                    className="winery-input"
                    type="text"
                    placeholder="Search"
                  /> */}
                {/* <img className="searchs-icon" src={search} alt="search" /> */}
              </div>
              <div className="sort-container">
                <Sort
                  setFilter={setFilter}
                  number={1}
                  title={langContext.lang === "EN" ? "Region" : "რეგიონი"}
                  arrowDownImage={arrowDown}
                  arrowUp={arrowUp}
                  filter={filter}
                  setFilterRegion={setFilterRegion}
                  filterRegion={filterRegion}
                />
                <Sort
                  setFilter={setFilter}
                  number={2}
                  title={
                    langContext.lang === "EN" ? "Wine types" : "ღვინის ტიპები"
                  }
                  arrowDownImage={arrowDown}
                  arrowUp={arrowUp}
                  filter={filter}
                  setFilterRegion={setFilterRegion}
                  filterRegion={filterRegion}
                />
                <Sort
                  setFilter={setFilter}
                  number={3}
                  title={langContext.lang === "EN" ? "Origin" : "წარმოშობა"}
                  arrowDownImage={arrowDown}
                  arrowUp={arrowUp}
                  filter={filter}
                  setFilterRegion={setFilterRegion}
                  filterRegion={filterRegion}
                />
                {filter === 1 ? (
                  <WinerisLabelOption
                    filterHandler={filterHandler}
                    labelClassname="region-sort-absolute"
                    filterData={regionInputs}
                    setPageNumber={setPageNumber}
                    filterRegion={filterRegion}
                  />
                ) : filter === 2 ? (
                  <WinerisLabelOption
                    filterHandler={filterWineType}
                    filterRegion={selectedWineTypes}
                    labelClassname="Wine-type-sort-absolute"
                    filterData={wineTypesInput}
                    setPageNumber={setPageNumber}
                  />
                ) : filter === 3 ? (
                  <WinerisLabelOption
                    filterHandler={filterTerritoryFn}
                    labelClassname="Teritories-sort-absolute"
                    filterData={territoriesInput}
                    setPageNumber={setPageNumber}
                    filterRegion={filterTerritory}
                  />
                ) : filter === 4 ? (
                  <h4>asdsada</h4>
                ) : null}
              </div>
            </div>
          </div>
          <div className="right-sort-container">
            <div className="sort-wrapper">
              <WinerySelect
                labelTiTle="Sort by:"
                selectClassName="wineriPageSelect"
                values={selectValues}
              />
            </div>
            <div className="boxes">
              <img
                onClick={() => setDisplayIndex(1)}
                src={twoRow}
                alt="threerow"
              />
              <img
                onClick={() => setDisplayIndex(0)}
                src={threeRow}
                alt="tworow"
              />
              <img
                onClick={() => setDisplayIndex(2)}
                style={{ marginRight: "0" }}
                src={fourRow}
                alt="fourrow"
              />
            </div>
          </div>
        </div>
        {loader ? (
          <div className="loarder-cont">
            <div className="loader">
              <div className="circle"></div>
              <div className="circle"></div>
              <div className="circle"></div>
              <div className="circle"></div>
            </div>
          </div>
        ) : (
          <>
            <div className="list-container-mob-tab">{displayWineries}</div>
            <div
              className="list-Container"
              style={
                displayIndex === 0
                  ? { gridTemplateColumns: "repeat(3, 1fr)" }
                  : displayIndex === 1
                  ? { gridTemplateColumns: "repeat(2, 1fr)" }
                  : displayIndex === 2
                  ? { gridTemplateColumns: "repeat(4, 1fr)" }
                  : null
              }
            >
              {displayWineries}
            </div>
            <motion.div
              variants={variants}
              initial={variants.initial}
              whileInView={variants.whileInView}
              transition={{
                ease: "easeOut",
                duration: 1,
                delay: 0.5,
                type: "spring",
              }}
              className="error-handler-contianer"
            >
              {error ? (
                <Alert style={{ textAlign: "center" }} severity="error">
                  {error}
                </Alert>
              ) : null}
            </motion.div>
            <ReactPaginate
              key={pageNumber}
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName="pagination-container"
              previousLinkClassName="pagination-prev"
              nextLinkClassName="pagination-next"
              disabledLinkClassName="pagination-disable"
              activeClassName="pagination-active"
              initialPage={pageNumber}
            />
          </>
        )}
      </div>
      {/* </div> */}
    </div>
  );
}
