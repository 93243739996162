import React from "react";
import { FaStar } from "react-icons/fa";

export default function Stars({ star }) {
  return (
    <div style={{ display: "flex" }}>
      {[...Array(star)].map((star, index) => {
        return <FaStar key={index} style={{ size: 20, color: "#F6BE59" }} />;
      })}
    </div>
  );
}
