import React, { useContext, useState } from "react";
import { context } from "./Root";
import personalInfoIcon from "../images/profilePage/personalInfoIcon.svg";
import editInfoIcon from "../images/profilePage/PencilSimpleLine.svg";
import visitorIcon from "../images/profilePage/visitors.svg";
import { Modal } from "@mui/material";
import closeBtn from "../images/profilePage/close-btn.svg";
import api from "../Api/getData";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export default function WineryProfilePage() {
  const [showProfileHideVisitors, setShowProfileHideVisitors] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [passInput, setPassInput] = useState("");
  const [passRecoveryInput, setPassRecoveryInput] = useState("");
  const [invalidFullName, setInvalitFullName] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPhoneNumber, setInvalPhoneNumber] = useState(false);
  const [invalidBirthday, setInvalBirthday] = useState(false);
  const [profileEdit, setProfileEdit] = useState({
    full_name: "Togonidze winery",
    email: "winery@gmail.com",
    phone: "+995599777777",
    location: "Telavi, Kakheti region",
  });

  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");

  const [openDeleteAcc, setOpenDeleteAcc] = useState(false);
  const handleClose = () => setOpenDeleteAcc(false);

  console.log(invalidBirthday);
  console.log(invalidFullName);
  console.log(invalidPhoneNumber);
  console.log(invalidEmail);

  const visitors = [
    {
      id: 1,
      fullName: "Wesley Mun",
      email: "Wesleymun@gmail.com",
      phone: "+995 599 555 222",
      country: "Canada",
    },
    {
      id: 2,
      fullName: "Anna Hepbern",
      email: "Wesleymun@gmail.com",
      phone: "+995 599 555 222",
      country: "France",
    },
    {
      id: 3,
      fullName: "Peter Lutz",
      email: "Wesleymun@gmail.com",
      phone: "+995 599 555 222",
      country: "Canada",
    },
  ];

  const changePassSubmit = (e) => {
    e.preventDefault();
    console.log("pass change");
  };

  const handleInputChange = (e) => {
    setInvalitFullName(false);
    setInvalidEmail(false);
    setInvalPhoneNumber(false);
    setInvalBirthday(false);
    const { name, value } = e.target;
    const valueWithoutExtraSpaces = value.replace(/\s+/g, " ");

    setProfileEdit((prevProfile) => ({
      ...prevProfile,
      [name]: valueWithoutExtraSpaces,
    }));
  };

  const handleDelete = async () => {
    try {
      await api.delete("/user/delete-user", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      Cookies.remove("userLogin");
      popUpContext.setUserLogin(false);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async () => {
    // API calls
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const phoneRegex = /^\+995\d{9}$/;
    const phoneRegex = /^.{5,}$/;
    const fullnameRegex = /^.{5,}$/;
    const birthdayRegex = /^.{2,}$/;

    if (!fullnameRegex.test(profileEdit.full_name)) {
      setInvalitFullName(true);
    } else if (!emailRegex.test(profileEdit.email)) {
      setInvalidEmail(true);
    } else if (!phoneRegex.test(profileEdit.phone)) {
      setInvalPhoneNumber(true);
    } else if (!birthdayRegex.test(profileEdit.birthday)) {
      setInvalBirthday(true);
    } else {
      try {
        setProfileEdit((prevProfile) => ({
          ...prevProfile,
        }));
      } catch (error) {
        console.log(error.message);
      }
      setEditInfo(false);
    }
  };
  const langContext = useContext(context);
  const popUpContext = useContext(context);
  return (
    <>
      <main
        style={popUpContext.showPopUp ? { filter: "brightness(0.5)" } : null}
        className="profile-page"
      >
        <div className="profile-page-main-titles">
          <h1
            onClick={() => setShowProfileHideVisitors(true)}
            className="profile-title"
            style={{
              ...(showProfileHideVisitors
                ? { color: "#3A3D43", borderBottom: "2px solid #B44D2D" }
                : { color: "rgba(58, 61, 67, 0.4)", borderBottom: "none" }),
              ...(langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }),
            }}
          >
            {langContext.lang === "EN" ? "PROFILE" : "პროფილი"}
          </h1>
          <h2
            onClick={() => setShowProfileHideVisitors(false)}
            className="wine-pass-title"
            style={{
              ...(!showProfileHideVisitors
                ? { color: "#3A3D43", borderBottom: "2px solid #B44D2D" }
                : { color: "rgba(58, 61, 67, 0.4)", borderBottom: "none" }),
              ...(langContext.lang !== "EN"
                ? { fontFamily: "BPG Nino Mtavruli" }
                : { fontFamily: "Montserrat" }),
            }}
          >
            {langContext.lang === "EN" ? "VISITORS" : "ვიზიტორები"}
          </h2>
        </div>
        <div style={{ borderBottom: "1px solid #C0C0C0", width: "100%" }}></div>
        {showProfileHideVisitors && (
          <>
            <section className="profile-page-container">
              <div className="personal-info-container">
                <div className="personal-info-head">
                  <div className="pers-info-wrapper">
                    <img
                      style={{ userSelect: "none" }}
                      src={personalInfoIcon}
                      alt="personal-info-icon"
                    />
                    <h5
                      style={
                        langContext.lang !== "EN"
                          ? { fontFamily: "BPG Nino Mtavruli" }
                          : { fontFamily: "Montserrat" }
                      }
                    >
                      {langContext.lang === "EN"
                        ? "PERSONAL INFO"
                        : "პერსონალური ინფორმაცია"}
                    </h5>
                  </div>
                  <img
                    src={editInfoIcon}
                    alt="editInfo"
                    style={{ cursor: "pointer", userSelect: "none" }}
                    onClick={() => setEditInfo(!editInfo)}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "rgba(41, 44, 49, 0.2)",
                    marginBottom: "25px",
                  }}
                ></div>

                {!editInfo && (
                  <>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN"
                          ? "Full name"
                          : "სრული სახელი"}
                      </span>
                      <span
                        className="prof-info-value"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {profileEdit.full_name}
                      </span>
                    </div>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN" ? "E-mail" : "ელფოსტა"}
                      </span>
                      <span
                        className="prof-info-value"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {profileEdit.email}
                      </span>
                    </div>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN" ? "Phone" : "ტელეფონი"}
                      </span>
                      <span
                        className="prof-info-value"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {profileEdit.phone}
                      </span>
                    </div>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN" ? "Location" : "ლოკაცია"}
                      </span>
                      <span
                        className="prof-info-value"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {profileEdit.location}
                      </span>
                    </div>
                  </>
                )}
                {editInfo && (
                  <div>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN"
                          ? "Full name"
                          : "სრული სახელი"}
                      </span>
                      <input
                        className="edit-info-data"
                        type="text"
                        name="full_name"
                        value={profileEdit.full_name}
                        onChange={handleInputChange}
                        placeholder="Enter your fullname"
                        // style={invalidFullName ? { borderColor: "red" } : null}
                      />
                    </div>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN" ? "E-mail" : "ელფოსტა"}
                      </span>
                      <input
                        readOnly
                        className="edit-info-data"
                        type="text"
                        name="email"
                        value={profileEdit.email}
                        onChange={handleInputChange}
                        style={{ opacity: "0.5" }}
                        placeholder="Enter your e-mail"
                      />
                    </div>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN" ? "Phone" : "ტელეფონი"}
                      </span>
                      <input
                        className="edit-info-data"
                        type="text"
                        name="phone"
                        value={profileEdit.phone}
                        onChange={handleInputChange}
                        // style={invalidPhoneNumber ? { borderColor: "red" } : null}
                        placeholder="Enter your phone"
                      />
                    </div>
                    <div className="prof-info-wrapper">
                      <span
                        className="prof-info-prop"
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Poppins" }
                        }
                      >
                        {langContext.lang === "EN" ? "Country" : "ქვეყანა"}
                      </span>
                      <input
                        readOnly
                        className="edit-info-data"
                        name="country"
                        value={"თბილისი"}
                        onChange={handleInputChange}
                        // style={invalidBirthday ? { borderColor: "red" } : null}
                        style={{ opacity: "0.5" }}
                        type="text"
                        placeholder="Enter your country"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        className="edit-btn"
                        onClick={handleSave}
                        style={
                          langContext.lang !== "EN"
                            ? { fontFamily: "FiraGo" }
                            : { fontFamily: "Montserrat" }
                        }
                      >
                        {langContext.lang === "EN" ? "SAVE" : "შენახვა"}
                      </button>
                    </div>
                    {/* {invalidFullName && (
                  <Alert style={{ marginTop: "10px" }} severity="error">
                    {langContext.lang === "EN"
                      ? " Invalid fullname format. Please enter fullname min 5 characters."
                      : "არასწორი სრული სახელის ფორმატია.გთხოვთ შეიყვანოთ სრული სახელი მინიმუმ 5 ასობგერით"}
                  </Alert>
                )}
                {invalidEmail && (
                  <Alert style={{ marginTop: "10px" }} severity="error">
                    {langContext.lang === "EN"
                      ? "Invalid email format. Please enter a valid email address."
                      : "არასწორია ელფოსტის ფორმატი.გთხოვთ შეიყვანოთ ვალიდური ელფოსტის მისამართი"}{" "}
                  </Alert>
                )}
                {invalidPhoneNumber && (
                  <Alert style={{ marginTop: "10px" }} severity="error">
                    {langContext.lang === "EN"
                      ? " Invalid phone format. Please enter valid phone number (+995 *** ** ** **)"
                      : "არასწორია ნომრის ფორმატი.გთხოვთ შეიყვანოთ ვალიდური ნომერი (+995 *** ** ** **)"}
                  </Alert>
                )}
                {invalidBirthday && (
                  <Alert style={{ marginTop: "10px" }} severity="error">
                    {langContext.lang === "EN"
                      ? "Invalid birthday format. Please enter valid birthday (dd/mm/yyyy)"
                      : "არასწორია დაბადების დღის ფორმათი.გთხოვთ შეიყვანოთ ვალიდური დაბადების დღის ფორმატი (dd/mm/yyyy)"}
                  </Alert>
                )} */}
                  </div>
                )}
              </div>
              <div className="visitor-point-container">
                <div className="visitorIcon-Text">
                  <img src={visitorIcon} alt="visitorIcon" />
                  <span>VISITORS</span>
                </div>
                <div className="visitor-line"></div>
                <p className="visitor-this-year">
                  You had <span>20</span> visitors this year
                </p>
                <span
                  onClick={() => setShowProfileHideVisitors(false)}
                  className="total-visitors-span"
                >
                  Total visitors
                </span>
              </div>
              <div className="change-pass-container">
                <span
                  style={
                    langContext.lang !== "EN"
                      ? { fontFamily: "FiraGo" }
                      : { fontFamily: "Montserrat" }
                  }
                >
                  {langContext.lang === "EN"
                    ? "CHANGE PASSWORD"
                    : "პაროლის შეცვლა"}
                </span>
                <p
                  style={
                    langContext.lang !== "EN"
                      ? { fontFamily: "FiraGo" }
                      : { fontFamily: "Inter" }
                  }
                >
                  {langContext.lang === "EN"
                    ? "To change your password, please input current and new password."
                    : "პაროლის შესაცვლელად,გთხოვთ შეიყვანოთ მიმდინარე და ახალი პაროლი"}
                </p>
                <form
                  style={{ display: "flex", flexDirection: "column" }}
                  onSubmit={changePassSubmit}
                >
                  <input
                    value={passInput}
                    onChange={(e) => {
                      setPassInput(e.target.value);
                      // setPassIsInvalid(false);
                      // setPasswordsNotMatches(false);
                      // setAllRigth(false);
                    }}
                    type="password"
                    style={
                      langContext.lang !== "EN"
                        ? { fontFamily: "FiraGo" }
                        : { fontFamily: "Inter" }
                    }
                    placeholder={
                      langContext.lang === "EN"
                        ? "Current password"
                        : "მიმდინარე პაროლი"
                    }
                  />

                  <input
                    value={passRecoveryInput}
                    type="password"
                    style={
                      langContext.lang !== "EN"
                        ? { fontFamily: "FiraGo" }
                        : { fontFamily: "Inter" }
                    }
                    onChange={(e) => {
                      setPassRecoveryInput(e.target.value);
                      // setPassIsInvalid(false);
                      // setPasswordsNotMatches(false);
                      // setAllRigth(false);
                    }}
                    placeholder={
                      langContext.lang === "EN"
                        ? "New password"
                        : "ახალი პაროლი"
                    }
                  />
                  {/* {passIsInvalid && (
                <Alert severity="error" style={{ marginBottom: "10px" }}>
                  {langContext.lang === "EN"
                    ? "Password must be at least 8 characters including a number"
                    : "პაროლი უნდა შეიცავდეს მინიმუმ 8 სიმბოლოს ციფრების ჩათვლით"}
                </Alert>
              )} */}
                  {/* {passwordsNotMatches && (
                <Alert severity="error" style={{ marginBottom: "10px" }}>
                  Passwords must match
                </Alert>
              )} */}
                  {/* {allRigth && (
                <Alert style={{ marginBottom: "10px" }} severity="success">
                  {langContext.lang === "EN"
                    ? "Password change successfully"
                    : "პაროლი შეიცვალა წარმატებით"}
                </Alert>
              )} */}

                  <button
                    type="submit"
                    style={
                      langContext.lang !== "EN"
                        ? { fontFamily: "FiraGo" }
                        : { fontFamily: "Montserrat" }
                    }
                  >
                    {langContext.lang === "EN" ? "SAVE" : "შენახვა"}
                  </button>
                </form>
              </div>
              <div
                className="visitor-point-container"
                style={{ marginTop: "30px" }}
              >
                <span className="scan-qr-code-text">Scan QR Code</span>
                <div className="visitor-line"></div>
                <p className="qr-text-for-app">Open App for scanning</p>
              </div>
            </section>
            <div className="delete-account-container">
              <h5>Delete Account</h5>
              <p>
                If you no longer wish to use Findwine application, you can
                permanently.
                <span onClick={() => setOpenDeleteAcc(true)}>delete</span> your
                account.
              </p>
            </div>
          </>
        )}
        {!showProfileHideVisitors && (
          <div className="winery-profile-wine-pass-main-container">
            <table>
              <tr
                style={{
                  borderBottom: "1px solid rgba(41, 44, 49, 0.2)",
                  paddingBottom: "20px",
                }}
              >
                <th>Full name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Country</th>
              </tr>
              {visitors.map((visitor) => {
                return (
                  <tr style={{ marginTop: "20px" }} key={visitor.id}>
                    <td>{visitor.fullName}</td>
                    <td>{visitor.email}</td>
                    <td>{visitor.phone}</td>
                    <td>{visitor.country}</td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}
      </main>
      <Modal open={openDeleteAcc} onClose={handleClose}>
        <div className="delete-account-modal-container">
          <div className="delete-account-wrapper">
            <img
              className="delete-acc-closeBtn"
              onClick={() => setOpenDeleteAcc(false)}
              src={closeBtn}
              alt="close-btn"
            />
            <h4>ARE YOU SURE?</h4>
            <p>
              By clicking delete all your information will be permanently
              deleted and you will no longer have access to your account.
            </p>
            <div className="delete-account-btns">
              <button
                onClick={() => handleDelete()}
                className="delete-acc-deleteBtn"
              >
                DELETE
              </button>
              <button
                className="delete-acc-cancelBtn"
                onClick={() => setOpenDeleteAcc(false)}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
