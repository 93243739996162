import React, { useContext } from "react";
import "./styles/WineriesDetailBg.css";
import { context } from "./Root";

export default function WineriesDetailBg({ wineryDetailInfo, showReviewForm }) {
  const langContext = useContext(context);

  return (
    <>
      <div
        className="wineries-detail-bg"
        style={showReviewForm ? { filter: "brightness(0.5)" } : null}
      >
        <div className="text-container">
          <h2
            style={
              langContext.lang === "EN"
                ? { fontFamily: "Montserrat" }
                : { fontFamily: "BPG Nino Mtavruli" }
            }
          >
            {wineryDetailInfo !== undefined && wineryDetailInfo}
          </h2>
        </div>
      </div>
      <div className="wineries-detail-bg-mob">
        <div className="text-container">
          <h2
            style={
              langContext.lang === "EN"
                ? { fontFamily: "Montserrat" }
                : { fontFamily: "BPG Nino Mtavruli" }
            }
          >
            {wineryDetailInfo !== undefined && wineryDetailInfo}
          </h2>
        </div>
      </div>
    </>
  );
}
