import React, { useContext, useEffect, useState } from "react";
import "../components/styles/SignIn.css";
// import fbIcon from "../images/logos_facebook.svg";
// import googleIcon from "../images/Google.svg";
import PopUpInput from "./PopUpInput";
import { Link } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { registrationtionSchema } from "../schemas";
import { context } from "./Root";
import { Alert } from "@mui/material";
import api from "../Api/getData";
import ReCAPTCHA from "react-google-recaptcha";

export default function Registration() {
  const signInFormContext = useContext(context);
  const langContext = useContext(context);
  const [allRigth, setAllRigth] = useState(false);
  const [recaptchaChecked, setRecaptchaChecked] = useState(false);
  const [recaptchaMessage, setRecaptchaMessage] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [fetchCountryError, setFetchCountryError] = useState(false);
  const [emailError, setEmailError] = useState("");

  console.log(emailError);
  const onChange = () => {
    setRecaptchaChecked(true);
  };

  const fetchOptionalCountry = async () => {
    try {
      const res = await api.get(`countries`);
      setCountryData(res.data);
    } catch (error) {
      setFetchCountryError(error.message);
    }
  };
  useEffect(() => {
    fetchOptionalCountry();
  }, []);

  // const onSubmit = async (values, actions) => {
  //   await new Promise((resolve) => setTimeout(resolve, 1000));
  //   try {
  //     // if (!recaptchaChecked) {
  //     //   setRecaptchaMessage(true);
  //     // } else {
  //     //   await api.post(`register`, values);
  //     //   setRecaptchaMessage(false);
  //     //   actions.resetForm();
  //     //   setAllRigth(true);
  //     // }

  //     console.log(values);
  //     // await axios.post("http://188.129.222.217:8000/api/register", values);
  //     // console.log(values);
  //     // Reset the form after successful submission

  //     // const signInTimer = setTimeout(() => {
  //     //   signInFormContext.setShowPopUp(false);
  //     // }, 2000);
  //     // signInTimer();

  //     // return () => clearInterval(signInTimer);
  //   } catch (error) {
  //     // console.log(error.message);
  //   }
  // };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      if (!recaptchaChecked) {
        setRecaptchaMessage(true);
      } else {
        await api.post(`register`, values);
        setRecaptchaMessage(false);
        resetForm(); // Reset the form
        setAllRigth(true);
      }
    } catch (error) {
      setEmailError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        fullName: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
        country: 80,
      }}
      validationSchema={registrationtionSchema}
      onSubmit={onSubmit}
    >
      <>
        <h2>{langContext.lang === "EN" ? "SIGN UP" : "რეგისტრაცია"}</h2>

        <Form className="login-form">
          {!allRigth && (
            <>
              <PopUpInput
                name="fullName"
                type="text"
                placeholder={langContext.lang === "EN" ? "Full Name" : "სახელი"}
              />
              <PopUpInput
                name="email"
                type="text"
                placeholder={langContext.lang === "EN" ? "Email" : "ელფოსტა"}
              />

              {/* <PopUpInput
                  name="country"
                  type="text"
                  placeholder={
                    langContext.lang === "EN" ? "Country" : "ქვეყანა"
                  }
                /> */}
              {/* <label htmlFor="country">Country:</label> */}
              {/* <select
                  className="country-select"
                  id="country"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  name="country"
                >
                  {countryData.map((country, index) => (
                    <option key={index} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select> */}
              {/* <label
                style={{ marginBottom: "5px", opacity: "0.5" }}
                htmlFor="country"
              >
                Country
              </label> */}
              <Field
                className="country-select"
                as="select"
                id="country"
                name="country"
              >
                {countryData.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Field>
              {fetchCountryError && <p>{fetchCountryError}</p>}
              <PopUpInput
                name="phone"
                type="text"
                placeholder={langContext.lang === "EN" ? "Mob" : "ტელ-ნომერი"}
              />
              <PopUpInput
                name="password"
                type="password"
                placeholder={langContext.lang === "EN" ? "Password" : "პაროლი"}
              />
              <PopUpInput
                name="password_confirmation"
                type="password"
                placeholder={
                  langContext.lang === "EN"
                    ? "Confirm Password"
                    : "გაიმეორე პაროლი"
                }
              />
              <div>
                <ReCAPTCHA
                  sitekey="6LcyJ4AnAAAAAFyYY0DSbnSfVJYlFxDVtrzB6yHk"
                  onChange={onChange}
                />
                {recaptchaMessage && <p>Please checked recaptcha</p>}
              </div>
            </>
          )}
          {emailError !== "" && (
            <Alert
              style={{ textAlign: "center", marginTop: "10px" }}
              severity="error"
            >
              The email has already been taken{" "}
            </Alert>
          )}
          {allRigth && (
            <Alert severity="success">
              {langContext.lang === "EN"
                ? "Your account has been successfully created,Please verify your email"
                : "თქვენი ექაუნთი შექმნილია.გთხოვთ,გაიაროთ ვერიფიკაცია მეილით"}
            </Alert>
          )}
          <div className="btn-cont">
            {allRigth ? (
              <button
                className="submit-button"
                onClick={() => signInFormContext.setShowPopUp(false)}
              >
                OK
              </button>
            ) : (
              <button className="submit-button" type="submit">
                {langContext.lang === "EN" ? "Register" : "რეგისტრაცია"}
              </button>
            )}

            {/* <h5 className="registration-with-social">Or sign up with</h5>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "31px",
                  marginBottom: "47px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginRight: "27px",
                  }}
                >
                  <div className="social-bg">
                    <img
                      className="social-icons"
                      src={googleIcon}
                      alt="GoogleIcon"
                    />
                  </div>
                  <span className="social-title">Google</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="social-bg">
                    <img className="social-icons" src={fbIcon} alt="fbIcon" />
                  </div>
                  <span className="social-title">Facebook</span>
                </div>
              </div> */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>
                {langContext.lang === "EN"
                  ? "Have account yet?"
                  : "უკვე გაქვს ექაუნთი?"}
              </span>
              <Link
                onClick={() => {
                  signInFormContext.setView("login");
                }}
                className="registration-sign-in-btn"
              >
                {langContext.lang === "EN" ? "Sign in" : "ავტორიზაცია"}
              </Link>
            </div>
          </div>
        </Form>
      </>
    </Formik>
  );
}
